import React, { useState } from 'react';
import GoogleIcon from "../../../assets/icons/google-icon.svg"
import { useGoogleLogin } from '@react-oauth/google';

export default function GoogleAuthButton(props) {
    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
          props.action(tokenResponse.access_token)
        },
        onError: errorResponse => console.log(errorResponse),
    });
  return (
    <button
        className="btn btn-primary loginv2-button-login"
        onClick={() => googleLogin()}
    >
      <img alt="" src={GoogleIcon} style={{ width: "16px", height: "16px", marginRight: 8 }} />
      <span className='fs-10'>Lanjutkan dengan Google</span>
    </button>
  );
}