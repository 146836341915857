import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import {Empty, Modal, Spin, Tooltip} from 'antd';
import {
    Button,
    ButtonGroup, Card, CardBody,
    Col, FormGroup, Label, Modal as ModalRs,
    Modal as ModalRS,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Paging from "../../components/paging";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import moment from "moment";
import format from "format-number";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import importIcon from "../../assets/icons/import-icon-blue-light.svg";
import './style.css';
import "./css/slipGaji.css";
import html2canvas from "html2canvas";
import pdfMake from 'pdfmake';
// edit Modal
import Edit from "./edit-modal";
import presensiHeaderModalBG from "../../assets/images/modal_header_bg.png";
import presensiWhiteLogo from "../../assets/images/presensi_white_logo.png";
import {BACKEND_API_HOST, DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import Potongan from "./edit-modal/modalPotongan"
import { toast } from "react-toastify";
import TimeInput from "../../helper/time_input";
import UpdateFiturModal from "../common/update_fitur_modal";
import dataVersion from "../../assets/json/version.json";
import dataPayrollVersion from "../../assets/json/payrollversion.json";
import MoreIcon from "../../assets/icons/more.svg";
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";

export class List_payroll extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("01 02 03 04 05 06 07 08 09 10 11 12");
        namabulan = namabulan.split(" ");
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        var bulan = lastDay.getMonth();
        var tahun = lastDay.getFullYear();
        this.state = {
            loading: true,
            datas: [],
            payroll: [],
            idDel: '',
            filter_nama: "",
            status: "",
            year: tahun,
            month: namabulan[bulan],
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 25,
            guide: false,
            modalSlip: false,
            modalEdit: false,
            modalClockout: false,
            modalAdd: false,
            visiblePot: false,
            data_payroll: [],
            dataSlip: [],
            kompensasi: [],
            karyawan: [],
            gaji: [],
            penghasilan: [],
            perusahaan: [],
            payroll_id: '',
            karyawan_nama: '',
            karyawan_id: '',
            saveAll: false,
            potongan: [],
            rows: [{}],
            data_term_potongan: [],
            data_no_clockout: [],
            data_clockout_edit: [],
            kompensasiDisableUpdata: false,
            potonganDisableUpdata: false,
            exportStartDate: "",
            exportEndDate: "",
            filterExport : "",
            modalExport: false,
            reactDateRange: false,
            valueDate: "",
            start_date: new Date(),
            end_date: new Date(),
            updateGaji: false,
            divisi: [],
            count: null,
            updateFiturModal: true,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.loadSlip = this.loadSlip.bind(this)
        this.checkPotongan = this.checkPotongan.bind(this)
        this.checkKompensasi = this.checkKompensasi.bind(this)
        this.handleChangeStock = this.handleChangeStock.bind(this)
        this.handleSubmitPotongan = this.handleSubmitPotongan.bind(this)
        this.exportPayroll = this.exportPayroll.bind(this)
        this.searchKaryawan = this.searchKaryawan.bind(this)
        this.loadUpdateGaji = this.loadUpdateGaji.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChangeStock = id => e => {
        const { name, value } = e.target;
        const list = [...this.state.rows];

        list[id] = { ...list[id], [name]: value };
        this.setState({
            rows: list,
        })
    };

    potonganModal = () => {
        this.setState({
            visiblePot: !this.state.visiblePot
        })
    };

    checkPotongan() {
        this.state.datas.map((data) => {
            if (data != null && data.potongan) {
                if (data.potongan.length == 0) {
                    this.setState({
                        potonganDisableUpdata: true
                    })
                }
            }
        }
        );
    }

    checkKompensasi() {
        this.state.datas.map((data) => {
            if (data != null) {
                if (data.kompensasi.length == 0) {
                    this.setState({
                        kompensasiDisableUpdata: true
                    })
                }
            }
        }
        );
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, datas: [] }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleAdd(event) {
        let formData = {
            month: this.state.month,
            year: this.state.year,
            karyawan_id: this.state.karyawan_id,
            rows: this.state.rows
        }
        this.props.request('payroll/add_payroll_manual', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        timer: 1500,
                        showConfirmButton: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                isLoading: false,
                                modalAdd: false,
                                rows: [{}]
                            }, () => this.loadData())
                        } else {
                            this.setState({
                                isLoading: false,
                                modalAdd: false,
                                rows: [{}]
                            }, () => this.loadData())
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ isLoading: false })
            })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            datas: [],
            loading: true
        }, () => this.loadData())
    }

    handleChangeType = id => e => {
        const { name, value } = e.target;
        const list = [...this.state.rows];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            rows: list,
        })
    };

    searchKaryawan(){
        let form = {
            karyawan_name : this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response)=> {
                if (response.success){
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }

    handleChangeRows(value, name, id) {
        var list = [...this.state.rows];
        if (typeof value === 'string' || typeof value === 'number') {
            parseFloat(value)
            value = /^\d{2,}/.test(value) ? value.replace(/^0+/, '') : value
            var str = value.split('Rp ').join('');
            var final = str.split('.').join('');

            list[id] = { ...list[id], [name]: final };
        } else {
            list[id] = { ...list[id], [name]: 0 };
        }

        this.setState({
            rows: list,
        }, () => this.countTotalRow(id))
    }

    countTotalRow = (id) => {
        var list = [...this.state.rows];

        var data = this.state.rows[id]
        var test = data.payroll_amount

        var jumlah = data.payroll_waktu_kerja === undefined ? 0 : data.payroll_waktu_kerja.toString().split(',').join('.')
        var nominal = data.payroll_amount === undefined ? 0 : data.payroll_amount.toString().split(',').join('.')

        var total = eval(jumlah) * eval(nominal)

        list[id] = { ...list[id], payroll_total: total };

        this.setState({
            rows: list,
        })
    }

    resetFilter(event) {
        var namabulan = ("01 02 03 04 05 06 07 08 09 10 11 12");
        namabulan = namabulan.split(" ");
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        var bulan = lastDay.getMonth();
        var tahun = lastDay.getFullYear();
        this.setState({
            filter_nama: "",
            year: tahun,
            month: namabulan[bulan],
            status: "",
            datas: [],
            loading: true
        }, () => this.loadData())
    }
    exportTemplatePayroll = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'karyawan/export_template?token=' + AuthKey, "_blank")
    }

    printDocumentFour = () => {
        var name = this.state.karyawan.karyawan_nama;
        var periode = this.state.dataSlip.payroll_date;
        var id = this.state.karyawan.karyawan_id;
        html2canvas(document.getElementById("content-list")).then(canvas => {
            var data = canvas.toDataURL();
            var pdfExportSetting = {
                content: [
                    {
                        image: data,
                        width: 550
                    }
                ]
            };
            pdfMake.createPdf(pdfExportSetting).download(name + '-' + periode + '-' + id + '.pdf');
        });

        this.setState({
            modalSlip: false
        })
    };

    loadUpdateGaji() {
        let form = {
            month: this.state.month,
            year: this.state.year
        }
        this.props.request("payroll/updateGajiMonth", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        datas: response.data,
                        loading: false,
                    }, () => this.loadData() )
                }
            });
    }

    updatePayroll() {
        this.setState({
            loading: true,
            datas: []
        })
        let formData = {
            month: this.state.month,
            year: this.state.year,
        }
        this.props.request('payroll/add_payroll_v2', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    this.loadData()
                } else if (response.checkout) {
                    Swal.fire({
                        title: 'Pemberitahuan!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'Atur Otomatis',
                        showCancelButton: true,
                        cancelButtonText: 'Atur Manual',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    })
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.setState({
                                    loading: true,
                                    datas: []
                                })
                                let form = {
                                    month: this.state.month,
                                    year: this.state.year,
                                }
                                this.props.request("presensi/set-clockout", form, 'POST')
                                    .then(response => {
                                        if (response.success) {
                                            Swal.fire({
                                                title: 'Berhasil!',
                                                text: response.message,
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timer: 800,
                                            })
                                            this.setState({
                                                loading: true,
                                                datas: []
                                            })
                                            this.updatePayroll()
                                        }
                                    });
                            }
                            else {
                                this.props.history.push('/presensi')
                            }
                        })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 5000,
                    })
                }
            })
    }

    loadSlip() {
        let form = {
            id: this.state.id
        }
        this.props.request("payroll/slip_gaji", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataSlip: response.data,
                        kompensasi: response.data.kompensasi,
                        potongan: response.data.potongan,
                        karyawan: response.data.karyawan,
                        gaji: response.gaji,
                        penghasilan: response.penghasilan,
                        perusahaan: response.data.perusahaan,
                        modalSlip: true
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    loadData() {
        let form = {
            paging: this.state.paging,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
            month: this.state.month,
            year: this.state.year,
            status: this.state.status
        }
        this.props.request("payroll/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        datas: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    }, () => this.checkPotongan(), () => this.checkKompensasi())
                }
            });

    }

    handleRowsPerPageChange = (newRowsPerPage) => {
        this.setState({ paging: newRowsPerPage }, () => {
            this.loadData();
        });
    };

    checkSlug(karyawan) {
        if(karyawan.karyawan_slug != null){
            this.props.history.push('/karyawan/profil/detail/'+ karyawan.karyawan_slug)
            return;
        }
        let form = {
            id_karyawan : karyawan.karyawan_id,
        };
        this.props.request("karyawan/generate-slug", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.history.push('/karyawan/profil/detail/'+ response.karyawan_slug)
                    this.setState({
                        loading: false,
                    })
                }
            });
    }

    deletePayroll() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus data payroll ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        datas: [],
                        loading: true,
                    })
                    let form = {
                        id: this.state.idDel,
                    }
                    this.props.request("payroll/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                }
                                )
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                            }
                            this.loadData()
                        });
                }
            })


    }

    handlePending() {
        Swal.fire({
            title: 'Mohon Menunggu',
            text: 'Memuat Data Absen...',
            icon: 'info',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        })
        let form = {
            month: this.state.month,
            year: this.state.year,
        }
        this.props.request("presensi/get-no-clockout", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.close();
                    this.setState({
                        data_no_clockout: response.data,
                        modalClockout: true,
                        loading: false,
                    })
                }
            });
    }

    handleStatusFinish() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menyelesaikan payroll ini?',
            icon: 'warning',
            confirmButtonText: 'Selesaikan',
            cancelButtonText: 'Batal',
            showConfirmButton: true,
            showCancelButton: true
        })
            .then((result) => {
                if (result.isConfirmed){
                    let form = {
                        payroll_id: this.state.payroll_id
                    }
                    this.setState({
                        loading: false
                    })
                    this.props.request('payroll/ubah_status_finish', form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                            }
                        });
                }
            })
    }

    handleClockoutTime(id_absensi, jam_pulang_absen) {
        const index = this.state.data_clockout_edit.findIndex(item => item.id_absensi === id_absensi);

        let data_clockout_edit = [...this.state.data_clockout_edit];

        if (index !== -1) {
            data_clockout_edit[index].jam_pulang_absen = jam_pulang_absen;
        } else {
            data_clockout_edit.push({id_absensi: id_absensi, jam_pulang_absen: jam_pulang_absen});
        }

        this.setState({ data_clockout_edit: data_clockout_edit });
    }

    setClockoutTime(){
        let form = {
            absen: this.state.data_clockout_edit,
        }
        this.props.request("presensi/bulk-change-clockout", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.setState({
                        loading: true,
                        modalClockout: false,
                        datas: []
                    })
                    this.loadUpdateGaji()
                }
            });
    }

    handlePotonganType = id => (e, maskedvalue) => {
        var { name, value } = e.target;
        var list = [...this.state.potongan];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            potongan: list,
        })
    };

    handlePotonganAmount = id => (e, maskedvalue) => {
        var { name, value } = e.target;
        var list = [...this.state.potongan];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };
        this.setState({
            potongan: list,
        })
    };

    handleSubmitPotongan(id) {
        let formData = {
            data: this.state.potongan,
            id: id,
        }
        this.props.request('karyawan_potongan/update', formData, 'POST')
            .then(response => {
                if (response.success) {
                    toast['success'](response.message, { toastId: Math.floor(Math.random() * 2) })
                    this.setState({
                        loading: true,
                        modalEdit: true,
                    })

                }
            });
    }

    bulkFinishDone() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menyelesaikan Seluruh Payroll Done sesuai Filter saat ini?',
            icon: 'warning',
            confirmButtonText: 'Selesaikan',
            cancelButtonText: 'Batal',
            showConfirmButton: true,
            showCancelButton: true
        })
            .then((result) => {
                if (result.isConfirmed){
                    this.setState({
                        loading: true,
                    })
                    let form = {
                        month: this.state.month,
                        year: this.state.year,
                    }
                    this.props.request("payroll/bulk-finish-payroll", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                this.loadData()
                            }
                        });
                }
            })
    }

    exportPayroll() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'payroll/export?token=' + AuthKey + '&month=' + this.state.month + '&year=' + this.state.year + "&status=" + this.state.status, "_blank")
    }

    checkUpdateFiturBanner(){
        const payrollVs = localStorage.getItem("payVs");
        this.setState({
            updateFiturModal: true,
            updateFiturText: dataPayrollVersion[dataVersion["payrollversion"]]
        })
        if(payrollVs){
            if(parseFloat(payrollVs) >= parseFloat(dataVersion["payrollversion"])){
                this.setState({
                    updateFiturModal: false,
                })
            }
        }
    }

    componentDidMount() {
        this.loadData()
        this.checkUpdateFiturBanner()
    }

    render() {
        let no = 0;
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Daftar Payroll") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Laporan Daftar Payroll - Presensi</title>
                        <meta name="description" content="Detail Daftar Payroll" />
                    </Helmet>
                    <Breadcrumb
                        title="Laporan Daftar Payroll"
                        parent="Daftar Payroll"
                        setGuide={() => { this.setState({ guide: true }) }}
                        showUpdateFiturBanner={() => {
                            this.setState({
                                updateFiturModal: true,
                                updateFiturText: dataPayrollVersion[dataVersion["payrollversion"]]
                            })
                        }}
                    />
                    <Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <UpdateFiturModal
                        version={dataPayrollVersion}
                        showModal={this.state.updateFiturModal}
                        modalText={this.state.updateFiturText}
                        setState={(val, callback) => this.setState(val, callback)}
                        handleClose={() => {this.setState({updateFiturModal: false})}}
                        handlerCheckBoxChanges={() => {
                            let payrollVs = localStorage.getItem("payVs")
                            if(payrollVs) {
                                if(parseFloat(payrollVs) >= parseFloat(dataVersion["payrollversion"])) {
                                    localStorage.removeItem("payVs");
                                }else {
                                    localStorage.setItem("payVs", dataVersion["payrollversion"])
                                }
                            }else {
                                localStorage.setItem("payVs", dataVersion["payrollversion"])
                            }
                        }}
                    />
                    <div className="container-fluid list-karyawan">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between mx-0">
                                    <h5>Daftar Payroll Karyawan</h5>
                                    <div className="row">
                                        {/*<div className='col-md-4'>*/}
                                        {/*    <button className="mr-2 karyawan-import-button" style={{float: 'right'}} onClick={() => { this.setState({ modalimport: true }) }}><img src={ImportIcon} alt='' /> Import</button>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-1 px-0">*/}
                                        {/*    <button className="button-reset-payroll" onClick={this.loadUpdateGaji}>*/}
                                        {/*        <img src={RefreshIcon} alt=''/>*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}
                                        <div className='col-auto px-2'>
                                            {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                <button className='btn btn-primary px-3 text-capitalize pull-right width-filter'
                                                        onClick={() => {
                                                            this.setState({
                                                                modalAdd: true,
                                                                karyawan_id: 0,
                                                                karyawan_selected: null
                                                            })
                                                        }}
                                                >
                                                    <img src={IconPlusWhite} className='mr-2' alt=""/> Tambah Payroll
                                                </button>
                                                )}
                                        </div>
                                        {this.state.datas.length === 0 ?
                                            <div className='col-auto px-0'>
                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                    <button className="btn btn-primary btn-gen-list-payrol"
                                                            disabled={this.state.potonganDisableUpdata || this.state.kompensasiDisableUpdata || this.state.loading}
                                                            onClick={() => {
                                                                this.updatePayroll()
                                                            }}
                                                    >
                                                        <span className="fa fa-download"></span> Generate Daftar Payroll
                                                    </button>
                                                )}
                                            </div>
                                            :
                                            <div className='col-auto px-0'>
                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                    <button className="btn btn-outline-primary"
                                                            disabled={this.state.potonganDisableUpdata || this.state.kompensasiDisableUpdata || this.state.loading}
                                                            onClick={() => {
                                                                this.updatePayroll()
                                                                this.loadUpdateGaji()
                                                            }}
                                                    >
                                                        <span className="fa fa-refresh"></span> Update Daftar Payroll
                                                    </button>
                                                )}
                                            </div>
                                        }
                                        <div className='col-auto px-2' align="center">
                                            <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                <div className={'p-2'}>
                                                    <a onClick={this.exportPayroll}
                                                       disabled={this.state.potonganDisableUpdata || this.state.kompensasiDisableUpdata || this.state.loading}
                                                       style={{marginRight: "50px"}}
                                                       className={'text-dark'}
                                                    >Export Payroll</a><br/>
                                                    <a disabled={this.state.potonganDisableUpdata || this.state.kompensasiDisableUpdata || this.state.loading}
                                                       onClick={() => {this.bulkFinishDone()}}
                                                       className={'text-dark'}>Selesaikan Seluruh Payroll Done</a>
                                                </div>

                                            }>
                                                <a><i
                                                    style={{
                                                        width: "15",
                                                        fontSize: 18,
                                                        color: 'rgb(51 51 51)',
                                                        alignItems: "center",
                                                        height: "38px",
                                                        paddingRight: "30px"
                                                    }}
                                                    className='btn border border-dark border-1 px-2'
                                                ><img src={MoreIcon}/></i></a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row filter-karyawan" style={{marginLeft: '5px'}}>
                                <div className="col-md-3">
                                    <label style={{fontSize: '10px'}}>Filter Nama:</label><br/>
                                    <input name="filter_nama"
                                        className="form-control"
                                        placeholder="Nama Karyawan..."
                                        type="text"
                                        required=""
                                        value={this.state.filter_nama}
                                        onChange={this.handleChange}
                                        onKeyUp={(event) => {
                                            event.preventDefault()
                                            if (event.which == 13) {

                                                this.setState({
                                                    page: 0,
                                                    paging: this.state.paging,
                                                    loading: true
                                                }, () => {
                                                    this.loadData()
                                                });
                                            }
                                        }} />
                                </div>
                                <div className="col-md-2">
                                    <label style={{ fontSize: '10px' }}>Filter Bulan:</label>
                                    <select name="month" className="form-control" onChange={this.handleFilter} value={this.state.month}>
                                        <option value="01">Januari</option>
                                        <option value="02">Februari</option>
                                        <option value="03">Maret</option>
                                        <option value="04">April</option>
                                        <option value="05">Mei</option>
                                        <option value="06">Juni</option>
                                        <option value="07">Juli</option>
                                        <option value="08">Agustus</option>
                                        <option value="09">September</option>
                                        <option value="10">Oktober</option>
                                        <option value="11">November</option>
                                        <option value="12">Desember</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label style={{ fontSize: '10px' }}>Filter Tahun:</label>
                                    <select name="year" className="form-control" onChange={this.handleFilter} value={this.state.year}>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2027">2027</option>
                                        <option value="2028">2028</option>
                                        <option value="2029">2029</option>
                                        <option value="2030">2030</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label style={{ fontSize: '10px' }}>Filter Status :</label>
                                    <select className="form-control" name="status" onChange={this.handleFilter}>
                                        <option value="" selected={this.state.status === ''}>Pilih Status</option>
                                        <option value="new">New</option>
                                        <option value="pending">Pending</option>
                                        <option value="done">Done</option>
                                        <option value="finish">Finish</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label style={{ fontSize: '10px' }}>Atur Ulang Filter:</label><br />
                                    <button className="btn btn-danger" onClick={this.resetFilter}><span className="fa fa-refresh"></span> Atur Ulang</button>
                                </div>
                            </div>
                            <div className="card-body daftar-karyawan">
                                {!this.state.loading && this.state.datas.length === 0 ?
                                    <Empty description="Data Kosong"
                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                    :
                                    <Table striped className="mb-5">
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th className="text-center">Nama</Th>
                                                <Th className="text-center">Periode</Th>
                                                <Th className="text-center">Tanggal</Th>
                                                <Th className="text-center">Waktu Kerja</Th>
                                                <Th className="text-center">Gaji</Th>
                                                <Th className="text-center">Kompensasi</Th>
                                                <Th className="text-center">Potongan</Th>
                                                <Th className="text-center">Total</Th>
                                                <Th className="text-center">Status</Th>
                                                <Th className="text-center">Aksi</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={11}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.datas.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr>
                                                        <small>
                                                            <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                        </small>
                                                        <Td align="center">
                                                            <a href={"#"} onClick={() => {this.checkSlug(item)}}>
                                                                {item.karyawan.karyawan_nama}
                                                            </a>
                                                        </Td>
                                                        <Td align="center">
                                                            {item.payroll_month !== null ? moment(item.payroll_year + '-' + item.payroll_month + '-01').format('MMM') : moment(this.state.month).format('MMM')} {item.payroll_year !== null ? (item.payroll_year) : (this.state.year)}
                                                        </Td>
                                                        <Td align="center">
                                                            {item.payroll_date !== null ? moment(item.payroll_date).format('ll') : ('-')}
                                                        </Td>
                                                        <Td align="center">
                                                            {item.payroll_waktu_kerja} {item.payroll_type}
                                                        </Td>
                                                        <Td align="center">
                                                            {item.payroll_gaji !== null ? format({ prefix: '', integerSeparator: "." })(item.payroll_gaji) : ('-')}
                                                        </Td>
                                                        <Td align="center">
                                                            {format({ prefix: '', integerSeparator: "." })(item.payroll_kompensasi ? item.payroll_kompensasi : '-')}
                                                        </Td>
                                                        <Td align="center">
                                                            {format({ prefix: '', integerSeparator: "." })(item.payroll_potongan ? item.payroll_potongan : '-')}
                                                        </Td>
                                                        <Td align="center">
                                                            {format({ prefix: '', integerSeparator: "." })(item.payroll_total)}
                                                        </Td>
                                                        <Td align="center">
                                                            {item.payroll_status === 'new' &&
                                                                < div className={"badge badge-warning"}>New</div>
                                                            }
                                                            {item.payroll_status === 'pending' &&
                                                                < div className={"badge badge-danger"}>Pending</div>
                                                            }
                                                            {item.payroll_status === 'done' &&
                                                                < div className={"badge badge-info"}>Done</div>
                                                            }
                                                            {item.payroll_status === 'finish' &&
                                                                < div className={"badge badge-success"}>Finish</div>
                                                            }
                                                        </Td>
                                                        <Td align="center">
                                                            <ButtonGroup size="sm">
                                                                {item.payroll_status === 'new' &&
                                                                    <div>
                                                                        <Button id={`detail-${item.payroll_id}`}
                                                                            className="btn-shadow"
                                                                            color="warning"
                                                                            disabled={item.payroll_id == null}
                                                                            style={{
                                                                                padding: '2px 2px',
                                                                                textAlign: 'center',
                                                                                textDecoration: 'none',
                                                                                display: 'inline-block',
                                                                                fontSize: '16px',
                                                                                margin: '4px 2px'
                                                                            }}
                                                                            onClick={() => {
                                                                                this.setState({ modalEdit: true, payroll_id: item.payroll_id, karyawan_nama: item.karyawan.karyawan_nama, karyawan_id: item.karyawan.karyawan_id })
                                                                            }}>
                                                                            <i className="fa fa-pencil" style={{
                                                                                width: 30,
                                                                                color: '#ffffff'
                                                                            }}></i>
                                                                        </Button>
                                                                        <Button id={`delete-${item.payroll_id}`}
                                                                            className="btn-shadow"
                                                                            color="danger"
                                                                            disabled={item.payroll_id == null}
                                                                            style={{
                                                                                padding: '2px 2px',
                                                                                textAlign: 'center',
                                                                                textDecoration: 'none',
                                                                                display: 'inline-block',
                                                                                fontSize: '16px',
                                                                                margin: '4px 2px'
                                                                            }}
                                                                            onClick={() => {
                                                                                this.setState({ idDel: item.payroll_id }, () => { this.deletePayroll() })
                                                                            }
                                                                            }>
                                                                            <i className="fa fa-trash" style={{
                                                                                width: 30,
                                                                                color: '#ffffff'
                                                                            }} />
                                                                        </Button>
                                                                    </div>
                                                                }
                                                                {item.payroll_status === 'pending' &&
                                                                    <div>
                                                                        <Button className="btn-shadow"
                                                                                color="warning"
                                                                                style={{
                                                                                    padding: '2px 2px',
                                                                                    textAlign: 'center',
                                                                                    textDecoration: 'none',
                                                                                    display: 'inline-block',
                                                                                    fontSize: '16px',
                                                                                    margin: '4px 2px'
                                                                                }}
                                                                                onClick={() => {this.handlePending()}}
                                                                        >
                                                                            <i className="fa fa-pencil" style={{
                                                                                width: 30,
                                                                                color: '#ffffff'
                                                                            }}></i>
                                                                        </Button>
                                                                        <Button id={`delete-${item.payroll_id}`}
                                                                                className="btn-shadow"
                                                                                color="danger"
                                                                                disabled={item.payroll_id == null}
                                                                                style={{
                                                                                    padding: '2px 2px',
                                                                                    textAlign: 'center',
                                                                                    textDecoration: 'none',
                                                                                    display: 'inline-block',
                                                                                    fontSize: '16px',
                                                                                    margin: '4px 2px'
                                                                                }}
                                                                                onClick={() => {
                                                                                    this.setState({ idDel: item.payroll_id }, () => { this.deletePayroll() })
                                                                                }
                                                                                }>
                                                                            <i className="fa fa-trash" style={{
                                                                                width: 30,
                                                                                color: '#ffffff'
                                                                            }} />
                                                                        </Button>
                                                                    </div>
                                                                }
                                                                {item.payroll_status === 'done' &&
                                                                    <div>
                                                                        <Button
                                                                            id={`detail-${item.payroll_id}`}
                                                                            className="btn-shadow"
                                                                            color="warning"
                                                                            disabled={item.payroll_id == null}
                                                                            style={{
                                                                                padding: '2px 2px',
                                                                                textAlign: 'center',
                                                                                textDecoration: 'none',
                                                                                display: 'inline-block',
                                                                                fontSize: '16px',
                                                                                margin: '4px 2px'
                                                                        }}
                                                                            onClick={() => {
                                                                                this.setState({ modalEdit: true, payroll_id: item.payroll_id, karyawan_nama: item.karyawan.karyawan_nama, karyawan_id: item.karyawan.karyawan_id })
                                                                            }}>
                                                                            <i className="fa fa-pencil" style={{
                                                                                width: 30,
                                                                                color: '#ffffff'
                                                                            }}></i>
                                                                        </Button>
                                                                        <Button
                                                                            className="btn-shadow"
                                                                            color="success"
                                                                            disabled={item.payroll_id == null}
                                                                            style={{
                                                                                padding: '2px 2px',
                                                                                textAlign: 'center',
                                                                                textDecoration: 'none',
                                                                                display: 'inline-block',
                                                                                fontSize: '16px',
                                                                                margin: '4px 2px'
                                                                        }}
                                                                            onClick={() => {
                                                                                this.setState({ payroll_id: item.payroll_id }, () => { this.handleStatusFinish() })
                                                                            }
                                                                        }>
                                                                            <i className="fa fa-check" style={{
                                                                                width: 30,
                                                                                color: '#ffffff'
                                                                            }} />
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    id: item.payroll_id
                                                                                }, () => {
                                                                                    this.loadSlip()
                                                                                })
                                                                            }}
                                                                            className="btn-shadow"
                                                                            color="info"
                                                                            disabled={item.payroll_id == null}
                                                                            style={{
                                                                                padding: '2px 2px',
                                                                                textAlign: 'center',
                                                                                textDecoration: 'none',
                                                                                display: 'inline-block',
                                                                                fontSize: '16px',
                                                                                margin: '4px 2px'
                                                                            }}>
                                                                            <i className="fa fa-eye"
                                                                               style={{
                                                                                   width: 30,
                                                                                   color: '#ffffff'
                                                                               }}></i>
                                                                        </Button>
                                                                    </div>
                                                                }
                                                                {item.payroll_status === 'finish' &&
                                                                    <div>
                                                                        <Button
                                                                            id={`detail-${item.payroll_id}`}
                                                                            className="btn-shadow"
                                                                            color="warning"
                                                                            disabled={item.payroll_id == null}
                                                                            style={{
                                                                                padding: '2px 2px',
                                                                                textAlign: 'center',
                                                                                textDecoration: 'none',
                                                                                display: 'inline-block',
                                                                                fontSize: '16px',
                                                                                margin: '4px 2px'
                                                                            }}
                                                                            onClick={() => {
                                                                                this.setState({ modalEdit: true, payroll_id: item.payroll_id, karyawan_nama: item.karyawan.karyawan_nama, karyawan_id: item.karyawan.karyawan_id })
                                                                            }}>
                                                                            <i className="fa fa-pencil" style={{
                                                                                width: 30,
                                                                                color: '#ffffff'
                                                                            }}></i>
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    id: item.payroll_id
                                                                                }, () => {
                                                                                    this.loadSlip()
                                                                                })
                                                                            }}
                                                                            className="btn-shadow"
                                                                            color="info"
                                                                            disabled={item.payroll_id == null}
                                                                            style={{
                                                                                padding: '2px 2px',
                                                                                textAlign: 'center',
                                                                                textDecoration: 'none',
                                                                                display: 'inline-block',
                                                                                fontSize: '16px',
                                                                                margin: '4px 2px'
                                                                            }}>
                                                                            <i className="fa fa-eye"
                                                                               style={{
                                                                                   width: 30,
                                                                                   color: '#ffffff'
                                                                               }}></i>
                                                                        </Button>
                                                                    </div>
                                                                }
                                                            </ButtonGroup>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                }
                                <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>
                                    menampilkan {this.state.datas.length === 0 || this.state.datas.length === null ? "0" : this.state.datas.length} dari {this.state.totalData} data, Tampilkan
                                    <button className='button border px-1 ml-1' onClick={() => this.handleRowsPerPageChange(25)}>25</button>
                                    <button className='button border px-1 ml-1' onClick={() => this.handleRowsPerPageChange(50)}>50</button>
                                    <button className='button border px-1 ml-1' onClick={() => this.handleRowsPerPageChange(100)}>100</button>
                                </div>
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                                <Modal
                                    title="Import Payroll"
                                    visible={this.state.modalimport}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[
                                        this.state.importLoading ?
                                            <button className={'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Sedang mengupload data</button>
                                            :
                                            <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                                    ]}
                                >
                                    <div>
                                        <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                                        <a onClick={this.exportTemplatePayroll} style={{ color: "#057CE4" }}>Unduh template disini</a>
                                        <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                            <input type='file'
                                                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                   onChange={(event) => {
                                                       this.setState({
                                                           importFile: event.target.files[0],
                                                       })
                                                   }} /><br />
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                            {/*Modal Add Payroll Manual*/}
                            {
                                this.state.modalAdd ?
                                    <ModalRs size='lg' style={{ maxWidth: "1000px" }} isOpen={this.state.modalAdd} toggle={() => this.setState({ modalAdd: false, rows: [{}] })}>
                                        <ModalHeader style={{ backgroundColor: "#FAFDFF", padding: "12px 24px" }} toggle={() => this.setState({ modalAdd: false, rows: [{}] })}><div className='modal-header-reactstrap'>Tambah Payroll</div></ModalHeader>
                                        <ModalBody>
                                            <Col lg="12">
                                                <Card className="main-card mb-3">
                                                    <CardBody>
                                                        <div>
                                                            <div>
                                                                <Row>
                                                                    <Col>
                                                                        <div className='row mb-3'>
                                                                            <div className="col-md-2">
                                                                                <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="form-label font-edit-label">Bulan</label>
                                                                                <select name="month"
                                                                                        className="form-control"
                                                                                        onChange={this.handleChange}
                                                                                        value={this.state.month}
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            paddingTop: "8px",
                                                                                            paddingBottom: "8px"
                                                                                        }}>
                                                                                    <option value="01">Januari</option>
                                                                                    <option value="02">Februari</option>
                                                                                    <option value="03">Maret</option>
                                                                                    <option value="04">April</option>
                                                                                    <option value="05">Mei</option>
                                                                                    <option value="06">Juni</option>
                                                                                    <option value="07">Juli</option>
                                                                                    <option value="08">Agustus</option>
                                                                                    <option value="09">September</option>
                                                                                    <option value="10">Oktober</option>
                                                                                    <option value="11">November</option>
                                                                                    <option value="12">Desember</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="form-label font-edit-label">Tahun</label>
                                                                                <select name="year"
                                                                                        className="form-control"
                                                                                        onChange={this.handleChange}
                                                                                        value={this.state.year}
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            paddingTop: "8px",
                                                                                            paddingBottom: "8px"
                                                                                        }}>
                                                                                    <option value="2020">2020</option>
                                                                                    <option value="2021">2021</option>
                                                                                    <option value="2022">2022</option>
                                                                                    <option value="2023">2023</option>
                                                                                    <option value="2024">2024</option>
                                                                                    <option value="2025">2025</option>
                                                                                    <option value="2026">2026</option>
                                                                                    <option value="2027">2027</option>
                                                                                    <option value="2028">2028</option>
                                                                                    <option value="2029">2029</option>
                                                                                    <option value="2030">2030</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="form-label font-edit-label">Karyawan</label>
                                                                                <div className='form-control p-0'>
                                                                                    <Select
                                                                                        className="font-edit-value"
                                                                                        placeholder="Nama Karyawan"
                                                                                        backspaceRemoves={true}
                                                                                        deleteRemoves={true}
                                                                                        components={{
                                                                                            DropdownIndicator: () => null,
                                                                                            IndicatorSeparator: () => null
                                                                                        }}
                                                                                        value={this.state.karyawan_selected}
                                                                                        isLoading={this.state.karyawan_loading}
                                                                                        onFocus={() => this.setState({karyawan_loading: true}, () => this.searchKaryawan())}
                                                                                        onBlur={() => this.setState({karyawan_loading: false})}
                                                                                        onInputChange={(val) => this.setState({karyawan_name: val}, () => this.setState({karyawan_loading: true}, () => this.searchKaryawan()))}
                                                                                        options={
                                                                                            this.state.karyawan != null ? (
                                                                                                    this.state.karyawan.map((item, index) =>
                                                                                                        (
                                                                                                            {
                                                                                                                key: index,
                                                                                                                value: item.karyawan_id,
                                                                                                                label: item.karyawan_nama
                                                                                                            }
                                                                                                        )
                                                                                                    ))
                                                                                                : ("")
                                                                                        }
                                                                                        onChange={
                                                                                            (karyawan_selected) => {
                                                                                                if (!karyawan_selected) {
                                                                                                    this.setState({
                                                                                                        karyawan_selected: '',
                                                                                                        karyawan_id: 0,
                                                                                                        pickKaryawan: false
                                                                                                    })
                                                                                                } else {
                                                                                                    this.setState({
                                                                                                        karyawan_selected,
                                                                                                        karyawan_id: karyawan_selected.value,
                                                                                                        pickKaryawan: true,
                                                                                                        loading_selected: true,
                                                                                                        karyawan: []
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        isClearable={() => {
                                                                                            this.setState({
                                                                                                isClearable: true,
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="12" md="8" lg="12">
                                                                        <div>
                                                                            <div className="card">
                                                                                <hr/>
                                                                                <div
                                                                                    className="card-header text-center"
                                                                                    style={{
                                                                                        border: "none",
                                                                                        fontSize: "14px",
                                                                                        paddingTop: "14px",
                                                                                        paddingBottom: "8px"
                                                                                    }}>
                                                                                    <h4>Form Input Gaji</h4>
                                                                                </div>
                                                                                <hr/>
                                                                                <div className="card-body">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-xl-12">
                                                                                            <div>
                                                                                                <Row
                                                                                                    className="mb-2">
                                                                                                    <Col
                                                                                                        className="text-center">
                                                                                                        <Label>Jumlah
                                                                                                            Gaji</Label>
                                                                                                    </Col>
                                                                                                    <Col
                                                                                                        className="text-center">
                                                                                                        <Label>Type
                                                                                                            Gaji</Label>
                                                                                                    </Col>
                                                                                                    <Col
                                                                                                        className="text-center">
                                                                                                        <Label>Nominal
                                                                                                            Gaji</Label>
                                                                                                    </Col>
                                                                                                    <Col
                                                                                                        className="text-center">
                                                                                                        <Label>Total
                                                                                                            Gaji</Label>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                {this.state.rows.map((item, id) => {
                                                                                                    return (
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <FormGroup>
                                                                                                                    <CurrencyInput
                                                                                                                        name="payroll_waktu_kerja"
                                                                                                                        className="form-control"
                                                                                                                        defaultValue={0}
                                                                                                                        value={item.payroll_waktu_kerja}
                                                                                                                        groupSeparator='.'
                                                                                                                        decimalSeparator=","
                                                                                                                        id="payroll_waktu_kerja"
                                                                                                                        onValueChange={(value, name) => {
                                                                                                                            this.handleChangeRows(value, name, id)
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                            <Col>
                                                                                                                <FormGroup>
                                                                                                                    <select
                                                                                                                        name="payroll_type"
                                                                                                                        id="payroll_type"
                                                                                                                        className="form-control"
                                                                                                                        onChange={this.handleChangeType(id)}
                                                                                                                        placeholder="Type Payroll"
                                                                                                                        value={item.payroll_type}
                                                                                                                    >
                                                                                                                        <option
                                                                                                                            value={""}>Type
                                                                                                                            Gaji
                                                                                                                        </option>
                                                                                                                        <option
                                                                                                                            value="jam">Jam
                                                                                                                        </option>
                                                                                                                        <option
                                                                                                                            value="hari">Hari
                                                                                                                        </option>
                                                                                                                        <option
                                                                                                                            value="bulan">Bulan
                                                                                                                        </option>
                                                                                                                    </select>
                                                                                                                    {/*<CurrencyInput*/}
                                                                                                                    {/*    style={{backgroundColor: '#f0f0f0'}}*/}
                                                                                                                    {/*    readOnly*/}
                                                                                                                    {/*    className="form-control"*/}
                                                                                                                    {/*/>*/}
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                            <Col>
                                                                                                                <FormGroup>
                                                                                                                    <CurrencyInput
                                                                                                                        name="payroll_amount"
                                                                                                                        className="form-control"
                                                                                                                        defaultValue={0}
                                                                                                                        value={item.payroll_amount}
                                                                                                                        groupSeparator='.'
                                                                                                                        decimalSeparator=","
                                                                                                                        id="payroll_amount"
                                                                                                                        onValueChange={(value, name) => {
                                                                                                                            this.handleChangeRows(value, name, id)
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                            <Col
                                                                                                                className="text-center">
                                                                                                                <FormGroup>
                                                                                                                    <CurrencyInput
                                                                                                                        style={{backgroundColor: '#f0f0f0'}}
                                                                                                                        readOnly
                                                                                                                        className="form-control"
                                                                                                                        groupSeparator='.'
                                                                                                                        decimalSeparator=','
                                                                                                                        defaultValue={0}
                                                                                                                        value={item.payroll_total}
                                                                                                                        prefix="Rp "
                                                                                                                        precision={0}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    );
                                                                                                })}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn button-add text-capitalize"
                                                    onClick={() => this.setState({
                                                        modalAdd: false,
                                                        karyawan_id: 0,
                                                        karyawan_selected: null,
                                                        rows: [{}]
                                                    })}>
                                                Batalkan
                                            </button>
                                            {' '}
                                            <Button
                                                style={{
                                                    border: "1px solid #DDDDDD",
                                                    fontSize: "14px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px"
                                                }}
                                                onClick={this.handleAdd.bind(this)}
                                                color="primary"
                                            >
                                                Tambah
                                            </Button>
                                        </ModalFooter>
                                    </ModalRs>

                                    : ""
                            }
                            {/*Modal Edit   Clockout*/}
                            {
                                this.state.modalClockout ?
                                    <ModalRS size="lg" isOpen={this.state.modalClockout}
                                             style={{maxWidth: '900px', width: '100%'}}>
                                        <ModalHeader toggle={() => this.setState({modalClockout: false})}>Ubah Absen
                                            Pulang Kosong</ModalHeader>
                                        <ModalBody>
                                            {this.state.data_no_clockout.map((item, index) => {
                                                return (
                                                    <div>
                                                        {index != 0 ? <hr/> : null}
                                                        <div className="row">
                                                            <div className="col-6 mb-2">
                                                                <div className="text-muted font-weight-bold">Nama
                                                                    Karyawan
                                                                </div>
                                                                <div
                                                                    className="text-blue-dark font-weight-bold fs-14">{item.karyawan.karyawan_nama}</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="text-muted font-weight-bold">Tanggal
                                                                    Absen
                                                                </div>
                                                                <div
                                                                    className="text-blue-dark font-weight-bold fs-14">{moment(item.tanggal).format('ll')}</div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="text-muted font-weight-bold mb-2">Jam
                                                                    Masuk
                                                                </div>
                                                                <div
                                                                    className="text-blue-dark font-weight-bold fs-14">{item.jam_masuk_absen}</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="text-muted font-weight-bold mb-2">Jam
                                                                    Pulang
                                                                </div>
                                                                <div>
                                                                    <TimeInput
                                                                        placeholder="Jam Pulang..."
                                                                        style={{fontSize: "12px"}}
                                                                        initTime={null}
                                                                        className='s-input -time form-control px-3 py-2'
                                                                        onTimeChange={(event) => {
                                                                            const value = event;
                                                                            this.handleClockoutTime(item.id_absensi, value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button onClick={() => this.setState({modalClockout: false})}
                                                    className={'btn btn-danger'}>Tutup</Button>
                                            <Button onClick={() => {
                                                this.setClockoutTime()
                                            }} disabled={this.state.loading}
                                                    className={'btn btn-success'}>Simpan</Button>
                                        </ModalFooter>
                                    </ModalRS>

                                    : ""
                            }
                            {
                                this.state.modalEdit ?
                                    <ModalRS size="lg" isOpen={this.state.modalEdit}
                                             style={{maxWidth: '900px', width: '100%'}}>
                                        <ModalHeader toggle={() => this.setState({modalEdit: false})}>Edit
                                            Payroll {this.state.karyawan_nama}</ModalHeader>
                                        <ModalBody>
                                            <Edit
                                                payroll_id={this.state.payroll_id}
                                                karyawan_id={this.state.karyawan_id}
                                                request={this.props.request}
                                                saveAll={this.state.saveAll}
                                                history={this.props.history}
                                                match={this.props.match}
                                                // loadPotonganKaryawan={this.loadPotonganKaryawan}
                                                // loadDataTermPotongan={this.loadDataTermPotongan}
                                                updateState={(state) => {
                                                    this.setState(state)
                                                }}
                                                loadData={() => {this.loadData()}}
                                                loadSlip={this.loadSlip}
                                            />
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button onClick={() => this.setState({modalEdit: false})}
                                                    className={'btn btn-danger'}>Tutup</Button>
                                        </ModalFooter>
                                    </ModalRS>

                                    : ""
                            }
                            {/*modal Slip Gaji Karyawan*/}
                            {
                                this.state.modalSlip ?
                                    <ModalRS size="lg" isOpen={this.state.modalSlip}
                                             toggle={() => this.setState({modalSlip: false})}>
                                        <ModalHeader>Slip Gaji Karyawan</ModalHeader>
                                        <ModalBody id="content-list">
                                            <div className="">
                                                <div>
                                                    <div className="row bg-blue-dark p-3 text-white border-bottom-blue"
                                                         style={{
                                                             backgroundImage: `url(${presensiHeaderModalBG})`,
                                                             backgroundRepeat: 'no-repeat',
                                                             backgroundPosition: 'left center'
                                                         }}>
                                                        <div className="col-4 d-flex align-items-center">
                                                            <img src={presensiWhiteLogo} alt="Logo"
                                                                 style={{height: '26px'}}/>
                                                        </div>
                                                        <div className="col-8 text-right">
                                                            <b>{moment(this.state.dataSlip.payroll_month).format('MMMM')} {this.state.dataSlip.payroll_year}</b><br/>
                                                            <span>{this.state.perusahaan.perusahaan_nama}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3 border-btm-gray">
                                                    <div className="col">
                                                        <div className="text-muted font-weight-bold mb-2">DARI</div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <img
                                                                    className="align-self-center pull-right rounded-circle blur-up lazyloaded"
                                                                    src={this.state.perusahaan.perusahaan_foto !== null ? IMG_HOST + this.state.perusahaan.perusahaan_foto : DEFAULT_IMG}
                                                                    alt="header-user" style={{
                                                                    width: "40px",
                                                                    height: "40px",
                                                                    objectFit: "cover"
                                                                }}/>
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className="text-blue-dark font-weight-bold fs-14">{this.state.perusahaan.perusahaan_nama}</div>
                                                                <div
                                                                    className="text-muted">{this.state.perusahaan.perusahaan_alamat}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="text-muted font-weight-bold mb-2">UNTUK</div>
                                                        <div>
                                                            <div
                                                                className="text-blue-dark font-weight-bold fs-14">{this.state.karyawan.karyawan_nama}</div>
                                                            <div
                                                                className="text-muted">{this.state.karyawan.divisi.divisi_name} - {this.state.karyawan.jabatan.jabatan_nama}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-6">
                                                        <div className="text-muted font-weight-bold mb-2">PENDAPATAN
                                                        </div>
                                                        <div className="pl-3 pr-1">
                                                            <div
                                                                className="row align-items-center justify-content-between pb-1">
                                                                <div>
                                                                    Gaji Pokok
                                                                </div>
                                                                <div className="text-blue-dark">
                                                                    Rp {format({
                                                                    prefix: '',
                                                                    integerSeparator: "."
                                                                })(this.state.gaji)}
                                                                </div>
                                                            </div>
                                                            {this.state.kompensasi.map((item, index) => {
                                                                return (
                                                                    <div
                                                                        className="row align-items-center justify-content-between pb-1">
                                                                        <div>
                                                                            {item.payroll_term.p_term_name}
                                                                        </div>
                                                                        <div className="text-blue-dark">
                                                                            Rp {format({
                                                                            prefix: '',
                                                                            integerSeparator: "."
                                                                        })(item.p_kompensasi_total)}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div
                                                                className="row align-items-center justify-content-between pt-2"
                                                                style={{borderTop: "1px dotted #DDDDDD"}}>
                                                                <div>
                                                                    <strong>Total</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>Rp {format({
                                                                        prefix: '',
                                                                        integerSeparator: "."
                                                                    })(this.state.penghasilan)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="text-muted font-weight-bold mb-2">POTONGAN</div>
                                                        <div className="pr-3 pl-3">
                                                            {this.state.potongan.map((item, index) => {
                                                                return (
                                                                    <div className="row align-items-center justify-content-between pb-1">
                                                                        <div>
                                                                            {item.payroll_term.p_term_name}
                                                                        </div>
                                                                        <div className="text-blue-dark">
                                                                            Rp {format({ prefix: '', integerSeparator: "." })(item.p_potongan_total)}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="row align-items-center justify-content-between pt-2" style={{ borderTop: "1px dotted #DDDDDD" }}>
                                                                <div>
                                                                    <strong>Total</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>Rp {format({
                                                                        prefix: '',
                                                                        integerSeparator: "."
                                                                    })(this.state.dataSlip.payroll_potongan)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-light mt-2 px-4 py-2">
                                                    <div className="row align-items-center justify-content-between fs-14 font-weight-bold text-blue-dark">
                                                        <div>
                                                            <strong>TAKE HOME PAY</strong>
                                                        </div>
                                                        <div>
                                                            <strong>Rp {format({ prefix: '', integerSeparator: "." })(this.state.dataSlip.payroll_total)}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="text-center text-secondary mt-2">
                                                        <span>Presensi.co.id @2024</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button className={'btn btn-success'} onClick={() => {this.printDocumentFour()}}>Download</Button>
                                            <Button className={'btn btn-danger'} onClick={() => this.setState({ modalSlip: false })}>Batal</Button>
                                        </ModalFooter>
                                    </ModalRS>
                                    :
                                    ""
                            }

                        </div>
                    </div>
                    <Potongan
                        potongan={this.state.potongan}
                        visiblePot={this.state.visiblePot}
                        id={this.state.karyawan_id}
                        rows={this.state.rows}
                        dataTermPotongan={this.state.data_term_potongan}
                        handleChangeStock={this.handleChangeStock}
                        potonganModal={this.potonganModal}
                        handlePotonganType={this.handlePotonganType}
                        handlePotonganAmount={this.handlePotonganAmount}
                        handleSubmitPotongan={this.handleSubmitPotongan}
                        removeClick={this.potonganDelete}
                        request={this.props.request}
                    />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

export default connect(mapStateToProps)(List_payroll);
