import React, {Component} from 'react'
import {Button, Drawer, Form, Select} from 'antd';
import Swal from "sweetalert2";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

class ShiftAdd extends Component {
    constructor() {
        super();
        this.state = {
            hari_kerja: 'Senin',
            sift_id: '',
            jam_masuk: moment().hour() + ':' + moment().minute() + ':00',
            jam_keluar: moment().hour() + ':' + moment().minute() + ':00',
            shift: [],
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSubmit = () => {
        let form = {
            hari_kerja: this.state.hari_kerja,
            sift_id: this.state.sift_id,
            jam_masuk: this.state.jam_masuk,
            jam_keluar: this.state.jam_keluar,
        }

        this.props.request("jamKerja/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    // this.setState({
                    //     sift_id: '',
                    //     jam_masuk: '',
                    //     jam_keluar: '',
                    // })
                    this.props.changeState({
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    loadShift(){
        let form = {}
        this.props.request("shift/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        shift: response.data.data,
                        sift_id: null,
                    })
                }
            });
    }

    componentDidMount() {
        this.loadShift()
    }

    render() {
        const { Option } = Select;
        return (
            <Drawer
                title="Tambah Jam Kerja"
                width={350}
                onClose={() => {
                    this.props.changeState({add_visible: false, divisi_name:''});
                }}
                visible={this.props.add_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => {
                            this.props.changeState({add_visible: false, sift_name:''});
                        }}
                                style={{ marginRight: 8 }}>
                            Batal
                        </Button>
                        <Button onClick={this.handleSubmit} type="primary">
                            Simpan
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Hari Kerja</label>
                        <Select placeholder="Pilih Hari Kerja"
                                name="hari_kerja"
                                className="form-control col-xl-12 col-md-12"
                                onChange={(value) => {
                                    this.setState({hari_kerja: value})
                                }}
                        >
                            <Option value="Senin">Senin</Option>
                            <Option value="Selasa">Selasa</Option>
                            <Option value="Rabu">Rabu</Option>
                            <Option value="Kamis">Kamis</Option>
                            <Option value="Jumat">Jumat</Option>
                            <Option value="Sabtu">Sabtu</Option>
                            <Option value="Minggu">Minggu</Option>
                        </Select>
                    </div>
                    <div className="form-group">
                        <label>Shift Kerja</label>
                        <Select showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="Pilih Shift Kerja"
                                name="sift_id"
                                className="form-control col-xl-12 col-md-12"
                                onChange={(value) => {
                                    this.setState({sift_id: value})
                                }}
                        >
                            {this.state.shift.map((item, index) => {
                                return (
                                    <Option value={item.sift_id}>{item.sift_name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="form-group">
                        <label>Jam Masuk Kerja</label><br/>
                        <TimePicker name="jam_masuk" defaultValue={moment()} allowEmpty={false} showSecond={false}
                                    onChange={(e) => {
                                        this.setState({
                                            jam_masuk: e.hour() + ':' + e.minute() + ':00'
                                        })
                                    }}/>
                    </div>
                    <div className="form-group">
                        <label>Jam Pulang Kerja</label><br/>
                        <TimePicker name="jam_keluar" defaultValue={moment()} allowEmpty={false} showSecond={false}
                                    onChange={(e) => {
                                        this.setState({
                                            jam_keluar: e.hour() + ':' + e.minute() + ':00'
                                        })
                                    }}/>
                    </div>
                </Form>
            </Drawer>

        )
    }
}

export default ShiftAdd
