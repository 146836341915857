export const INIT_AUTH = 'AUTH/INIT';
export const SET_AUTH = 'AUTH/SET';
export const CLEAR_AUTH = 'AUTH/CLEAR';

export const init_auth = (auth, api_token, user) => ({
    type: INIT_AUTH,
    auth: auth,
    api_token: api_token,
    user: user
});

export const set_auth = (api_token, user) => ({
    type: SET_AUTH,
    api_token: api_token,
    user: user
});

export const clear_auth = (api_token) => ({
    type: CLEAR_AUTH
});

export default function reducer(state = {
    api_token: '',
    auth: false,
    user: null
}, action) {
    switch (action.type) {
        case INIT_AUTH:
            return {
                ...state,
                auth: action.auth,
                api_token: action.api_token,
                user: action.user
            }

        case SET_AUTH:
            localStorage.setItem('Presensi-Auth', true)
            localStorage.setItem('Presensi-Auth-Key', action.api_token)
            localStorage.setItem('Presensi-Auth-User', JSON.stringify(action.user))

            return {
                ...state,
                auth: true,
                api_token: action.api_token,
                user: action.user
            };
        case CLEAR_AUTH:
            localStorage.removeItem("Presensi-Auth");
            localStorage.removeItem("Presensi-Auth-Key");
            localStorage.removeItem("Presensi-Auth-User");
            // localStorage.clear()

            return {
                ...state,
                auth: false,
                api_token: ''
            };
    }

    return state;
}
