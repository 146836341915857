import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom'
import {Modal, Spin, Tooltip} from 'antd';
import {
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    Modal as ModalRs,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import Swal from "sweetalert2";
import moment from "moment";
import {Helmet} from "react-helmet";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {pageNumber} from "../../functions/functionGeneral";
import {APIKEY, BACKEND_API_HOST, IMG_HOST} from "../../helper/host";
import DatePicker from "react-datepicker";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import ReactPaginate from "react-paginate";
import ArrowRight from '../../assets/icons/arrow-right.svg'
import ArrowLeft from '../../assets/icons/arrow-left.svg'
import FilterIcon from '../../assets/icons/filter.svg';
import PlusIcon from '../../assets/icons/plus.svg';
import RefreshIcon from '../../assets/icons/refresh.svg';
import EmptyDataImage from '../../assets/images/data-kosong.png';
import WarningIcon from '../../assets/icons/warning-icon.svg';
import MoreIcon from '../../assets/icons/more.svg'
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg"
import eyeIcon from "../../assets/icons/eye.svg";
import pencilIcon from "../../assets/icons/pencil.svg";
import trashIcon from "../../assets/icons/trash.svg";
import './style-absensi.css';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import Select from "react-select";
import TimeInput from '../../helper/time_input';
import {faCalendarAlt,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file daterange
import 'react-date-range/dist/theme/default.css';
import {Table, Td, Tr} from "react-super-responsive-table";
import ArrowUpIcon from "../../assets/icons/arrow-up-blue.svg";
import ArrowDownIcon from "../../assets/icons/arrow-down-blue.svg"; // theme css file daterange
import checkBlue from "../../assets/icons/Check Blue.svg"

export class List_absensi extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("01 02 03 04 05 06 07 08 09 10 11 12");
        namabulan = namabulan.split(" ");
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        var bulan = lastDay.getMonth();
        var tahun = lastDay.getFullYear();
        var tanggal = new Date();
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            idDel: '',
            absensi_id: '',
            filter_nama: "",
            filter_status_in: "",
            filter_status_out: "",
            year: tahun,
            month: namabulan[bulan],
            tanggal: tanggal,
            sort_by: '',
            sort_value: '',
            absensiStartDate: '',
            absensiEndDate: '',
            modalexport: false,
            modaldateclockout: false,
            modalupdate: false,
            modalAction: false,
            pageCount: 0,
            currentPage: 0,
            currentPageHistory:0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination:2,
            dataKosong: "",
            guide: false,
            dateFilter: moment(),
            totalPerpage: 0,
            filter: false,
            data_lokasi: [],
            data_divisi: [],
            data_shift: [],
            filter_divisi: "",
            filter_lokasi: "",
            filter_belum_masuk: "",
            filter_belum_keluar: "",
            filter_status: "",
            filter_shift: "",
            modalManagement: false,
            idDetail: "",
            modalDetail: false,
            modalHistory: false,
            dataDetail: [],
            dataHistory: [],
            dataLembur: [],
            dataKaryawan: {},
            DataDivisi: {},
            datepicker: false,
            valueFilterDate: moment()._d,
            detail_statusin: "",
            detail_statusout: "",
            modalMapIn: false,
            modalMapOut: false,
            karyawan_id: 0,
            shift_nama: "",
            jam_masuk: "",
            lokasi_masuk: 0,
            status_masuk: "",
            keterangan_masuk: "",
            jam_keluar: null,
            istirahat_start: null,
            istirahat_end: null,
            lokasi_keluar: 0,
            status_keluar: "",
            keterangan_keluar: "",
            karyawan_selected: null,
            shift: [],
            modalAdd: false,
            shift_id: 0,
            lokasi_nama_masuk: "",
            lokasi_nama_keluar: "",
            lokasi_masuk_id: 0,
            lokasi_keluar_id: 0,
            lokasi_in: [],
            lokasi_out: [],
            exportStartDate: "",
            exportEndDate: "",
            valueDate: "",
            start_date: new Date(),
            end_date: new Date(),
            reactDateRange: false,
            loadingHistory: false,
            slice_karyawan_nama: true,
            sortBy: '',
            direction: '',
            sortByDirection: "",
            loading_selected: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.exportAbsensi = this.exportAbsensi.bind(this)
        this.exportLaporanLembur = this.exportLaporanLembur.bind(this)
        this.exportLaporanAbsensi = this.exportLaporanAbsensi.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.searchKaryawan = this.searchKaryawan.bind(this)
        this.searchLokasiIn = this.searchLokasiIn.bind(this)
        this.searchLokasiOut = this.searchLokasiOut.bind(this)
        this.applyCallback = this.applyCallback.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    handleOk = e => {
        this.setState({
            modalexport: false,
            modaldateclockout: false,
            modalAction: false,
            modalupdate: false,
            modalManagement: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            sortByDirection: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };

    handleCancel = e => {
        this.setState({
            modalAction: false,
            modalexport: false,
            modaldateclockout: false,
            modaludpate: false,
            modalManagement: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            sortByDirection: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            modalexport: true,
            modaldateclockout: true,
            mdoalupdate: true,
            exportStartDate: start,
            exportEndDate: end
        }, () => {
            this.loadData()
        });
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_status_in: '',
            filter_status_out: '',
            filter_shift: '',
            dataKosong: '',
            absensiStartDate: '',
            absensiEndDate: '',
            data: [],
            loading: true,
            status: "",
        }, () => this.loadData())
    }

    exportAbsensi() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'presensi/exportv2?token=' + AuthKey + '&presensiStartDate=' + this.state.exportStartDate + '&presensiEndDate=' + this.state.exportEndDate + "&sortByDirection=" + this.state.sortByDirection, "_blank")
    }

    exportLaporanLembur() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'presensi/export-lemburv2?token=' + AuthKey + '&presensiStartDate=' + this.state.exportStartDate + '&presensiEndDate=' + this.state.exportEndDate + "&sortByDirection=" + this.state.sortByDirection, "_blank")
    }

    exportLaporanAbsensi() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'presensi/export_laporan?token=' + AuthKey + '&presensiStartDate=' + this.state.exportStartDate + '&presensiEndDate=' + this.state.exportEndDate + "&sortByDirection=" + this.state.sortByDirection, "_blank")
    }

    applyCallback(startDate, endDate) {
        var valueStart = moment(startDate).format("YYYY-MM-DD");
        var valueEnd = moment(endDate).format("YYYY-MM-DD");
        this.setState({
            start: startDate,
            end: endDate,
            exportStartDate: valueStart,
            exportEndDate: valueEnd,
            valueDate: valueStart + " - " + valueEnd
        })
    }

    wantGenerateAlpa = e => {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghitung alpa absen karyawan?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                    })
                    let form = {
                        id: this.state.idDel,
                        presensiStartDate: this.state.exportStartDate,
                        presensiEndDate: this.state.exportEndDate,
                    }
                    this.props.request("presensi/generate-absen", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    };

    wantDeleteDuplicateData = e => {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus data absen karyawan yang terdeteksi sebagai duplikat?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        data: [],
                        loading: true,
                    })
                    let form = {
                        presensiStartDate: this.state.exportStartDate,
                        presensiEndDate: this.state.exportEndDate,
                    }
                    this.props.request("presensi/delete-duplicate", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    };

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus presensi?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        loading: true,
                        data: [],
                    })
                    this.props.request("presensi/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.absensi_id,
                paging : this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("presensi/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            absensi_id: ''
                        });
                    }
                });
        })
    }

    loadDetail(type) {
        let form = {
            id: this.state.idDetail
        }
        this.props.request("presensi/profil", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataDetail: response.data,
                        detail_statusin: response.data.status_in,
                        detail_statusout: response.data.status_out,
                        dataLembur: response.lembur,
                        dataKaryawan: response.data.karyawan,
                        DataDivisi: response.data.divisi,
                        absenMasuk: {
                            lat: response.data.latitude_in,
                            lng: response.data.longitude_in,
                        },
                        absenPulang: {
                            lat: response.data.latitude_out,
                            lng: response.data.longitude_out,
                        },
                        tanggal: response.data.tanggal,
                        karyawan_id: response.data.karyawan_id,
                        shift_nama: response.data.sif_name,
                        jam_masuk: response.data.jam_masuk_absen,
                        status_masuk: response.data.status_in,
                        keterangan_masuk: response.data.keterangan_in,
                        jam_keluar: response.data.jam_keluar_absen,
                        status_keluar: response.data.status_out,
                        keterangan_keluar: response.data.keterangan_out,
                        istirahat_start: response.data.istirahat_mulai,
                        istirahat_end: response.data.istirahat_selesai,
                        karyawan_selected: {
                            value: response.data.karyawan_id,
                            label: response.data.karyawan.karyawan_nama
                        },
                        shift: response.data.karyawan.divisi_sift
                    })
                }
                if (type === "detail") {
                    this.setState({
                        modalDetail: true
                    })
                } else if (type === "edit") {
                    this.setState({
                        modalEdit: true
                    })
                }
            });
    }

    searchLokasiIn() {
        let form = {
            lokasi_nama: this.state.lokasi_nama_masuk
        }
        this.props.request("search_lokasi_perusahaan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        lokasi_in: response.data,
                        lokasi_in_loading: false
                    })
                }
            })
    }

    searchLokasiOut() {
        let form = {
            lokasi_nama: this.state.lokasi_nama_keluar
        }
        this.props.request("search_lokasi_perusahaan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        lokasi_out: response.data,
                        lokasi_out_loading: false
                    })
                }
            })
    }

    tambahAbsensi = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            karyawan_id: this.state.karyawan_id,
            sift: this.state.shift_id,
            lokasi_in: this.state.lokasi_masuk_id,
            lokasi_out: this.state.lokasi_keluar_id,
            tanggal: this.state.tanggal,
            jam_in: this.state.jam_masuk,
            jam_out: this.state.jam_keluar,
            istirahat_start: this.state.istirahat_start,
            istirahat_end: this.state.istirahat_end,
            status_in: this.state.status_masuk,
            status_out: this.state.status_keluar,
            keterangan_in: this.state.keterangan_masuk,
            keterangan_out: this.state.keterangan_keluar,
        }
        this.props.request("presensi/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalAdd: false,
                                loading: true
                            }, () => this.loadData())
                        } else {
                            this.setState({
                                modalAdd: false,
                                loading: true
                            }, () => this.loadData())
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    simpanAbsensi = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id: this.state.idDetail,
            sift: this.state.shift_nama,
            tanggal: this.state.tanggal,
            jam_in: this.state.jam_masuk,
            jam_out: this.state.jam_keluar,
            istirahat_start: this.state.istirahat_start,
            istirahat_end: this.state.istirahat_end,
            status_in: this.state.status_masuk,
            status_out: this.state.status_keluar,
            keterangan_in: this.state.keterangan_masuk,
            keterangan_out: this.state.keterangan_keluar,
        }

        this.props.request("presensi/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalEdit: false,
                                loading: true
                            }, () => this.loadData())
                        } else {
                            this.setState({
                                modalEdit: false,
                                loading: true
                            }, () => this.loadData())
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadData() {
        let form = {
            paginate: this.state.paging,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
            filter_lokasi: this.state.filter_lokasi,
            filter_divisi: this.state.filter_divisi,
            filter_shift: this.state.filter_shift,
            filter_tanggal: this.state.dateFilter.format('L'),
            filter_dataKosong: this.state.dataKosong,
            filter_belum_masuk: this.state.filter_belum_masuk,
            filter_belum_keluar: this.state.filter_belum_keluar,
            filter_status: this.state.filter_status,
            month: this.state.month,
            year: this.state.year,
            valueFilterDate: this.state.valueFilterDate,
            sort_by: this.state.sort_by,
            sort_value: this.state.sort_value
        }
        this.props.request("presensi/list-v3", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        totalPerpage: response.data.to,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    loadLokasi() {
        this.props.request("lokasi/list-lokasi", {}, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_lokasi: response.data
                    })
                }
            })
    }

    loadDivisi() {
        this.props.request("divisi/list-divisi", {}, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_divisi: response.data
                    })
                }
            })
    }

    loadShift() {
        this.props.request("shift/list-shift", {}, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_shift: response.data
                    })
                }
            })
    }

    searchKaryawan() {
        let form = {
            karyawan_name: this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false,
                        loading_selected: false
                    })
                }
            })
    }

    sortabledate = (val) => {
        const copyArray = [...this.state.data];

        copyArray.sort((a, b) => {
            const jamA = a.absen.jam_masuk_absen.toUpperCase();
            const jamB = b.absen.jam_masuk_absen.toUpperCase();

            return val === 0
                ? jamA.localeCompare(jamB)
                : jamB.localeCompare(jamA);
        });

        this.setState({
            data: copyArray
        });
    }

    sortablename = (val) => {
        const copyArray = [...this.state.data];

        copyArray.sort((a, b) => {
            const nameA = a.karyawan_nama.toUpperCase();
            const nameB = b.karyawan_nama.toUpperCase();

            return val === 0
                ? nameA.localeCompare(nameB)
                : nameB.localeCompare(nameA);
        });

        this.setState({
            data: copyArray
        });
    }

    clockoutAuto() {
        this.setState({
            loading: true,
            modaldateclockout: false,
            modalupdate: false,
            data: [],
            exportStartDate: "",
            exportEndDate: ""
        })
        let formData = {
            start: this.state.exportStartDate,
            end: this.state.exportEndDate,
        }
        this.props.request('presensi/set-clockoutv3', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        timer: 1500,
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'error',
                        timer: 5000,
                    })
                    this.loadData()
                }})
    }

    updateAbsensi()  {
        this.setState({
            loading: true,
            modalupdate: false,
            data: []
        })
        let formData = {
            dateFilter: this.state.dateFilter.format('YYYY-MM-DD'),
        }
        this.props.request('presensi/set-clockout-presensi', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        timer: 1500,
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'error',
                        timer: 5000,
                    })
                    this.loadData()
                }})
    }

    Capitalize(str){
        const Capitalize = str
            .toLowerCase()
            .split(' ')
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');

        return Capitalize;
    }

    componentDidMount() {
        this.loadData()
        this.loadLokasi()
        this.loadDivisi()
        this.loadShift()
    }

    render() {

        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }
        const detail_statusin = this.state.detail_statusin
        const detail_statusout = this.state.detail_statusout
        let no = 0;
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Presensi") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Presensi - Presensi</title>
                        <meta name="description" content="Daftar Presensi" />
                    </Helmet>
                    <div className="container-fluid" style={{ paddingTop: "30px" }}>
                        <div className="card border">
                            <div className="card-header" style={{ backgroundColor: "#FAFDFF", padding: "12px 24px", fontSize: "24px", fontWeight: 700 }}>
                                Presensi Harian
                            </div>
                            <ModalRs className="modal-content-export" isOpen={this.state.modalexport} toggle={this.handleCancel}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={this.handleCancel}>Export Excel</ModalHeader>
                                <ModalBody>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='d-flex flex-row warning-info px-2 mb-2'>
                                                <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                                <div className='px-0' style={{ fontSize: "14px" }}>
                                                    Filter berdasarkan tanggal terlebih dahulu
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-12'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <div className="input-group-text">
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </div>
                                                </InputGroupAddon>

                                                <Input bsSize="sm" className='font-edit-value' style={{ paddingTop: "8px", paddingBottom: "8px" }} onFocus={() => this.setState({ reactDateRange: true })} placeholder="Filter Tanggal" value={this.state.valueDate} />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="row-daterange">
                                        <div className={`col-md-12 col-md-6-daterange ${this.state.reactDateRange ? 'active' : 'inactive'}`}>
                                            {this.state.reactDateRange === true &&
                                                <div className='shadow d-flex flex-column' style={{ maxWidth: "332px" }}>
                                                    <div>
                                                        <DateRange
                                                            onBlur={() => this.setState({ reactDateRange: false })}
                                                            ranges={[selectionRange]}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    start_date: e.selection.startDate,
                                                                    end_date: e.selection.endDate,
                                                                    exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                                    exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                                })
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className='d-flex justify-content-end pr-2'>
                                                            <button className='btn button-add text-capitalize mb-2' style={{ fontSize: "14px", fontWeight: 500 }} onClick={() => this.setState({ reactDateRange: false })}>Batal</button>
                                                            <button className='btn btn-primary mb-2 ml-2 text-capitalize' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }} onClick={
                                                                () => this.setState({
                                                                    valueDate: this.state.exportStartDate + " - " + this.state.exportEndDate,
                                                                    reactDateRange: false
                                                                })
                                                            }>Terapkan</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={`col-md-12 col-md-6-daterange position-relative ${this.state.reactDateRange ? 'inactive' : 'active'}`}>
                                            {this.state.valueDate !== "" &&
                                                <div className='col-md-9 pl-0'>
                                                    <div className="row">
                                                        <div className="col-md-6 pr-0">
                                                            <button
                                                                onClick={() => {this.wantGenerateAlpa()}}
                                                                className={"btn btn-block text-capitalize px-4 py-1 mb-2"}
                                                                style={{
                                                                    backgroundColor: "#FFFFFF",
                                                                    color: "#143B5E",
                                                                    borderColor: "#143B5e",
                                                                    fontSize: "12px",
                                                                    borderRadius: "12px"
                                                                }}><i className="fa fa-book"/> Hitung Alpa
                                                            </button>
                                                        </div>
                                                        <div className="col-md-6 pl-1">
                                                            <button
                                                                onClick={() => {this.wantDeleteDuplicateData()}}
                                                                className={"btn btn-block text-capitalize px-3 py-1"}
                                                                style={{
                                                                    backgroundColor: "#FFECE8",
                                                                    color: "#E03616",
                                                                    borderColor: "#E03616",
                                                                    fontSize: "12px",
                                                                    borderRadius: "12px"
                                                                }}><i className="fa fa-book"/> Hapus Data Duplikat
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <label className="form-label">Pengaturan Export</label>
                                                    <select id="sortBySelect" name="sortByDirection"
                                                            className="form-control sortBySelect"
                                                            value={this.state.sortByDirection}
                                                            onChange={this.handleChange}>
                                                        <option value="">Pilih Sort</option>
                                                        <option value="karyawan_asc">Karyawan A-Z</option>
                                                        <option value="karyawan_desc">Karyawan Z-A</option>
                                                        <option value="tanggal_asc">Tanggal Terlama</option>
                                                        <option value="tanggal_desc">Tanggal Terbaru</option>
                                                    </select>
                                                    <button
                                                        className={'btn btn-block btn-primary px-3 py-2 text-capitalize mb-2'}
                                                        style={{fontSize: "14px", borderRadius: "11px"}}
                                                        onClick={this.exportAbsensi}>Export Data Presensi
                                                    </button>
                                                    <button
                                                        className={'btn btn-block btn-primary px-3 py-2 text-capitalize mb-2'}
                                                        style={{fontSize: "14px", borderRadius: "11px"}}
                                                        onClick={this.exportLaporanLembur}>Export Data Lembur
                                                    </button>
                                                    {this.state.sortByDirection !== 'tanggal_asc' && this.state.sortByDirection !== 'tanggal_desc' &&
                                                        <button
                                                            className={'btn btn-block btn-primary px-3 py-2 text-capitalize'}
                                                            style={{fontSize: "14px", borderRadius: "11px"}}
                                                            onClick={this.exportLaporanAbsensi}>Export Data Laporan Presensi
                                                        </button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                                    <button className="btn button-add text-capitalize" onClick={this.handleCancel}>
                                        Batalkan
                                    </button>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs className="modal-content-export" isOpen={this.state.modaldateclockout} toggle={this.handleCancel}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={this.handleCancel}>Clockout Otomatis</ModalHeader>
                                <ModalBody>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='d-flex flex-row warning-info px-2 mb-2'>
                                                <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                                <div className='px-0' style={{ fontSize: "14px" }}>
                                                    Filter berdasarkan tanggal terlebih dahulu
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-12'>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <div className="input-group-text">
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </div>
                                                </InputGroupAddon>

                                                <Input bsSize="sm" className='font-edit-value' style={{ paddingTop: "8px", paddingBottom: "8px" }} onFocus={() => this.setState({ reactDateRange: true })} placeholder="Filter Tanggal" value={this.state.valueDate} />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="row-daterange">
                                        <div className={`col-md-12 col-md-6-daterange ${this.state.reactDateRange ? 'active' : 'inactive'}`}>
                                            {this.state.reactDateRange === true &&
                                                <div className='shadow d-flex flex-column' style={{ maxWidth: "332px" }}>
                                                    <div>
                                                        <DateRange
                                                            onBlur={() => this.setState({ reactDateRange: false })}
                                                            ranges={[selectionRange]}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    start_date: e.selection.startDate,
                                                                    end_date: e.selection.endDate,
                                                                    exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                                    exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                                })
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className='d-flex justify-content-end pr-2'>
                                                            <button className='btn button-add text-capitalize mb-2' style={{ fontSize: "14px", fontWeight: 500 }} onClick={() => this.setState({ reactDateRange: false })}>Batal</button>
                                                            <button className='btn btn-primary mb-2 ml-2 text-capitalize' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }} onClick={
                                                                () => this.setState({
                                                                    valueDate: this.state.exportStartDate + " - " + this.state.exportEndDate,
                                                                    reactDateRange: false
                                                                })
                                                            }>Terapkan</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={`col-md-12 col-md-6-daterange position-relative ${this.state.reactDateRange ? 'inactive' : 'active'}`}>
                                            {this.state.valueDate !== "" &&
                                                <div className='col-md-9 pl-0'>
                                                    <button className={'btn btn-block btn-primary px-3 py-2 text-capitalize mb-2'} style={{ fontSize: "14px", borderRadius: "11px" }} onClick={() => {this.clockoutAuto()}}>Atur Clockout Otomatis</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                                    <button className="btn button-add text-capitalize" onClick={this.handleCancel}>
                                        Batalkan
                                    </button>
                                </ModalFooter>
                            </ModalRs>
                            <Modal
                                title="Olah Data Absensi"
                                open={this.state.modalManagement}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                            >
                                <div className='pb-4'>
                                </div>
                            </Modal>
                            <ModalRs size='lg' isOpen={this.state.modalMapIn} toggle={() => this.setState({ modalMapIn: false })}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalMapIn: false })}>Detail Lokasi</ModalHeader>
                                <ModalBody>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '250px'
                                    }}>
                                        <Map google={this.props.google}
                                            initialCenter={{
                                                lat: this.state.dataDetail.latitude_in,
                                                lng: this.state.dataDetail.longitude_in
                                            }}
                                            zoom={15}>
                                            <Marker />
                                        </Map>
                                    </div>
                                </ModalBody>
                            </ModalRs>
                            <ModalRs size='lg' isOpen={this.state.modalMapOut} toggle={() => this.setState({ modalMapOut: false })}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalMapOut: false })}>Detail Lokasi</ModalHeader>
                                <ModalBody>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '250px'
                                    }}>
                                        <Map google={this.props.google}
                                            initialCenter={{
                                                lat: this.state.dataDetail.latitude_out,
                                                lng: this.state.dataDetail.longitude_out
                                            }}
                                            zoom={15}>
                                            <Marker />
                                        </Map>
                                    </div>
                                </ModalBody>
                            </ModalRs>
                            <ModalRs size='md' isOpen={this.state.modalupdate} toggle={() => this.setState({ modalupdate: false })} style={{ justifyContent:"center", alignItems:"center" }}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalupdate: false })}>Apakah Anda Yakin Untuk Clockout Otomatis presensi harian?</ModalHeader>
                                <ModalBody>
                                    <span>Seluruh Data Absensi anda pada hari ini yang tidak memiliki absen pulang akan di generate secara otomatis.</span>
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                                    <button className="btn button-add text-capitalize" onClick={() => this.setState({ modalupdate: false })}>
                                        Batal
                                    </button>
                                <button className='btn btn-info' onClick={() => { this.updateAbsensi() }}>
                                    <span>Yakin</span>
                                </button>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs size='lg' style={{ maxWidth: "1000px" }} isOpen={this.state.modalAdd} toggle={() => this.setState({ modalAdd: false })}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF", padding: "12px 24px" }} toggle={() => this.setState({ modalAdd: false })}><div className='modal-header-reactstrap'>Tambah Presensi</div></ModalHeader>
                                <ModalBody>
                                    <div className='row px-3 mb-3'>
                                        <div className='col-md-3 px-2'>
                                            <label for="exampleFormControlInput1" class="form-label font-edit-label">Tanggal Presensi</label>
                                            <input style={{ fontSize: "12px", paddingTop: "10px", paddingBottom: "10px" }} type="date" name="tanggal" className="form-control font-edit-value" defaultValue={this.state.tanggal} onChange={this.handleChange} />
                                        </div>
                                        <div className='col-md-3 px-2'>
                                            <label for="exampleFormControlInput1" class="form-label font-edit-label">Karyawan</label>
                                            <div className='form-control p-0'>
                                                <Select
                                                    className="font-edit-value"
                                                    placeholder="Nama Karyawan"
                                                    backspaceRemoves={true}
                                                    deleteRemoves={true}
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    value={this.state.karyawan_selected}
                                                    isLoading={this.state.karyawan_loading}
                                                    onFocus={() => this.setState({ karyawan_loading: true }, () => this.searchKaryawan())}
                                                    onBlur={() => this.setState({ karyawan_loading: false })}
                                                    onInputChange={(val) => this.setState({ karyawan_name: val }, () => this.setState({ karyawan_loading: true }, () => this.searchKaryawan()))}
                                                    options={
                                                        this.state.karyawan != null ? (
                                                            this.state.karyawan.map((item, index) =>
                                                            (
                                                                {
                                                                    key: index,
                                                                    value: item.karyawan_id,
                                                                    label: item.karyawan_nama,
                                                                    shift: item.divisi_sift
                                                                }
                                                            )
                                                            ))
                                                            : ("")
                                                    }
                                                    onChange={
                                                        (karyawan_selected) => {
                                                            if (!karyawan_selected) {
                                                                this.setState({
                                                                    karyawan_selected: '',
                                                                    karyawan_id: 0,
                                                                    pickKaryawan: false,
                                                                    shift: []
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    karyawan_selected,
                                                                    karyawan_id: karyawan_selected.value,
                                                                    pickKaryawan: true,
                                                                    shift: karyawan_selected.shift,
                                                                    loading_selected: true,
                                                                    karyawan: []
                                                                }, () => this.searchLokasiIn(), this.searchLokasiOut())
                                                            }
                                                        }
                                                    }
                                                    isClearable={() => {
                                                        this.setState({
                                                            isClearable: true,
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {!this.state.loading_selected && this.state.karyawan_selected &&
                                            <div className='col-md-3 px-2'>
                                                <label htmlFor="exampleFormControlInput1" className="form-label font-edit-label">Shift</label>
                                                <select name="shift_id" className='form-control font-edit-value' onChange={this.handleChange}>
                                                    <option>Pilih Shift</option>
                                                    {this.state.shift.length > 0 &&
                                                        this.state.shift.map((item, index) => {
                                                            return (
                                                                <option value={item.sif.sift_id}>{item.sif.sift_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }
                                        <div className='col-md-3 px-2'></div>
                                    </div>
                                    <hr className='mb-3' />
                                    {this.state.loading_selected ?
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="small"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                        :
                                        this.state.karyawan_selected &&
                                            <div className='row px-3'>
                                            <div className='col-md-4 px-2'>
                                                <div className='card border'>
                                                    <div className='card-header modal-header-reactstrap px-3 border-bottom' style={{ paddingTop: "10px", paddingBottom: "10px", fontSize: "16px", lineHeight: "24px", backgroundColor: "#FAFDFF" }}>Masuk</div>
                                                    <div className='card-body p-3'>
                                                        <div className='mb-1'>
                                                            <label htmlFor="exampleFormControlInput1" className="form-label font-edit-label-2">Jam</label>
                                                            <TimeInput
                                                                style={{ fontSize: "12px" }}
                                                                initTime={this.state.jam_masuk !== null && this.state.jam_masuk.substr(0, 5)}
                                                                className='s-input -time form-control px-3 py-2'
                                                                onTimeChange={(event) => {
                                                                    const value = event;
                                                                    this.setState({
                                                                        jam_masuk: value + ':00'
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="mb-1">
                                                            <label className="form-label font-edit-label-2">Lokasi Presensi</label>
                                                            <select style={{ fontSize: "12px" }} className="form-control" onChange={this.handleChange} name="lokasi_masuk_id">
                                                                <option value="">Pilih Lokasi</option>
                                                                {this.state.lokasi_in.length !== 0 ?
                                                                    this.state.lokasi_in.map((item, index) => {
                                                                        return (
                                                                            <option value={item.lokasi_id}>{item.lokasi_nama}</option>
                                                                        )
                                                                    })
                                                                    : null}
                                                            </select>
                                                        </div>
                                                        <div className='mb-1'>
                                                            <label htmlFor="exampleFormControlInput1" className="form-label font-edit-label-2">Status</label>
                                                            <select style={{ fontSize: "12px" }} name="status_masuk" value={this.state.status_masuk} className="form-control" onChange={this.handleChange}>
                                                                <option value="">Pilih Status</option>
                                                                <option value="Masuk Kerja">Masuk Kerja (Tepat Waktu)</option>
                                                                <option value="Terlambat">Terlambat</option>
                                                                <option value="Izin">Izin</option>
                                                                <option value="Sakit">Sakit</option>
                                                                <option value="Cuti">Cuti</option>
                                                            </select>
                                                        </div>
                                                        <div className='mb-1'>
                                                            <label htmlFor="exampleFormControlInput1" className="form-label font-edit-label-2">Keterangan</label>
                                                            <textarea style={{ fontSize: "12px" }} value={this.state.keterangan_masuk} name="keterangan_masuk" className="form-control" placeholder="Keterangan Presensi..." onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4 px-2'>
                                                <div className='card border'>
                                                    <div className='card-header modal-header-reactstrap px-3 border-bottom' style={{ paddingTop: "10px", paddingBottom: "10px", fontSize: "16px", lineHeight: "24px", backgroundColor: "#FAFDFF" }}>Keluar</div>
                                                    <div className='card-body p-3'>
                                                        <div className='mb-1'>
                                                            <label htmlFor="exampleFormControlInput1" className="form-label font-edit-label-2">Jam</label>
                                                            <TimeInput
                                                                placeholder="Jam Pulang..."
                                                                style={{ fontSize: "12px" }}
                                                                initTime={this.state.jam_keluar ? this.state.jam_keluar.substr(0, 5) : null}
                                                                className='s-input -time form-control px-3 py-2'
                                                                onTimeChange={(event) => {
                                                                    const value = event;
                                                                    this.setState({
                                                                        jam_keluar: value + ':00'
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="mb-1">
                                                            <label className="form-label font-edit-label-2">Lokasi Presensi</label>
                                                            <select style={{ fontSize: "12px" }} className='form-control' name='lokasi_keluar_id' onChange={this.handleChange}>
                                                                <option value="">Pilih Lokasi</option>
                                                                {this.state.lokasi_out.length !== 0 ?
                                                                    this.state.lokasi_out.map((item, index) => {
                                                                        return (
                                                                            <option value={item.lokasi_id}>{item.lokasi_nama}</option>
                                                                        )
                                                                    })
                                                                    : null}
                                                            </select>
                                                        </div>
                                                        <div className='mb-1'>
                                                            <label htmlFor="exampleFormControlInput1" className="form-label font-edit-label-2">Status</label>
                                                            <select style={{ fontSize: "12px" }} name="status_keluar" value={this.state.status_keluar} className="form-control" onChange={this.handleChange}>
                                                                <option value="">Pilih Status</option>
                                                                <option value="Pulang Kerja">Pulang Kerja</option>
                                                                <option value="Sakit">Sakit</option>
                                                                <option value="Izin">Izin</option>
                                                            </select>
                                                        </div>
                                                        <div className='mb-1'>
                                                            <label htmlFor="exampleFormControlInput1" className="form-label font-edit-label-2">Keterangan</label>
                                                            <textarea style={{ fontSize: "12px" }} value={this.state.keterangan_keluar} type="text" name="keterangan_keluar" className="form-control" placeholder="Keterangan Presensi..." onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='card border'>
                                                    <div className='card-header modal-header-reactstrap px-3 border-bottom'
                                                         style={{
                                                             paddingTop: "10px",
                                                             paddingBottom: "10px",
                                                             fontSize: "16px",
                                                             lineHeight: "24px",
                                                             backgroundColor: "#FAFDFF"
                                                         }}>Istirahat (Opsional)
                                                    </div>
                                                    <div className='card-body p-3'>
                                                        <div className='mb-1'>
                                                            <label htmlFor="exampleFormControlInput1"
                                                                   className="form-label font-edit-label-2">Jam Mulai Istirahat</label>
                                                            <TimeInput
                                                                placeholder="Jam Mulai Istirahat..."
                                                                style={{ fontSize: "12px" }}
                                                                initTime={this.state.istirahat_start}
                                                                className='s-input -time form-control px-3 py-2'
                                                                onTimeChange={(event) => {
                                                                    const value = event;
                                                                    this.setState({
                                                                        istirahat_start: value + ':00'
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='mb-1'>
                                                            <label htmlFor="exampleFormControlInput1"
                                                                   className="form-label font-edit-label-2">Jam Berakhir Istirahat</label>
                                                            <TimeInput
                                                                placeholder="Jam Berakhir Istirahat..."
                                                                style={{ fontSize: "12px" }}
                                                                initTime={this.state.istirahat_end}
                                                                className='s-input -time form-control px-3 py-2'
                                                                onTimeChange={(event) => {
                                                                    const value = event;
                                                                    this.setState({
                                                                        istirahat_end: value + ':00'
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                                    <button className="btn button-add text-capitalize" onClick={() => this.setState({ modalAdd: false })}>
                                        Batalkan
                                    </button>
                                    {' '}
                                    <button className="btn btn-primary text-capitalize" onClick={this.tambahAbsensi}>
                                        Simpan
                                    </button>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs size='lg' style={{ maxWidth: "1000px" }} isOpen={this.state.modalEdit} toggle={() => this.setState({ modalEdit: false })}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF", padding: "12px 24px" }} toggle={() => this.setState({ modalEdit: false })}><div className='modal-header-reactstrap'>Edit Presensi</div></ModalHeader>
                                <ModalBody>
                                    <div className='row px-3 mb-3'>
                                        <div className='col-md-3 px-2'>
                                            <label for="exampleFormControlInput1" class="form-label font-edit-label">Tanggal Presensi</label>
                                            <input style={{ fontSize: "12px", paddingTop: "10px", paddingBottom: "10px" }} type="date" name="tanggal" className="form-control font-edit-value" defaultValue={this.state.tanggal} onChange={this.handleChange} />
                                        </div>
                                        <div className='col-md-3 px-2'>
                                            <label for="exampleFormControlInput1" class="form-label font-edit-label">Karyawan</label>
                                            <div className='form-control p-0'>
                                                <Select
                                                    isDisabled={true}
                                                    className="font-edit-value"
                                                    placeholder="Nama Karyawan"
                                                    backspaceRemoves={true}
                                                    deleteRemoves={true}
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    value={this.state.karyawan_selected}
                                                    isLoading={this.state.karyawan_loading}
                                                    onFocus={() => this.setState({ karyawan_loading: true }, () => this.searchKaryawan())}
                                                    onBlur={() => this.setState({ karyawan_loading: false })}
                                                    onInputChange={(val) => this.setState({ karyawan_name: val }, () => this.setState({ karyawan_loading: true }, () => this.searchKaryawan()))}
                                                    options={
                                                        this.state.karyawan != null ? (
                                                            this.state.karyawan.map((item, index) =>
                                                            (
                                                                {
                                                                    key: index,
                                                                    value: item.karyawan_id,
                                                                    label: item.karyawan_nama,
                                                                    shift: item.divisi_sift
                                                                }
                                                            )
                                                            ))
                                                            : ("")
                                                    }
                                                    onChange={
                                                        (karyawan_selected) => {
                                                            if (!karyawan_selected) {
                                                                this.setState({
                                                                    karyawan_selected: '',
                                                                    karyawan_id: 0,
                                                                    pickKaryawan: false,
                                                                    shift: []
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    karyawan_selected,
                                                                    karyawan_id: karyawan_selected.value,
                                                                    pickKaryawan: true,
                                                                    shift: karyawan_selected.shift,
                                                                    karyawan: []
                                                                }, () => this.searchLokasiIn(), this.searchLokasiOut())
                                                            }
                                                        }
                                                    }
                                                    isClearable={() => {
                                                        this.setState({
                                                            isClearable: true,
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-3 px-2'>
                                            <label for="exampleFormControlInput1" class="form-label font-edit-label">Shift</label>
                                            <select name="shift_nama" className='form-control font-edit-value' onChange={this.handleChange}>
                                                <option>Pilih Shift</option>
                                                {this.state.shift.length > 0 &&
                                                    this.state.shift.map((item, index) => {
                                                        return (
                                                            <option value={item.sif.sift_name}
                                                                selected={item.sif.sift_name === this.state.dataDetail.sif_name}>{item.sif.sift_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-3 px-2'></div>
                                    </div>
                                    <hr className='mb-3' />
                                    <div className='row px-3'>
                                        <div className='col-md-4 px-2'>
                                            <div className='card border'>
                                                <div className='card-header modal-header-reactstrap px-3 border-bottom' style={{ paddingTop: "10px", paddingBottom: "10px", fontSize: "16px", lineHeight: "24px", backgroundColor: "#FAFDFF" }}>Masuk</div>
                                                <div className='card-body p-3'>
                                                    <div className='mb-1'>
                                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Jam</label>
                                                        <TimeInput
                                                            style={{ fontSize: "12px" }}
                                                            initTime={this.state.jam_masuk !== null && this.state.jam_masuk.substr(0, 5)}
                                                            className='s-input -time form-control px-3 py-2'
                                                            onTimeChange={(event) => {
                                                                const value = event;
                                                                this.setState({
                                                                    jam_masuk: value + ':00'
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='mb-1'>
                                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Status</label>
                                                        <select style={{ fontSize: "12px" }} name="status_masuk" value={this.state.status_masuk} className="form-control" onChange={this.handleChange}>
                                                            <option value="">Pilih Status</option>
                                                            <option value="Masuk Kerja">Masuk Kerja (Tepat Waktu)</option>
                                                            <option value="Terlambat">Terlambat</option>
                                                            <option value="Izin">Izin</option>
                                                            <option value="Sakit">Sakit</option>
                                                            <option value="Cuti">Cuti</option>
                                                        </select>
                                                    </div>
                                                    <div className='mb-1'>
                                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Keterangan</label>
                                                        <textarea style={{ fontSize: "12px" }} value={this.state.keterangan_masuk} name="keterangan_masuk" className="form-control" placeholder="Keterangan Presensi..." onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 px-2'>
                                            <div className='card border'>
                                                <div className='card-header modal-header-reactstrap px-3 border-bottom' style={{ paddingTop: "10px", paddingBottom: "10px", fontSize: "16px", lineHeight: "24px", backgroundColor: "#FAFDFF" }}>Keluar</div>
                                                <div className='card-body p-3'>
                                                    <div className='mb-1'>
                                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Jam</label>
                                                        <TimeInput
                                                            style={{ fontSize: "12px" }}
                                                            initTime={this.state.jam_keluar && this.state.jam_keluar.substr(0, 5)}
                                                            className='s-input -time form-control px-3 py-2'
                                                            onTimeChange={(event) => {
                                                                const value = event;
                                                                this.setState({
                                                                    jam_keluar: value + ':00'
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='mb-1'>
                                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Status</label>
                                                        <select style={{ fontSize: "12px" }} name="status_keluar" value={this.state.status_keluar} className="form-control" onChange={this.handleChange}>
                                                            <option value="">Pilih Status</option>
                                                            <option value="Pulang Kerja">Pulang Kerja</option>
                                                            <option value="Sakit">Sakit</option>
                                                            <option value="Izin">Izin</option>
                                                        </select>
                                                    </div>
                                                    <div className='mb-1'>
                                                        <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Keterangan</label>
                                                        <textarea style={{ fontSize: "12px" }} value={this.state.keterangan_keluar} type="text" name="keterangan_keluar" className="form-control" placeholder="Keterangan Presensi..." onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='card border'>
                                                <div className='card-header modal-header-reactstrap px-3 border-bottom'
                                                    style={{
                                                        paddingTop: "10px",
                                                        paddingBottom: "10px",
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                        backgroundColor: "#FAFDFF"
                                                    }}>Istirahat (Opsional)
                                                </div>
                                                <div className='card-body p-3'>
                                                    <div className='mb-1'>
                                                        <label htmlFor="exampleFormControlInput1"
                                                            className="form-label font-edit-label-2">Jam Mulai Istirahat</label>
                                                        <TimeInput
                                                            placeholder="Jam Mulai Istirahat..."
                                                            style={{ fontSize: "12px" }}
                                                            initTime={this.state.istirahat_start}
                                                            className='s-input -time form-control px-3 py-2'
                                                            onTimeChange={(event) => {
                                                                const value = event;
                                                                this.setState({
                                                                    istirahat_start: value + ':00'
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='mb-1'>
                                                        <label htmlFor="exampleFormControlInput1"
                                                            className="form-label font-edit-label-2">Jam Berakhir Istirahat</label>
                                                        <TimeInput
                                                            placeholder="Jam Berakhir Istirahat..."
                                                            style={{ fontSize: "12px" }}
                                                            initTime={this.state.istirahat_end}
                                                            className='s-input -time form-control px-3 py-2'
                                                            onTimeChange={(event) => {
                                                                const value = event;
                                                                this.setState({
                                                                    istirahat_end: value + ':00'
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                                    <button className="btn button-add text-capitalize" onClick={() => this.setState({ modalEdit: false })}>
                                        Batalkan
                                    </button>
                                    {' '}
                                    <button className="btn btn-primary text-capitalize" onClick={this.simpanAbsensi}>
                                        Simpan
                                    </button>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalDetail} toggle={() => this.setState({ modalDetail: false })}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalDetail: false })}><div className='modal-header-reactstrap'>Laporan Presensi Karyawan</div></ModalHeader>
                                <ModalBody>
                                    <div className='d-flex flex-row warning-info px-2 mb-2'>
                                        <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                        <div className='px-0' style={{ fontSize: "14px" }}>
                                            Perlu diketahui, setiap 3 bulan sekali foto presensi karyawan akan mereset secara otomatis. Apabila terdapat pertanyaan lebih lanjut dapat menghubungi Customer Service
                                        </div>
                                    </div>
                                    <div className='row px-3'>
                                        <div className='col-md-3 pl-0 pr-1'>
                                            <div className='card border px-0'>
                                                <div className='card-body px-2 py-2'>
                                                    <h5 className='font-14 mb-0' style={{ fontWeight: 700, lineHeight: "24px", color: "#143B5E" }}>Presensi Masuk</h5>
                                                    <div className='d-flex justify-content-start' style={{ fontSize: "12px", fontWeight: 500, lineHeight: "16px", marginBottom: "12px" }}>
                                                        <div className='mr-1'>{this.state.dataDetail.jam_masuk_absen}</div>
                                                        <div className={detail_statusin !== "Masuk Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{detail_statusin.replace(" Kerja", "")}</div></div>
                                                    <div className='mb-3'>
                                                        {this.state.dataDetail.foto_in !== null ?
                                                            <img style={{ width: "100%", borderRadius: "8px" }} src={IMG_HOST + 'frontapp/absensi/' + this.state.dataDetail.foto_in} alt="foto presensi masuk" />
                                                            :
                                                            <img style={{ width: "100%", borderRadius: "8px" }} src={IMG_HOST + "user-profile.png"} alt="foto presensi masuk" />
                                                        }
                                                    </div>
                                                    <div>
                                                        <button className='btn button-add text-capitalize btn-block' onClick={() => this.setState({ modalDetail: false }, () => this.setState({ modalMapIn: true }))}>Lihat Lokasi</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 pl-1 pr-0 mx-0'>
                                            <div className='card border px-0'>
                                                <div className='card-body px-2 py-2'>
                                                    <h5 className='font-14 mb-0' style={{ fontWeight: 700, lineHeight: "24px", color: "#143B5E" }}>Presensi Keluar</h5>
                                                    <div className='d-flex justify-content-start' style={{ fontSize: "12px", fontWeight: 500, lineHeight: "16px", marginBottom: "12px" }}>
                                                        <div className='mr-1'>{this.state.dataDetail.jam_keluar_absen === null || this.state.dataDetail.jam_keluar_absen === "" ? "__:__:__" : this.state.dataDetail.jam_keluar_absen}</div>
                                                        <div className={detail_statusout !== "Masuk Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{detail_statusout.replace(" Kerja", "")}</div></div>
                                                    <div className='mb-3'>
                                                        {this.state.dataDetail.foto_out !== null ?
                                                            <img style={{ width: "100%", borderRadius: "8px" }} src={IMG_HOST + 'frontapp/absensi/' + this.state.dataDetail.foto_out} alt="foto presensi masuk" />
                                                            :
                                                            <img style={{ width: "100%", borderRadius: "8px" }} src={IMG_HOST + "user-profile.png"} alt="foto presensi masuk" />
                                                        }
                                                    </div>
                                                    <div>
                                                        <button className='btn button-add text-capitalize btn-block' onClick={() => this.setState({ modalDetail: false }, () => this.setState({ modalMapOut: true }))}>Lihat Lokasi</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 pl-3 pr-0 mx-0'>
                                            <div className='card border'>
                                                <div className='card-header px-3 py-2' style={{ backgroundColor: "#FAFDFF", color: "#143B5E", fontSize: "16px", fontWeight: 700 }}>Presensi {this.state.dateFilter.format('dddd')} {this.state.dateFilter.format('LL')}</div>
                                                <div className='card-body px-3'>
                                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                                        <div className='col-md-5 font-detail-label pr-0'>Nama Karyawan</div>
                                                        <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                        <div className='col-md-6 px-0 font-detail-value'>{this.state.dataDetail.karyawan ? this.state.dataDetail.karyawan.karyawan_nama : "-"}</div>
                                                    </div>
                                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                                        <div className='col-md-5 font-detail-label pr-0'>Divisi</div>
                                                        <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                        <div className='col-md-6 px-0 font-detail-value'>
                                                            {this.state.dataDetail.divisi ? this.state.dataDetail.divisi.divisi_name : "-"}
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                                        <div className='col-md-5 font-detail-label pr-0'>Shift</div>
                                                        <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                        <div className='col-md-6 px-0 font-detail-value'>
                                                            {this.state.dataDetail.sif_name}
                                                        </div>
                                                    </div>
                                                    {this.state.dataDetail && this.state.dataDetail.istirahat_mulai ?
                                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                                            <div className='col-md-5 font-detail-label pr-0'>Jam Mulai Istirahat</div>
                                                            <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                            <div className='col-md-6 px-0 font-detail-value'>
                                                                {this.state.dataDetail.istirahat_mulai}
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {this.state.dataDetail && this.state.dataDetail.istirahat_selesai ?
                                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                                            <div className='col-md-5 font-detail-label pr-0'>Jam Selesai Istirahat</div>
                                                            <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                            <div className='col-md-6 px-0 font-detail-value'>
                                                                {this.state.dataDetail.istirahat_selesai}
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                                        <div className='col-md-5 font-detail-label pr-0'>Keterangan Presensi Masuk</div>
                                                        <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                        <div className='col-md-6 px-0 font-detail-value'>
                                                            {this.state.dataDetail.keterangan_in === "" || this.state.dataDetail.keterangan_in === null ?
                                                                "-" : this.state.dataDetail.keterangan_in}
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                                        <div className='col-md-5 font-detail-label pr-0'>Keterangan Presensi Pulang</div>
                                                        <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                        <div className='col-md-6 px-0 font-detail-value'>
                                                            {this.state.dataDetail.keterangan_out === "" || this.state.dataDetail.keterangan_out === null ?
                                                                "-" : this.state.dataDetail.keterangan_out}
                                                        </div>
                                                    </div>
                                                    {this.state.dataDetail && this.state.dataDetail.status_in === "Izin" ?
                                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                                            <div className='col-md-5 font-detail-label pr-0'>Keterangan Izin</div>
                                                            <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                            <div className='col-md-6 px-0 font-detail-value'>
                                                                {this.state.dataDetail.keterangan_cuti}
                                                            </div>
                                                        </div>
                                                        : this.state.dataDetail && this.state.dataDetail.status_out === "Izin" ?
                                                            <div className='row d-flex justify-content-start flex-nowrap'>
                                                                <div className='col-md-5 font-detail-label pr-0'>Keterangan Izin</div>
                                                                <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                                <div className='col-md-6 px-0 font-detail-value'>
                                                                    {this.state.dataDetail.keterangan_cuti}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                    {this.state.dataDetail && this.state.dataDetail.status_in === "Cuti" ?
                                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                                            <div className='col-md-5 font-detail-label pr-0'>Keterangan Cuti</div>
                                                            <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                            <div className='col-md-6 px-0 font-detail-value'>
                                                                {this.state.dataDetail.keterangan_cuti}
                                                            </div>
                                                        </div>
                                                        : this.state.dataDetail && this.state.dataDetail.status_out === "Cuti" ?
                                                            <div className='row d-flex justify-content-start flex-nowrap'>
                                                                <div className='col-md-5 font-detail-label pr-0'>Keterangan Cuti</div>
                                                                <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                                <div className='col-md-6 px-0 font-detail-value'>
                                                                    {this.state.dataDetail.keterangan_cuti}
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                                    <button className='btn btn-primary text-capitalize' style={{ fontFamily: 'DM Sans', fontSize: "14px", fontWeight: "14px" }} onClick={() => this.setState({ modalDetail: false }, () => this.setState({ modalEdit: true }))}>Edit Presensi</button>
                                </ModalFooter>
                            </ModalRs>
                            <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                                    <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                                </ModalHeader>
                                <ModalBody>
                                    <div className="list-group">
                                        <div>
                                            {this.state.loadingHistory && (
                                                <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                                    <Spin
                                                        size="large"
                                                        tip="Loading..."
                                                        style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {this.state.dataHistory.length !== 0 && (
                                                <div>
                                                    <Row>
                                                        <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                            <h4>User</h4>
                                                        </Col>
                                                        <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                                            <h4>Aktivitas</h4>
                                                        </Col>
                                                    </Row>

                                                    {this.state.dataHistory.map((item, index) => {
                                                        return (
                                                            <div key={index} style={{ marginBottom: '20px' }}>
                                                                <Row>
                                                                    <Col xs={12} md={6} className="text-left">
                                                                        <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                            <div style={{ marginRight: '16px' }}>
                                                                                <img
                                                                                    src="https://servicesdev.presensi.co.id/media/logo-default-user.png"
                                                                                    alt=""
                                                                                    style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-0 font-13"
                                                                                    style={{
                                                                                        fontWeight: 500,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    {item.p_user_name} | {item.level_name}
                                                                                </h6>
                                                                                <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                      {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                                </p>
                                                                                <div style={{ marginTop: 12 }}>
                                                                                    {item.history_status === 'created' ? (
                                                                                        <div
                                                                                            className="m-0"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '50%',
                                                                                                color: '#057CE4',
                                                                                                borderColor: '#057CE4',
                                                                                            }}
                                                                                        >
                                                                                            <div className="d-flex justify-content-center" style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                                Dibuat
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div
                                                                                            className="m-0"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '50%',
                                                                                                color: '#E6462E',
                                                                                                borderColor: '#E6462E',
                                                                                            }}
                                                                                        >
                                                                                            <div className="d-flex justify-content-center" style={{
                                                                                                paddingTop: '6px',
                                                                                                paddingBottom: '6px'
                                                                                            }}>
                                                                                                Diubah
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                        {item.history_status !== 'created' && (
                                                                            <div>
                                                                                {item.parent_history ?
                                                                                        item.parent_history.jam_masuk_absen != item.jam_masuk_absen && (
                                                                                        <div>
                                                                                            <h6
                                                                                               className="mb-3 font-13 text-left"
                                                                                                      style={{
                                                                                                          fontWeight: 500,
                                                                                                          fontSize: '16px',
                                                                                                          lineHeight: '24px',
                                                                                                      }}
                                                                                            >
                                                                                                Jam Masuk Absen: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.jam_masuk_absen
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.jam_masuk_absen ? item.jam_masuk_absen : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.jam_keluar_absen != item.jam_keluar_absen && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Jam Keluar Absen: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.jam_keluar_absen
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.jam_keluar_absen ? item.jam_keluar_absen : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.sif_name != item.sif_name && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Shift: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.sif_name
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.sif_name ? item.sif_name : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.status_in != item.status_in && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Status Masuk: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.status_in
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.status_in ? item.status_in : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.status_out != item.status_out && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Status Keluar: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.status_out
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.status_out ? item.status_out : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.istirahat_mulai != item.istirahat_mulai && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Istirahat Mulai: dari{' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.istirahat_mulai
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.istirahat_mulai ? item.istirahat_mulai : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.istirahat_selesai != item.istirahat_selesai && (
                                                                                        <div>
                                                                                            <h6
                                                                                                className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}
                                                                                            >
                                                                                                Istirahat Mulai: dari {' '}
                                                                                                {item.parent_history
                                                                                                    ? item.parent_history.istirahat_selesai
                                                                                                    : 'Kosong'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                />jadi: {item.istirahat_selesai ? item.istirahat_selesai : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                        {this.state.dataHistory.length !== 0 &&
                                            <>
                                                <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} dari {this.state.totalDataHistory} data</div>
                                                <div className='col'>
                                                    <ReactPaginate
                                                        previousLabel={<img src={ArrowLeft} alt='' />}
                                                        nextLabel={<img src={ArrowRight} alt='' />}
                                                        pageCount={this.state.pageCountHistory}
                                                        forcePage={this.state.currentPageHistory}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageHistory}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link custom-page-link'}
                                                        containerClassName={'pagination my-2'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link custom-page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                                        activeClassName={'active custom-active'}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>

                                </ModalFooter>
                            </ModalRs>
                            <div className='row d-flex justify-content-between mx-0 mt-3 px-3'>
                                <div className="col-md-8 row m-0">
                                    <div className='col-auto px-0 mb-2'>
                                        <div className='d-flex flex-nowrap' style={{ width: "100%" }}>
                                            <button className='ph-filter-date-left' onClick={() => this.setState({
                                                dateFilter: moment(this.state.dateFilter).subtract(1, 'days'),
                                                loading: true
                                            }, () => this.loadData())}>
                                                <img src={ArrowLeft} alt="" style={{ height: "16px", width: "16px" }} />
                                            </button>
                                            <DatePicker
                                                selected={this.state.valueFilterDate}
                                                customInput={<button className='ph-filter-date-mid btn-tgl-absen'>{this.state.dateFilter.format('L')}</button>}
                                                onSelect={(e) =>
                                                    this.setState({
                                                        dateFilter: moment(e),
                                                        valueFilterDate: e,
                                                        loading: true
                                                    }, () => this.loadData())
                                                }
                                            />
                                            <button className='ph-filter-date-right' onClick={() => this.setState({
                                                dateFilter: moment(this.state.dateFilter).add(1, 'days'),
                                                loading: true
                                            }, () => this.loadData())}>
                                                <img src={ArrowRight} alt="" style={{ height: "16px", width: "16px" }} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-md-5 pr-0 filter-ml col-name-filter'>
                                        <input name="filter_nama"
                                               className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name" : "form-control filter-name"}
                                               placeholder="       Cari nama karyawan ..."
                                               type="text"
                                               style={{ backgroundColor: "#F9F9F9", fontSize: "12px", fontWeight: 400, paddingTop: "9px", paddingBottom: "9px" }}
                                               required=""
                                               value={this.state.filter_nama}
                                               onChange={this.handleChange}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()
                                                   if (event.which == 13) {

                                                       this.setState({
                                                           page: 0,
                                                           paging: this.state.paging,
                                                           loading: true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}
                                        />
                                    </div>
                                    <div className='col-auto pr-0 filter-ml col-btn-fltr-absen'>
                                        <button className='btn primary button-filter px-2 btn-fltr-absen' style={{ width:"94px" }} align="center" onClick={() => this.setState({ filter: !this.state.filter })}><img src={FilterIcon} />Filter</button>
                                    </div>
                                    <div className='col-auto pr-0 filter-ml pr-0'>
                                        <button className='btn secondary button-filter px-2' onClick={
                                            () => {
                                                this.setState({
                                                    loading: true,
                                                    filter_nama: "",
                                                    filter_lokasi: "",
                                                    filter_divisi: "",
                                                    filter_belum_masuk: "",
                                                    filter_belum_keluar: "",
                                                    filter_status: "",
                                                    filter_shift: "",
                                                    dateFilter: moment()
                                                }, () => this.loadData())
                                            }
                                        }><img src={RefreshIcon} /></button>
                                    </div>
                                </div>
                                <div className="col-md-4 row m-0 d-flex justify-content-end">
                                    <div className='col-auto px-0 offset-md-2 col-btn-add-absen'>
                                            <button onClick={() => {
                                                this.setState({
                                                    modalAdd: true,
                                                    jam_masuk: moment().format('HH:mm'),
                                                    jam_keluar: moment().format('HH:mm'),
                                                    istirahat_start: null,
                                                    istirahat_end: null,
                                                    karyawan_id: 0,
                                                    shift_nama: "",
                                                    lokasi_masuk: 0,
                                                    status_masuk: "",
                                                    keterangan_masuk: "",
                                                    lokasi_keluar: 0,
                                                    status_keluar: "",
                                                    keterangan_keluar: "",
                                                    karyawan_selected: null,
                                                    shift: [],
                                                    tanggal: moment(this.state.dateFilter._d).format("YYYY-MM-DD")
                                                })
                                            }} className='btn btn-primary px-3 text-capitalize pull-right width-filter tombol-tambah btn-add-absen'><img src={IconPlusWhite} className='mr-2' alt="" /> Tambah Presensi</button>
                                    </div>
                                    <div className='col-auto px-0' align="center">
                                            <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                <div className={'p-2'}>
                                                <a onClick={() => { this.setState({modalexport: true}) }} style={{ marginRight: "50px" }} className={'text-dark'}>Ekspor Excel</a><br />
                                                <a onClick={() => { this.setState({modaldateclockout: true})}}
                                                   className={'text-dark'}>Clockout Otomatis</a><br />
                                            </div>
                                            }>
                                                <a><i
                                                style={{
                                                    width:"15",
                                                    fontSize: 18,
                                                    color: 'rgb(51 51 51)',
                                                    alignItems:"center",
                                                    height:"38px",
                                                    paddingRight: "30px"
                                                }}
                                                className='btn border border-dark border-1 px-2'
                                                ><img src={MoreIcon} /></i></a>
                                            </Tooltip>
                                    </div>
                                </div>
                            </div>
                            {this.state.filter &&
                                <div className="col-xl-12 px-4">
                                    <div className="row mx-0 mb-2">
                                        <div className='col-md-2 px-1'>
                                            <label className='form-label' style={{ fontSize: "12px" }}>Lokasi</label>
                                            <select className='form-control' name="filter_lokasi" value={this.state.filter_lokasi} onChange={this.handleFilter} style={{ fontSize: "12px" }}>
                                                <option value="">Pilih Lokasi</option>
                                                {this.state.data_lokasi.length !== 0 &&
                                                    this.state.data_lokasi.map((item, index) => {
                                                        return (
                                                            <option value={item.lokasi_id}>{item.lokasi_nama}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-2 px-1 mb-2'>
                                            <label className='form-label' style={{ fontSize: "12px" }}>Divisi</label>
                                            <select name="filter_divisi" className='form-control' value={this.state.filter_divisi} style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                                <option value="">Pilih Divisi</option>
                                                {this.state.data_divisi.length !== 0 &&
                                                    this.state.data_divisi.map((item, index) => {
                                                        return (
                                                            <option value={item.divisi_id}>{item.divisi_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-2 px-1 mb-2'>
                                            <label className='form-label' style={{ fontSize: "12px" }}>Shift</label>
                                            <select name="filter_shift" className='form-control' value={this.state.filter_shift} style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                                <option value="">Pilih Shift</option>
                                                {this.state.data_shift.length !== 0 &&
                                                    this.state.data_shift.map((item, index) => {
                                                        return (
                                                            <option value={item.sift_id}>{item.sift_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='btn-group width-filter px-1'>
                                        <button className='btn text-capitalize custom-btn-group filter-masuk' onClick={() => this.setState({ filter_belum_masuk: "", filter_belum_keluar: "", filter_status: "", loading: true }, () => this.loadData())}>Masuk</button>
                                        <button className='btn text-capitalize custom-btn-group filter-belum-masuk' onClick={() => this.setState({ filter_belum_masuk: "belum_masuk", filter_belum_keluar: "", filter_status: "", loading: true }, () => this.loadData())}>Belum Masuk</button>
                                        <button className='btn text-capitalize custom-btn-group filter-belum-keluar' onClick={() => this.setState({ filter_belum_keluar: "belum_keluar", filter_belum_masuk: "", loading: true }, () => this.loadData())}>Belum Keluar</button>
                                        <button className='btn text-capitalize custom-btn-group filter-terlambat' onClick={() => this.setState({ filter_status: "terlambat", filter_belum_masuk: "", filter_belum_keluar: "", loading: true }, () => this.loadData())}>Terlambat</button>
                                        <button className='btn text-capitalize custom-btn-group filter-izin' onClick={() => this.setState({ filter_status: "izin", filter_belum_masuk: "", filter_belum_keluar: "", loading: true }, () => this.loadData())}>Izin</button>
                                    </div>
                                </div>
                            }
                            <div className="card-body daftar-absen padding-list">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <div className='row'>
                                        <div className='col' style={{ textAlign: "center" }}>
                                            <img src={EmptyDataImage} alt="icon data kosong" />
                                            <p className='mb-3'>Data Kosong ...</p>
                                        </div>
                                    </div>
                                    :
                                    <div className='row'>
                                        <div className='col pr-custom'>
                                            <div className='table-responsive'>
                                                <div className='border-table mb-5'>
                                                    <table className='table table-striped table-borderless' style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th className="th-font th-padding">#</th>
                                                                <th className="th-font th-padding" style={{ maxWidth: "150px" }}>
                                                                    <div className='d-flex'>
                                                                        <div className='izin-th-style'>Nama </div>
                                                                            <div className='ml-1 d-flex flex-column pt-1'>
                                                                                <img alt='' onClick={() => {
                                                                                    this.sortablename(1)
                                                                                }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowUpIcon} />
                                                                                <img alt='' onClick={() => {
                                                                                    this.sortablename(0)
                                                                                }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowDownIcon} />
                                                                    </div>
                                                                </div>
                                                                </th>
                                                                <th className="th-font th-padding" style={{ minWidth: "155px" }}>
                                                                    <div className='d-flex'>
                                                                        <div className='izin-th-style'>Jam Masuk </div>
                                                                        <div className='ml-1 d-flex flex-column pt-1'>
                                                                            <img alt='' onClick={() => {
                                                                                this.sortabledate(1)
                                                                            }} style={{ width: "8px", height: "8px" }} className='active-sort-by' src={ArrowUpIcon} />
                                                                            <img alt='' onClick={() => {
                                                                                this.sortabledate(0)
                                                                            }} style={{ width: "8px", height: "8px" }} className='active-sort-by' src={ArrowDownIcon} />
                                                                    </div>
                                                                </div>
                                                                </th>
                                                                <th className="th-font th-padding" style={{ minWidth: "100px" }}>Istirahat</th>
                                                                <th className="th-font th-padding" style={{ minWidth: "103px" }}>Jam Keluar</th>
                                                                <th className="th-font th-padding" style={{ minWidth: "105px" }}>Jam Aktual</th>
                                                                <th className="th-font th-padding" style={{ minWidth: "135px" }}>Total Jam Kerja</th>
                                                                <th className="th-font th-padding" style={{ minWidth: "135px" }}>Keterangan</th>
                                                                <th className="th-font th-padding text-center" style={{ minWidth: "105px" }}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ border: "1px" }}>
                                                            {this.state.loading &&
                                                                <tr>
                                                                    <td colSpan={9}>
                                                                        <Spin size="large" tip="Loading..." style={{
                                                                            width: '100%',
                                                                            margin: 'auto',
                                                                            marginBottom: '10px',
                                                                            marginTop: '10px'
                                                                        }} />
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {this.state.data.map((item, index) => {
                                                                no += 1
                                                                let backgroundColor = (no % 2 === 0) ? "#ffffff" : "#f2f2f2"

                                                                    return (
                                                                        <tr>
                                                                            <td className='td-font th-padding' align='left'>{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                            <td className='td-font th-padding' align='left' style={{ maxWidth: "140px" }}>{item.karyawan_nama}</td>
                                                                            <td className='td-font th-padding' align="left"><div className='jam-masuk'>{this.state.filter_belum_masuk === "belum_masuk" ? "__:__:__" : item.absen && item.absen.jam_masuk_absen ? item.absen.jam_masuk_absen : "__:__:__"}</div></td>
                                                                            <td className='td-font th-padding' align='left'>{item.absen && item.absen.istirahat_mulai !== null ? item.absen.istirahat_mulai.slice(0, 5) : "__:__"}-{item.absen && item.absen.istirahat_selesai !== null ? item.absen.istirahat_selesai.slice(0, 5) : "__:__"}</td>
                                                                            <td className='td-font th-padding' align="left">
                                                                                {item.absen && item.absen.jam_keluar_absen !== null ?
                                                                                    <div>{this.state.filter_belum_masuk === "belum_masuk" ? "__:__:__" : item.absen.jam_keluar_absen}</div>
                                                                                    :
                                                                                    <div>__:__:__</div>
                                                                                }
                                                                            </td>
                                                                            <td className='td-font th-padding' align="left">{this.state.filter_belum_masuk === "belum_masuk" ? "-" : item.absen && item.absen.jam_terhitung ? item.absen.jam_terhitung : "-"}</td>
                                                                            <td className='td-font th-padding' align="left">{this.state.filter_belum_masuk === "belum_masuk" ? "-" : item.absen && item.absen.jam_kerja ? item.absen.jam_kerja : "-"}</td>
                                                                            <td className='td-font th-padding border-top-0' align="left">
                                                                                <div className='d-flex flex-nowrap'>
                                                                                    {this.state.filter_belum_masuk === "belum_masuk" ?
                                                                                        <div>Belum Masuk</div>
                                                                                        :
                                                                                        <>
                                                                                            <div className={item.absen && item.absen.status_in !== "Masuk Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{item.absen && item.absen.status_in.replace(" Kerja", "")}</div>
                                                                                            <div>-</div>
                                                                                            <div className={item.absen && item.absen.status_out !== "Pulang Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{item.absen && item.absen.status_out.replace(" Kerja", "")}</div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className='td-font th-padding px-0' align="left">
                                                                                {this.state.filter_belum_masuk === "belum_masuk" ?
                                                                                    <Link to="/tambah-presensi" className='btn button-add px-3 text-capitalize width-filter'><img src={PlusIcon} /> Tambah</Link>
                                                                                    :
                                                                                    <>
                                                                                        <a className='mr-3' onClick={() => this.setState({ idDetail: item.absen.id_absensi },
                                                                                            () => this.loadDetail("detail"))}>
                                                                                            <img src={eyeIcon} />
                                                                                        </a>
                                                                                        <a onClick={() => this.setState({ idDetail: item.absen.id_absensi }, () => this.loadDetail("edit"))} className="mr-3">
                                                                                            <img src={pencilIcon} />
                                                                                        </a>
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                idDel: item.absen.id_absensi
                                                                                            })
                                                                                            this.wantDelete()
                                                                                        }}>
                                                                                            <img src={trashIcon} />
                                                                                        </a>
                                                                                    </>
                                                                                }
                                                                            </td>

                                                                        </tr>
                                                                    )
                                                                })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >
                                        }
                                    </div >
                                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                        {this.state.data.length !== 0 &&
                                            <>
                                                <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} dari {this.state.totalData} data</div>
                                                <div className='col'>
                                                    <ReactPaginate
                                                        previousLabel={<img src={ArrowLeft} alt='' />}
                                                        nextLabel={<img src={ArrowRight} alt='' />}
                                                        pageCount={this.state.pageCount}
                                                        forcePage={this.state.currentPage}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageClick}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link custom-page-link'}
                                                        containerClassName={'pagination my-2'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link custom-page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                                        activeClassName={'active custom-active'}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: APIKEY
})(List_absensi));
