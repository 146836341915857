import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Swal from "sweetalert2";
import ImgCrop from "antd-img-crop";
import { Select, Upload } from "antd";
import { API_HOST, IMG_HOST } from "../../helper/host";
import { Settings, User } from "react-feather";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { CloudUpload } from "@material-ui/icons";
import UploadIcon from '../../assets/icons/import-icon-blue-light.svg';

export class GuideKaryawanEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            jabatan: [],
            lokasi: [],
            fileList: [],
            fileKtp: [],
            fileSelfie: [],
            data_karyawan: [],
            divisi: [],
            perusahaan_karyawan: '',
            foto_karyawan: '',
            foto_ktp: '',
            foto_selfie: '',
            nama_karyawan: '',
            no_induk_karyawan: '',
            gender_karyawan: '',
            jabatan_karyawan: '',
            divisi_karyawan: '',
            lokasi_karyawan: null,
            lokasi_karyawanData: '',
            email_karyawan: '',
            password_karyawan: '',
            ttl_karyawan: '',
            join_date_karyawan: '',
            no_hp_karyawan: '',
            alamat_karyawan: '',
            gaji_karyawan: '',
            status_karyawan: ''
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handlePressChange(event) {


        let formData = {
            karyawan_id: this.props.match.params.id,
        }

        this.props.request('karyawan/ubah-status', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message)
                    this.loadDataKaryawan()
                }
                else {
                    if (Array.isArray(response.message)) {

                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                }
            })
    }

    loadJabatan() {
        let form = {}
        this.props.request("get_jabatan_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        jabatan: response.data,
                    })
                }
            });
    }

    loadLokasi() {
        let form = {}
        this.props.request("get_lokasi_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        lokasi: response.data,
                    })
                }
            });
    }
    lokasiHandler = () => {
        if (this.state.lokasi_karyawan === null) {
            if (this.state.lokasi_karyawanData !== null) {
                this.setState({
                    lokasi_karyawan: this.state.lokasi_karyawanData
                })
            } else {
                this.setState({
                    lokasi_karyawan: "" + this.state.lokasi[0].lokasi_id + ""
                })
            }
        } else {
            this.setState({
                lokasi_karyawan: null
            })
        }
    }

    simpanKaryawan = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            karyawan_id: this.props.match.params.id,
            lokasi_karyawan: this.state.lokasi_karyawan,
            foto_karyawan: this.state.foto_karyawan === null ? this.state.data_karyawan.karyawan_foto : this.state.foto_karyawan,
            foto_ktp: this.state.foto_ktp === null ? this.state.data_karyawan.karyawan_ktp : this.state.foto_ktp,
            foto_selfie: this.state.foto_selfie === null ? this.state.data_karyawan.karyawan_selfie : this.state.foto_selfie,
            nama_karyawan: this.state.nama_karyawan,
            no_induk_karyawan: this.state.no_induk_karyawan,
            gender_karyawan: this.state.gender_karyawan,
            email_karyawan: this.state.email_karyawan,
            jabatan_karyawan: this.state.jabatan_karyawan,
            divisi_karyawan: this.state.divisi_karyawan,
            ttl_karyawan: this.state.ttl_karyawan,
            join_date_karyawan: this.state.join_date_karyawan,
            no_hp_karyawan: this.state.no_hp_karyawan,
            alamat_karyawan: this.state.alamat_karyawan,
            outlet: this.state.pilih_outlet
        }
        this.props.request("karyawan/update_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.success,
                        icon: 'success'
                    })
                    this.props.history.push('/guide/karyawan')
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    simpanPasswordKaryawan = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id_karyawan: this.props.match.params.id,
            password_baru: this.state.password_baru,
        }

        this.props.request("karyawan/update_password_karyawan", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/guide/karyawan')
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadDivisi() {
        let form = {}
        this.props.request("get_divisi_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: false,
                        divisi: response.data
                    })
                }
            });
    }

    loadDataKaryawan() {
        let form = {
            id_karyawan: this.props.match.params.id
        }
        this.props.request("karyawan/get_karyawan_by_id", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_karyawan: response.data,
                        jabatan_karyawan: response.data.jabatan_id,
                        divisi_karyawan: response.data.divisi_id,
                        lokasi_karyawan: response.data.lokasi_id,
                        lokasi_karyawanData: response.data.lokasi_id,
                        nama_karyawan: response.data.karyawan_nama,
                        no_induk_karyawan: response.data.karyawan_no_induk,
                        gender_karyawan: response.data.karyawan_gender,
                        foto_karyawan: response.data.karyawan_foto,
                        foto_ktp: response.data.karyawan_ktp,
                        foto_selfie: response.data.karyawan_selfie,
                        email_karyawan: response.data.karyawan_email,
                        ttl_karyawan: response.data.karyawan_ttl,
                        join_date_karyawan: response.data.karyawan_join_date,
                        no_hp_karyawan: response.data.no_hp,
                        alamat_karyawan: response.data.karyawan_alamat,
                        status_karyawan: response.data.status_karyawan
                    })
                    if (response.data.karyawan_foto !== null) {
                        this.setState({
                            fileList: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_foto,
                                    status: "done",
                                    url: IMG_HOST + 'frontapp/karyawan/' + response.data.karyawan_foto
                                }
                            ],
                        })
                    }

                    if (response.data.karyawan_selfie !== null) {
                        this.setState({
                            fileSelfie: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_selfie,
                                    status: "done",
                                    url: IMG_HOST + 'frontapp/karyawan/' + response.data.karyawan_selfie
                                }
                            ],
                        })
                    }

                    if (response.data.karyawan_ktp !== null) {
                        this.setState({
                            fileKtp: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_ktp,
                                    status: "done",
                                    url: IMG_HOST + 'frontapp/karyawan/' + response.data.karyawan_ktp
                                }
                            ],
                        })
                    }
                }
            });
    }



    componentDidMount() {
        this.loadLokasi()
        this.loadJabatan()
        this.loadDivisi()
        this.loadDataKaryawan()
    }

    render() {
        const { Option } = Select;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Edit Karyawan - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <Breadcrumb title="Edit Karyawan" parent="Karyawan" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Edit Karyawan</h5>
                                </div>
                                <div className="card-body">
                                    <Tabs>
                                        <TabList className="nav nav-tabs tab-coupon">
                                            <Tab className="nav-link"><User className="mr-2" /><b>Detail Karyawan</b></Tab>
                                            <Tab className="nav-link"><Settings className="mr-2" /><b>Ganti Password</b></Tab>
                                        </TabList>

                                        <TabPanel>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <ImgCrop
                                                        rotate
                                                        width={750}
                                                        height={1000}>
                                                        <Upload customRequest={({ file, onSuccess }) => {
                                                            setTimeout(() => {
                                                                onSuccess("ok");
                                                            }, 0);
                                                        }}
                                                            listType="picture-card"
                                                            fileList={this.state.fileList}
                                                            className="antd-upload-custom"
                                                            onRemove={() => {
                                                                this.setState({ foto_karyawan: null })
                                                            }}
                                                            onChange={(file) => {
                                                                this.setState({ fileList: file.fileList })

                                                                if (file.fileList.length > 0) {
                                                                    let files = file.file.originFileObj
                                                                    let reader = new FileReader()

                                                                    reader.readAsDataURL(files)
                                                                    reader.onloadend = () => {
                                                                        this.setState({
                                                                            foto_karyawan: {
                                                                                name: file.file.name,
                                                                                file: reader.result
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                else {
                                                                    this.setState({ foto_karyawan: [] })
                                                                }
                                                            }}
                                                        >
                                                            {this.state.fileList.length >= 1 ? null :
                                                                <div className="p-2" style={{ height: "167px" }}>
                                                                    <span>
                                                                        <img src={UploadIcon} style={{ height: "24px", width: "24px" }} />
                                                                    </span>
                                                                    <p className="ant-upload-text mb-3" style={{ fontSize: "12px", fontWeight: 400, lineHeight: "16px", marginTop: "6px" }}>Upload foto karyawan</p>
                                                                    <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                                </div>
                                                            }
                                                        </Upload>
                                                    </ImgCrop>
                                                </div>
                                                <div className="col-md-3">
                                                    <ImgCrop
                                                        rotate
                                                        width={550}
                                                        height={860}>
                                                        <Upload customRequest={({ file, onSuccess }) => {
                                                            setTimeout(() => {
                                                                onSuccess("ok");
                                                            }, 0);
                                                        }}
                                                            listType="picture-card"
                                                            fileList={this.state.fileSelfie}
                                                            className="antd-upload-custom"
                                                            onRemove={() => {
                                                                this.setState({ foto_selfie: null })
                                                            }}
                                                            onChange={(file) => {
                                                                this.setState({ fileSelfie: file.fileList })

                                                                if (file.fileList.length > 0) {
                                                                    let files = file.file.originFileObj
                                                                    let reader = new FileReader()

                                                                    reader.readAsDataURL(files)
                                                                    reader.onloadend = () => {
                                                                        this.setState({
                                                                            foto_selfie: {
                                                                                name: file.file.name,
                                                                                file: reader.result
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                else {
                                                                    this.setState({ foto_selfie: [] })
                                                                }
                                                            }}
                                                        >
                                                            {this.state.fileSelfie.length >= 1 ? null :
                                                                <div className="p-2" style={{ height: "167px" }}>
                                                                    <span>
                                                                        <img src={UploadIcon} style={{ height: "24px", width: "24px" }} />
                                                                    </span>
                                                                    <p className="ant-upload-text mb-3" style={{ fontSize: "12px", fontWeight: 400, lineHeight: "16px", marginTop: "6px" }}>Upload foto selfie karyawan</p>
                                                                    <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                                </div>
                                                            }
                                                        </Upload>
                                                    </ImgCrop>
                                                </div>
                                                <div className="col-md-3">
                                                    <ImgCrop
                                                        rotate
                                                        width={960}
                                                        height={480}>
                                                        <Upload customRequest={({ file, onSuccess }) => {
                                                            setTimeout(() => {
                                                                onSuccess("ok");
                                                            }, 0);
                                                        }}
                                                            listType="picture-card"
                                                            fileList={this.state.fileKtp}
                                                            className="antd-upload-custom"
                                                            onRemove={() => {
                                                                this.setState({ foto_ktp: null })
                                                            }}
                                                            onChange={(file) => {
                                                                this.setState({ fileKtp: file.fileList })

                                                                if (file.fileList.length > 0) {
                                                                    let files = file.file.originFileObj
                                                                    let reader = new FileReader()

                                                                    reader.readAsDataURL(files)
                                                                    reader.onloadend = () => {
                                                                        this.setState({
                                                                            foto_ktp: {
                                                                                name: file.file.name,
                                                                                file: reader.result
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                else {
                                                                    this.setState({ foto_ktp: [] })
                                                                }
                                                            }}
                                                        >
                                                            {this.state.fileKtp.length >= 1 ? null :
                                                                <div className="p-2" style={{ height: "167px" }}>
                                                                    <span>
                                                                        <img src={UploadIcon} style={{ height: "24px", width: "24px" }} />
                                                                    </span>
                                                                    <p className="ant-upload-text mb-3" style={{ fontSize: "12px", fontWeight: 400, lineHeight: "16px", marginTop: "6px" }}>Upload foto KTP karyawan</p>
                                                                    <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                                </div>
                                                            }
                                                        </Upload>
                                                    </ImgCrop>
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Nama Karyawan</label>
                                                        <div>
                                                            <input style={{ fontSize: "12px" }} name="nama_karyawan" className="form-control" placeholder="Nama Karyawan..."
                                                                type="text" required="" value={this.state.nama_karyawan} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Nomor Induk Karyawan (Opsional)</label>
                                                        <div>
                                                            <input style={{ fontSize: "12px" }} name="no_induk_karyawan" className="form-control" placeholder="Nomor Induk Karyawan..."
                                                                   type="text" required="" value={this.state.no_induk_karyawan} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Email</label>
                                                        <div>
                                                            <input style={{ fontSize: "12px" }} name="email_karyawan" className="form-control" placeholder="Email Karyawan..."
                                                                type="email" value={this.state.email_karyawan} required="" onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">No. Handphone</label>
                                                        <div>
                                                            <input style={{ fontSize: "12px" }} name="no_hp_karyawan" className="form-control"
                                                                type="number" value={this.state.no_hp_karyawan} required="" placeholder="No. HP Karyawan..." onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Status</label>
                                                        <div>
                                                            <div class="form-check form-check-inline">
                                                                <input id="status_active" class="form-check-input" type="radio" checked={this.state.status_karyawan === "active"} onClick={(event) => this.handlePressChange(event)} value="active" />
                                                                <label class="form-check-label" style={{ fontSize: "12px" }} for="status_active">Active</label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" id='status_non-active' type="radio" onClick={(event) => this.handlePressChange(event)} checked={this.state.status_karyawan === "non-active"} value="non-active" />
                                                                <label class="form-check-label" style={{ fontSize: "12px" }} for="status_non-active">Non-Active</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Alamat</label>
                                                        <div>
                                                            <textarea style={{ fontSize: "12px" }} value={this.state.alamat_karyawan} name="alamat_karyawan" type="text" className="form-control" placeholder="Alamat Karyawan..." onChange={this.handleChange}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Tanggal Lahir</label>
                                                                <div>
                                                                    <input style={{ fontSize: "12px" }} name="ttl_karyawan" className="form-control" onChange={this.handleChange}
                                                                        type="date" required="" value={this.state.ttl_karyawan} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Jenis Kelamin Karyawan</label>
                                                                <div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" id="gender_laki" type="radio" checked={this.state.gender_karyawan === "Laki-laki"} onClick={(e) => this.setState({ gender_karyawan: e.target.value })} name="inlineRadioOptions" value="Laki-laki" />
                                                                        <label class="form-check-label" style={{ fontSize: "12px" }} for="gender_laki">Laki-Laki</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" id="gender_perempuan" type="radio" name="inlineRadioOptions" onClick={(e) => this.setState({ gender_karyawan: e.target.value })} checked={this.state.gender_karyawan === "Perempuan"} value="Perempuan" />
                                                                        <label class="form-check-label" style={{ fontSize: "12px" }} for="gender_perempuan">Perempuan</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }}className="mb-2 font-weight-bold">Tanggal Mulai Kerja/Bergabung Karyawan</label>
                                                        <div>
                                                            <input style={{ fontSize: "12px" }} name="join_date_karyawan" className="form-control" onChange={this.handleChange}
                                                                   type="date" required="" value={this.state.join_date_karyawan} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Divisi Karyawan</label>
                                                        <div>
                                                            <select style={{ fontSize: "12px" }} name="divisi_karyawan" className="form-control" onChange={this.handleChange} value={this.state.divisi_karyawan}>
                                                                <option style={{ fontSize: "12px" }}>Pilih Divisi</option>
                                                                {this.state.divisi.map((item, index) => {
                                                                    return (
                                                                        <option style={{ fontSize: "12px" }} value={item.divisi_id}>{item.divisi_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Jabatan Karyawan</label>
                                                        <div>
                                                            <select style={{ fontSize: "12px" }} name="jabatan_karyawan" className="form-control"
                                                                onChange={this.handleChange} value={this.state.jabatan_karyawan}>
                                                                <option style={{ fontSize: "12px" }}>Pilih Jabatan</option>
                                                                {this.state.jabatan.map((item, index) => {
                                                                    return (
                                                                        <option style={{ fontSize: "12px" }} value={item.jabatan_id}>{item.jabatan_nama}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Lokasi Absen
                                                            Karyawan</label>
                                                        <div>
                                                            <label style={{ fontSize: "12px" }}>
                                                                <input style={{ fontSize: "12px" }} type="checkbox"
                                                                    checked={this.state.lokasi_karyawan === null}
                                                                    onChange={() => this.lokasiHandler()} />
                                                                &nbsp;Semua Lokasi Perusahaan
                                                            </label>
                                                            {this.state.lokasi_karyawan !== null &&
                                                                <Select name="lokasi_karyawan"
                                                                    style={{ width: '100%' }}
                                                                    value={this.state.lokasi_karyawan}
                                                                    mode={"multiple"}
                                                                    placeholder="Pilih Lokasi"
                                                                    filterOption={(input, option) =>
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    onChange={(event) => {
                                                                        this.setState({
                                                                            lokasi_karyawan: event
                                                                        })
                                                                    }}>
                                                                    {this.state.lokasi.map((item, index) => {
                                                                        return (<Option
                                                                            key={item.lokasi_id}>{item.lokasi_nama}</Option>)
                                                                    })}
                                                                </Select>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pull-right">
                                                {this.state.prosesSimpan === false ?
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={this.simpanKaryawan}>Simpan</button>
                                                    :
                                                    <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="form-group">
                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Password Baru</label>
                                                <div>
                                                    <input style={{ fontSize: "12px" }} name="password_baru" className="form-control" placeholder="Password Baru Karyawan..."
                                                        type="password" required="" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="pull-right">
                                                {this.state.prosesSimpan === false ?
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={this.simpanPasswordKaryawan}>Simpan</button>
                                                    :
                                                    <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                                }
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default GuideKaryawanEdit
