import React, { Component } from "react";
import {
    Route,
    Redirect
} from "react-router-dom";
import { connect } from "react-redux"

const AdminRoute = ({ component: Component, auth, user, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => !auth ? (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            ) : (
                user.perusahaan.status === 'pending' || user.perusahaan.status === 'expired' ?
                    <Redirect to={{
                        pathname: "/langganan",
                        state: {from: props.location}
                    }}/> :
                <Component {...props} request={(action, data, method = 'POST') => rest.request(action, data, method) } />
            )
            }
        />
    );
};

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    user: state.Auth.user
});

export default connect(mapStateToProps)(AdminRoute)
