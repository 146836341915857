import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import {Empty, Modal, Select, Spin} from 'antd';
import ModalDefault from '../menu-guide/modal-default';
import EditDivisi from './edit-divisi-karyawan'
import Add from "./add";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import { Row } from "reactstrap";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import ImportIcon from "../../assets/icons/import-icon.svg";
import {BACKEND_API_HOST} from "../../helper/host";
import RefreshIcon from "../../assets/icons/refresh.svg";

export class List_divisi_karyawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shift: [],
            idDel: '',
            isLoading: true,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            divisi_name: '',
            divisi_id: '',
            filter_nama: '',
            filter_shift: "",
            divisi_shift: '',
            divisi_sift: null,
            name_divisi: '',
            visable: false,
            add_visible: false,
            guide: false,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            confirm_modal_default: false,
            modalimport: false,
            importFile: null
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }
    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_shift: "",
            data: [],
            isLoading: true
        }, () => this.loadData())
    }

    handleFilter = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            isLoading: true,
            data: [],
        }, () => this.loadData())
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], isLoading: true }, () => {
            this.loadData();
        });
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus divisi karyawan?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        data: [],
                        isLoading: true,
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("divisi/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    data: []
                                })
                                this.props.set_auth(this.props.api_token, response.perusahaan);
                                this.loadData()
                                this.loadCheck()
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    data: []
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            filter_shift: this.state.filter_shift,
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("divisi/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        isLoading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }

    loadShift() {
        let form = {}
        this.props.request("shift/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        shift: response.data.data,
                    })
                }
            });
    }
    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        jam_kerja_check: response.jam_kerja,
                        karyawan_check: response.karyawan
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                            this.setState({
                                confirm_modal_default: true
                            })
                        }
                    })
                }
            })
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    exportDivisi = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'divisi/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'divisi/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    componentDidMount() {
        this.loadData()
        this.loadShift()
        this.loadCheck()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-divisi',
                content: 'Anda dapat membuat Divisi disini',
            },
            {
                selector: '.daftar-divisi',
                content: 'Daftar Divisi anda dapat dilihat disini',
            },
        ];
        const { Option } = Select;
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Divisi") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Divisi - Presensi</title>
                        <meta name="description" content="Detail Absensi" />
                    </Helmet>
                    <EditDivisi
                        data={this.state}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Breadcrumb parent="Manajemen Divisi" title="Daftar Divisi" parentLink="/karyawan/divisi-karyawan" setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <ModalDefault
                        shift={this.state.shift_check}
                        lokasi={this.state.lokasi_check}
                        divisi={this.state.divisi_check}
                        karyawan={this.state.karyawan_check}
                        confirm_modal_default={this.state.confirm_modal_default}
                        setState={(name, val) => this.setState(name, val)}
                        history={this.props.history}
                        jam_kerja={this.state.jam_kerja_check}
                    />
                    <Modal
                        title="Import Divisi"
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Sedang mengupload data</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                            <a onClick={this.exportDivisi} style={{ color: "#057CE4" }}>Unduh template disini</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card profile-card">
                                    <div className="card-header pb-0 px-3 mb-3" style={{borderBottom: 'none'}}>
                                        <div className="row mx-0" style={{marginLeft: '5px'}}>
                                            <div className="col-md-3">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text input-group-text-custom" id="basic-addon1"><span className="fa fa-search">
                                                        </span></span>
                                                    </div>
                                                    <input name="filter_nama"
                                                           className="form-control"
                                                           placeholder="Nama Divisi..."
                                                           type="text"
                                                           value={this.state.filter_nama}
                                                           onChange={this.handleChange}
                                                           onKeyUp={(event) => {
                                                               event.preventDefault()
                                                               if (event.which == 13) {

                                                                   this.setState({
                                                                       page: 0,
                                                                       paging: this.state.paging,
                                                                       isLoading: true
                                                                   }, () => {
                                                                       this.loadData()
                                                                   });
                                                               }
                                                           }}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3 px-1 d-flex flex-nowrap karyawan-filter">
                                                <select name="filter_shift"
                                                        className="form-control karyawan-filter-jabatan mr-1"
                                                        onChange={this.handleFilter}>
                                                    <option value="" selected={this.state.filter_shift === ''}>Pilih
                                                        Shift Kerja
                                                    </option>
                                                    {this.state.shift.map((item, index) => {
                                                        return (
                                                            <option
                                                                value={item.sift_id}>{item.sift_name}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-md-1">
                                                <button className="btn btn-outline-" style={{lineHeight: "26px"}}
                                                        onClick={this.resetFilter}>
                                                    <span className="fa fa-refresh"></span>
                                                </button>
                                            </div>
                                            <div className="col"></div>
                                            <div className='col-auto px-0'>
                                                <button className="mr-2 karyawan-import-button" onClick={() => {
                                                    this.setState({modalimport: true})
                                                }}><img src={ImportIcon} alt=''/> Import
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                    <a className="col-auto btn btn-primary tambah-divisi"
                                                       style={{
                                                           float: "right",
                                                           color: "white",
                                                           textTransform: "none",
                                                           lineHeight: '25px'
                                                       }} onClick={() => {
                                                        this.setState({
                                                            add_visible: true
                                                        })
                                                    }}><i className={"fa fa-plus"}></i> Tambah Divisi</a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body daftar-divisi">
                                        {!this.state.isLoading && this.state.data.length === 0 ?
                                            <Empty
                                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                imageStyle={{height: 60}}
                                                description={<span>Data tidak ditemukan!</span>}
                                            />
                                            :
                                            (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                                <div>
                                                    <Table striped className="mb-5">
                                                        <Thead>
                                                            <Tr>
                                                                <Th width="50">#</Th>
                                                                <Th>Nama Divisi</Th>
                                                                <Th>Shift Divisi</Th>
                                                                <Th className={'text-center'}>Perhitungan Gaji</Th>
                                                                <Th width={175} className={'text-center'}>Aksi</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {this.state.data.map((item, index) => {
                                                                no += 1
                                                                return (
                                                                    <Tr>
                                                                        <small>
                                                                            <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                        </small>
                                                                        <Td>
                                                                            <div className='p-1'>
                                                                                {item.divisi_name.toUpperCase()}
                                                                            </div>
                                                                        </Td>
                                                                        <Td><Row>{item.divisi_sif.length > 0 ? item.divisi_sif.map((item2, index2) => {
                                                                            return (
                                                                                <h5>
                                                                                    <div className="divisi-item">
                                                                                        {item2.sif && item2.sif.sift_name}
                                                                                    </div>
                                                                                </h5>
                                                                            )
                                                                        }) : (<div>Kosong</div>)}</Row></Td>
                                                                        <Td align={"center"}>
                                                                            <div className='p-2'>
                                                                                {item.divisi_payroll_type ? item.divisi_payroll_type : "-"}
                                                                            </div>
                                                                        </Td>
                                                                        <Td align={"center"}>
                                                                            <div className="row">
                                                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                    <div className="col-sm-6 pr-0 pl-0">
                                                                                        <a onClick={() => {
                                                                                            let sif = item.divisi_sif.map((item3, index3) =>
                                                                                                '' + item3.sif.sift_id + ''
                                                                                            )
                                                                                            this.setState({
                                                                                                divisi_id: item.divisi_id,
                                                                                                divisi_name: item.divisi_name,
                                                                                                divisi_payroll_type: item.divisi_payroll_type,
                                                                                                // divisi_sift: ['1', '2',],
                                                                                                divisi_sift: sif,
                                                                                                visable: true
                                                                                            })
                                                                                        }}
                                                                                            className="btn btn-outline-custom">Edit</a>
                                                                                    </div>
                                                                                )}
                                                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                                    <div className="col-sm-6 pr-0 pl-0">
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                idDel: item.divisi_id
                                                                                            })
                                                                                            this.wantDelete()
                                                                                        }}
                                                                                            className="btn btn-danger-custom">Hapus</a>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })}
                                                            {this.state.isLoading &&
                                                                <Tr>
                                                                    <Td colSpan="4">
                                                                        <Spin style={{ width: '100%' }} size="small" tip="Mohon Tunggu..." />
                                                                    </Td>
                                                                </Tr>
                                                            }
                                                        </Tbody>
                                                    </Table>
                                                    <Paging
                                                        className="float-right"
                                                        handlePageClick={this.handlePageClick}
                                                        pageCount={this.state.pageCount}
                                                        currentPage={this.state.currentPage}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_divisi_karyawan);
