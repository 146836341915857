import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";

class IstillahAdd extends Component {
    constructor() {
        super();
        this.state = {
            p_term_name: '',
            p_term_type: '',
            loading: false
        }
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let form = {
            p_term_type: this.state.p_term_type,
            p_term_name: this.state.p_term_name
        }

        this.props.request("payroll/add-payroll-term", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({p_term_name: ''})
                    this.props.changeState({
                        p_term_name: '',
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false
                })
            });
    }

    render() {
        return (
            <Drawer
                title="Tambah Istillah"
                width={350}
                onClose={() => {
                    this.props.changeState({add_visible: false, p_term_name: ''});
                }}
                visible={this.props.add_visible}
                bodyStyle={{paddingBottom: 80}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => {
                            this.props.changeState({add_visible: false, p_term_name: ''});
                        }}
                                style={{marginRight: 8}}>
                            Batal
                        </Button>
                        <Button onClick={this.handleSubmit} disabled={this.state.loading} type="primary">
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> Loading</>
                                :
                                "Simpan"
                            }
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Nama Istillah</label>
                        <input name="p_term_name" className="form-control col-xl-12 col-md-12" placeholder="Nama..."
                               type="text" required="" value={this.state.p_term_name}
                               onChange={(event) => {
                                   const target = event.target;
                                   const value = target.value;
                                   const name = target.name;
                                   this.setState({
                                       [name]: value
                                   });
                               }}/>
                    </div>
                    <div className="form-group">
                        <label>Type Istillah</label>
                        <select
                            name="p_term_type"
                            className="form-control col-xl-12 col-md-12"
                            value={this.state.p_term_type}
                            onChange={(event) => this.handleChange(event)}>

                            <option value="">Pilih Type Istillah</option>
                            <option value="kompensasi">Kompensasi</option>
                            <option value="potongan">Potongan</option>

                        </select>
                    </div>
                </Form>
            </Drawer>

        )
    }
}

export default IstillahAdd
