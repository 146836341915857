import HomeIcon from '../assets/icons/home.svg'
import PengumumanIcon from '../assets/icons/pengumuman.svg'
import MasterIcon from '../assets/icons/master.svg'
import PengajuanIcon from '../assets/icons/pengajuan.svg'
import UserIcon from '../assets/icons/user.svg'
import CalendarIcon from '../assets/icons/calendar.svg'
import LocationIcon from '../assets/icons/location.svg'
import PayrollIcon from '../assets/icons/payroll.svg'
import WalletIcon from '../assets/icons/wallet.svg'
import TaskIcon from '../assets/icons/task.svg'
import MedalIcon from '../assets/icons/medal.svg'
import SettingIcon from '../assets/icons/settings.svg'
import MonitorIcon from '../assets/icons/monitor_company.svg'

export const MENUITEMS = [
    {
        path: '/dashboard', title: 'Dashboard', icon: HomeIcon, iconType: 'svg', type: 'link', badgeType: 'primary', active: false
    },
    {
        path: '/pemantauan', title: 'Monitor Perusahaan', icon: MonitorIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Pengumuman', path: '/pengumuman', icon: PengumumanIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Master Data', icon: MasterIcon, iconType: 'svg', type: 'sub', children: [
            // { path: '/karyawan/shift-jam-kerja', title: 'Jam Kerja', type: 'link' },
            { path: '/karyawan/shift', title: 'Shift', type: 'link' },
            { path: '/karyawan/jam-kerja', title: 'Jam Kerja', type: 'link' },
            { path: '/karyawan/divisi-karyawan', title: 'Divisi', type: 'link' },
            { path: '/karyawan/jabatan-karyawan', title: 'Jabatan', type: 'link' },
            { path: '/lokasi-kunjungan', title: 'Lokasi Kunjungan', type: 'link' },
            { path: '/lokasi-presensi', title: 'Lokasi Presensi', type: 'link' },
            { path: '/daftar-gaji-istillah', title: 'Daftar Istillah', type: 'link' },
        ]
    },
    {
        title: 'Pengajuan Karyawan', icon: PengajuanIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/pengajuan/izin', title: 'Pengajuan Izin/Cuti', type: 'link' },
            { path: '/pengajuan/lembur', title: 'Pengajuan Lembur', type: 'link' },
            { path: '/pengajuan/kasbon', title: 'Pengajuan Kasbon', type: 'link' },
            { path: '/pengajuan/klaim-beban', title: 'Pengajuan Klaim Beban', type: 'link' },
            // { path: '/pengajuan/revisi', title: 'Pengajuan Revisi Data', type: 'link' },
        ]
    },
    {
        title: 'Karyawan', path: '/karyawan/list-karyawan/0', icon: UserIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Presensi', icon: CalendarIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/presensi', title: 'Presensi Harian', type: 'link' },
            { path: '/presensi-bulan', title: 'Presensi Bulanan', type: 'link' },
        ]
    },
    {
        title: 'Kunjungan', icon: LocationIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/kunjungan/daftar-kunjungan', title: 'Daftar Kunjungan', type: 'link' },
            // {path: '/kunjungan/daftar-tracking', title: 'Tracking Kunjungan', type: 'link'},
            { path: '/kunjungan/laporan-kunjungan', title: 'Laporan Kunjungan', type: 'link' }
        ]
    },

    {
        title: 'Tugas', icon: TaskIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/tugas', title: 'Daftar Tugas', type: 'link' },
            { path: '/tugas-selesai', title: 'Tugas Terselesaikan', type: 'link' }
        ]
    },
    {
        title: 'Payroll', icon: PayrollIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/daftar-gaji-karyawan', title: 'Daftar Gaji Karyawan', type: 'link' },
            // { path: '/pengaturan/cuti', title: 'Pengaturan Cuti Karyawan', type: 'link' },
            { path: '/daftar-gaji-istillah', title: 'Daftar Istilah', type: 'link' },
            { path: '/daftar-gaji/list', title: 'Daftar Payroll', type: 'link' },
            { path: '/payroll/setting', title: 'Pengaturan Payroll', type: 'link' },
            // {path: '/kunjungan/daftar-tracking', title: 'Tracking Kunjungan', type: 'link'},
        ]
    },
    {
        title: 'Pembayaran Kasbon', path: '/pembayaran-kasbon/list', icon: WalletIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Langganan', path: '/langganan', icon: MedalIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Pengaturan', icon: SettingIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/settings/profile', title: 'Profile', type: 'link' },
            { path: '/settings/mobile', title: 'Pengaturan Aplikasi', type: 'link' },
            // { path: '/settings/presensi', title: 'Pengaturan Presensi', type: 'link' },
            { path: '/settings/user', title: 'User Login', type: 'link' },
            { path: '/settings/user/level', title: 'User Level', type: 'link' },
            // { path: '/settings/migrasi-data', title: 'Migrasi Data', type: 'link' },
        ]
    },
]

export const MENUITEMSGUIDE = [
    {
        path: '/dashboard', title: 'Dashboard', icon: HomeIcon, iconType: 'svg', type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Master Data', icon: MasterIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/guide/jam-kerja', title: 'Jam Kerja', type: 'link' },
            { path: '/guide/divisi', title: 'Divisi', type: 'link' },
            { path: '/guide/lokasi', title: 'Lokasi', type: 'link' },
        ]
    },
    {
        title: 'Karyawan', path: '/guide/karyawan', icon: UserIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Langganan', path: '/langganan', icon: MedalIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Pengaturan', icon: SettingIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/settings/profile', title: 'Profile', type: 'link' },
            { path: '/settings/user', title: 'User Login', type: 'link' },
            { path: '/settings/user/level', title: 'User Level', type: 'link' },
        ]
    },
]
