import React, { Component, Fragment} from 'react';
import { Empty, Modal, Spin, Tooltip } from 'antd';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { BACKEND_API_HOST, IMG_HOST } from "../../helper/host";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import { toast } from "react-toastify";
import { clear_auth, set_auth } from "../../reducers/Auth";
import Dropdown from 'react-bootstrap/Dropdown';
import ImportIcon from '../../assets/icons/import-icon.svg';
import RefreshIcon from '../../assets/icons/refresh.svg';
import ModalDefault from '../menu-guide/modal-default';
import PlusWhiteIcon from "../../assets/icons/plus-white.svg";
import './style.css'
import { addMonthsToDate, formatDateToYMD } from "../../helper/date";
import { isOdd } from "../../helper/function";
import {Modal as ModalRs, ModalHeader, ModalBody, ModalFooter, Row, Col} from 'reactstrap';
import moment from "moment";
import ReactPaginate from "react-paginate";
import ArrowRight from '../../assets/icons/arrow-right.svg'
import ArrowLeft from '../../assets/icons/arrow-left.svg'
import FilterIcon from "../../assets/icons/filter.svg";

import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg";
import MoreIcon from "../../assets/icons/more.svg";



export class List_karyawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            jabatan: [],
            data: [],
            history: [],
            idDel: '',
            karyawan_id: '',
            filter_nama: "",
            filter_jabatan: "",
            filter_divisi: "",
            importFile: null,
            modalimport: false,
            modalAction: false,
            modalHistory: false,
            dataAction: [],
            dataHistory: [],
            pageCount: 0,
            totalData: 0,
            perPage: 0,
            paging: 15,
            pagination:2,
            currentPageHistory:0,
            currentPage: parseInt(props.match.params.page),
            guide: false,
            kunci_pengaturan: ['Tes'],
            icon_success: false,
            itemsPerPage: 10,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            loadingHistory: false,
            rowsPerPage: 50,
            confirm_modal_default: false,
            redirect : false,
            karyawan_slug : '',
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.importExcel = this.importExcel.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.exportExcelKaryawan = this.exportExcelKaryawan.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData()
            this.props.history.push('/karyawan/list-karyawan/' + selected)
        });
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_jabatan: '',
            filter_divisi: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleOk = e => {
        this.setState({
            modalimport: false,
            modalAction: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false,
            modalAction: false
        });
    };

    exportKaryawan = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'karyawan/export_template?token=' + AuthKey, "_blank")
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus karyawan?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                        data: []
                    })
                    let form = {
                        karyawan_id: this.state.idDel
                    }
                    this.props.request("karyawan/delete_karyawan", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    loading: true,
                                    data: []
                                })
                                this.loadData()
                                this.loadCheck()
                            }
                        });
                }
            })
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'karyawan/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadJabatan() {
        let form = {}
        this.props.request("get_jabatan_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        jabatan: response.data,
                    })
                }
            });
    }

    loadDivisi() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("divisi/list-divisi", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        divisi: response.data
                    })
                }
            });
    }

    loadData() {
        let form = {
            paginate: this.state.paging,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
            filter_jabatan: this.state.filter_jabatan,
            filter_divisi: this.state.filter_divisi,
        }
        this.props.request("karyawan/get_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })

                    const kunci_pengaturan = response.data.data.map(item => {
                        let kunci = "Kunci ";
                        if (item.kunci_lokasi === '1') {
                            kunci += 'Lokasi';
                        }

                        if (item.kunci_foto === '1') {
                            kunci += 'Foto'
                        }

                        if (item.kunci_kunjungan === '1') {
                            kunci += 'Kunjungan'
                        }

                        return kunci;
                    });

                    this.setState({ kunci_pengaturan });
                }
            });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.karyawan_id,
                paging : this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("karyawan/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            karyawan_id: ''
                        });
                    }
                });
        })
    }

    checkSlug(karyawan, type) {
        if(karyawan.karyawan_slug != null){
            if (type === "detail") {
                this.props.history.push('/karyawan/profil/detail/'+ karyawan.karyawan_slug)
            } else if (type === "edit") {
                this.props.history.push('/karyawan/edit-karyawan/'+ karyawan.karyawan_slug + "/" + this.state.currentPage)
            }
            return;
        }
        let form = {
            id_karyawan : karyawan.karyawan_id,
        };
        this.props.request("karyawan/generate-slug", form, 'POST')
            .then(response => {
                if (response.success) {
                    if (type === "detail") {
                        this.props.history.push('/karyawan/profil/detail/'+ response.karyawan_slug)
                    } else if (type === "edit") {
                        this.props.history.push('/karyawan/edit-karyawan/'+ response.karyawan_slug + "/" + this.state.currentPage)
                    }
                    this.setState({
                        loading: false,
                    })
                }
            });
    }


    cekMasterData() {
        if (this.props.user.perusahaan.divisi === null || this.props.user.perusahaan.jabatan.length < 1 || this.props.user.perusahaan.lokasi.length < 1) {
            Swal.fire({
                title: 'Peringatan!',
                text: 'Anda harus menambahkan Seluruh Master Data terlebih dahulu',
                icon: 'warning',
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push('/karyawan/shift')
                    }
                })
        }
    }
    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        karyawan_check: response.karyawan,
                        jam_kerja_check: response.jam_kerja ? response.jam_kerja : []
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                            this.setState({
                                confirm_modal_default: true
                            })
                        }
                    })
                }
            })
    }

    handleRowsPerPageChange = (newRowsPerPage) => {
        this.setState({ rowsPerPage: newRowsPerPage});
      };


    componentDidMount() {
        this.cekMasterData()
        this.loadData()
        this.loadJabatan()
        this.loadCheck()
        this.loadDivisi()
    }

    exportExcelKaryawan = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'karyawan/export_excel_karyawan?token=' + AuthKey, "_blank")
    }

    render() {
        const { rowsPerPage } = this.state;
        let no = 0;
        const stylefoto = {
            height: '50px',
            width: '50px',
            objectFit: 'cover'
        }
        const steps = [
            {
                selector: '.tambah-karyawan',
                content: 'Anda dapat menambah Karyawan disini',
            },
            {
                selector: '.daftar-karyawan',
                content: 'Daftar Karyawan anda dapat dilihat disini',
            },
            {
                selector: '.filter-karyawan',
                content: 'Anda dapat mem-Filter daftar Karyawan anda lewat sini',
            },
        ];
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Karyawan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Karyawan - Presensi</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <ModalDefault
                        shift={this.state.shift_check}
                        lokasi={this.state.lokasi_check}
                        divisi={this.state.divisi_check}
                        karyawan={this.state.karyawan_check}
                        confirm_modal_default={this.state.confirm_modal_default}
                        setState={(name, val) => this.setState(name, val)}
                        history={this.props.history}
                        jam_kerja={this.state.jam_kerja_check}
                    />
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4 style={{ fontWeight: 900 }}>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4 style={{ fontWeight: 900 }}>Aktivitas</h4>
                                                </Col>
                                            </Row>


                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src="https://servicesdev.presensi.co.id/media/logo-default-user.png"
                                                                            alt=""
                                                                            style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 900,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                      {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === 'created' ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Dibuat
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Diubah
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.history_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.karyawan_nama != item.karyawan_nama && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontWeight: 900 }}>
                                                                                            Dari Nama:</span>{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.karyawan_nama
                                                                                            : 'N/A'}{' '}
                                                                                        -> <span style={{ fontWeight: 900 }}>
                                                                                            Jadi:</span> {item.karyawan_nama ? item.karyawan_nama : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.karyawan_email != item.karyawan_email && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontWeight: 900 }}>
                                                                                             Dari Email:</span>{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.karyawan_email
                                                                                            : 'N/A'}{' '}
                                                                                        -> <span style={{ fontWeight: 900 }}>
                                                                                            Jadi:</span> {item.karyawan_email ? item.karyawan_email : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.karyawan_password != item.karyawan_password && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontWeight: 900 }}>
                                                                                            Dari Password:</span>: {'Password Lama'}
                                                                                        {' '}
                                                                                        -> <span style={{ fontWeight: 900 }}>
                                                                                            Jadi:</span> {'Password Baru'}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.no_hp != item.no_hp && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontWeight: 900 }}>
                                                                                            Dari No HP:</span>{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.no_hp
                                                                                            : 'N/A'}{' '}
                                                                                        -> <span style={{ fontWeight: 900 }}>
                                                                                            Jadi:</span> {item.no_hp ? item.no_hp : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.karyawan_alamat !== item.karyawan_alamat && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontWeight: 900 }}>
                                                                                            Dari Alamat:</span>{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.karyawan_alamat
                                                                                            : 'N/A'}{' '}
                                                                                        -> <span style={{ fontWeight: 900 }}>
                                                                                            Jadi:</span> {item.karyawan_alamat ? item.karyawan_alamat : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.kunci_foto !== item.kunci_foto && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontWeight: 900 }}>
                                                                                            Dari Kunci Foto:</span>{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.kunci_foto
                                                                                            : 'N/A'}{' '}
                                                                                        -> <span style={{ fontWeight: 900 }}>
                                                                                            Jadi:</span> {item.kunci_foto ? item.kunci_foto : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.kunci_lokasi !== item.kunci_lokasi && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ fontWeight: 900 }}>
                                                                                            Dari Kunci Lokasi:</span>{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.kunci_lokasi
                                                                                            : 'N/A'}{' '}
                                                                                        -> <span style={{ fontWeight: 900 }}>
                                                                                            Jadi:</span> {item.kunci_lokasi ? item.kunci_lokasi : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} dari {this.state.totalDataHistory} data</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>
                    <div className="container-fluid list-karyawan">
                        <div className='row pt-4'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-12' style={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        lineHeight: "16px",
                                        color: "#5D6F80"
                                    }}>Presensi Admin Panel</div>
                                </div>
                                <div className='row' style={{ marginBottom: "28px" }}>
                                    <div className='col-md-12' style={{
                                        fontSize: "24px",
                                        lineHeight: "36px",
                                        fontWeight: 700,
                                        color: "#143B5E"
                                    }}>Daftar Karyawan</div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header pt-4 pb-0 px-3" style={{ borderBottom: "0px" }}>
                                <div className="row justify-content-between mx-0">
                                    <div className="col-9 row mx-0">
                                        <div className="col-md-4 px-1 karyawan-filter">
                                            <input name="filter_nama"
                                                   className="form-control karyawan-filter-nama"
                                                   placeholder="&#xF002; Nama Karyawan..."
                                                   type="text"
                                                   value={this.state.filter_nama}
                                                   onChange={this.handleChange}
                                                   onKeyUp={(event) => {
                                                       event.preventDefault()
                                                       if (event.which == 13) {
                                                           this.setState({
                                                               page: 0,
                                                               paging: this.state.paging,
                                                               loading: true
                                                           }, () => {
                                                               this.loadData()
                                                           });
                                                       }
                                                   }} />
                                        </div>
                                        <div className='col-md-3 px-1 d-flex flex-nowrap karyawan-filter'>
                                            <button className='btn primary button-filter px-2 btn-fltr-absnbln' style={{ width: "94px" }} align="center" onClick={() => this.setState({ filter: !this.state.filter })}>
                                                <img src={FilterIcon} />Filter
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-auto row mx-0">
                                        <div className='col-auto px-1 d-flex flex-nowrap karyawan-filter'>
                                            {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                <button onClick={() => this.props.history.push("/karyawan/tambah-karyawan")} className="karyawan-add-button ml-1" >
                                                    <img src={PlusWhiteIcon} alt='' /> Tambah
                                                </button>
                                            )}
                                        </div>
                                        <div className='col-auto px-0' align="center">
                                            <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                <div className={'p-2'}>
                                                    <a onClick={() => {
                                                        this.setState({modalimport: true})
                                                    }} style={{marginRight: "50px"}} className={'text-dark'}>Import
                                                        Excel</a><br/>
                                                    <a onClick={this.exportExcelKaryawan}
                                                       disabled={this.state.potonganDisableUpdata || this.state.kompensasiDisableUpdata || this.state.loading}
                                                       style={{marginRight: "50px"}}
                                                       className={'text-dark'}
                                                    >Export Excel</a>
                                                </div>
                                            }>
                                                <a><i
                                                    style={{
                                                        width: "15",
                                                        fontSize: 18,
                                                        color: 'rgb(51 51 51)',
                                                        alignItems: "center",
                                                        height: "38px",
                                                        paddingRight: "30px"
                                                    }}
                                                    className='btn border border-dark border-1 px-2'
                                                ><img src={MoreIcon}/></i></a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mx-0 mt-3'>
                                    {this.state.filter && (
                                        <div className="px-1 d-flex flex-nowrap karyawan-filter">
                                            <select name="filter_jabatan" className="form-control karyawan-filter-jabatan mr-1" onChange={this.handleFilter}>
                                                <option value="">Pilih Jabatan</option>
                                                {this.state.jabatan.map((item, index) => {
                                                    return (
                                                        <option value={item.jabatan_id}>{item.jabatan_nama}</option>
                                                    )
                                                })}
                                            </select>
                                            <select name="filter_divisi" className="form-control karyawan-filter-jabatan mr-1" onChange={this.handleFilter}>
                                                <option value="">Pilih Divisi</option>
                                                {this.state.divisi.map((item, index) => {
                                                    return (
                                                        <option value={item.divisi_id}>{item.divisi_name}</option>
                                                    )
                                                })}
                                            </select>
                                            <div>
                                                <button className="karyawan-filter-reset" onClick={this.resetFilter}>
                                                    <img src={RefreshIcon} alt=''/>
                                                </button>
                                            </div>
                                        </div>
                                            )}
                                </div>
                            </div>
                            <div className="card-body px-2 daftar-karyawan" style={{ paddingTop: "40px" }}>
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description="Data Kosong"
                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                    :
                                    (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                        <Table className="table table-striped mb-5">
                                        <Thead>
                                            <Tr>
                                                <Th style={{ border: "none" }} width="30">#</Th>
                                                <Th style={{ border: "none" }} className="text-left">Foto</Th>
                                                <Th style={{ border: "none" }} className="text-left">Nama Karyawan</Th>
                                                <Th style={{ border: "none" }} className="text-left">Jabatan-Divisi</Th>
                                                <Th style={{ border: "none" }} className="text-left">Lokasi Presensi</Th>
                                                <Th style={{ border: "none" }} className="text-left">Kunci Pengaturan</Th>
                                                <Th style={{ border: "none" }} className="text-left">Tipe Kontrak</Th>
                                                <Th style={{ border: "none" }} className="text-left"></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={10}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            }
                                                {this.state.data.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr style={item.status_karyawan !== 'active' ? (isOdd(index) ? {backgroundColor: '#FFDED7'} : {backgroundColor: '#F9B5A6'}) : {}}>
                                                        <small>
                                                            <Th style={{ border: "none" }} scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                        </small>
                                                        <Td style={{ border: "none" }} align="center">{item.karyawan_foto !== null ? (
                                                            <img style={stylefoto} alt=''
                                                                src={IMG_HOST + 'frontapp/karyawan/' + item.karyawan_foto} />
                                                        ) : (
                                                            <img style={stylefoto} alt=''
                                                                src={IMG_HOST + "user-profile.png"} />
                                                        )}</Td>
                                                        <Td style={{ border: "none" }} align="left">
                                                            {item.status_karyawan !== 'active' && (
                                                                <div>
                                                                    <p className={'badges-danger'}>KARYAWAN BELUM AKTIF</p>
                                                                </div>
                                                            )}
                                                            {item.karyawan_no_induk && (
                                                            <div>
                                                                <p className={'badges-induk'}>NI: {item.karyawan_no_induk}</p>
                                                            </div>
                                                            )}
                                                            <span style={{ fontWeight: 500, color: "#248CE7", fontSize: "14px" }}><a onClick={this.checkSlug.bind(this, item, 'detail')}>{item.karyawan_nama}</a></span>
                                                            <br />
                                                            <div>
                                                                <p className={'text-muted fs-5'} style={{ fontSize: "12px", lineHeight: "24px", fontWeight: 400 }}>{item.karyawan_email}</p>
                                                            </div>
                                                            {item.karyawan_payroll_amount === 0 ?
                                                                <a href={"/daftar-gaji-karyawan"}>
                                                                    <div className={"badge badge-warning"}>Lengkapi Payroll</div>
                                                                </a>
                                                                : ""
                                                            }
                                                        </Td>
                                                        <Td style={{ border: "none" }} align="left">
                                                            <span style={{ fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#143B5E" }}>{item.jabatan && item.jabatan.jabatan_nama ? item.jabatan.jabatan_nama : '-'}</span><br /><div><p className={'text-muted fs-5'} style={{ fontSize: "14px", fontWeight: 400, lineHeight: "24px" }}>{item.divisi && item.divisi.divisi_name ? item.divisi.divisi_name : '-'}</p></div>
                                                        </Td>
                                                        <Td style={{ border: "none" }} align="left">{item.lokasi.length > 0 ? item.lokasi.map((item2, index2) => {
                                                            return (<span className="" style={{ marginRight: '2px', fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#143B5E" }}>{item2.lokasi_nama}{item.lokasi.length > (index2 + 1) && ", "}</span>)
                                                        }) : (<div className="" style={{ marginRight: '2px', fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#143B5E" }}>Semua Lokasi</div>)}</Td>
                                                        <Td style={{ border: "none" }} className="text-left">
                                                            <Dropdown className='px-1'>
                                                                <Dropdown.Toggle style={{ textTransform: "capitalize", fontSize: "12px", backgroundColor: "transparent", fontWeight: 500, lineHeight: "16px" }} className="border border-info-subtle px-1" variant="outline" id="dropdown-basic">
                                                                    {this.state.kunci_pengaturan[index] ? this.state.kunci_pengaturan[index] !== "Kunci " ? this.state.kunci_pengaturan[index].replace("LokasiFoto", "Lokasi, Foto").replace("FotoKunjungan", "Foto, Kunjungan").replace("LokasiKunjungan", "Lokasi, Kunjungan").substring(0, 11) + "..." : "Pilih Disini" : "Pilih Disini"}
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item style={{ fontSize: "12px", fontWeight: 400, lineHeight: "16px", color: "#143B5E" }} href="#/action-1" onClick={(val) => {
                                                                        var data = this.state.data

                                                                        data[index]['kunci_lokasi'] = (val) ? '1' : '0'

                                                                        this.setState({
                                                                            loading: true,
                                                                            data: []
                                                                        })

                                                                        let formData = {
                                                                            karyawan_id: item.karyawan_id,
                                                                            perusahaan_id: this.props.user.perusahaan_id,

                                                                        }

                                                                        this.props.request('karyawan/kunci-lokasi', formData, 'POST')
                                                                            .then((response) => {
                                                                                if (response.success) {
                                                                                    Swal.fire({
                                                                                        title: 'Berhasil!',
                                                                                        text: response.message,
                                                                                        icon: 'success',
                                                                                        showConfirmButton: false,
                                                                                        timer: 1500,
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    if (Array.isArray(response.message)) {

                                                                                        response.message.map(item => {
                                                                                            toast['error'](item)
                                                                                            return true
                                                                                        })
                                                                                    } else {
                                                                                        toast['error'](response.message)
                                                                                    }
                                                                                }
                                                                                this.loadData()
                                                                                this.setState({ loading: false })
                                                                            })

                                                                    }}>Kunci Lokasi {item.kunci_lokasi === '1' ? <i className='fa fa-check pull-right' style={{ color: "#248CE7" }}></i> : ''}</Dropdown.Item>
                                                                    <Dropdown.Item style={{ fontSize: "12px", fontWeight: 400, lineHeight: "16px", color: "#143B5E" }} href="#/action-2" onClick={(val) => {
                                                                        var data = this.state.data


                                                                        data[index]['kunci_foto'] = (val) ? '1' : '0'

                                                                        this.setState({
                                                                            loading: true,
                                                                            data: []
                                                                        })

                                                                        let formData = {
                                                                            karyawan_id: item.karyawan_id,
                                                                            perusahaan_id: this.props.user.perusahaan_id,


                                                                        }

                                                                        this.props.request('karyawan/kunci-foto', formData, 'POST')
                                                                            .then((response) => {
                                                                                if (response.success) {
                                                                                    Swal.fire({
                                                                                        title: 'Berhasil!',
                                                                                        text: response.message,
                                                                                        icon: 'success',
                                                                                        showConfirmButton: false,
                                                                                        timer: 1500,
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    if (Array.isArray(response.message)) {

                                                                                        response.message.map(item => {
                                                                                            toast['error'](item)
                                                                                            return true
                                                                                        })
                                                                                    } else {
                                                                                        toast['error'](response.message)
                                                                                    }
                                                                                }
                                                                                this.loadData()
                                                                                this.setState({ loading: false })
                                                                            })

                                                                    }}>Kunci Foto {item.kunci_foto === '1' ? <i className='fa fa-check pull-right' style={{ color: "#248CE7" }}></i> : ''}</Dropdown.Item>
                                                                    <Dropdown.Item style={{ fontSize: "12px", fontWeight: 400, lineHeight: "16px", color: "#143B5E" }} href="#/action-3" onClick={(val) => {
                                                                        var data = this.state.data

                                                                        data[index]['kunci_kunjungan'] = (val) ? '1' : '0'

                                                                        this.setState({
                                                                            loading: true,
                                                                            data: []
                                                                        })

                                                                        let formData = {
                                                                            karyawan_id: item.karyawan_id,
                                                                        }

                                                                        this.props.request('karyawan/kunci-kunjungan', formData, 'POST')
                                                                            .then((response) => {
                                                                                if (response.success) {
                                                                                    Swal.fire({
                                                                                        title: 'Berhasil!',
                                                                                        text: response.message,
                                                                                        icon: 'success',
                                                                                        showConfirmButton: false,
                                                                                        timer: 1500,
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    if (Array.isArray(response.message)) {

                                                                                        response.message.map(item => {
                                                                                            toast['error'](item)
                                                                                            return true
                                                                                        })
                                                                                    } else {
                                                                                        toast['error'](response.message)
                                                                                    }
                                                                                }
                                                                                this.loadData()
                                                                                this.setState({ loading: false })
                                                                            })

                                                                    }}>Kunci Kunjungan {item.kunci_kunjungan === '1' ? <i className='fa fa-check' style={{ color: "#248CE7" }}></i> : ''}</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Td>
                                                        <Td style={{ border: "none" }} align="center">
                                                            {item.kontrak_aktif ? (
                                                                <div>
                                                                    <span className="badge badge-success">{item.kontrak_aktif.contract_type.toUpperCase()}</span><br/>
                                                                    {item.kontrak_aktif.contract_duration ? (
                                                                        <span className="fs-12">Exp: {formatDateToYMD(addMonthsToDate(new Date(item.kontrak_aktif.contract_start), item.kontrak_aktif.contract_duration))}</span>
                                                                    ) : null}
                                                                </div>
                                                            ): '-'}
                                                        </Td>
                                                        <Td style={{ border: "none" }} align="center">
                                                            <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                                <div className={'p-2'}>
                                                                    <a onClick={this.checkSlug.bind(this, item, 'detail')}
                                                                       style={{marginRight: "50px"}}
                                                                       className={'text-dark'}>Detail</a><br/>
                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                        <>
                                                                            <a onClick={this.checkSlug.bind(this, item, 'edit')}
                                                                               className={'text-dark'}>Edit</a><br/>
                                                                        </>
                                                                    )}

                                                                    {/*<a onClick={() => {*/}
                                                                    {/*    this.loadHistory(item.karyawan_id);*/}
                                                                    {/*    this.setState({ modalHistory: true, karyawan_id: item.karyawan_id });*/}
                                                                    {/*}} className={'text-dark'}>Riwayat</a><br />*/}
                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                        <a onClick={() => {
                                                                            this.setState({
                                                                                idDel: item.karyawan_id
                                                                            })
                                                                            this.wantDelete()
                                                                        }} className={'text-danger'}>Hapus</a>
                                                                    )}
                                                                </div>
                                                            }>
                                                                <a><i
                                                                    className="fa fa-ellipsis-h" style={{
                                                                        width: 35,
                                                                        fontSize: 18,
                                                                        padding: 11,
                                                                        color: 'rgb(51 51 51)'
                                                                    }}
                                                                ></i></a>
                                                            </Tooltip>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                    )
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                                <Modal
                                    title="Import Karyawan"
                                    visible={this.state.modalimport}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[
                                        this.state.importLoading ?
                                            <button className={'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Sedang mengupload data</button>
                                            :
                                            <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                                    ]}
                                >
                                    <div>
                                        <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                                        <a onClick={this.exportKaryawan} style={{ color: "#057CE4" }}>Unduh template disini</a>
                                        <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                            <input type='file'
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                onChange={(event) => {
                                                    this.setState({
                                                        importFile: event.target.files[0],
                                                    })
                                                }} /><br />
                                        </div>
                                    </div>
                                </Modal>
                                <Modal
                                    title="Aksi Karyawan"
                                    visible={this.state.modalAction}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[]}
                                >
                                    <a href={"/karyawan/profil/detail/" + this.state.dataAction.karyawan_id}><i
                                        className="fa fa-eye" style={{
                                            width: 35,
                                            fontSize: 20,
                                            padding: 11,
                                            color: 'rgb(9 153 255)'
                                        }}
                                    ></i>Detail</a>
                                    <a href={"/karyawan/edit-karyawan/" + this.state.dataAction.karyawan_slug}><i
                                        className="fa fa-pencil" style={{
                                            width: 35,
                                            fontSize: 20,
                                            padding: 11,
                                            color: 'rgb(255 172 21)'
                                        }}
                                    ></i>Edit</a>
                                    <a onClick={() => {
                                        this.setState({
                                            idDel: this.state.dataAction.karyawan_id
                                        })
                                        this.wantDelete()
                                    }}><i className="fa fa-trash" style={{
                                        width: 35,
                                        fontSize: 20,
                                        padding: 11,
                                        color: 'rgb(255 37 37)'
                                    }}
                                    ></i>Hapus
                                    </a>
                                </Modal>
                                <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>
                                menampilkan {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} dari {this.state.totalData} data.
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>

            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}



const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_karyawan);
