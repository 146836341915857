import moment from "moment";

export const salam = () => {
    var now = moment().format('HH')

    if(now>=0 && now<=11) {
        return 'Selamat Pagi, '
    }
    else if(now >=12 && now<=14) {
        return 'Selamat Siang, '
    }
    else if(now >=15 && now<=18) {
        return 'Selamat Sore, '
    }
    else {
        return 'Selamat Malam, '
    }
}