import React, {Component} from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import '../home/landing.scss';
import {
    FacebookFilled,
    GoogleCircleFilled,
    InstagramFilled,
    MailTwoTone,
    PhoneTwoTone,
    PushpinTwoTone,
    YoutubeFilled
} from '@ant-design/icons';
import {requestData} from '../../helper/request'

import logo from '../../assets/images/dashboard/logo-header.png'
import android from '../../assets/images/android.png'
import apple from '../../assets/images/apple.png'
import {Helmet} from "react-helmet";

export class KebijakanPrivasi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
            data: {},
            blog: [],
        }
    }

    loadData() {
        let form = {}
        requestData("home", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    toggleMenu = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    }

    render() {
        return (
            <div className="landing-page-multikart">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kebijakan & Privasi - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <header id="sticky" className="sticky">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col p-0">
                                <div className="top-header">
                                    <div className="logo pl-2">
                                        <AnchorLink className="nav-link navbar-brand" href="/"><img src={logo}
                                                                                                        alt="logo"/></AnchorLink>
                                    </div>
                                    <div className="main-menu mx-auto" id="nav">
                                        <nav id="navbar-example2" className="navbar navbar-expand-lg navbar-light">
                                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                                    data-target="#scroll-spy"
                                                    aria-controls="scroll-spy" aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                    onClick={() => this.toggleMenu()}>
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                            <div
                                                className={`collapse navbar-collapse ${this.state.toggle ? 'show' : ''}`}
                                                id="scroll-spy">
                                                <ul className="navbar-nav mx-auto nav">
                                                    <li className="nav-item">
                                                        <a className="nav-link"
                                                           href='/'>Home</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href='/blog'>Blog</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <AnchorLink className="nav-link"
                                                                    href='#kontak'>Kontak</AnchorLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <span><a className="nav-link" href="/login">Masuk</a></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                    <div>
                                        <form target="_blank" className="form-inline my-lg-0"
                                              action="/register">
                                            <button className="btn my-sm-0 purchase-btn">Demo</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/*Home section*/}
                <section className="main-img responsive-img  pt-0" id="kebijakan" style={{maxHeight: 400}}>
                    <div className="container">
                        <div className="main-contain">
                            <div className="col-md-12 text-center">
                                <h3 className="m-0">Kebijakan & Privasi</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="blog" className="section-lr feature-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="feature text-center">
                                    <div>
                                        <h5 className="title-landing">Kebijakan & Privasi</h5>
                                        <p className="pb-3"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className="row feature_row">
                                <div className="col-xl-12 col-md-12">
                                    <div className="feature-box text-center">
                                        <div style={{display: 'block !important', textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: this.state.data.app_privacy}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>

                {/*Kontak*/}
                <section id="kontak" className="main-feature section-b-space grey-bg">
                    <div className="container mt-5">
                        <h4 className="pb-3">Punya pertanyaan mengenai absensi online?</h4>
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-12">
                                <h5 className="title-landing">Hubungi Kami</h5>
                                <p>Presensi.co.id</p>
                                <ul className="navbar-nav">
                                    <li>
                                        <PhoneTwoTone/>
                                        <a className='ml-2 text-custome-16'
                                           href={'tel' + this.state.data.contact_phone}
                                           alt={'Telepon ' + this.state.data.contact_phone}
                                           title={'Telepon ' + this.state.data.contact_phone}>
                                            {this.state.data.contact_phone}</a>
                                    </li>
                                    <li className="mt-3">
                                        <MailTwoTone/>
                                        <a className='ml-2 text-custome-16'
                                           href={"mailto:" + this.state.data.contact_email}
                                           alt={"email:" + this.state.data.contact_email}
                                           title={"email:" + this.state.data.contact_email}>
                                            {this.state.data.contact_email}</a>
                                    </li>
                                    <li className="mt-3 mb-3">
                                        <PushpinTwoTone/>
                                        <span className='ml-2 text-custome-16'>
                                            {this.state.data.contact_address}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Menu</h5>
                                <ul className="navbar-nav">
                                    <li className="mb-3">
                                        <AnchorLink className="text-custome-16" href='/login'>Masuk</AnchorLink>
                                    </li>
                                    <li className="mb-3">
                                        <AnchorLink className="text-custome-16" href='/register'>Daftar</AnchorLink>
                                    </li>
                                    <li className="mb-3">
                                        <a className="text-custome-16" href='#'>Kebijakan & Privasi</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Temukan Kami</h5>
                                <ul>
                                    <li>
                                        <GoogleCircleFilled className="text-custome-16"/>
                                    </li>
                                    <li className="ml-4">
                                        <a target='_blank' href={this.state.data.contact_facebook_link}><FacebookFilled
                                            className="text-custome-16"/></a>
                                    </li>
                                    <li className="ml-4">
                                        <a target='_blank'
                                           href={this.state.data.contact_instagram_link}><InstagramFilled
                                            className="text-custome-16"/></a>
                                    </li>
                                    <li className="ml-4 mb-3">
                                        <a target='_blank' href={this.state.data.contact_youtube_link}><YoutubeFilled
                                            className="text-custome-16"/></a>\
                                    </li>

                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Tersedia di</h5>
                                <div className="set-image mb-3">
                                    <a target='_blank' href={this.state.data.app_url_android}><img
                                        style={{maxWidth: 100}} src={android} alt="android"/></a>
                                </div>
                                <div className="set-image">
                                    <a target='_blank' href={this.state.data.app_url_ios}><img style={{maxWidth: 100}}
                                                                                               src={apple} alt="apple"/></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <div className="footer-shadow text-center">
                    Copyright by presensi.co.id 2020
                </div>

                {/*Tap To Top*/}
                <div className="tap-top">
                    <div>
                        <i className="fa fa-angle-double-up"></i>
                    </div>
                </div>
            </div>
        )
    }
}

export default KebijakanPrivasi
