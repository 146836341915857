import React from "react";
import { Helmet } from "react-helmet";
import "./style.css";
import { set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";

import LoginV2Img from "../../assets/images/login-v2.png"
import logo from "../../assets/images/full-logo.png"
import InfoIcon from "../../assets/icons/info-icon-green.svg"
import CrossMarkIcon from "../../assets/icons/cross-mark.svg"

class LupaPasswordV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            messageEmail: "",
            messageSuccess: {
                header: "Berhasil",
                value: ""
            },
            messageFailed: {
                header: "Gagal",
                value: ""
            },
            invalidEmail: false,
            prosesSimpan: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value

        this.setState({
            [name]: value
        })
    }

    submitLogin() {
        this.setState({
            prosesSimpan: true,
        })
        let form = {
            email: this.state.email
        }
        this.props.request("reset_password_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.history.push({
                        pathname: '/login',
                        state: { success: true, message: response.message }
                    });
                } else {
                    this.setState({
                        messageFailed: {
                            header: "Gagal",
                            value: response.message
                        }
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Login - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <div className="container-fluid">
                    <div className="row flex-row-reverse">
                        <div className="col-md-8 p-0">
                            <div className="loginv2-div-img">
                                <img alt="" src={LoginV2Img} className="loginv2-img" />
                            </div>
                        </div>
                        <div className="col-md-4 loginv2-col-form">
                            <div className="row loginv2-row-logo">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <img alt="" src={logo} />
                                </div>
                            </div>
                            {this.state.messageFailed.value !== "" &&
                                <div className="row">
                                    <div className="col-md-12 loginv2-row-form">
                                        <div className="d-flex flex-row loginv2-failed">
                                            <div>
                                                <img alt="" src={CrossMarkIcon} style={{ width: "24px", height: "24px" }} />
                                            </div>
                                            <div className="pl-2">
                                                <div style={{
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "18px"
                                                }}>{this.state.messageFailed.header}</div>
                                                <div>{this.state.messageFailed.value}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.messageSuccess.value !== "" &&
                                <div className="row">
                                    <div className="col-md-12 loginv2-row-form">
                                        <div className="d-flex flex-row loginv2-success">
                                            <div>
                                                <img alt="" src={InfoIcon} style={{ width: "24px", height: "24px" }} />
                                            </div>
                                            <div className="pl-2">
                                                <div style={{
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "18px"
                                                }}>{this.state.messageSuccess.header}</div>
                                                <div>{this.state.messageSuccess.value}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-md-12 text-center loginv2-masuk-akun">
                                    Masukan email anda!
                                </div>
                            </div>
                            <div className="row loginv2-row-form mb-4">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="loginv2-label">Email</label>
                                        <input className={this.state.invalidEmail ? "form-control is-invalid loginv2-input" : "form-control loginv2-input"} placeholder="emailanda@gmail.com" name="email" onChange={this.handleChange} type="text" value={this.state.email} onKeyDown={(e) => {
                                            if (this.state.email !== "") {
                                                if (e.key === "Enter") {
                                                    this.submitLogin()
                                                }
                                            }
                                        }} />
                                        {this.state.invalidEmail &&
                                            <div className="invalid-feedback loginv2-invalid">
                                                {this.state.messageEmail}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row loginv2-row-form mb-4">
                                <div className="col-md-12">
                                    <button className="btn btn-primary loginv2-button-login" disabled={this.state.email === "" || this.state.prosesSimpan} onClick={this.submitLogin}>Verifikasi</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LupaPasswordV2);