import React, {Component} from 'react'
import {APIKEY} from "../../../../helper/host";
import {Button, ButtonGroup, Col, FormGroup, Modal, ModalBody, ModalHeader, Row} from 'reactstrap'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import CurrencyInput from 'react-currency-input';
import {GoogleApiWrapper} from 'google-maps-react';

export class ModalPotongan extends Component {
    constructor(props) {
        super(props);
        this.state      = {
            loading                 : false,
        }

    }




    render() {
        return (
            <div>
                <Modal isOpen={this.props.visiblePot} toggle={this.props.potonganModal}
                       style={{maxWidth: '900px', width: '100%'}}>
                    <ModalHeader>
                        List Potongan
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <Col lg="12">
                                {this.props.potongan.map((item, id) =>{
                                    return(
                                        <Row>
                                            <Col className="text-center">
                                                {item.payroll_term.p_term_name}
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <select
                                                        name="k_potongan_type"
                                                        id="k_potongan_type"
                                                        className="form-control"
                                                        onChange={this.props.handlePotonganType(id)}
                                                        placeholder="Type Potongan"
                                                        value={item.k_potongan_type}
                                                    >
                                                        <option value={""}>Type Potongan</option>
                                                        <option value="jam">Jam</option>
                                                        <option value="hari">Hari</option>
                                                        <option value="bulan">Bulan</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <CurrencyInput
                                                        name="k_potongan_amount"
                                                        className="form-control"
                                                        thousandSeparator="."
                                                        value={item.k_potongan_amount}
                                                        prefix="Rp "
                                                        id="k_potongan_amount"
                                                        precision={0}
                                                        onChangeEvent={this.props.handlePotonganAmount(id)}
                                                        placeholder="Nominal potongan"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2">
                                                {item.payroll_term.p_term_prefix == "general" ?
                                                    <ButtonGroup size="sm">

                                                        <Button
                                                            onClick={this.props.removeClick.bind(this,item.karyawan_id, item.k_potongan_id)}
                                                            color='danger'
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </Button>


                                                    </ButtonGroup>
                                                    : ""}
                                            </Col>
                                        </Row>
                                    )
                                })}
                                <Row form className="text-center col-md-12 mt-3 mb-3" style={{alignItems: "center", justifyContent: "center"}}>
                                    <Button
                                        onClick={()=>{this.props.handleSubmitPotongan(this.props.id)}}
                                        color="success"
                                        className="btn-sm"
                                    >
                                        Save Potongan
                                    </Button>
                                </Row>
                            </Col>
                        </div>
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}

export default  GoogleApiWrapper({
    apiKey: APIKEY
}) (ModalPotongan)
