import React, {Component, Fragment} from 'react';
import Breadcrumb from '../common/breadcrumb';
import {Empty, Spin} from 'antd';
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Paging from "../../components/paging";
import {Helmet} from "react-helmet";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {pageNumber} from "../../functions/functionGeneral";
import {APIKEY} from "../../helper/host";
import {GoogleApiWrapper} from 'google-maps-react';
import {requestTracking} from "../../helper/request";
import {connect} from "react-redux";

export class List_tracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading             : true,
            karyawan            : [],
            data                : [],
            idDel               : '',
            keyword             : "",
            modal               : false,
            kunjunganStartDate  : '',
            kunjunganEndDate    : '',
            modalexport         : false,
            pageCount           : 0,
            currentPage         : 0,
            totalData           : 0,
            perPage             : 0,
            paging              : 10,
            guide               : false
        }
        this.handleChange           = this.handleChange.bind(this)
        this.handleFilter           = this.handleFilter.bind(this)
        this.resetFilter            = this.resetFilter.bind(this)
        this.handlePageClick        = this.handlePageClick.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({currentPage: selected, data: [], loading: true}, () => {
            this.loadData();
        });
    }

    handleOk = e => {
        this.setState({
            modalexport : false,
            modal       : false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalexport : false,
            modal       : false,
        });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            [name]: value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter(event){
        this.setState({
            keyword             : '',
            kunjunganStartDate  : '',
            kunjunganEndDate    : '',
            data                : [],
            loading             : true
        }, () => this.loadData())
    }


    loadData() {
        let form = {
            limit            : 10,
            page                : this.state.currentPage + 1,
            namaKaryawan             : this.state.keyword,
            perusahaanId  : this.props.user.perusahaan.perusahaan_id,
            kunjunganEndDate    : this.state.kunjunganEndDate,
        }
        requestTracking("trackings/getTrackings", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data        : response.data.docs,
                        pageCount   : response.data.totalPages,
                        perPage     : response.data.limit,
                        loading     : false
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Daftar Tracking - Presensi</title>
                    <meta name="description" content="Daftar Tracking" />
                </Helmet>
                <Breadcrumb title="Laporan Tracking Karyawan" parent="Karyawan" setGuide={() => {this.setState({guide: true}) }}/>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">

                            {this.state.kunjunganStartDate &&
                            <button onClick={() => {this.setState({modalexport: true})}} className={"btn btn-success"} style={{float: "right"}}>Export <i className="fa fa-share"></i></button>
                            }
                            <h5>Detail Tracking</h5>
                        </div>
                        <div className="row filter-absen" style={{marginLeft: '5px'}}>
                            <div className="col-md-3">
                                <label style={{fontSize: '10px'}}>Filter Karyawan:</label>
                                <input name="keyword"
                                       className="form-control"
                                       placeholder="Telusuri Karyawan ..."
                                       type="text"
                                       required=""
                                       value={this.state.keyword}
                                       onChange={this.handleChange}
                                       onKeyUp={(event) => {
                                           event.preventDefault()
                                           if(event.which == 13) {

                                               this.setState({
                                                   page        : 0,
                                                   paging      : this.state.paging,
                                                   loading: true
                                               }, () => {
                                                   this.loadData()
                                               });
                                           }
                                       }}
                                />
                            </div>
                            <div className="col-md-2">
                                <label style={{fontSize: '10px'}}>Atur Ulang Filter:</label><br/>
                                <button className="btn btn-danger" onClick={this.resetFilter}><span className="fa fa-refresh"></span> Atur Ulang</button>
                            </div>
                        </div>
                        <div className="card-body daftar-absen">
                            {!this.state.loading && this.state.data.length === 0 ?
                                <Empty description="Data Kosong"
                                       image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"/>
                                :
                                <Table striped className="mb-5">
                                    <Thead>
                                    <Tr>
                                        <Th width="30" className="text-center">#</Th>
                                        <Th className="text-center">Nama Karyawan</Th>
                                        <Th className="text-center">Aksi</Th>
                                    </Tr>
                                    </Thead>
                                    <Tbody>
                                    {this.state.loading &&
                                    <Tr>
                                        <Td colSpan={9}>
                                            <Spin size="large" tip="Loading..." style={{
                                                width: '100%',
                                                margin: 'auto',
                                                marginBottom: '10px',
                                                marginTop: '10px'
                                            }}/>
                                        </Td>
                                    </Tr>
                                    }
                                    {this.state.data.map((item, index) => {
                                        no += 1
                                        return (
                                            <Tr>
                                                <small>
                                                    <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                </small>

                                                <Td align="center">
                                                    <a href={"/karyawan/profil/detail/" + item._id}>
                                                        {item.namaKaryawan != null ?
                                                            <span>{item.namaKaryawan}</span>
                                                            :
                                                            <span>-</span>
                                                        }
                                                    </a>
                                                </Td>
                                                <Td align="center">
                                                    <div className="btn-group-sm btn-group">
                                                        <a href={"/kunjungan/detail-tracking/" + item._id}>
                                                            <i
                                                                className="fa fa-eye" style={{
                                                                width: 35,
                                                                fontSize: 20,
                                                                padding: 11,
                                                                color: 'rgb(9 153 255)'
                                                            }}
                                                            ></i>
                                                        </a>
                                                    </div>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                    </Tbody>
                                </Table>
                            }
                            <Paging
                                className="float-right"
                                handlePageClick={this.handlePageClick}
                                pageCount={this.state.pageCount}
                                currentPage={this.state.currentPage}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

const google = GoogleApiWrapper({
    apiKey: APIKEY
})(List_tracking)
export default connect(mapStateToProps)(google)
