import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Hari from "./laporan_harian";
import Minggu from "./laporan_mingguan";
import Bulan from "./laporan_bulanan";
import { Col, Row } from 'reactstrap';
import "./style.css";


export class Laporan_Kunjungan extends Component {
    render() {
        let no = 0;

        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Kunjungan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Laporan Kunjungan - Presensi</title>
                        <meta name="description" content="Laporan Kunjungan" />
                    </Helmet>
                    <Breadcrumb title="Laporan Kunjungan" parent="Kunjungan" setGuide={() => { this.setState({ guide: true }) }} />
                    <div className="container-fluid laporan-karyawan">
                        <div className="card" style={{ border: "1px solid #DDDDDD" }}>
                            <div className='card-header lk-card-header'>
                                Laporan Kunjungan
                            </div>
                            <div className="card-body p-4">
                                <div>
                                    <Row>
                                        <Col sm="12" md="8" lg="12">
                                            <Tabs>
                                                <TabList className="nav nav-tabs tab-coupon">
                                                    <Tab className="nav-link">Hari</Tab>
                                                    <Tab className="nav-link">Minggu</Tab>
                                                    <Tab className="nav-link">Bulan</Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <Hari
                                                        request={this.props.request}
                                                        match={this.props.match}
                                                        history={this.props.history} />
                                                </TabPanel>
                                                <TabPanel>
                                                    <Minggu
                                                        request={this.props.request}
                                                        match={this.props.match}
                                                        history={this.props.history}
                                                    />
                                                </TabPanel>
                                                <TabPanel>
                                                    <Bulan
                                                        request={this.props.request}
                                                        match={this.props.match}
                                                        history={this.props.history}
                                                    />
                                                </TabPanel>
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

export default connect(mapStateToProps)(Laporan_Kunjungan);
