import React, {Component, Fragment} from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Swal from "sweetalert2";
import {requestData} from "../../helper/request";
import {Helmet} from "react-helmet";

export class AktifasiKaryawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            disabledButton: false,
            success: false,
            password: "",
            ulangipassword: "",
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    submitAktifasi = () => {
        this.setState({
            loading: true
        })

        let form = {
            id: this.props.match.params.id,
        }

        requestData("karyawan/aktifasi_akun", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false
                    })
                }else{
                    Swal.fire({
                        title: response.failed,
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    componentDidMount() {
        this.submitAktifasi()
    }

    componentWillReceiveProps(newProps) {
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false
        };

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Aktifasi Akun - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">

                                            {this.props.match.params.id ?
                                                <div>
                                                    <div className="card-header">
                                                        <h5>Aktifasi Akun</h5>
                                                    </div>
                                                    {this.state.loading ?
                                                        <form className="form-horizontal auth-form">
                                                            <b><i className={'fa fa-refresh fa-spin'}></i> Sedang mengaktifasi akun anda</b><br/>
                                                            <label>Mohon tunggu sebentar...</label>
                                                        </form>
                                                        :
                                                        <form className="form-horizontal auth-form">
                                                            <b>Akun anda sudah Aktif!</b><br/>
                                                            <label>Silahkan masuk ke aplikasi Presensi dan Masuk dengan
                                                                email dan pasword yang sudah dikirimkan ke email anda sebelumnya...</label>
                                                        </form>
                                                    }
                                                </div> :
                                                <div>
                                                    <div className="card-header">
                                                        <h5>Akun tidak Ditemukan!</h5>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default AktifasiKaryawan
