import React, { Component, Fragment } from 'react'
import moment from 'moment';
import { APIKEY } from "../../helper/host";
import { Empty, Spin } from "antd";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Paging from "../paging";
import { Col } from 'reactstrap'
import { BACKEND_API_HOST } from "../../helper/host";
import { Else, ElseIf, If, Then } from "react-if-elseif-else-render";
import { pageNumber } from "../../functions/functionGeneral";
import Swal from "sweetalert2";
import { GoogleApiWrapper } from 'google-maps-react';
import { Button, Dropdown, Space } from 'antd';

export class GuideAbsensiDetailKaryawan extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        this.state = {
            loading: false,
            absen: [],
            laporan: [],
            year: tahun,
            month: namabulan[bulan],
            absensiStartDate: '',
            absensiEndDate: '',
            pageCount: 0,
            perPage: 0,
            currentPage: 0,

        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
    }

    resetFilter(event) {
        this.setState({

            kunjunganStartDate: '',
            kunjunganEndDate: '',
            data_kunjungan: [],
            loading: true
        }, () => this.loadDataKaryawan())
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadDataKaryawan())
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, absen: [] }, () => {
            this.loadDataKaryawan();
        });
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            absensiStartDate: start,
            absensiEndDate: end
        }, () => {
            this.loadDataKaryawan()
        });
    }

    loadDataKaryawan() {
        this.setState({ loading: true })
        let form = {
            id_karyawan: this.props.match.params.id,
            absensiStartDate: this.state.absensiStartDate,
            absensiEndDate: this.state.absensiEndDate,
            month: this.state.month,
            page: this.state.currentPage + 1,
            year: this.state.year,
        }
        this.props.request("karyawan/get_absensi", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        laporan: response.laporan,
                        absen: response.absen.data,
                        pageCount: response.absen.last_page,
                        perPage: response.absen.per_page,
                        visible: true
                    })
                }
                this.setState({ loading: false })
            });
    }

    exportAbsensiKaryawan() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'karyawan/export-absen-karyawan?token=' + AuthKey + '&presensiStartDate=' + this.state.absensiStartDate + '&presensiEndDate=' + this.state.absensiEndDate + '&id=' + this.props.match.params.id, "_blank")
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Presensi ?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        loading: true,
                        data: [],
                    })
                    this.props.request("presensi/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadDataKaryawan()
                            }
                        });
                }
            })
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    componentDidMount() {
        this.loadDataKaryawan()
    }

    render() {

        let to = 0;


        return (
            <Fragment>

                <div className="container-fluid">
                    {/*Laporan Absen*/}
                    <div className="card mb-2">
                        <div className="card-header">
                            <h5>Laporan Presensi Karyawan</h5>
                        </div>
                        <div className="row" style={{ marginLeft: 18 }}>
                            <Col md={3}>
                                <label style={{ fontSize: '10px' }}>Filter Bulan:</label>
                                <select name="month" className="form-control" onChange={this.handleFilter} value={this.state.month}>
                                    <option value="1">Januari</option>
                                    <option value="2">Februari</option>
                                    <option value="3">Maret</option>
                                    <option value="4">April</option>
                                    <option value="5">Mei</option>
                                    <option value="6">Juni</option>
                                    <option value="7">Juli</option>
                                    <option value="8">Agustus</option>
                                    <option value="9">September</option>
                                    <option value="10">Oktober</option>
                                    <option value="11">November</option>
                                    <option value="12">Desember</option>
                                </select>
                            </Col>
                            <Col md={2}>
                                <label style={{ fontSize: '10px' }}>Filter Tahun:</label>
                                <select name="year" className="form-control" onChange={this.handleFilter} value={this.state.year}>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                    <option value="2029">2029</option>
                                    <option value="2030">2030</option>
                                </select>
                            </Col>
                        </div>
                        <div className="card-body daftar-absen" style={{ marginLeft: 12, paddingBottom: "24px" }}>
                            {this.state.loading ?
                                <Spin size="large" tip="Sedang memuat data..." style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }} />
                                :
                                <div>
                                    <div className="row">
                                        <div className='col-md-6'>
                                            <div className='row mb-2'>
                                                <div className='col-md-8' style={{ fontSize: "14px", color: "#5D6F80" }}>
                                                    Jumlah Hari
                                                </div>
                                                <div className='col-md-1' width="20" style={{ fontSize: "14px" }}>
                                                    :
                                                </div>
                                                <div className='col-md-3' style={{ fontSize: "14px", color: "#143B5E" }}>
                                                    {this.state.laporan.jumlah_hari_bulan} Hari
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-md-8' style={{ fontSize: "14px", color: "#5D6F80" }}>
                                                    Jumlah Berangkat
                                                </div>
                                                <div className='col-md-1' style={{ fontSize: "14px" }}>
                                                    :
                                                </div>
                                                <div className='col-md-3' style={{ fontSize: "14px", color: "#143B5E" }}>
                                                    {this.state.laporan.jumlah_berangkat} Hari
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-md-8' style={{ fontSize: "14px", color: "#5D6F80" }}>
                                                    Jumlah Terlambat
                                                </div>
                                                <div className='col-md-1' style={{ fontSize: "14px" }}>
                                                    :
                                                </div>
                                                <div className='col-md-3' style={{ fontSize: "14px", color: "#143B5E" }}>
                                                    {this.state.laporan.jumlah_telat} Hari
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-md-8' style={{ fontSize: "14px", color: "#5D6F80" }}>
                                                    Jumlah Jam Kerja Aktual
                                                </div>
                                                <div className='col-md-1' style={{ fontSize: "14px" }}>
                                                    :
                                                </div>
                                                <div className='col-md-3' style={{ fontSize: "14px", color: "#143B5E" }}>
                                                    {this.state.laporan.jam_kerja} Menit
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    {/*Data Absen*/}
                    <div className="card">
                        <div className="card-body daftar-absen">
                            {!this.state.loading && this.state.absen.length === 0 ?
                                <Empty description="Data Kosong"
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                :
                                <table className="table mb-5">
                                    <thead>
                                        <tr>
                                            <th style={{ color: "#143B5E", fontSize: "14px" }}>#</th>
                                            <th style={{ color: "#143B5E", fontSize: "14px" }}>Hari / Tanggal</th>
                                            <th style={{ color: "#143B5E", fontSize: "14px" }} className="text-center">Jam Masuk-Keluar</th>
                                            <th style={{ color: "#143B5E", fontSize: "14px" }} className="text-center">Jam Aktual</th>
                                            <th style={{ color: "#143B5E", fontSize: "14px" }} className="text-center">Total Jam Kerja</th>
                                            <th style={{ color: "#143B5E", fontSize: "14px" }} className="text-center">Status</th>
                                            <th style={{ color: "#143B5E", fontSize: "14px" }} className="text-center" width={140}>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.loading &&
                                            <tr>
                                                <td colSpan={8}>
                                                    <Spin size="large" tip="Loading..." style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }} />
                                                </td>
                                            </tr>
                                        }
                                        {this.state.absen.map((item, index) => {
                                            to += 1
                                            let status = 'badge'
                                            const items = [
                                                {
                                                    key: '1',
                                                    label: (
                                                        <a rel="noopener noreferrer" href={"/detail-presensi/" + item.id_absensi}>
                                                            Detail
                                                        </a>
                                                    ),
                                                },
                                                {
                                                    key: '2',
                                                    label: (
                                                        <a rel="noopener noreferrer" href={"/edit-presensi/" + item.id_absensi}>
                                                            Edit
                                                        </a>
                                                    ),
                                                },
                                                {
                                                    key: '3',
                                                    label: (
                                                        <a rel="noopener noreferrer" style={{ color: '#E03616' }} onClick={() => {
                                                            this.setState({
                                                                idDel: item.id_absensi
                                                            })
                                                            this.wantDelete()
                                                        }}>
                                                            Hapus
                                                        </a>
                                                    ),
                                                },
                                            ];
                                            return (
                                                <tr>
                                                    <td>
                                                        <small>
                                                            <th style={{ border: "none" }} scope="row">{pageNumber(to, this.state.perPage, this.state.currentPage)}</th>
                                                        </small>
                                                    </td>
                                                    <td style={{ fontSize: "14px", color: "#143B5E" }}>{item.absen_hari} / {moment(item.tanggal).format('ll')}</td>
                                                    <td style={{ fontSize: "14px" }} align="center">{item.jam_masuk_absen !== null || item.jam_masuk_absen !== "" ?
                                                        <div style={{ color: "#143B5E" }}>{item.jam_masuk_absen} - {item.jam_keluar_absen !== null || item.jam_keluar_absen !== "" ? item.jam_keluar_absen : ""}</div>
                                                        : <div style={{ color: "#B6B6B6" }}>-</div>}</td>
                                                    <td style={{ fontSize: "14px", color: "#143B5E" }} align="center">{item.jam_terhitung ? item.jam_terhitung + ' menit' : "-"}</td>
                                                    <td style={{ fontSize: "14px", color: "#143B5E" }} align="center">{item.jam_kerja ? item.jam_kerja + ' menit' : "-"}</td>
                                                    <td style={{ fontSize: "14px", color: "#143B5E" }} align="center">
                                                        <If condition={item.status_in === 'Masuk Kerja'}>
                                                            <Then>
                                                                <div style={{ color: '#6BAA61' }}>{item.status_in}</div>
                                                            </Then>
                                                            <ElseIf condition={item.status_in === 'Terlambat'}>
                                                                <div style={{ color: '#FF5507' }}>{item.status_in}</div>
                                                            </ElseIf>
                                                            <Else>
                                                                <div style={{ color: "#FFC107" }} >{item.status_in}</div>
                                                            </Else>
                                                        </If>
                                                    </td>
                                                    <td align="center">
                                                        <Space direction="vertical">
                                                            <Space wrap>
                                                                <Dropdown
                                                                    menu={{ items }}
                                                                    placement="bottom"
                                                                >
                                                                    <Button style={{ border: "none" }}><i
                                                                        className="fa fa-ellipsis-h" style={{
                                                                            fontSize: 18,
                                                                            color: 'rgb(51 51 51)'
                                                                        }}
                                                                    ></i></Button>
                                                                </Dropdown>
                                                            </Space>
                                                        </Space>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            }
                            <Paging
                                className="float-right"
                                handlePageClick={this.handlePageClick}
                                pageCount={this.state.pageCount}
                                currentPage={this.state.currentPage}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: APIKEY
})(GuideAbsensiDetailKaryawan)
