import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import {Empty, Modal, Select, Spin, Tooltip} from 'antd';
import Edit from './edit'
import Add from "./add";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import ModalDefault from '../menu-guide/modal-default';
import './style.css'

import TimeInput from '../../helper/time_input'
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ImportIcon from "../../assets/icons/import-icon.svg";
import {BACKEND_API_HOST} from "../../helper/host";

export class JamKerja extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shift: [],
            idDel: '',
            isLoading: true,
            pageCount: 0,
            currentPage: 0,
            currentPageHistory:0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination:2,
            id_jam_kerja: '',
            hari_kerja: '',
            dataEdit: [],
            dataHistory: [],
            sift_id: '',
            jam_masuk: '',
            jam_keluar: '',
            filter_shift: '',
            add_visible: false,
            edit_visible: false,
            visable: false,
            guide: false,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            loadingHistory: false,
            modalHistory: false,
            modalimport: false,
            importFile: null,
            confirm_modal_default: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], isLoading: true }, () => {
            this.loadData();
        });
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    handleEdit = () => {
        this.setState({
            isLoading: true,
            data: []
        })
        let form = {
            sift_id: this.state.sift_id,
            id: this.state.id_jam_kerja,
            hari_kerja: this.state.hari_kerja,
            jam_masuk: this.state.jam_masuk,
            jam_keluar: this.state.jam_keluar,
        }
        this.props.request("jamKerja/update_inline", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.setState({
                        data: []
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        data: []
                    })
                    this.loadData()
                }
            });
    }

    handleSubmit(event) {
        const list = this.state.data
        let jam = []
        for (const val of list) {
            jam = [
                ...jam,
                val.jam_kerja_senin,
                val.jam_kerja_selasa,
                val.jam_kerja_rabu,
                val.jam_kerja_kamis,
                val.jam_kerja_jumat,
                val.jam_kerja_sabtu,
                val.jam_kerja_minggu
            ];

        }

        this.setState({
            isLoading: true,
            data: []
        })
        let formData = {
            rows: jam,

        }
        this.props.request('jamKerja/add_row', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.setState({
                        data: []
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        data: []
                    })
                    this.loadData()
                }
            })
    }


    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true,
                        data: []
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("jamKerja/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    data: []
                                })
                                this.loadData()
                                this.loadCheck()
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                                this.setState({
                                    data: []
                                })
                                this.loadData()
                                this.loadCheck()
                            }
                        });
                }
            })
    }

    handleFilter = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            isLoading: true,
            data: [],
        }, () => this.loadData())
    }

    resetFilter = () => {
        this.setState({
            filter_shift: '',
            isLoading: true,
            data: [],
        }, () => this.loadData())
    }

    loadData() {
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
            filter_hari_kerja: this.state.filter_hari_kerja,
            filter_shift: this.state.filter_shift,
        }
        this.props.request("jamKerja/list_per_shift", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        isLoading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }

    loadShift() {
        let form = {}
        this.props.request("shift/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        shift: response.data.data,
                        sift_id: null,
                    })
                }
            });
    }
    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        karyawan_check: response.karyawan,
                        jam_kerja_check: response.jam_kerja
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                            this.setState({
                                confirm_modal_default: true
                            })
                        }
                    })
                }
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.sift_id,
                paging : this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("jamKerja/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            sift_id: ''
                        });
                    }
                });
        })
    }

    exportJamKerja = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'jamKerja/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'jamKerja/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: true
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }


    componentDidMount() {
        this.loadData()
        this.loadShift()
        this.loadCheck()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-jamker',
                content: 'Anda dapat membuat Jam Kerja disini',
            },
            {
                selector: '.daftar-jamker',
                content: 'Daftar Jam Kerja anda dapat dilihat disini',
            },
        ];
        const { Option } = Select;
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Jam Kerja") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Jam Kerja - Presensi</title>
                        <meta name="description" content="Daftar Jam Kerja" />
                    </Helmet>
                    <Edit
                        data={this.state}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Breadcrumb parent="Manajemen Jam Kerja" title="Daftar Jam Kerja" setGuide={() => {
                        this.setState({ guide: true })
                    }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody}
                        onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <ModalDefault
                        jam_kerja={this.state.jam_kerja_check}
                        shift={this.state.shift_check}
                        lokasi={this.state.lokasi_check}
                        divisi={this.state.divisi_check}
                        karyawan={this.state.karyawan_check}
                        confirm_modal_default={this.state.confirm_modal_default}
                        setState={(name, val) => this.setState(name, val)}
                        history={this.props.history}
                    />
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>Aktivitas</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src="https://servicesdev.presensi.co.id/media/logo-default-user.png"
                                                                            alt=""
                                                                            style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === 'created' ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Dibuat
                                                                                    </div>
                                                                                </div>
                                                                            ):null}
                                                                            {item.history_status === 'edited' ? (
                                                                                    <div
                                                                                        className="m-0"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#FF6200FF',
                                                                                            borderColor: '#FF6200FF',
                                                                                        }}
                                                                                    >
                                                                                        <div className="d-flex justify-content-center" style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                            Diubah
                                                                                        </div>
                                                                                    </div>
                                                                                ):null}
                                                                            {item.history_status === 'deleted' ? (
                                                                                    <div
                                                                                        className="m-0"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#e50606',
                                                                                            borderColor: '#e50606',
                                                                                        }}
                                                                                    >
                                                                                        <div className="d-flex justify-content-center" style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                            Dihapus
                                                                                        </div>
                                                                                    </div>
                                                                                ):null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                <div>
                                                                    {item.history_status !== 'deleted' && (
                                                                        <div>
                                                                            <h6
                                                                                className="mb-0 font-13 text-left"
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}
                                                                            >
                                                                                Jam Kerja {' '}
                                                                                ({item.parent_history
                                                                                ? item.parent_history.kerja_hari
                                                                                : item.kerja_hari}){' '}
                                                                            </h6>
                                                                        </div>
                                                                    )}
                                                                    {item.history_status === 'created' ? (
                                                                        <div>
                                                                            <h6
                                                                                className="mb-0 font-13 text-left"
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}
                                                                            >
                                                                                Jam Masuk Baru: {' '}
                                                                                {item.parent_history
                                                                                    ? item.parent_history.jam_masuk
                                                                                    : item.jam_masuk}{' '}
                                                                                <br/>
                                                                                Jam keluar Baru: {' '}
                                                                                {item.parent_history
                                                                                    ? item.parent_history.jam_keluar
                                                                                    : item.jam_keluar}{' '}
                                                                            </h6>
                                                                        </div>
                                                                        ):null
                                                                    }
                                                                    {item.history_status === 'deleted' ? (
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-0 font-13 text-left"
                                                                                    style={{
                                                                                        fontWeight: 500,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    <i>Data telah dihapus!</i>
                                                                                </h6>
                                                                            </div>
                                                                        ):null
                                                                    }
                                                                    {item.parent_history ?
                                                                        item.parent_history.jam_masuk != item.jam_masuk && (
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-0 font-13 text-left"
                                                                                    style={{
                                                                                        fontWeight: 500,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    Jam Masuk: {' '}
                                                                                    {item.parent_history
                                                                                        ? item.parent_history.jam_masuk
                                                                                        : 'Kosong'}{' '}
                                                                                    <img
                                                                                        src={ArrowRightIcon}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '20x',
                                                                                            marginBottom: '3px',
                                                                                            height: '20px'
                                                                                        }}
                                                                                    /> Jam Masuk Baru: {item.jam_masuk ? item.jam_masuk : ''}
                                                                                </h6>
                                                                            </div>
                                                                        ):null
                                                                    }
                                                                    {item.parent_history ?
                                                                        item.parent_history.jam_keluar != item.jam_keluar && (
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-0 font-13 text-left"
                                                                                    style={{
                                                                                        fontWeight: 500,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    Jam keluar: {' '}
                                                                                    {item.parent_history
                                                                                        ? item.parent_history.jam_keluar
                                                                                        : 'Kosong'}{' '}
                                                                                    <img
                                                                                        src={ArrowRightIcon}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '20x',
                                                                                            marginBottom: '3px',
                                                                                            height: '20px'
                                                                                        }}
                                                                                    /> Jam Keluar Baru: {item.jam_keluar ? item.jam_keluar : ''}
                                                                                </h6>
                                                                            </div>
                                                                        ):null
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} dari {this.state.totalDataHistory} data</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>
                    <Modal
                        title="Import Jam Kerja"
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Sedang mengupload data</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                            <a onClick={this.exportJamKerja} style={{ color: "#057CE4" }}>Unduh template disini</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card profile-card">
                                    <div className="card-header pb-0 px-3 mb-3" style={{ borderBottom: 'none', padding: "20px" }}>
                                        <div className="row mx-0" style={{marginLeft: '5px'}}>
                                            <div className="col-md-3">
                                                <label style={{fontSize: '10px'}}>Filter Shift:</label>
                                                <select name="filter_shift"
                                                        className="form-control jam-kerja-filter-shift mr-1"
                                                        onChange={this.handleFilter}>
                                                    <option value="" selected={this.state.filter_shift === ''}>Pilih
                                                        Shift Kerja
                                                    </option>
                                                    {this.state.shift.map((item, index) => {
                                                        return (
                                                            <option
                                                                value={item.sift_id}>{item.sift_name}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="px-1 mr-3">
                                                <label style={{fontSize: '10px'}}>Atur Ulang Filter:</label><br/>
                                                <button className="btn btn-danger" style={{lineHeight: "24px"}} onClick={this.resetFilter}><span
                                                    className="fa fa-refresh"></span> Atur Ulang
                                                </button>
                                            </div>
                                            <div className="col"></div>
                                            <div className='col-auto px-3' style={{paddingTop: "25px"}}>
                                                <button className="mr-2 jam-kerja-import-button" onClick={() => {
                                                    this.setState({modalimport: true})
                                                }}>
                                                    <img src={ImportIcon} alt=''/> Import
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body daftar-jamker">
                                        {!this.state.isLoading && this.state.data.length === 0 ?
                                            <Empty
                                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                imageStyle={{height: 60}}
                                                description={<span>Data tidak ditemukan!</span>}
                                            />
                                            :
                                            (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                                <div>
                                                    <Table striped className="mb-5">
                                                        <Thead>
                                                            <Tr>
                                                                <Th>#</Th>
                                                                <Th className="text-center">Shift</Th>
                                                                <Th className={"text-center"}>Senin</Th>
                                                                <Th className={"text-center"}>Selasa</Th>
                                                                <Th className={"text-center"}>Rabu</Th>
                                                                <Th className={"text-center"}>Kamis</Th>
                                                                <Th className={"text-center"}>Jumat</Th>
                                                                <Th className={"text-center"}>Sabtu</Th>
                                                                <Th className={"text-center"}>Minggu</Th>
                                                                <Th className={"text-center"} width="30">Aksi</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {this.state.data.map((item, index) => {
                                                                no += 1
                                                                return (
                                                                    <Tr>
                                                                        <td>
                                                                            <Th>{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                        </td>
                                                                        <Td className="text-center" style={{fontSize: 12 }}>
                                                                            <div style={{ padding: 5 }}>
                                                                                {item.sift_name.toUpperCase()}
                                                                            </div>
                                                                        </Td>
                                                                        <Td style={{ fontSize: 12 }}>
                                                                            <div style={{ padding: 5 }} >
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Masuk</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                    <TimeInput
                                                                                        initTime={item.jam_kerja_senin !== null &&
                                                                                            item.jam_kerja_senin.jam_masuk !== undefined
                                                                                            ? item.jam_kerja_senin.jam_masuk.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {
                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_senin,
                                                                                                jam_masuk: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Senin',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_senin: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                    /> ) : <span>{item.jam_kerja_senin && item.jam_kerja_senin.jam_masuk ? item.jam_kerja_senin.jam_masuk : "-"}</span>}
                                                                                </div>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Pulang</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                            initTime={item.jam_kerja_senin !== null &&
                                                                                                item.jam_kerja_senin.jam_keluar !== undefined
                                                                                                ? item.jam_kerja_senin.jam_keluar.substr(0, 5) : ''}
                                                                                            className='s-input -time form-control'
                                                                                            onTimeChange={(event) => {

                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_senin,
                                                                                                jam_keluar: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Senin',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_senin: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_senin && item.jam_kerja_senin.jam_keluar ? item.jam_kerja_senin.jam_keluar : "-"}</span>}
                                                                                </div>
                                                                            </div>
                                                                        </Td>
                                                                        <Td style={{ fontSize: 12 }}>
                                                                            <div style={{ padding: 5 }}>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Masuk</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_selasa !== null &&
                                                                                            item.jam_kerja_selasa.jam_masuk !== undefined
                                                                                            ? item.jam_kerja_selasa.jam_masuk.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {

                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_selasa,
                                                                                                jam_masuk: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Selasa',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_selasa: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_selasa && item.jam_kerja_selasa.jam_masuk ? item.jam_kerja_selasa.jam_masuk : "-"}</span>}
                                                                                </div>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Pulang</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_selasa !== null &&
                                                                                            item.jam_kerja_selasa.jam_keluar !== undefined
                                                                                            ? item.jam_kerja_selasa.jam_keluar.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {

                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_selasa,
                                                                                                jam_keluar: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Selasa',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_selasa: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_selasa && item.jam_kerja_selasa.jam_keluar ? item.jam_kerja_selasa.jam_keluar : "-"}</span>}
                                                                                </div>
                                                                            </div>
                                                                        </Td>
                                                                        <Td style={{ fontSize: 12 }}>
                                                                            <div style={{ padding: 5 }}>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Masuk</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_rabu !== null &&
                                                                                            item.jam_kerja_rabu.jam_masuk !== undefined
                                                                                            ? item.jam_kerja_rabu.jam_masuk.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {

                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_rabu,
                                                                                                jam_masuk: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Rabu',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_rabu: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_rabu && item.jam_kerja_rabu.jam_masuk ? item.jam_kerja_rabu.jam_masuk : "-"}</span>}
                                                                                </div>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Pulang</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_rabu !== null &&
                                                                                            item.jam_kerja_rabu.jam_keluar !== undefined
                                                                                            ? item.jam_kerja_rabu.jam_keluar.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {

                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_rabu,
                                                                                                jam_keluar: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Rabu',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_rabu: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_rabu && item.jam_kerja_rabu.jam_keluar ? item.jam_kerja_rabu.jam_keluar : "-"}</span>}
                                                                                </div>
                                                                            </div>
                                                                        </Td>
                                                                        <Td style={{ fontSize: 12 }}>
                                                                            <div style={{ padding: 5 }}>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Masuk</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_kamis !== null &&
                                                                                            item.jam_kerja_kamis.jam_masuk !== undefined
                                                                                            ? item.jam_kerja_kamis.jam_masuk.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {

                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_kamis,
                                                                                                jam_masuk: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Kamis',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_kamis: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_kamis && item.jam_kerja_kamis.jam_masuk ? item.jam_kerja_kamis.jam_masuk : "-"}</span>}
                                                                                </div>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Pulang</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_kamis !== null &&
                                                                                            item.jam_kerja_kamis.jam_keluar !== undefined
                                                                                            ? item.jam_kerja_kamis.jam_keluar.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {

                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_kamis,
                                                                                                jam_keluar: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Kamis',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_kamis: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_kamis && item.jam_kerja_kamis.jam_keluar ? item.jam_kerja_kamis.jam_keluar : "-"}</span>}
                                                                                </div>
                                                                            </div>
                                                                        </Td>
                                                                        <Td style={{ fontSize: 12 }}>
                                                                            <div style={{ padding: 5 }}>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Masuk</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_jumat !== null &&
                                                                                            item.jam_kerja_jumat.jam_masuk !== undefined
                                                                                            ? item.jam_kerja_jumat.jam_masuk.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {

                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_jumat,
                                                                                                jam_masuk: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Jumat',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_jumat: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_jumat && item.jam_kerja_jumat.jam_masuk ? item.jam_kerja_jumat.jam_masuk : "-"}</span>}
                                                                                </div>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Pulang</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_jumat !== null &&
                                                                                            item.jam_kerja_jumat.jam_keluar !== undefined
                                                                                            ? item.jam_kerja_jumat.jam_keluar.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {

                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_jumat,
                                                                                                jam_keluar: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Jumat',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_jumat: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_jumat && item.jam_kerja_jumat.jam_keluar ? item.jam_kerja_jumat.jam_keluar : "-"}</span>}
                                                                                </div>
                                                                            </div>
                                                                        </Td>
                                                                        <Td style={{ fontSize: 12 }}>
                                                                            <div style={{ padding: 5 }}>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Masuk</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_sabtu !== null &&
                                                                                            item.jam_kerja_sabtu.jam_masuk !== undefined
                                                                                            ? item.jam_kerja_sabtu.jam_masuk.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {
                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_sabtu,
                                                                                                jam_masuk: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Sabtu',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_sabtu: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_sabtu && item.jam_kerja_sabtu.jam_masuk ? item.jam_kerja_sabtu.jam_masuk : "-"}</span>}
                                                                                </div>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Pulang</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_sabtu !== null &&
                                                                                            item.jam_kerja_sabtu.jam_keluar !== undefined
                                                                                            ? item.jam_kerja_sabtu.jam_keluar.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {
                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_sabtu,
                                                                                                jam_keluar: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Sabtu',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_sabtu: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_sabtu && item.jam_kerja_sabtu.jam_keluar ? item.jam_kerja_sabtu.jam_keluar : "-"}</span>}
                                                                                </div>
                                                                            </div>
                                                                        </Td>
                                                                        <Td style={{ fontSize: 12 }}>
                                                                            <div style={{ padding: 5 }}>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Masuk</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_minggu !== null &&
                                                                                            item.jam_kerja_minggu.jam_masuk !== undefined
                                                                                            ? item.jam_kerja_minggu.jam_masuk.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {
                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_minggu,
                                                                                                jam_masuk: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Minggu',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_minggu: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_minggu && item.jam_kerja_minggu.jam_masuk ? item.jam_kerja_minggu.jam_masuk : "-"}</span>}
                                                                                </div>
                                                                                <div style={{ padding: 1 }}>
                                                                                    <label style={{
                                                                                        fontSize: 10,
                                                                                        marginBottom: 0
                                                                                    }}><strong>Pulang</strong></label><br />
                                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) ? (
                                                                                        <TimeInput
                                                                                        initTime={item.jam_kerja_minggu !== null &&
                                                                                            item.jam_kerja_minggu.jam_keluar !== undefined
                                                                                            ? item.jam_kerja_minggu.jam_keluar.substr(0, 5) : ''}
                                                                                        className='s-input -time form-control'
                                                                                        onTimeChange={(event) => {
                                                                                            const value = event;
                                                                                            const list = [...this.state.data]
                                                                                            const tunggal = {
                                                                                                ...list[index].jam_kerja_minggu,
                                                                                                jam_keluar: value === "" ? value : value + ':00',
                                                                                                kerja_hari: 'Minggu',
                                                                                                sift_id: item.sift_id
                                                                                            }
                                                                                            list[index] = {
                                                                                                ...list[index],
                                                                                                jam_kerja_minggu: tunggal
                                                                                            }
                                                                                            this.setState({
                                                                                                data: list
                                                                                            })
                                                                                        }}
                                                                                        /> ) : <span>{item.jam_kerja_minggu && item.jam_kerja_minggu.jam_keluar ? item.jam_kerja_minggu.jam_keluar : "-"}</span>}
                                                                                </div>
                                                                            </div>
                                                                        </Td>
                                                                        <Td>
                                                                            {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                                <Tooltip trigger="click" placement="bottom" color="#fff" title={
                                                                                    <div className={'p-2'}>
                                                                                        {/*<a onClick={() => {*/}
                                                                                        {/*    this.setState({*/}
                                                                                        {/*        modalHistory: true,*/}
                                                                                        {/*        sift_id : item.sift_id*/}
                                                                                        {/*    },()=>{*/}
                                                                                        {/*        this.loadHistory(item.sift_id);*/}
                                                                                        {/*    })*/}
                                                                                        {/*}} className={'text-dark'}>Riwayat</a><br/>*/}
                                                                                            <a onClick={() => {
                                                                                                this.setState({
                                                                                                    idDel: item.sift_id
                                                                                                })
                                                                                                this.wantDelete()
                                                                                            }} className={'text-danger'}>Hapus</a>
                                                                                    </div>
                                                                                }>
                                                                                    <a><i
                                                                                        className="fa fa-ellipsis-h" style={{
                                                                                        width: 35,
                                                                                        fontSize: 18,
                                                                                        padding: 11,
                                                                                        color: 'rgb(51 51 51)'
                                                                                    }}
                                                                                    ></i></a>
                                                                                </Tooltip>
                                                                            )}
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })}
                                                            {this.state.isLoading &&
                                                                <Tr>
                                                                    <td colSpan="10">
                                                                        <Spin style={{ width: '100%' }} size="small"
                                                                            tip="Mohon Tunggu..." />
                                                                    </td>
                                                                </Tr>
                                                            }
                                                        </Tbody>
                                                    </Table>
                                                    {this.state.isLoading ? '' :
                                                        <button className="btn btn-primary tambah-absen ml-2"
                                                            onClick={this.handleSubmit.bind(this)}
                                                        >
                                                            Simpan
                                                        </button>
                                                    }
                                                    <Paging
                                                        className="float-right"
                                                        handlePageClick={this.handlePageClick}
                                                        pageCount={this.state.pageCount}
                                                        currentPage={this.state.currentPage}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(JamKerja);
