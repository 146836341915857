import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";
import eyeIcon from "../../../assets/icons/eye.svg";
import eyeIconSlash from "../../../assets/icons/eye-slash-grey.svg";

class EditPassword extends Component {
    constructor() {
        super();
        this.state = {
            p_user_password : '',
            loading         : false,
            hidePassword    : false
        }
    }

    handleEdit = () => {
        let form = {
            p_user_id: this.props.data.p_user_id,
            p_user_password: this.state.p_user_password,
        }
        this.props.request("edit_user/password", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        p_user_password : ''
                    },() =>this.props.changeState({
                        edit_pw_visible: false,
                        data: [],
                        loading: true,
                    },() => {
                        this.props.loadData()
                    }))
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }else{
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    render() {
        return (
            <Drawer
                title="Edit Password User"
                width={350}
                onClose={() => {
                    this.props.changeState({edit_pw_visible: false, p_user_id:''});
                }}
                visible={this.props.data.edit_pw_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={this.handleEdit} type="primary">
                            Simpan
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="input-group">
                        <label>Password Baru</label>
                        <input name="p_user_password" className="form-control col-xl-12 col-md-12" placeholder="Password User..."
                               type={this.state.hidePassword ? "text" : "password"} required="" value={this.state.p_user_password}
                               onChange={(event) => {
                                   const target = event.target;
                                   const value  = target.value;
                                   const name   = target.name;
                                   this.setState({
                                       [name]: value
                                   });
                               }}/>
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <img className="show-hide-button" style={{ width: "16px", height: "16px", backgroundColor: "#E9ECEF" }} src={this.state.hidePassword ? eyeIcon : eyeIconSlash} onClick={() => this.setState({
                                    hidePassword: !this.state.hidePassword
                                })}  />
                            </div>
                        </div>
                    </div>
                </Form>
            </Drawer>

        )
    }
}

export default EditPassword
