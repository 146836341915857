import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {clear_auth} from '../../../reducers/Auth'
import notifIcon from '../../../assets/icons/notification.png'
import {DEFAULT_IMG, IMG_HOST} from "../../../helper/host";
import {countDiffHourFromNow, dateFormat} from "../../../helper/date";
import {toast} from "react-toastify";
import Survey from "../Survey";
import PinIcon from "../../../assets/icons/pin.svg";

export class User_menu extends Component{
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            data_user: [],
            notif: [],
            unread: false,
            showModal: false,
            dataSurvey  : '',
            survey      :"",
            dontShowAgain: false
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleLogout() {
        this.props.clear_auth();
    }

    loadDataUser() {
        let form = {}
        this.props.request("get_company", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_user: response.data,
                    });
                }
            });
        let notifForm = {
            paginate: 3
        }
        this.props.request("get_notif_perusahaan", notifForm, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        notif: response.data.data,
                        unread: response.unread,
                    });
                }
            });
    }

    componentDidMount() {
        this.loadDataUser()
        // this.shouldShowModal()
        this.setState({showModal:false})
    }
    // shouldShowModal() {
    //     this.props.request("dashboard", {}, 'POST')
    //         .then(response => {
    //             if (response.success) {
    //                 const showModalValue = response.data.perusahaan_last_survey;
    //                 this.setState({
    //                     showModal: showModalValue || false,
    //                     data: response,
    //                 });
    //             }
    //             this.setState({
    //                 loading: false
    //             });
    //         })
    //
    // }
    handleCancel = () => {
        const currentDate = new Date();
        localStorage.setItem('lastShown', currentDate.toString());
        this.setState({ showModal: false });
    }

    closeModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    handleCheckboxChange = (event) => {
        this.setState({ dontShowAgain: event.target.checked });
    }

    handleClose = () => {
        this.props.request('last-survey-p2', {}, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        dataSurvey      : response.data,
                        isLoading       : false,
                        showModal       : false,
                    });
                }
                else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                }
                this.setState({ isLoading: false })
            })
    }


    // showModalAgain() {
    //     localStorage.removeItem('dontShowAgain');
    //     localStorage.removeItem('lastShown');
    //     this.setState({showModal: true})
    // }
    handleSave(){
        let formData ={
            survey_content        : this.state.survey,
        }
        this.props.request('last-survey', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        dataSurvey      : response.data,
                        isLoading       : false,
                        showModal       : false
                    });
                }
                else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                }
                this.setState({ isLoading: false })
            })
    }

    render() {
        return (
            <Fragment>
                    <Survey
                        showModal={this.state.showModal}
                        toggle={this.closeModal}
                        valueSurvey={this.state.survey}
                        setState={(val, callback) => this.setState(val, callback)}
                        handlerCheckBoxChanges={this.handleCheckboxChange}
                        saveHandler={this.handleSave}
                        dontshow={this.state.dontShowAgain}
                        cancel={this.handleClose}
                        handleCancel={this.handleCancel}
                    />
                <li className="onhover-dropdown">
                    {this.state.data_user &&
                        <div className="media align-items-center">
                            <img className="align-self-center pull-right rounded-circle blur-up lazyloaded"
                                 src={notifIcon} alt="header-user"
                                 style={{width: "40px", height: "40px", objectFit: "cover"}}/>
                            {this.state.unread &&
                                <div className="dotted-animation">
                                    <span className="animate-circle"></span>
                                    <span className="main-circle"></span>
                                </div>
                            }
                        </div>
                    }
                    <div className="notif-popup onhover-show-div p-20 profile-dropdown-hover">
                        <div className="">
                            <div className="d-flex justify-content-between mb-3">
                                <h4>Notifikasi</h4>
                                <div><a href="/notifikasi">Lihat Semua</a></div>
                            </div>
                            {this.state.notif.map((item, index) => {
                                let mulai = new Date(item.notif_created_at).toISOString().split('T')[0];
                                let jangka_waktu = new Date(mulai);
                                jangka_waktu.setDate(jangka_waktu.getDate() + parseInt(item.notif_pinned_duration));
                                let akhir = jangka_waktu.toISOString().split('T')[0];

                                return (
                                    item.notif_pinned_duration > 0 && new Date().toISOString().split('T')[0] <= akhir ?
                                        <div>
                                            <div className="fs-14 text-left">
                                                <i className="fa fa-info mr-1" style={{color: "#057CE4"}}/>
                                                <a className="text-dark" href={item.notif_type === 'beban' ? "/pengajuan/klaim-beban" : "/pengajuan/" + item.notif_type}>{item.notif_message}</a>
                                                <i style={{ float:"right" }}><img alt="" src={PinIcon}/></i>
                                            </div>
                                            <p className="ml-auto fs-12 text-right">{(countDiffHourFromNow(item.notif_created_at) > 24) ?
                                                dateFormat(item.notif_created_at, 'Do MMMM YYYY') :
                                                (countDiffHourFromNow(item.notif_created_at) + " Jam lalu")}</p>
                                        </div>
                                        :
                                        <div>
                                            <div className="fs-14 text-left">
                                                <i className="fa fa-info mr-1" style={{color: "#057CE4"}}/>
                                                <a className="text-dark" href={item.notif_type === 'beban' ? "/pengajuan/klaim-beban" : "/pengajuan/" + item.notif_type}>{item.notif_message}</a>
                                            </div>
                                            <p className="ml-auto fs-12 text-right">{(countDiffHourFromNow(item.notif_created_at) > 24) ?
                                                dateFormat(item.notif_created_at, 'Do MMMM YYYY') :
                                                (countDiffHourFromNow(item.notif_created_at) + " Jam lalu")}</p>
                                        </div>
                                )
                            })}
                            {this.state.notif.length <= 0 &&
                                <div className="d-flex justify-content-center"><a>Belum ada Notifikasi saat ini</a>
                                </div>
                            }
                        </div>
                    </div>
                </li>
                <li className="onhover-dropdown">
                    <div className="media align-items-center">
                        <img className="align-self-center pull-right rounded-circle blur-up lazyloaded"
                             src={this.state.data_user.perusahaan_foto !== null ? IMG_HOST + this.state.data_user.perusahaan_foto : DEFAULT_IMG}
                             alt="header-user" style={{width: "40px", height: "40px", objectFit: "cover"}}/>
                    </div>
                    <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <li><a onClick={() => this.setState({showModal:true})}><i data-feather="survey"></i>Kritik dan Saran</a></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/settings/profile`}><i data-feather="user"></i>Edit
                            Profile</Link></li>
                        <li><a href="/" onClick={this.handleLogout}><i data-feather="log-out"></i>Logout</a></li>
                    </ul>
                </li>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth())
})

export default connect(mapStateToProps, mapDispatchToProps)(User_menu);
