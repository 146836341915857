import React, {Component} from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import '../home/landing.scss';
import {
    FacebookFilled,
    GoogleCircleFilled,
    InstagramFilled,
    MailTwoTone,
    PhoneTwoTone,
    PushpinTwoTone,
    YoutubeFilled
} from '@ant-design/icons';
import moment from 'moment';
import {requestData} from '../../helper/request'
import {IMG_HOST} from '../../helper/host'
import android from '../../assets/images/android.png'
import apple from '../../assets/images/apple.png'
import blogDefault from '../../assets/images/dashboard/cat3.png'
import Paging from "../paging";
import {Spin} from "antd";
import {Helmet} from "react-helmet";
import FrontHeader from "../common/front_header";

export class Blog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
            loading: true,
            data: {},
            blog: [],
            pageCount   : 0,
            currentPage : 0,
            totalData   : 0,
            perPage     : 0,
        }

        this.handlePageClick = this.handlePageClick.bind(this)
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({currentPage: selected, loading: true}, () => {
            this.loadData();
        });
    }

    loadData() {
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        requestData("blog/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        blog: response.data.data,
                        totalData       : response.data.total,
                        pageCount       : response.data.last_page,
                        perPage         : response.data.per_page,
                        loading         : false
                    })
                }
            });
    }

    loadDataFooter() {
        let form = {}
        requestData("home", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.loadDataFooter()
    }

    toggleMenu = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    }

    render() {
        return (
            <div className="landing-page-multikart" style={{ fontFamily: 'Lato, sans-serif' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Daftar Blog - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <FrontHeader />

                {/*Blog section*/}
                <section id="blog" className="section-lr feature-section text-left mt-4">
                    <div className="container-fluid pt-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="feature text-center">
                                    <div>
                                        <h5 className="title-landing">Blog Terbaru</h5>
                                        <p className="pb-3"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.loading ?
                            <Spin size="large" tip="Loading..." style={{
                                width: '100%',
                                margin: 'auto'
                            }}/>
                            :
                            <div className="row feature_row pb-4">
                                {this.state.blog.map((item, index) => {
                                    return (
                                        <div className="col-xl-4 col-md-6 ">
                                            <div className="feature-box">
                                                <div className="feature-image">
                                                    {item.blog_image !== null ?
                                                        <a href={"/blog/" + item.blog_alias}>
                                                            <img className="img-fluid lazyload" alt={item.blog_image}
                                                                 src={IMG_HOST + 'admin/blog/' + item.blog_image}/>
                                                        </a>
                                                        :
                                                        <a href={"/blog/" + item.blog_alias}>
                                                            <img className="img-fluid lazyload" alt='default Img'
                                                                 src={blogDefault}/>
                                                        </a>
                                                    }
                                                </div>
                                                <p className="date-text mt-4">
                                                    {moment(item.blog_create_date).format('lll')}
                                                </p>
                                                <div className="feature-content pt-1">
                                                    <ul className="color-varient">
                                                        <li className="blue"></li>
                                                    </ul>
                                                    <a href={"/blog/" + item.blog_alias}>
                                                        <h6>{item.blog_title}</h6>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <Paging
                                    className="float-right text-center"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        }
                    </div>
                </section>

                {/*Kontak*/}
                <section id="kontak" className="main-feature section-b-space grey-bg text-left">
                    <div className="container mt-5">
                        <h4 className="pb-3">Punya pertanyaan mengenai absensi online?</h4>
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-12">
                                <h5 className="title-landing">Hubungi Kami</h5>
                                <p>Presensi.co.id</p>
                                <ul className="navbar-nav">
                                    <li>
                                        <PhoneTwoTone/>
                                        <a className='ml-2 text-custome-16'
                                           href={'tel' + this.state.data.contact_phone}
                                           alt={'Telepon ' + this.state.data.contact_phone}
                                           title={'Telepon ' + this.state.data.contact_phone}>
                                            {this.state.data.contact_phone}</a>
                                    </li>
                                    <li className="mt-3">
                                        <MailTwoTone/>
                                        <a className='ml-2 text-custome-16'
                                           href={"mailto:" + this.state.data.contact_email}
                                           alt={"email:" + this.state.data.contact_email}
                                           title={"email:" + this.state.data.contact_email}>
                                            {this.state.data.contact_email}</a>
                                    </li>
                                    <li className="mt-3 mb-3">
                                        <PushpinTwoTone/>
                                        <span className='ml-2 text-custome-16'>
                                            {this.state.data.contact_address}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Menu</h5>
                                <ul className="navbar-nav">
                                    <li className="mb-3">
                                        <AnchorLink className="text-custome-16" href='/login'>Masuk</AnchorLink>
                                    </li>
                                    <li className="mb-3">
                                        <AnchorLink className="text-custome-16" href='/register'>Daftar</AnchorLink>
                                    </li>
                                    <li className="mb-3">
                                        <a className="text-custome-16" href='/kebijakan-privasi'>Kebijakan & Privasi</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Temukan Kami</h5>
                                <ul>
                                    <li>
                                        <GoogleCircleFilled className="text-custome-16"/>
                                    </li>
                                    <li className="ml-4">
                                        <a target='_blank' href={this.state.data.contact_facebook_link}><FacebookFilled
                                            className="text-custome-16"/></a>
                                    </li>
                                    <li className="ml-4">
                                        <a target='_blank'
                                           href={this.state.data.contact_instagram_link}><InstagramFilled
                                            className="text-custome-16"/></a>
                                    </li>
                                    <li className="ml-4 mb-3">
                                        <a target='_blank' href={this.state.data.contact_youtube_link}><YoutubeFilled
                                            className="text-custome-16"/></a>\
                                    </li>

                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Tersedia di</h5>
                                <div className="set-image mb-3">
                                    <a target='_blank' href={this.state.data.app_url_android}><img
                                        style={{maxWidth: 100}} src={android} alt="android"/></a>
                                </div>
                                <div className="set-image">
                                    <a target='_blank' href={this.state.data.app_url_ios}><img style={{maxWidth: 100}}
                                                                                               src={apple} alt="apple"/></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <div className="footer-shadow text-center">
                    Copyright by presensi.co.id 2020
                </div>

                {/*Tap To Top*/}
                <div className="tap-top">
                    <div>
                        <i className="fa fa-angle-double-up"></i>
                    </div>
                </div>
            </div>
        )
    }
}

export default Blog
