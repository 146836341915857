import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import format from 'format-number';
import { IMG_HOST } from "../../helper/host";
import { set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";


const imgMyimageexample = require('../../assets/images/bg2.png');
const divStyle = {
    width: '88%',
    height: '100%',
    backgroundImage: `url(${imgMyimageexample})`,
    backgroundSize: 'cover'
};
export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: this.props.auth,
            disabledButton: false,
            activeShow: true,
            confirm: false,
            data_register: false,
            emaillogin: "",
            passwordlogin: "",
            otplogin: "",
            emailregister: "",
            passwordregister: "",
            passwordconfirmregister: "",
            startDate: new Date(),
            unsubscribe: false,
            pilihPaket: false,
            paket: [],
            dataUser: [],
            api_token: "",
            paketForm: false,
            paket_id: null,
        }
        this.handleChange = this.handleChange.bind(this)
        this.loadPaket = this.loadPaket.bind(this)
        this.prosesPaket = this.prosesPaket.bind(this)
        this.block = this.block.bind(this)
        this.prosesPaket = this.prosesPaket.bind(this)
        this.submitAuth = this.submitAuth.bind(this)
    }

    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    onChangeText = ({ target }) => {
        this.setState({
            [target.name]: target.value
        })
    }

    loadPaket() {
        this.setState({
            disabledButton: true
        })
        let form = {}
        this.props.request("paket/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        paket: response.data.data.filter(paket => paket.paket_harga > 0),
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }

    prosesPaket = () => {
        this.setState({
            disabledButton: true
        })

        let form = {
            perusahaan_email: this.state.emaillogin,
            paket_id: this.state.paket_id,
        }
        this.props.request("paket/update-paket-perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        this.setState({
                            pilihPaket: false
                        })
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }

    submitLogin = (event) => {
        this.setState({
            disabledButton: true
        })

        let form = {
            perusahaan_email: this.state.emaillogin,
            perusahaan_password: this.state.passwordlogin,
        }

        this.props.request("req_code", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        confirm: true
                    })
                } else if (response.skip_otp) {
                    this.submitAuth(event)
                } else if (response.failed) {
                    Swal.fire({
                        title: response.failed,
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                } else if (response.expired) {
                    Swal.fire({
                        title: 'Akun anda sudah Expired!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'Lanjutkan',
                        cancelButtonText: 'Berhenti',
                        cancelButtonColor: '#d33',
                        showCancelButton: true,
                    }).then(result => {
                        if (result.isConfirmed) {
                            this.setState({
                                pilihPaket: true
                            })
                            this.loadPaket()
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            this.block();
                        }
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }

    block() {
        let form = {
            perusahaan_email: this.state.emaillogin
        }

        this.props.request("block-perusahaan", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                }

                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }

    submitAuth = (event) => {
        event.preventDefault()
        this.setState({
            disabledButton: true
        })

        let form = {
            perusahaan_email: this.state.emaillogin,
            perusahaan_password: this.state.passwordlogin,
            perusahaan_otp: this.state.otplogin,
        }

        this.props.request("login", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        showConfirmButton: false,
                        icon: 'success',
                    })
                    this.props.set_auth(response.api_token, response.data);

                    window.location.reload(false);
                }

                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }

    checkToken() {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const getparam = urlSearchParams.get('token')

        let formData = {
            token: getparam
        }
        this.props.request('check_token', formData, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.set_auth(response.api_token, response.data);
                    // this.props.history.push('/dashboard')
                }
                this.setState({ loading: false })
            })
    }

    componentDidMount() {
        this.setState({ auth: this.props.auth, })
        this.checkToken()
    }

    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({ auth: newProps.auth })
        }
    }

    render() {
        if (this.props.auth) {
            var { location } = this.props

            if (typeof location.state !== 'undefined' && location.state.from.pathname !== '/login')
                var redirect = location.state.from.pathname
            else
                var redirect = '/dashboard'

            return <Redirect to={redirect} />
        }
        console.log(this.props)
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Login - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <div className="page-wrapper text-left">
                    <div className="authentication-box" style={divStyle}>
                        <div className="container" style={this.state.pilihPaket ? { maxWidth: '90%' } : null}>
                            <div className="row">
                                <div className="col-md-12 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <div >
                                                <div className="card-header">
                                                    <h5>{this.state.pilihPaket ? 'Pilih Paket' : 'Login'}</h5>
                                                </div>
                                                {this.state.pilihPaket === true ?
                                                    <form className="form-horizontal auth-form" onSubmit={(e) => { e.preventDefault(); }}>
                                                        <div className="pricing card-deck flex-column flex-md-row m-0">
                                                            {this.state.paket.map((item, index) => {
                                                                return (
                                                                    <label className="label-input">
                                                                        <input type="radio" name="demo"
                                                                            className="card-input-element d-none"
                                                                            id="demo1" onClick={() => {
                                                                                this.setState({
                                                                                    paket_id: item.paket_id,
                                                                                    tagihan: item.paket_harga
                                                                                })
                                                                            }} />
                                                                        <div className="card card-body bg-light d-flex flex-row justify-content-between align-items-center p-0">
                                                                            <div className="col-xl-12 p-0">
                                                                                <div className="pricing card-deck flex-column flex-md-row m-0" style={{ backgroundColor: '#ffffff' }}>
                                                                                    <div className="card card-pricing text-center px-0 mb-10">
                                                                                        <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">{item.paket_nama}</span>
                                                                                        <div className="bg-transparent card-header p-0 border-0">
                                                                                            <img src={IMG_HOST + 'admin/paket/' + item.paket_foto} style={{ width: '50%', height: 'auto', margin: '5px' }} />
                                                                                            <h3 className="text-primary text-center m-2">{item.paket_harga !== 0 ? format({ prefix: 'Rp ', integerSeparator: "." })(item.paket_harga) : 'Gratis'} <br /><span
                                                                                                className="h6 text-muted ml-2">/ per {item.paket_bulan} Bulan</span>
                                                                                            </h3>
                                                                                        </div>
                                                                                        <div className="card-body p-2 mb-3">
                                                                                            <span className="text-muted mb-2"><b>Max {item.paket_jumlah} Karyawan</b></span><br />
                                                                                            <span>{item.paket_desc}</span>
                                                                                            {/*<button type="button" className="btn btn-outline-primary mb-3 text-center" onClick={() => {*/}
                                                                                            {/*    this.setState({*/}
                                                                                            {/*        btnactive: true*/}
                                                                                            {/*    })*/}
                                                                                            {/*}}>Pilih Paket*/}
                                                                                            {/*</button>*/}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                )
                                                            })}
                                                        </div>
                                                        <div className="form-button">
                                                            {this.state.disabledButton === false ? (
                                                                <a className="btn btn-primary" type="submit" style={{ color: "white" }}
                                                                    onClick={this.prosesPaket}>Lanjutkan</a>
                                                            ) : (
                                                                <a className="btn btn-primary" type="submit" style={{ color: "white" }} disabled><i className="fa fa-refresh fa-spin"></i> Loading...</a>
                                                            )}
                                                        </div>
                                                    </form>
                                                    : !this.state.confirm ?
                                                        <form className="form-horizontal auth-form">
                                                            <div className="form-group">
                                                                <input required="" name="emaillogin" onChange={(event) => this.onChangeText(event)} type="email"
                                                                    className="form-control" placeholder="Email Perusahaan" />
                                                            </div>
                                                            <div className="form-group">
                                                                <input required="" name="passwordlogin" onChange={(event) => this.onChangeText(event)} type="password"
                                                                    className="form-control" id={"password"} placeholder="Password Perusahaan"
                                                                    onKeyUp={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault()
                                                                            this.submitLogin(e)
                                                                        }
                                                                    }
                                                                    }

                                                                />
                                                            </div>
                                                            <div className="form-button">
                                                                {this.state.disabledButton === false ? (
                                                                    <a className="btn btn-primary" type="submit" style={{ backgroundColor: 'gray !important', color: "white" }}
                                                                        onClick={(e) => {
                                                                            this.submitLogin(e)
                                                                        }}
                                                                    >Login</a>
                                                                ) : (
                                                                    <a className="btn btn-primary" type="submit" style={{ color: "white" }} disabled><i className="fa fa-refresh fa-spin"></i> Loading...</a>
                                                                )}
                                                            </div>
                                                            <div className="form-footer">
                                                                <span>
                                                                    <a href="/lupa-password">Lupa Password? </a><br />
                                                                    <div style={{ justifyContent: 'center' }}>
                                                                        Belum Punya Akun?<a href="/register"> Register Disini</a>
                                                                    </div>

                                                                </span>
                                                            </div>
                                                        </form>
                                                        :
                                                        <form className="form-horizontal auth-form">
                                                            <label>OTP telah dikirim ke Email anda, silahkan cek email anda dan masukkan OTP tersebut ke form dibawah..</label>
                                                            <div className="form-group">
                                                                <input id="otp" name="otplogin" onChange={(event) => this.onChangeText(event)} type="number"
                                                                    className="form-control" placeholder="OTP Perusahaan" value={this.state.otplogin}
                                                                    onKeyUp={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            this.submitAuth()

                                                                        }
                                                                    }} />
                                                            </div>
                                                            <div className="form-button">
                                                                {this.state.disabledButton === false ? (
                                                                    <button className="btn btn-primary" type="submit" style={{ color: "white" }}
                                                                        onClick={this.submitAuth}
                                                                    >Konfirmasi OTP</button>
                                                                ) : (
                                                                    <a className="btn btn-primary" type="submit" style={{ color: "white" }} disabled><i className="fa fa-refresh fa-spin"></i> Loading...</a>
                                                                )}
                                                            </div>
                                                        </form>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
