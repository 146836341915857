import React, { Component, Fragment } from 'react';
import { Empty, Spin, Tooltip } from 'antd';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Helmet } from "react-helmet";
import { IMG_HOST } from "../../helper/host";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import { CloudUpload } from "@material-ui/icons";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { API_HOST } from "../../helper/host";
import CKEditor from "react-ckeditor-component";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import SaveIcon from "../../assets/icons/save.svg";
import ImportIcon from "../../assets/icons/import-icon-blue-light.svg";
import "./style.css"

export class List_pengumuman extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            prosesSimpan: false,
            karyawan: [],
            data: [],
            idDel: '',
            filter_judul: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            guide: false,
            modalAdd: false,
            judul: '',
            shortdesc: '',
            gambar: '',
            fileList: [],
            deskripsi: '',
            modalEdit: false,
            idDetail: 0,
            judulEdit: '',
            shortdescEdit: '',
            gambarEdit: null,
            fileListEdit: [],
            deskripsiEdit: '',
            modalDetail: false,
            judulDetail: '',
            shortdescDetail: '',
            gambarDetail: null,
            fileListDetail: [],
            deskripsiDetail: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onEditorStateChangeEdit = this.onEditorStateChangeEdit.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter(event) {
        this.setState({
            filter_judul: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus pengumuman?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("pengumuman/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            filter_judul: this.state.filter_judul,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("pengumuman/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    onEditorStateChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            deskripsi: newContent
        })
    }
    onEditorStateChangeEdit(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            deskripsiEdit: newContent
        })
    }

    loadDetail() {
        let form = {
            id: this.state.idDetail
        }
        this.props.request("pengumuman/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataDetail: response.data,
                        judulEdit: response.data.pengumuman_judul,
                        shortdescEdit: response.data.pengumuman_shortdesc,
                        deskripsiEdit: response.data.pengumuman_deskripsi,
                        modalEdit: true
                    })
                    if (response.data.pengumuman_gambar !== null) {
                        this.setState({
                            fileListEdit: [
                                {
                                    uid: "-1",
                                    name: response.data.pengumuman_gambar,
                                    status: "done",
                                    url: IMG_HOST + 'mitra/perusahaan/pengumuman/' + response.data.pengumuman_gambar
                                }
                            ],
                        })
                    }
                }
            });
    }

    simpanPengumuman = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            gambar: this.state.gambar,
            judul: this.state.judul,
            shortdesc: this.state.shortdesc,
            deskripsi: this.state.deskripsi,
        }

        this.props.request("pengumuman/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.props.history.push('/pengumuman')
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalAdd: false,
                    judul: '',
                    shortdesc: '',
                    gambar: '',
                    fileList: [],
                    deskripsi: '',
                }, () => this.loadData())
            });
    }

    editPengumuman = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id: this.state.idDetail,
            judul: this.state.judulEdit,
            shortdesc: this.state.shortdescEdit,
            gambar: this.state.gambarEdit === null ? this.state.dataDetail.pengumuman_gambar : this.state.gambarEdit,
            deskripsi: this.state.deskripsiEdit,
        }

        this.props.request("pengumuman/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/pengumuman')
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalEdit: false,
                    gambarEdit: null,
                    fileListEdit: []
                }, () => this.loadData())
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0
        const stylefoto = {
            maxHeight: 60,
            borderRadius: "4px"
        }
        const steps = [
            {
                selector: '.tambah-pengumuman',
                content: 'Anda dapat membuat Pengumuman disini',
            },
            {
                selector: '.daftar-pengumuman',
                content: 'Daftar Pengumuman anda dapat dilihat disini',
            },
        ];
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengumuman") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Pengumuman - Presensi</title>
                        <meta name="description" content="Daftar Pengumuman" />
                    </Helmet>
                    <Modal size='lg' zIndex={999} isOpen={this.state.modalAdd} toggle={() => this.setState({
                        modalAdd: false, judul: '',
                        shortdesc: '',
                        gambar: '',
                        fileList: [],
                        deskripsi: '',
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalAdd: false, judul: '',
                            shortdesc: '',
                            gambar: '',
                            fileList: [],
                            deskripsi: '',
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>Tambah Pengumuman</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <div className="col-md-4 col-sm-12 px-0">
                                            <ImgCrop
                                                width={2000}
                                                height={1000}>
                                                <Upload
                                                    listType="picture-card"
                                                    style={{ width: '400px !important' }}
                                                    fileList={this.state.fileList}
                                                    customRequest={({ file, onSuccess }) => {
                                                        setTimeout(() => {
                                                            onSuccess("ok");
                                                        }, 0);
                                                    }}
                                                    onChange={(file) => {
                                                        this.setState({ fileList: file.fileList })

                                                        if (file.fileList.length > 0) {
                                                            let files = file.file.originFileObj
                                                            let reader = new FileReader()

                                                            reader.readAsDataURL(files)
                                                            reader.onloadend = () => {
                                                                this.setState({
                                                                    gambar: {
                                                                        name: file.file.name,
                                                                        file: reader.result
                                                                    }
                                                                })
                                                            }
                                                        }
                                                        else {
                                                            this.setState({ gambar: [] })
                                                        }
                                                    }}
                                                >
                                                    {this.state.fileList.length >= 1 ? null :
                                                        <div className="p-2">
                                                            <img alt='' src={ImportIcon} style={{ width: "32px", height: "32px" }} /><br />
                                                            <div style={{
                                                                fontSize: "12px",
                                                                lineHeight: "16px",
                                                                color: "#5D6F80",
                                                                marginTop: "8px"
                                                            }}>Upload gambar pengumuman</div><br />
                                                            <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                        </div>
                                                    }
                                                </Upload>
                                            </ImgCrop>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">Judul Pengumuman</label>
                                        <div>
                                            <input name="judul" className="form-control pengumuman-input" placeholder="Ketik judul pengumuman disini"
                                                type="text" required="" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">Deskripsi Singkat Pengumuman</label>
                                        <div>
                                            <input name="shortdesc" className="form-control pengumuman-input" placeholder="Ketik deksripsi singkat disini"
                                                type="text" required="" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">Deskripsi Pengumuman</label>
                                        <div>
                                            <CKEditor
                                                content={this.state.deskripsi}
                                                events={{
                                                    change: this.onEditorStateChange
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalAdd: false, judul: '',
                                shortdesc: '',
                                gambar: '',
                                fileList: [],
                                deskripsi: '',
                            })} className='pengumuman-cancel-button' >Batalkan</button>
                            <button className='pengumuman-save-button' disabled={this.state.prosesSimpan} onClick={this.simpanPengumuman}><img alt='' src={SaveIcon} /> {this.state.prosesSimpan ? "Menyimpan.." : "Simpan"}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal size='lg' zIndex={999} isOpen={this.state.modalEdit} toggle={() => this.setState({
                        modalEdit: false, gambarEdit: null,
                        fileListEdit: []
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalEdit: false, gambarEdit: null,
                            fileListEdit: []
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>Edit Pengumuman</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <div className="col-md-4 col-sm-12 px-0">
                                            <ImgCrop
                                                width={2000}
                                                height={1000}>
                                                <Upload customRequest={({ file, onSuccess }) => {
                                                    setTimeout(() => {
                                                        onSuccess("ok");
                                                    }, 0);
                                                }}
                                                    listType="picture-card"
                                                    style={{ width: '400px !important' }}
                                                    fileList={this.state.fileListEdit}
                                                    onChange={(file) => {
                                                        this.setState({ fileListEdit: file.fileList })

                                                        if (file.fileList.length > 0) {
                                                            let files = file.file.originFileObj
                                                            let reader = new FileReader()

                                                            reader.readAsDataURL(files)
                                                            reader.onloadend = () => {
                                                                this.setState({
                                                                    gambarEdit: {
                                                                        name: file.file.name,
                                                                        file: reader.result
                                                                    }
                                                                })
                                                            }
                                                        }
                                                        else {
                                                            this.setState({ gambarEdit: [] })
                                                        }
                                                    }}
                                                >
                                                    {this.state.fileListEdit.length >= 1 ? null :
                                                        <div className="p-2">
                                                            <img alt='' src={ImportIcon} style={{ width: "32px", height: "32px" }} /><br />
                                                            <div style={{
                                                                fontSize: "12px",
                                                                lineHeight: "16px",
                                                                color: "#5D6F80",
                                                                marginTop: "8px"
                                                            }}>Upload gambar pengumuman</div><br />
                                                            <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                        </div>
                                                    }
                                                </Upload>
                                            </ImgCrop>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">Judul Pengumuman</label>
                                        <div>
                                            <input name="judulEdit" className="form-control pengumuman-input" placeholder="Ketik judul pengumuman disini"
                                                type="text" required="" onChange={this.handleChange} value={this.state.judulEdit} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">Deskripsi Singkat Pengumuman</label>
                                        <div>
                                            <input name="shortdescEdit" className="form-control pengumuman-input" placeholder="Ketik deksripsi singkat disini"
                                                type="text" required="" onChange={this.handleChange} value={this.state.shortdescEdit} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">Deskripsi Pengumuman</label>
                                        <div>
                                            <CKEditor
                                                content={this.state.deskripsiEdit}
                                                events={{
                                                    change: this.onEditorStateChangeEdit
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalEdit: false, gambarEdit: null,
                                fileListEdit: []
                            })} className='pengumuman-cancel-button' >Batalkan</button>
                            <button className='pengumuman-save-button' onClick={this.editPengumuman}><img alt='' src={SaveIcon} /> Simpan</button>
                        </ModalFooter>
                    </Modal>
                    <Modal size='lg' zIndex={999} isOpen={this.state.modalDetail} toggle={() => this.setState({
                        modalDetail: false
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalDetail: false
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>Edit Pengumuman</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row mb-3'>
                                <div className='col-md-12' style={{
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    lineHeight: "32px",
                                    color: "#143B5E"
                                }}>{this.state.judulDetail}</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-5 pengumuman-col-filter'>
                                    <img alt='' src={this.state.gambarDetail} style={{ maxWidth: "297px", maxHeight: "148px", borderRadius: "8px" }} />
                                </div>
                                <div className='col-md-7'>
                                    <div className='row mb-4'>
                                        <div className='col-md-12' style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            lineHeight: "16px",
                                            color: "#143B5E"
                                        }}>{this.state.shortdescDetail}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12' style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            lineHeight: "16px",
                                            color: "#143B5E",
                                            wordWrap: "break-word"
                                        }} dangerouslySetInnerHTML={{ __html: this.state.deskripsiDetail }}></div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <div className="container-fluid pt-4">
                        <div className='row'>
                            <div className='col-md-12' style={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5D6F80"
                            }}>Presensi Admin Panel</div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12' style={{
                                fontWeight: 700,
                                fontSize: "24px",
                                lineHeight: "36px",
                                color: "#143B5E"
                            }}>Daftar Pengumuman</div>
                        </div>
                        <div className="card">
                            <div className="card-header border-0 p-4">
                                <div className='row'>
                                    <div className='col-md-6 pengumuman-col-filter'>
                                        <div className='d-flex flex-row flex-nowrap'>
                                            <div className='input-group form-search mr-1'>
                                                <span className='input-group-text pengumuman-filter-nama-input-text'>
                                                    <img alt='' src={SearchIcon} />
                                                </span>
                                                <input className='form-control pengumuman-filter-nama-input' type='text' placeholder='Cari judul pengumuman ...' onKeyUp={(event) => {
                                                    if (event.which === 13) {
                                                        event.preventDefault()
                                                        this.setState({
                                                            page: 0,
                                                            paging: this.state.paging,
                                                            loading: true,
                                                            data: []
                                                        }, () => {
                                                            this.loadData()
                                                        });
                                                    }
                                                }} onChange={this.handleChange} name="filter_judul" value={this.state.filter_judul} />
                                            </div>
                                            <button className='pengumuman-button-refresh' onClick={this.resetFilter}>
                                                <img alt='' src={RefreshIcon} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                            <button className='pengumuman-add-button' onClick={() => this.setState({
                                                    modalAdd: true
                                                })}><img alt='' src={PlusIcon}/> Tambah Pengumuman
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body daftar-pengumuman pt-3">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description="Data Kosong"
                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                    :
                                    (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                        <Table className="table table-borderless mb-5">
                                            <Thead>
                                                <Tr>
                                                    <Th className="pengumuman-th-table">#</Th>
                                                    <Th className="pengumuman-th-table">Gambar</Th>
                                                    <Th className="pengumuman-th-table">Judul Pengumuman</Th>
                                                    <Th className="pengumuman-th-table">Deskripsi Singkat</Th>
                                                    <Th className="pengumuman-th-table"></Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.loading &&
                                                    <Tr>
                                                        <Td colSpan={5}>
                                                            <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }} />
                                                        </Td>
                                                    </Tr>
                                                }
                                                {this.state.data.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <Tr>
                                                            <Td className="pengumuman-td-table">
                                                                {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                            </Td>
                                                            <Td><img style={stylefoto}
                                                                src={item.pengumuman_gambar !== null ? IMG_HOST + 'mitra/perusahaan/pengumuman/' + item.pengumuman_gambar : IMG_HOST + "user-profile.png"}
                                                                alt="" /></Td>
                                                            <Td className="pengumuman-td-table">{item.pengumuman_judul}</Td>
                                                            <Td className="pengumuman-td-table">{item.pengumuman_shortdesc}</Td>
                                                            <Td className="pengumuman-td-table">
                                                                <Tooltip trigger={'click'} placement="bottom" color={'#fff'} zIndex={900}
                                                                    title={
                                                                        <div className={'p-2'}>
                                                                            <a className={'text-dark'} onClick={() => this.setState({
                                                                                judulDetail: item.pengumuman_judul,
                                                                                shortdescDetail: item.pengumuman_shortdesc,
                                                                                deskripsiDetail: item.pengumuman_deskripsi,
                                                                                gambarDetail: item.pengumuman_gambar !== null ? IMG_HOST + 'mitra/perusahaan/pengumuman/' + item.pengumuman_gambar : IMG_HOST + "user-profile.png",
                                                                                modalDetail: true
                                                                            })}>Detail</a><br />
                                                                            {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                <>
                                                                                    <a onClick={() => this.setState({
                                                                                        idDetail: item.id_pengumuman
                                                                                    }, () => this.loadDetail())}
                                                                                       className={'text-dark'}>Edit</a><br />
                                                                                </>
                                                                            )}
                                                                            {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                                <>
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            idDel: item.id_pengumuman
                                                                                        })
                                                                                        this.wantDelete()
                                                                                    }} className={'text-danger'}>Hapus</a>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    }>
                                                                    <a><i
                                                                        className="fa fa-ellipsis-v" style={{
                                                                            width: 35,
                                                                            fontSize: 18,
                                                                            padding: 11,
                                                                            color: 'rgb(51 51 51)'
                                                                        }}
                                                                    ></i></a>
                                                                </Tooltip>

                                                            </Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>
                                    )
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_pengumuman);
