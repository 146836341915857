import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";
import eyeIcon from "../../../assets/icons/eye.svg";
import eyeIconSlash from "../../../assets/icons/eye-slash-grey.svg";
import "../../styling.css"

class UserAdd extends Component {
    constructor() {
        super();
        this.state = {
            p_user_name     : '',
            p_user_email    : '',
            p_user_wa    : '',
            p_user_password : '',
            p_user_level : '',
            loading         : false,
            hidePassword    : false
        }
    }
    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let form = {
            p_user_name     : this.state.p_user_name,
            p_user_email    : this.state.p_user_email,
            p_user_wa    : this.state.p_user_wa,
            p_user_password : this.state.p_user_password,
            p_user_level : this.state.p_user_level,
        }

        this.props.request("add_user", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({p_user_name: ''})
                    this.props.changeState({
                        p_user_name: '',
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false
                })
            });
    }

    render() {
        return (
            <Drawer
                title="Tambah User"
                width={350}
                onClose={() => {
                    this.props.changeState({add_visible: false});
                }}
                visible={this.props.add_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => {
                            this.props.changeState({add_visible: false, p_user_name:''});
                        }}
                                style={{ marginRight: 8 }}>
                            Batal
                        </Button>
                        <Button onClick={this.handleSubmit} disabled={this.state.loading} type="primary">
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> Loading</>
                                :
                                "Simpan"
                            }
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Nama User</label>
                        <input name="p_user_name" className="form-control col-xl-12 col-md-12" placeholder="Nama User..."
                               type="text" required="" value={this.state.p_user_name}
                               onChange={(event) => {
                                   const target = event.target;
                                   const value  = target.value;
                                   const name   = target.name;
                                   this.setState({
                                       [name]: value
                                   });
                               }}/>
                    </div>
                    <div className="form-group">
                        <label>Email User</label>
                        <input name="p_user_email" className="form-control col-xl-12 col-md-12" placeholder="Email User..."
                               type="text" required="" value={this.state.p_user_email}
                               onChange={(event) => {
                                   const target = event.target;
                                   const value  = target.value;
                                   const name   = target.name;
                                   this.setState({
                                       [name]: value
                                   });
                               }}/>
                    </div>
                    <div className="form-group">
                        <label>No. WA User (Opsional)</label>
                        <input name="p_user_wa" className="form-control col-xl-12 col-md-12" placeholder="No Wa User..."
                               type="number" required="" value={this.state.p_user_wa}
                               onChange={(event) => {
                                   const target = event.target;
                                   const value  = target.value;
                                   const name   = target.name;
                                   this.setState({
                                       [name]: value
                                   });
                               }}/>
                    </div>
                    <div className="form-group">
                        <div className="input-group btn-password-add-akun">
                            <label>Password User</label>
                            <input name="p_user_password" className="form-control col-xl-12 col-md-12" placeholder="Password User..."
                                   type={this.state.hidePassword ? "text" : "password"} required="" value={this.state.p_user_password}
                                   onChange={(event) => {
                                       const target = event.target;
                                       const value  = target.value;
                                       const name   = target.name;
                                       this.setState({
                                           [name]: value
                                       });
                                   }}/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <img className="show-hide-button" style={{ width: "16px", height: "16px", backgroundColor: "#E9ECEF" }} src={this.state.hidePassword ? eyeIcon : eyeIconSlash} onClick={() => this.setState({
                                        hidePassword: !this.state.hidePassword
                                    })}  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Level User</label>
                        <select
                            name="p_user_level"
                            className="form-control col-xl-12 col-md-12"
                            value={this.state.p_user_level}
                            onChange={(event) => {
                                const target = event.target;
                                const value  = target.value;
                                const name   = target.name;
                                this.setState({
                                    [name]: value
                                });
                            }}>
                            <option value="">Pilih Level User</option>
                            {this.props.data.dataLevel && this.props.data.dataLevel.map((item, index) => {
                                return(
                                    <option value={item.level_id}>{item.level_name}</option>
                                )
                            })}
                        </select>
                    </div>
                 </Form>
            </Drawer>

        )
    }
}

export default UserAdd
