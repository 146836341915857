import React, {Component, Fragment} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import './styling.css';
import closeIcon from "../../assets/icons/close_circle.svg"
import {IMG_HOST} from "../../helper/host";
import {Spin} from "antd";

export default class Slideshow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slideshow: '',
            cmsbanner: '',
            fileList: "",
            data: "",
            loading : true,
        };
    }

    loadData() {
        this.props.request("list-banner-cms", {}, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        loading: false,
                        fileList: [
                            {
                                uid: "-1",
                                name: response.data.app_logo,
                                status: "done",
                                url: IMG_HOST + response.data.app_logo
                            }
                        ],
                    });
                } else {
                    this.setState({
                        loading: true,
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        if (!this.props.modalSlideshow) return null;
        return (
            <Fragment>
                <Modal toggle={() => this.props.setState({modalSlideshow: !this.props.modalSlideshow})} mask={true} bodyStyle={{ backgroundColor: "rgba(0, 0, 0, 0)" }} className="custom-antd-modal modal-styling modal-style" isOpen={this.props.modalSlideshow} style={{
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    height: '80vh',
                    maxWidth: '851px',
                    flexWrap: "wrap",
                }}>
                    <img src={closeIcon} onClick={() => this.props.setState({modalSlideshow:false})} className="button-close"/>
                    <div className='carousel slide' id={"carouselExampleIndicators"}>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                {this.state.loading ?
                                    <Spin size="large" tip="Loading..." style={{
                                        width: '100%',
                                    }}/>
                                    :
                                    <div>
                                        {this.state.data &&
                                            <a href="/langganan">
                                                <img className="img-styling" alt=''
                                                     src={IMG_HOST + 'banner/' + this.state.data.setting_value}/>
                                            </a>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            </Fragment>

        )
    }
}
