import React, {Component} from 'react'
import logo from '../../../assets/images/dashboard/logo-header.png'
import CheckWhite from '../../../assets/icons/vector-check-white.svg'
import './style.css';

export class HeaderRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: this.props.step
        }
    }
    
    render() {
        return (
            <header id="sticky" className="sticky">
                <div className="container-fluid fixed-top mb-4" style={{backgroundColor: "#FFFFFF"}}>
                    <div className="row pt-2">
                        <div className="col p-0">
                            <div className="top-header padding-body my-1 mx-2 px-5">
                                <div className='row'>
                                    <div className='col mb-2 lg:mb-0' style={{textAlign: "center"}}>
                                        <div className="logo pl-2">
                                            <a className="nav-link pl-3" 
                                            href="/">
                                                <img src={logo} alt="logo" className='logo-header' />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-6 px-0 lg:px-1' style={{ textAlign: "center", fontSize: "12px"}}>
                                        {this.props.hide === true ? ""
                                        :
                                        <div className='row d-flex justify-content-center flex-nowrap'>
                                            <div className='col-sm-2'>
                                                <div className='row d-flex align-items-center justify-content-center'>
                                                    <button disabled={this.props.step === 2} onClick={() => this.props.setState({ stepHeader: 0 })} className={this.props.step === 0 ? 'btn btn-sm btn-primary rounded-pill px-3 py-2' :  this.props.step > 0 ? 'btn btn-sm btn-primary rounded-pill px-2 py-2' :'btn btn-sm rounded-pill px-3 py-2'} style={{borderRadius: "50%"}}>{this.props.step > 0 ? <img src={CheckWhite} style={{width: "23px"}} /> : 1}</button>
                                                </div>
                                                <div className='row'>
                                                    <div className={this.props.step === 0 || this.props.step > 0 ? 'col px-0 text-header-color' : 'col px-0'} style={{fontSize: "12px"}}>
                                                        Pilih Paket
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm py-4'>
                                                <div className={this.props.step > 0 ? 'bg-primary' : 'secondary-color'} style={{height: "2px", borderRadius: "5px"}}></div>
                                            </div>
                                            <div className='col-sm-2'>
                                                <div className='row d-flex align-items-center justify-content-center'>
                                                    <button disabled={this.props.step === 2} onClick={() => this.props.setState({ stepHeader: 1, paket_id: this.props.paket_selected.paket_id })} className={this.props.step === 1 ? 'btn btn-sm btn-primary rounded-pill px-3 py-2' :  this.props.step > 1 ? 'btn btn-sm btn-primary rounded-pill px-2 py-2' :'btn btn-sm rounded-pill px-3 py-2'} style={{borderRadius: "50%"}}>{this.props.step > 1? <img src={CheckWhite} style={{width: "23px"}} /> : 2}</button>
                                                </div>
                                                <div className='row'>
                                                    <div className={this.props.step === 1 || this.props.step > 1 ? 'col px-0 text-header-color' : 'col px-0'} style={{fontSize: "12px"}}>
                                                        Daftar Akun
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm py-4'>
                                                <div className={this.props.step > 1 ? 'bg-primary' : 'secondary-color'} style={{height: "2px", borderRadius: "5px"}}></div>
                                            </div>
                                            <div className='col-sm-2'>
                                                <div className='row d-flex align-items-center justify-content-center'>
                                                    <button disabled={this.props.step !== 2} onClick={() => this.props.setState({ stepHeader: 2 })} className={this.props.step === 2 ? 'btn btn-sm btn-primary rounded-pill px-3 py-2' : 'btn btn-sm rounded-pill px-3 py-2'} style={{borderRadius: "50%"}}>3</button>
                                                </div>
                                                <div className='row'>
                                                    <div className={this.props.step === 2 || this.props.step > 2 ? 'col px-0 text-primary' : 'col px-0'} style={{fontSize: "12px"}}>
                                                        Finish
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className='col'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderRegister
