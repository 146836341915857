import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import {Empty, Modal, Spin} from 'antd';

import Edit from './edit'
import Add from "./add";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import Switch from "react-switch";
import { toast } from "react-toastify";
import "../../assets/css/style.css"
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import ModalDefault from '../menu-guide/modal-default';
import ImportIcon from "../../assets/icons/import-icon.svg";
import {BACKEND_API_HOST} from "../../helper/host";

export class ListShift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            idDel: '',
            isLoading: true,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            sift_name: '',
            sift_late_tolerance: '',
            sift_id: '',
            nama_jabatan: '',
            filter_nama: '',
            add_visible: false,
            visable: false,
            guide: false,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            confirm_modal_default: false,
            modalimport: false,
            importFile: null
        }
        this.handleChange    = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter     = this.resetFilter.bind(this)
        this.wantDelete      = this.wantDelete.bind(this)
        this.importExcel     = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            isLoading: true
        }, () => this.loadData())
    }


    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true,
                        data: []
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("shift/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    data: []
                                })
                                this.props.set_auth(this.props.api_token, response.perusahaan);
                                this.loadData()
                                this.loadCheck()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("shift/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        isLoading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }
    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        karyawan_check: response.karyawan,
                        jam_kerja_check: response.jam_kerja
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                            this.setState({
                                confirm_modal_default: true
                            })
                        }
                    })
                }
            })
    }

    exportShift = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'shift/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'shift/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    componentDidMount() {
        this.loadData()
        this.loadCheck()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-shift',
                content: 'Anda dapat membuat Shift disini',
            },
            {
                selector: '.daftar-shift',
                content: 'Daftar Shift anda dapat dilihat disini',
            },
        ];
        const style = {
            card_header: {
                backgroundColor: "#FAFDFF",
                color: "#143B5E",
                padding: "12px 24px",
                fontSize: "24px",
                lineHeight: "36px",
                fontWeight: 700
            },
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            card_header: {
                backgroundColor: "#FAFDFF",
                padding: "10px 16px",
                color: "#143B5E",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px"
            },
            card_body: {
                backgroundColor: "#F9F9F9",
                padding: "12px 16px"
            },
            column_card_body: {
                backgroundColor: "#F9F9F9",
                textAlign: "center"
            },
            hari: {
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#252B2",
                textAlign: "left",
                margin: "0px"
            },
            time_input: {
                padding: "12px",
                fontSize: "14px",
                color: "#252B2B",
                backgroundColor: "#FFFFFF",
                lineHeight: "16px",
                width: "88px"
            }
        }
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Shift") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Shift - Presensi</title>
                        <meta name="description" content="Detail Absensi" />
                    </Helmet>
                    <Edit
                        data={this.state}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                        loadCheck={() => this.loadCheck()}
                    />
                    <Breadcrumb parent="Manajemen Shift" title="Daftar Shift" setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <ModalDefault
                        jam_kerja={this.state.jam_kerja_check}
                        shift={this.state.shift_check}
                        lokasi={this.state.lokasi_check}
                        divisi={this.state.divisi_check}
                        karyawan={this.state.karyawan_check}
                        confirm_modal_default={this.state.confirm_modal_default}
                        setState={(name, val) => this.setState(name, val)}
                        history={this.props.history}
                    />
                    <Modal
                        title="Import Shift"
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Sedang mengupload data</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                            <a onClick={this.exportShift} style={{ color: "#057CE4" }}>Unduh template disini</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    {/*<div className='container-fluid'>*/}
                    {/*    <div className='row'>*/}
                    {/*        <div className='col-md-12 px-0'>*/}
                    {/*            <div className='card border'>*/}
                    {/*                <div className='card-body' style={{ padding: "24px" }}>*/}
                    {/*                    <div className='row mb-3'>*/}
                    {/*                        <div className='col-md-5 pr-1'>*/}
                    {/*                            <input type='text' onChange={this.handleChange} name='filter_nama' value={this.state.filter_nama} className='form-control' placeholder='Cari nama jam kerja ...' style={style.filter_nama} onKeyDown={(e) => {*/}
                    {/*                                if (e.key === "Enter") {*/}
                    {/*                                    this.loadData()*/}
                    {/*                                }*/}
                    {/*                            }}></input>*/}
                    {/*                        </div>*/}
                    {/*                        <div className='col-md-1 tombol-refresh'>*/}
                    {/*                            <button className='hover-pointer' style={style.button_refresh}><img alt="" src={RefreshIcon} onClick={(e) => {*/}
                    {/*                                this.setState({*/}
                    {/*                                    filter_nama: ""*/}
                    {/*                                }, () => this.loadData())*/}
                    {/*                            }} /></button>*/}
                    {/*                        </div>*/}
                    {/*                        <div className='col-md'>*/}
                    {/*                            <button onClick={() => this.setState({ modalAdd: true })} className='btn btn-primary text-capitalize pull-right button-w-full'><img className='mr-2' alt='' src={PlusWhiteIcon} />Tambah Jam Kerja</button>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className='row'>*/}
                    {/*                        <div className='col-md-12'>*/}
                    {/*                            <div className='table-responsive'>*/}
                    {/*                                <table className='table table-striped table-borderless'>*/}
                    {/*                                    <thead>*/}
                    {/*                                    <tr>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B"*/}
                    {/*                                        }}>#</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "126px"*/}
                    {/*                                        }}>Nama</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "126px"*/}
                    {/*                                        }}>Jadwal Kerja</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "126px"*/}
                    {/*                                        }}>Jam Kerja</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "196px"*/}
                    {/*                                        }} maxWidth={"100px"}>Toleransi Keterlambatan</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "96px"*/}
                    {/*                                        }}>Status</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "136px"*/}
                    {/*                                        }}></th>*/}
                    {/*                                    </tr>*/}
                    {/*                                    </thead>*/}
                    {/*                                    <tbody>*/}
                    {/*                                    {this.state.loadingList ?*/}
                    {/*                                        <tr>*/}
                    {/*                                            <td colSpan={7} style={{ textAlign: "center" }}>*/}
                    {/*                                                <Spin size="large" />*/}
                    {/*                                            </td>*/}
                    {/*                                        </tr>*/}
                    {/*                                        :*/}
                    {/*                                        this.state.data.length === 0 ?*/}
                    {/*                                            <tr>*/}
                    {/*                                                <td colSpan={7} style={{ textAlign: "center", backgroundColor: "#FFFFFF" }}>*/}
                    {/*                                                    <img src={EmptyDataImage} alt="icon data kosong" />*/}
                    {/*                                                    <p className='mb-2'>Data Kosong...</p>*/}
                    {/*                                                </td>*/}
                    {/*                                            </tr>*/}
                    {/*                                            :*/}
                    {/*                                            this.state.data.map((item, index) => {*/}
                    {/*                                                no += 1*/}
                    {/*                                                return (*/}
                    {/*                                                    <tr>*/}
                    {/*                                                        <td style={style.td_style}>{no}</td>*/}
                    {/*                                                        <td style={style.td_style}>{item.sift_name}</td>*/}
                    {/*                                                        <td style={style.td_style}>{item.jam_kerja.length} hari kerja</td>*/}
                    {/*                                                        <td style={style.td_style}>{item.jam_kerja.length} hari kerja</td>*/}
                    {/*                                                        /!*<td style={style.td_style}>{item.jamkerja.jam_masuk !== "" ? item.jamkerja.jam_masuk.slice(0, 5) : ""} - {item.jamkerja.jam_keluar !== "" ? item.jamkerja.jam_keluar.slice(0, 5) : ""}</td>*!/*/}
                    {/*                                                        <td style={style.td_style}>{item.sift_late_tolerance} menit</td>*/}
                    {/*                                                        <td style={style.td_style} className='d-flex flex-nowrap'>*/}
                    {/*                                                            <Switch onChange={(e) => this.setState({ status: e, shift_id: item.sift_id }, () => this.updateStatus())} defaultChecked={item.sift_status === "1"}*/}
                    {/*                                                            /> {item.sift_status === "1" ? <div className='flex-fill pl-1' style={{ color: "#057CE4" }}>Aktif</div> : <div className='flex-fill pl-1'>Non-Aktif</div>}*/}
                    {/*                                                        </td>*/}
                    {/*                                                        <td style={style.td_style}>*/}
                    {/*                                                            <>*/}
                    {/*                                                                <a className="mr-3" onClick={() => this.setState({ shift_id: item.sift_id }, () => this.byIdJamKerja())}>*/}
                    {/*                                                                    <img src={PencilIcon} alt='' />*/}
                    {/*                                                                </a>*/}
                    {/*                                                                <a onClick={() => this.setState({ shift_id: item.sift_id }, () => {*/}
                    {/*                                                                    Swal.fire({*/}
                    {/*                                                                        title: 'Apakah anda yakin?',*/}
                    {/*                                                                        text: "Anda tidak dapat mengembalikan data ini setelah dihapus!",*/}
                    {/*                                                                        icon: 'warning',*/}
                    {/*                                                                        showCancelButton: true,*/}
                    {/*                                                                        confirmButtonColor: '#3085d6',*/}
                    {/*                                                                        cancelButtonColor: '#d33',*/}
                    {/*                                                                        confirmButtonText: 'Hapus',*/}
                    {/*                                                                        cancelButtonText: "Batal"*/}
                    {/*                                                                    }).then((result) => {*/}
                    {/*                                                                        if (result.isConfirmed) {*/}
                    {/*                                                                            this.deleteJamKerja()*/}
                    {/*                                                                        }*/}
                    {/*                                                                    })*/}
                    {/*                                                                })}>*/}
                    {/*                                                                    <img src={TrashIcon} alt='' />*/}
                    {/*                                                                </a>*/}
                    {/*                                                            </>*/}
                    {/*                                                        </td>*/}
                    {/*                                                    </tr>*/}
                    {/*                                                )*/}
                    {/*                                            })*/}
                    {/*                                    }*/}
                    {/*                                    </tbody>*/}
                    {/*                                </table>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className='card-footer p-0'>*/}
                    {/*                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>*/}
                    {/*                        /!* {this.state.data.length !== 0 && *!/*/}
                    {/*                        <>*/}
                    {/*                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} dari {this.state.totalData} data</div>*/}
                    {/*                            <div className='col'>*/}
                    {/*                                <ReactPaginate*/}
                    {/*                                    previousLabel={<img src={ArrowLeft} alt='' />}*/}
                    {/*                                    nextLabel={<img src={ArrowRight} alt='' />}*/}
                    {/*                                    pageCount={this.state.pageCount}*/}
                    {/*                                    forcePage={this.state.currentPage}*/}
                    {/*                                    marginPagesDisplayed={2}*/}
                    {/*                                    pageRangeDisplayed={3}*/}
                    {/*                                    onPageChange={this.handlePageClick}*/}
                    {/*                                    breakClassName={'page-item'}*/}
                    {/*                                    breakLinkClassName={'page-link custom-page-link'}*/}
                    {/*                                    containerClassName={'pagination my-2'}*/}
                    {/*                                    pageClassName={'page-item'}*/}
                    {/*                                    pageLinkClassName={'page-link custom-page-link'}*/}
                    {/*                                    previousClassName={'page-item'}*/}
                    {/*                                    previousLinkClassName={'page-link custom-prev-next-link'}*/}
                    {/*                                    nextClassName={'page-item'}*/}
                    {/*                                    nextLinkClassName={'page-link custom-prev-next-link'}*/}
                    {/*                                    activeClassName={'active custom-active'}*/}
                    {/*                                />*/}
                    {/*                                /!* catatan.*/}
                    {/*                            custom classname pagination dipanggil dari file css di folder absensi harian*/}
                    {/*                            ga hanya pagination, semua classname dari css absensi harian bisa dipanggil file ini *!/*/}
                    {/*                            </div>*/}
                    {/*                        </>*/}
                    {/*                        /!* // } *!/*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card profile-card">
                                    <div className="card-header pb-0 px-3 mb-3" style={{ borderBottom: 'none' }}>
                                        <div className="row mx-0" style={{marginLeft: '5px'}}>
                                            <div className="col-md-5">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                            <span className="input-group-text input-group-text-custom"
                                                                  id="basic-addon1"><span
                                                                className="fa fa-search"></span></span>
                                                    </div>
                                                    <input name="filter_nama"
                                                           className="form-control"
                                                           placeholder="Nama Shift..."
                                                           type="text"
                                                           value={this.state.filter_nama}
                                                           onChange={this.handleChange}
                                                           onKeyUp={(event) => {
                                                               event.preventDefault()
                                                               if (event.which == 13) {

                                                                   this.setState({
                                                                       page: 0,
                                                                       paging: this.state.paging,
                                                                       isLoading: true
                                                                   }, () => {
                                                                       this.loadData()
                                                                   });
                                                               }
                                                           }}/>
                                                </div>
                                            </div>
                                            <div className="col-md-1 px-0">
                                                <button className="btn btn-outline-" style={{ lineHeight: "26px" }} onClick={this.resetFilter}>
                                                    <span className="fa fa-refresh"></span></button>
                                            </div>
                                            <div className="col px-0"></div>
                                            <div className='col-auto px-0' style={{ textAlign: "right" }}>
                                                <button className="mr-2 karyawan-import-button" onClick={() => {
                                                    this.setState({modalimport: true})}}><img src={ImportIcon} alt=''/> Import
                                                </button>
                                            </div>
                                            <div className='col-auto px-2'>
                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                    <a className="col-auto btn btn-primary tambah-shift"
                                                       style={{float: "right", color: "white", textTransform: "none", lineHeight: '25px' }} onClick={() => {
                                                        this.setState({
                                                            add_visible: true
                                                        })
                                                    }}><i className={"fa fa-plus"}></i> Tambah Shift</a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body daftar-shift">
                                        {!this.state.isLoading && this.state.data.length === 0 ?
                                            <Empty
                                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                imageStyle={{ height: 60 }}
                                                description={<span>Data tidak ditemukan!</span>}
                                            />
                                            :
                                            (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                                <div>
                                                    <Table striped className="mb-5">
                                                        <Thead>
                                                            <Tr>
                                                                <Th width="30">#</Th>
                                                                <Th >Shift</Th>
                                                                <Th >Toleransi Telat</Th>
                                                                <Th className={"text-center"}>Status</Th>
                                                                <Th className={"text-center"} width="200">Aksi</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {this.state.data.map((item, index) => {
                                                                no += 1
                                                                return (
                                                                    <Tr className="mt-2 mb-2 border-bottom-light">
                                                                        <small>
                                                                            <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                        </small>
                                                                        <Td>{item.sift_name.toUpperCase()}</Td>
                                                                        <Td>{item.sift_late_tolerance} Menit</Td>
                                                                        <Td className="item-center" align={"center"}>
                                                                            {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                    <Switch
                                                                                        id={`detail-${item.sift_id}`}
                                                                                        name="sift_status"
                                                                                        checked={
                                                                                            item.sift_status === '1' ? true : false
                                                                                        }
                                                                                        onChange={(val) => {
                                                                                            this.setState({
                                                                                                loading: true,
                                                                                                data: []
                                                                                            })

                                                                                            let formData = {
                                                                                                id: item.sift_id,
                                                                                            }

                                                                                            this.props.request('shift/ubah-status', formData, 'POST')
                                                                                                .then((response) => {
                                                                                                    if (response.success) {
                                                                                                        Swal.fire({
                                                                                                            title: 'Berhasil!',
                                                                                                            text: response.message,
                                                                                                            icon: 'success',
                                                                                                            showConfirmButton: false,
                                                                                                            timer: 1500,
                                                                                                        })
                                                                                                    } else {
                                                                                                        if (Array.isArray(response.message)) {
                                                                                                            response.message.map(item => {
                                                                                                                toast['error'](item)
                                                                                                                return true
                                                                                                            })
                                                                                                        } else {
                                                                                                            toast['error'](response.message)
                                                                                                        }
                                                                                                    }
                                                                                                    this.loadData()
                                                                                                    this.setState({ loading: false })
                                                                                                })
                                                                                        }}
                                                                                        onColor="#057CE4"
                                                                                        offColor="#EFEFEF"
                                                                                        uncheckedIcon={false}
                                                                                        checkedIcon={false}
                                                                                        handleDiameter={24}
                                                                                        boxShadow="1px 2px 2px rgba(0, 0, 0, 0.6)"
                                                                                        activeBoxShadow="1px 2px 1px 12px rgba(0, 0, 0, 0.2)"
                                                                                        height={18}
                                                                                        width={40}
                                                                                        className="mr-1"
                                                                                    />
                                                                            )}
                                                                            <span className="mb-2" style={{ color: item.sift_status === '1' ? "#057CE4" : "#5D6F80" }}>{item.sift_status === '1' ? "Aktif" : "Non-Aktif"}</span>
                                                                        </Td>
                                                                        <Td align={"center"}>
                                                                            <div className="row">
                                                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            sift_id: item.sift_id,
                                                                                            sift_name: item.sift_name,
                                                                                            sift_late_tolerance: item.sift_late_tolerance,
                                                                                            visable: true
                                                                                        })
                                                                                    }} className="btn btn-outline-custom btn-block-50 mr-2">Edit</a>
                                                                                )}
                                                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            idDel: item.sift_id
                                                                                        })
                                                                                        this.wantDelete()
                                                                                    }} className="btn btn-danger-custom btn-block-50">Hapus</a>
                                                                                )}
                                                                            </div>
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })}
                                                            {this.state.isLoading &&
                                                                <Tr>
                                                                    <Td colSpan="5">
                                                                        <Spin style={{ width: '100%' }} size="small" tip="Mohon Tunggu..." />
                                                                    </Td>
                                                                </Tr>
                                                            }
                                                        </Tbody>
                                                    </Table>
                                                    <Paging
                                                        className="float-right"
                                                        handlePageClick={this.handlePageClick}
                                                        pageCount={this.state.pageCount}
                                                        currentPage={this.state.currentPage}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListShift);
