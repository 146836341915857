import React, {Component} from 'react';
import importIcon from "../../assets/icons/import-icon-blue-light.svg";


export class Breadcrumb extends Component {
    render() {
        return (
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row d-flex justify-content-between">
                            <div className="col-lg-6">
                                <div className="page-header-left">
                                    <h3>
                                        <small className="mb-1">Presensi Admin panel {this.props.header}</small>
                                        {this.props.title}
                                    </h3>
                                </div>
                                {!this.props.hideBreadcumb && (
                                <ol className="breadcrumb mt-2">
                                    <li className="breadcrumb-item active"><a href="/dashboard" style={{color: '#5D6F80'}}>Beranda</a></li>
                                    {this.props.parent && (
                                        <li className="breadcrumb-item active"><a href={this.props.parentLink} style={{color: '#5D6F80'}}>{this.props.parent}</a></li>
                                    )}
                                    <li className="breadcrumb-item">{this.props.title}</li>
                                    {/*<li>&nbsp;&nbsp;<i className="fa fa-info-circle" style={{color: '#057CE4'}} onClick={this.props.setGuide}></i></li>*/}
                                </ol>
                                )}
                            </div>
                            {this.props.showUpdateFiturBanner ? (
                            <div className="col-auto">
                                <button className="btn btn-export"
                                        onClick={this.props.showUpdateFiturBanner}
                                >
                                    <i className="fa fa-info-circle" style={{color: '#057CE4'}}/>
                                </button>
                            </div>
                            ) : null}
                        </div>
                    </div>
                </div>
        )
    }
}

export default Breadcrumb
