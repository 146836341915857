
export const dateIndo = (str, useDateTime) => {

    var splitTglWaktu = str.split(" ")
    var tglPiece = splitTglWaktu[0]

    var splitTglPiece = tglPiece.split("-")

    var tgl = splitTglPiece[2]
    var bln = splitTglPiece[1]
    var thn = splitTglPiece[0]

    var ret = tgl + "-" + bln + "-" + thn

    if (useDateTime) {

        var waktuPiece = splitTglWaktu[1]

        if (waktuPiece !== undefined) {

            ret += " " + waktuPiece
        }
    }

    return ret
}



export const convertToRupiah = (angka, showRp) => {
    if(!angka){
        angka = 0
        if(showRp){
            angka = "Rp " + angka
        }
        return angka;
    }

    var rupiah = '';
    var angkarev = angka.toString().split('').reverse().join('');
    for(var i = 0; i < angkarev.length; i++){
        if(i%3 === 0) {
            rupiah += angkarev.substr(i,3)+'.';
        }
    }

    var ret = rupiah.split('',rupiah.length-1).reverse().join('')

    /*
     * Jika showRp bernilai TRUE maka tampilkan string "Rp" di depan angka hasil convert
     */
    if (showRp) {

        ret = "Rp " + ret
    }

    return ret;
}

export const convertAngkaKeHari = (angka) => {

    switch (parseInt(angka, 10)) {
        case 1:
            return 'Senin';
        case 2:
            return 'Selasa';
        case 3:
            return 'Rabu';
        case 4:
            return 'Kamis';
        case 5:
            return 'Jumat';
        case 6:
            return 'Sabtu';
        default:
            return 'Minggu';
    }
}
export const convertBulan = (angka) => {

    switch (parseInt(angka, 10)) {
        case 1:
            return 'Januari';
        case 2:
            return 'Februari';
        case 3:
            return 'Maret';
        case 4:
            return 'April';
        case 5:
            return 'Mei';
        case 6:
            return 'Juni';
        case 7:
            return 'Juli';
        case 8:
            return 'Agustus';
        case 9:
            return 'September';
        case 10:
            return 'Oktober';
        case 11:
            return 'November';
        case 12:
            return 'Desember';
        default:
            return 'Januari';
    }
}

export const convertStatus = (status) => {

    if (status == 'paid'){
        return'Terbaru'
    }if (status == 'process'){
        return'Proses'
    }if (status == 'finish'){
        return'Selesai'
    }if (status == 'cancel'){
        return'Batal'
    }if (status == 'refund'){
        return'Refund'
    }
}
export const StokLabel = (status) => {

    if (status == '0'){
        return'Stok Habis'
    }if (status != '0'){
        return status
    }
}

export const pageNumber = (no, count, current) => {
    var number = (count*current)+no
    return number
}
