import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import { API_HOST, DEFAULT_IMG, IMG_HOST } from "../../../helper/host";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import Swal from 'sweetalert2'
import { Select, Spin } from 'antd';
import 'antd/dist/antd.css';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import "../../styling.css"

export class MigrasiData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data_user: [],
            data: [],
            perusahaan_nama: "",
            perusahaan_foto: {},
            perusahaan_email: "",
            perusahaan_bidang: "",
            perusahaan_provinsi: "",
            perusahaan_kota: "",
            perusahaan_kecamatan: "",
            perusahaan_alamat: "",
            perusahaan_telp: "",
            perusahaan_no_wa: "",
        };

        this.handleChange = this.handleChange.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    migrasiData() {
        this.setState({
            loading: true,
        })
        this.props.request("request-migrasi", {}, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    this.setState({
                        loading: false,
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    this.setState({
                        loading: false,
                    })
                }
            });
    }

    loadDataUser() {
        this.setState({
            loading: true
        })
        let form = {}
        this.props.request("get_company", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        data_user: response.data,
                        karyawan_status: response.karyawan,
                        jumlah_karyawan: response.jumlah_karyawan,
                        max_karyawan: response.maks_karyawan,
                        perusahaan_nama: response.data.perusahaan_nama,
                        perusahaan_foto: response.data.perusahaan_foto,
                        perusahaan_email: response.data.perusahaan_email,
                        perusahaan_user: response.data.perusahaan_user,
                        perusahaan_password: response.data.perusahaan_password,
                        perusahaan_bidang: response.data.perusahaan_bidang,
                        perusahaan_alamat: response.data.perusahaan_alamat,
                        perusahaan_telp: response.data.perusahaan_telp,
                        perusahaan_no_wa: response.data.perusahaan_no_wa,
                        data: response.data.user,
                        primary: response.primary,
                        fileList: [
                            response.data.perusahaan_foto ?
                                {
                                    uid: "-1",
                                    name: response.data.perusahaan_foto,
                                    status: "done",
                                    url: IMG_HOST + response.data.perusahaan_foto
                                }
                                : {}
                        ],
                    })

                }
            })
        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        this.loadDataUser()
    }

    render() {

        const steps = [
            {
                selector: '.profil-akun',
                content: 'Detail Akun anda dapat dilihat disini',
            },
            {
                selector: '.migrasi-data',
                content: 'Anda dapat request migrasi data perusahaan anda disini',
            },
        ];
        const { Option } = Select;
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Migrasi Data - Presensi</title>
                        <meta name="description" content="Detail Absensi" />
                    </Helmet>
                    <Breadcrumb title="Migrasi Data" parent="Settings" setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    {this.state.loading ?
                                        <Spin size="large" tip="Sedang memuat data..." style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }} />
                                        :
                                        <>
                                            <div className="profile-details text-center profil-akun">
                                                <img
                                                    src={this.state.data_user.perusahaan_foto !== null ? IMG_HOST + this.state.data_user.perusahaan_foto : DEFAULT_IMG}
                                                    style={{ width: "90px", height: "90px", objectFit: "cover" }}
                                                    className="img-fluid rounded-circle blur-up lazyloaded" />
                                                <h5 className="f-w-600 f-16 mb-0">{this.state.data_user.perusahaan_nama}</h5>
                                                <span>{this.state.data_user.perusahaan_email}</span>
                                                <div className="social">
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="project-status migrasi-data">
                                                <button type="button" className="btn btn-primary btn-sm btn-block px-0" onClick={() => {this.migrasiData()}}>
                                                    Request Migrasi Data</button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    };
}

const mapStateToProps = state => ({
    api_token: state.Auth.api_token,
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, auth_key, data) => dispatch(set_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth())
})

export default connect(mapStateToProps, mapDispatchToProps)(MigrasiData);
