import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";

class EditSift extends Component {

    handleEdit = () => {
        let form = {
            id: this.props.data.sift_id,
            name: this.props.data.sift_name,
            tolerance: this.props.data.sift_late_tolerance,
        }
        this.props.request("shift/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        sift_name : '',
                        sift_late_tolerance : '',
                    },() =>this.props.changeState({
                        visable: false,
                        sift_name : '',
                        sift_late_tolerance : '',
                        data: [],
                        isLoading: true,
                    },() => {
                        this.props.loadData()
                    }))
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }


    render() {
        return (
            <Drawer
                title="Edit Sift"
                width={350}
                onClose={() => {
                    this.props.changeState({visable: false, sift_name:''});
                }}
                visible={this.props.data.visable}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onChange={() => {
                            this.props.changeState({visable: false, sift_name:''});
                        }}
                                style={{ marginRight: 8 }}>
                            Batal
                        </Button>
                        <Button onClick={this.handleEdit} type="primary">
                            Simpan
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Nama Shift</label>
                        <input name="sift_name" className="form-control col-xl-12 col-md-12" placeholder="Nama Shift..."
                               type="text" required="" value={this.props.data.sift_name}
                               onChange={(event) => {
                                   const target = event.target;
                                   const value  = target.value;
                                   const name   = target.name;
                                   this.props.changeState({
                                       [name]: value
                                   });
                               }}/>
                    </div>
                    <div className="form-group">
                        <label>Toleransi Terlambat (menit)</label>
                        <input name="sift_late_tolerance" className="form-control col-xl-12 col-md-12" placeholder="Nama Shift..."
                               type="number" required="" value={this.props.data.sift_late_tolerance}
                               onChange={(event) => {
                                   const target = event.target;
                                   const value  = target.value;
                                   const name   = target.name;
                                   this.props.changeState({
                                       [name]: value
                                   });
                               }}/>
                    </div>
                </Form>
            </Drawer>

        )
    }
}

export default EditSift
