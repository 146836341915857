import React, {Component} from 'react'
import {APIKEY} from "../../helper/host";
import {Button, ButtonGroup, Col, FormGroup, Input, Modal, ModalBody, ModalHeader, Row} from 'reactstrap'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import CurrencyInput from 'react-currency-input-field';
import {GoogleApiWrapper} from 'google-maps-react';

export class ModalPotongan extends Component {
    constructor(props) {
        super(props);

        this.state      = {
            loading                 : false,

        }

    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.visiblePot} toggle={this.props.potonganModal}
                       style={{maxWidth: '900px', width: '100%'}}>
                    <ModalHeader>
                        List Potongan
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <Col lg="12">
                                {this.props.potongan.map((item, id) =>{
                                    return(
                                        <Row>
                                            <Col className="text-center">
                                                {item.payroll_term.p_term_name}
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <select
                                                        name="k_potongan_type"
                                                        id="k_potongan_type"
                                                        className="form-control"
                                                        onChange={this.props.handlePotonganType(id)}
                                                        placeholder="Type Potongan"
                                                        value={item.k_potongan_type}
                                                    >
                                                        <option value={""}>Type Potongan</option>
                                                        <option value="jam">Jam</option>
                                                        <option value="hari">Hari</option>
                                                        <option value="bulan">Bulan</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <CurrencyInput
                                                        className="form-control border-radius upah-upah"
                                                        name="k_potongan_amount"
                                                        id="k_potongan_amount"
                                                        decimalSeparator={','}
                                                        groupSeparator="."
                                                        value={item.k_potongan_amount}
                                                        placeholder="Nominal potongan"
                                                        onValueChange={(value, name) => {
                                                            let newData = this.props.potongan
                                                            newData[id].k_potongan_amount = value
                                                            this.setState({
                                                                k_potongan_amount: value,
                                                                data: newData
                                                            })
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2">
                                                {item.payroll_term.p_term_prefix == "general" ?
                                                <ButtonGroup size="sm">

                                                    <Button
                                                        onClick={this.props.removeClick.bind(this,item.karyawan_id, item.k_potongan_id)}
                                                        color='danger'
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Button>


                                                </ButtonGroup>
                                                    : ""}
                                            </Col>
                                        </Row>
                                    )
                                })}
                                <Row>
                                    <Col lg="12">
                                        {this.props.rows.map((data, id) =>{
                                            return(
                                                <Row className="row-md-12">
                                                    <Col>
                                                        <FormGroup>
                                                            {this.props.inputType == "select" ?
                                                                <Input  type="select" name="p_term_id" value={data.p_term_id}
                                                                        onChange={this.props.handleChangeStock(id)}

                                                                >
                                                                    <option value="">- Payroll Istillah -</option>
                                                                    {
                                                                        this.props.dataTermPotongan.map((item, index) => {
                                                                            return (
                                                                                <option
                                                                                    key={index}
                                                                                    value={item.p_term_id}
                                                                                    selected={item.p_term_id == this.props.dataTermPotongan.p_term_id}>
                                                                                    {item.p_term_name}
                                                                                </option>
                                                                            )
                                                                        })
                                                                    } <option value="text"  className="text-center"
                                                                              style={{color:"#0080ff"}}>+ Tambah Payroll Istillah</option>
                                                                </Input>
                                                                :
                                                                <div className="form-group">
                                                                    <div className="input-group mb-3">
                                                                        <input name="p_term_name" style={{fontSize: "12px"}}
                                                                               type="text" onChange={this.props.handleChangeistilah}
                                                                               className="form-control border-right-0 "
                                                                               id="exampleInputEmail1"
                                                                               aria-describedby="emailHelp"
                                                                               placeholder="Masukkan Istillah Baru"
                                                                               autoFocus
                                                                        />
                                                                        {this.props.p_term_name.length >= 1 ?
                                                                            <div className="input-group-append border-left-0">
                                                                        <span class="input-group-text" style={{
                                                                            fontSize: "12px",
                                                                            backgroundColor: "#FFFFFF"
                                                                        }} id="basic-addon2">
                                                                            <a style={{fontSize: "12px", color: "#057CE4"}}
                                                                               onClick={this.props.handleSubmitIstilah.bind(this,"potongan")}
                                                                            >Tambah Istillah</a>
                                                                        </span>
                                                                            </div>
                                                                            :
                                                                            <div className="input-group-append border-left-0">
                                                                        <span className="input-group-text" style={{
                                                                            fontSize: "12px",
                                                                            backgroundColor: "#FFFFFF"
                                                                        }} id="basic-addon2">
                                                                            <a style={{
                                                                                fontSize: "12px",
                                                                                color: "#057CE4"
                                                                            }}
                                                                               onClick={this.props.istillahBatal.bind(this)}
                                                                            >Batal</a>
                                                                        </span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <select
                                                                name="k_potongan_type"
                                                                id="k_potongan_type"
                                                                className="form-control"
                                                                onChange={this.props.handleChangeStock(id)}
                                                                placeholder="Type Potongan"
                                                                value={data.k_potongan_type}
                                                            >
                                                                <option value={""}>Type Potongan</option>
                                                                <option value="jam">Jam</option>
                                                                <option value="hari">Hari</option>
                                                                <option value="bulan">Bulan</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <CurrencyInput
                                                                name="k_potongan_amount"
                                                                decimalSeparator=","
                                                                groupSeparator="."
                                                                className="form-control border-radius upah-upah"
                                                                id="k_potongan_amount"
                                                                value={data.k_potongan_amount}
                                                                placeholder="Nominal potongan"
                                                                onValueChange={(value, name) => {
                                                                    let newData = this.props.rows
                                                                    newData[id].k_potongan_amount = value
                                                                    this.setState({
                                                                        k_potongan_amount: value,
                                                                        data: newData
                                                                    })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">

                                                    </Col>
                                                </Row>
                                            )})}
                                    </Col>
                                </Row>
                                <Row form className="text-center col-md-12 mt-3 mb-3" style={{alignItems: "center", justifyContent: "center"}}>
                                    <Button
                                        onClick={()=>{this.props.handleSubmitPotongan(this.props.id)}}
                                        color="success"
                                        className="btn-sm"
                                    >
                                        Save Potongan
                                    </Button>
                                </Row>
                            </Col>
                        </div>
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}

export default  GoogleApiWrapper({
    apiKey: APIKEY
}) (ModalPotongan)
