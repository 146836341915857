import React, {Component, Fragment} from 'react'
import User_menu from './user-menu';
import {Link} from 'react-router-dom';
import {AlignLeft, MoreHorizontal} from 'react-feather';
//images
import logo from '../../../assets/images/dashboard/logo-header.png'

export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            sidebar: true,
            rightSidebar: true,
            navMenus: false,
            cs_wa: '',
            grup_telegram: '',
        }
    }
    toggle() {
        this.setState(prevState => ({
            navMenus: !prevState.navMenus
        }));
    }
    showRightSidebar = () => {
        if (this.state.rightSidebar) {
            this.setState({ rightSidebar: false })
            document.querySelector(".right-sidebar").classList.add('show');
        } else {
            this.setState({ rightSidebar: true })
            document.querySelector(".right-sidebar").classList.remove('show');
        }
    }
    goFull = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    openCloseSidebar = () => {
        const headerElement = document.querySelector(".page-main-header");
        if (headerElement.classList.contains('open')) {
            document.querySelector(".page-main-header").classList.remove('open');
        } else {
            document.querySelector(".page-main-header").classList.add('open');
        }

        const sidebarElement = document.querySelector(".page-sidebar");
        if (sidebarElement.classList.contains('open')) {
            document.querySelector(".page-sidebar").classList.remove('open');
        } else {
            document.querySelector(".page-sidebar").classList.add('open');
        }
    }

    loadDataHeader() {
        this.setState({loading: true})
        let form = {}
        this.props.request("get_settings", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        cs_wa: response.cs_wa,
                        grup_telegram: response.grup_telegram,
                    })
                }
                this.setState({loading: false})
            });

    }

    componentDidMount() {
        if (window.innerWidth <= 768) {
            this.openCloseSidebar()
        }
        this.loadDataHeader()
    }

    render() {
        return (
            <Fragment>
                {/* open */}
                <div className="page-main-header ">
                    <div className="main-header-right row">
                        <div className="main-header-left d-lg-none" >
                            <div className="logo-wrapper">
                                <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                                    <img className="blur-up lazyloaded" src={logo} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="mobile-sidebar">
                            <div className="media-body text-right switch-sm">
                                <label className="switch"><a onClick={this.openCloseSidebar}><AlignLeft /></a></label>
                            </div>
                        </div>
                        <div className="nav-right col">
                            <ul className={"nav-menus " + (this.state.navMenus ? 'open' : '')}>
                                {!this.state.loading &&
                                    <div>
                                        <a className="btn btn-outline-custom mr-2 btn-social" target="_blank" href="https://www.youtube.com/channel/UCJQ6Ade-KEbxNSjH-drwlMw"><i className="fa fa-brands fa-youtube text-danger"></i> Tutorial Youtube <i className="fa fa-external-link"></i></a>
                                        <a className="btn btn-outline-custom mr-2 btn-social" href={this.state.cs_wa}><i className="fa fa-whatsapp text-success"></i> Customer Service <i className="fa fa-external-link"></i></a>
                                        <a className="btn btn-outline-custom mr-2 btn-social" href={this.state.grup_telegram}><i className="fa fa-telegram text-primary"></i> Grup Telegram <i className="fa fa-external-link"></i></a>
                                    </div>
                                }
                                <User_menu request={this.props.request}/>
                            </ul>
                            <div className="d-lg-none mobile-toggle pull-right" onClick={() => this.toggle()}><MoreHorizontal /></div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Header
