import React, { Component } from 'react';

import ModalProgres from './modalProgres';

import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";

import WarningIcon from '../../assets/icons/warning-icon.svg';
import ArrowRightWhite from '../../assets/icons/arrow-right-white.svg';

class ProgresGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ModalProgres: this.props.progresModal,
            shift: this.props.shift,
            lokasi: this.props.lokasi,
            divisi: this.props.divisi,
            karyawan: this.props.karyawan,
        };
    }

    render() {
        let progres_value_text = 0;
        if (this.props.user.perusahaan.perusahaan_nama !== "") {
            progres_value_text += 1
        }
        if (this.props.shift.length !== 0) {
            progres_value_text += 1
        }
        if (this.props.divisi.length !== 0) {
            progres_value_text += 1
        }
        if (this.props.karyawan.length !== 0) {
            progres_value_text += 1
        }
        if (this.props.lokasi.length !== 0) {
            progres_value_text += 1
        }

        return (
            <div className='container-fluid pt-3 px-0'>
                <div className="d-flex flex-row warning-info px-2 mb-2 flex-fill border" style={{ borderRadius: "8px" }}>
                    <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                    <div className='px-0 d-flex flex-column flex-fill'>
                        <div style={{ fontSize: "16px" }}>
                            Silahkan lengkapi pengisian data dasar aplikasi Presensi
                        </div>
                        <div style={{ fontSize: "14px" }}>
                            {progres_value_text} dari 5 data telah dilengkapi
                        </div>
                    </div>
                    <div className='flex-fill'>
                        <button className='btn btn-primary text-capitalize pull-right py-2 px-3' onClick={() => {
                            this.props.loadCheck()
                            this.props.setState({ progresModal: true })
                        }} >Lihat Data <img alt='' src={ArrowRightWhite} /></button>
                    </div>
                </div>
                <ModalProgres
                    set_auth={this.props.set_auth}
                    api_token={this.props.api_token}
                    history={this.props.history}
                    request={this.props.request}
                    modalProgres={this.props.progresModal}
                    progresValue={progres_value_text}
                    setState={(nameState, value) => this.props.setState(nameState, value)}
                    lokasi={this.props.lokasi}
                    divisi={this.props.divisi}
                    shift={this.props.shift}
                    karyawan={this.props.karyawan}
                    perusahaan={this.props.user.perusahaan}
                />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgresGuide);