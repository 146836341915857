import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../../components/paging";
import { Empty, Spin } from 'antd';
import AddUser from "./add";
import Edit from "./edit";
import EditPassword from "./editpassword";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../../functions/functionGeneral";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import moment from "moment/moment";
import UpdateFiturModal from "../../common/update_fitur_modal";
import dataVersion from "../../../assets/json/version.json";
import dataUserLoginVersion from "../../../assets/json/userloginversion.json";

export class UserPerusahaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataPrimary: '',
            idDel: '',
            isLoading: true,
            loading: true,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            p_user_name: '',
            p_user_id: '',
            p_user_email: '',
            p_user_wa: '',
            user_name: '',
            add_visible: false,
            visable: false,
            edit_pw_visible: false,
            guide: false,
            updateFiturModal: true,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData();
        });
    }

    resetFilter(event) {
        this.setState({
            user_name: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }


    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true,
                        loading: true,
                        data: []
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("delete_user", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    loading: true,
                                    data: []
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            user_name: this.state.user_name,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("user_list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        dataPrimary: response.primary,
                        isLoading: false,
                        loading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }

    loadDataLevel() {
        let form = {
            paginate: 9999,
            page: this.state.currentPage + 1,
        }
        this.props.request("userlevel/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataLevel: response.data.data,
                    })
                }
            });
    }

    checkUpdateFiturBanner(){
        const userloginVs = localStorage.getItem("userloginVs");
        this.setState({
            updateFiturModal: true,
            updateFiturText: dataUserLoginVersion[dataVersion["userloginversion"]]
        })
        if(userloginVs){
            if(parseFloat(userloginVs) >= parseFloat(dataVersion["userloginversion"])){
                this.setState({
                    updateFiturModal: false,
                })
            }
        }
    }

    componentDidMount() {
        this.loadData()
        this.loadDataLevel()
        this.checkUpdateFiturBanner()
    }

    render() {
        let no = 0;

        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <div>
                    {!this.state.isLoading &&
                        <Fragment>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>Daftar User - Presensi</title>
                                <meta name="description" content="Detail User" />
                            </Helmet>
                            <UpdateFiturModal
                                version={dataUserLoginVersion}
                                showModal={this.state.updateFiturModal}
                                modalText={this.state.updateFiturText}
                                setState={(val, callback) => this.setState(val, callback)}
                                handleClose={() => {this.setState({updateFiturModal: false})}}
                                handlerCheckBoxChanges={() => {
                                    let userloginVs = localStorage.getItem("userloginVs")
                                    if(userloginVs) {
                                        if(parseFloat(userloginVs) >= parseFloat(dataVersion["userloginversion"])) {
                                            localStorage.removeItem("userloginVs");
                                        }else {
                                            localStorage.setItem("userloginVs", dataVersion["userloginversion"])
                                        }
                                    }else {
                                        localStorage.setItem("userloginVs", dataVersion["userloginversion"])
                                    }
                                }}
                            />
                            <Edit
                                data={this.state}
                                request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                changeState={(state, callback) => {
                                    this.setState(state, () => {
                                        if (typeof callback != 'undefined')
                                            callback()
                                    })
                                }}
                                loadData={() => this.loadData()}
                            />
                            <EditPassword
                                data={this.state}
                                request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                changeState={(state, callback) => {
                                    this.setState(state, () => {
                                        if (typeof callback != 'undefined')
                                            callback()
                                    })
                                }}
                                loadData={() => this.loadData()}
                            />
                            <AddUser
                                data={this.state}
                                add_visible={this.state.add_visible}
                                request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                changeState={(state, callback) => {
                                    this.setState(state, () => {
                                        if (typeof callback != 'undefined')
                                            callback()
                                    })
                                }}
                                loadData={() => this.loadData()}
                            />

                            <Breadcrumb
                                parent="Manajemen User"
                                title="Daftar User"
                                setGuide={() => { this.setState({ guide: true }) }}
                                showUpdateFiturBanner={() => {
                                    this.setState({
                                        updateFiturModal: true,
                                        updateFiturText: dataUserLoginVersion[dataVersion["userloginversion"]]
                                    })
                                }}
                            />
                            <Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card profile-card">
                                            <div className="row filter-karyawan" style={{marginLeft: '15px', marginTop: '25px'}}>
                                                <div className="col-3">
                                                    <label style={{fontSize: '10px'}}>Filter Nama:</label><br/>
                                                    <input name="user_name"
                                                           className="form-control"
                                                           placeholder="Nama User..."
                                                           type="text"
                                                           value={this.state.user_name}
                                                           onChange={this.handleChange}
                                                           onKeyUp={(event) => {
                                                               event.preventDefault()
                                                               if (event.which == 13) {

                                                                   this.setState({
                                                                       page: 0,
                                                                       paging: this.state.paging,
                                                                       loading: true
                                                                   }, () => {
                                                                       this.loadData()
                                                                   });
                                                               }
                                                           }}/>
                                                </div>
                                                <div className="col-2">
                                                    <label style={{fontSize: '10px'}}>Atur Ulang Filter:</label><br/>
                                                    <button className="btn btn-danger" onClick={this.resetFilter}><span
                                                        className="fa fa-refresh"></span> Atur Ulang
                                                    </button>
                                                </div>
                                                <div className="col-7" style={{marginTop: '27px', paddingRight: "50px"}}>
                                                    <a className="btn btn-primary tambah-user"
                                                       style={{float: "right", color: "white"}} onClick={() => {
                                                        this.setState({
                                                            add_visible: true
                                                        })
                                                    }}><i className={"fa fa-plus"}></i> Tambah</a>
                                                </div>
                                            </div>
                                            <div className="card-body daftar-shift">
                                                {!this.state.loading && this.state.data.length === 0 ?
                                                    <Empty
                                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                        imageStyle={{height: 60}}
                                                        description={<span>Data Kosong!</span>}
                                                    />
                                                    :
                                                    <div>
                                                        <Table className="table table-striped mb-5">
                                                            <Thead>
                                                                <Tr>
                                                                    <Th style={{ border: "none" }} width="30">#</Th>
                                                                    <Th style={{ border: "none" }} className="text-left" width="250">User</Th>
                                                                    <Th style={{ border: "none" }} className="text-center">User WA</Th>
                                                                    <Th style={{ border: "none" }} className="text-center">User Level</Th>
                                                                    <Th style={{ border: "none" }} className="text-center">Terakhir Login</Th>
                                                                    <Th style={{ border: "none" }} className="text-center">Status</Th>
                                                                    <Th style={{ border: "none" }} className="text-center">OTP Login</Th>
                                                                    <Th style={{ border: "none" }} className="text-center">Aksi</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {this.state.loading &&
                                                                    <Tr>
                                                                        <Td colSpan={10}>
                                                                            <Spin size="large" tip="Loading..." style={{
                                                                                width: '100%',
                                                                                margin: 'auto',
                                                                                marginBottom: '10px',
                                                                                marginTop: '10px'
                                                                            }} />
                                                                        </Td>
                                                                    </Tr>
                                                                }
                                                                {this.state.data.map((item, index) => {
                                                                    no += 1
                                                                    return (
                                                                        <Tr>
                                                                            <Td style={{border: "none", fontSize: "14px", color: "#143B5E"}} scope="row"><b>{pageNumber(no, this.state.perPage, this.state.currentPage)}</b></Td>
                                                                            <Td style={{border: "none"}}>
                                                                                <div>
                                                                                    <p className={'text-muted fs-5'}
                                                                                       style={{
                                                                                           fontSize: "14px",
                                                                                           color: "#248CE7",
                                                                                           lineHeight: "24px",
                                                                                           fontWeight: 400
                                                                                       }}><b>{item.p_user_name}</b></p>
                                                                                </div>
                                                                                <div>
                                                                                    <p className={'text-muted fs-5'}
                                                                                       style={{
                                                                                           fontSize: "12px",
                                                                                           lineHeight: "24px",
                                                                                           fontWeight: 400
                                                                                       }}>{item.p_user_email}</p>
                                                                                </div>
                                                                            </Td>
                                                                            <Td style={{border: "none"}} align={"center"}>
                                                                                <span style={{fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#143B5E"}}>
                                                                                    {item.p_user_wa ? item.p_user_wa : '-'}
                                                                                </span>
                                                                            </Td>
                                                                            <Td style={{border: "none"}} align={"center"}>
                                                                                <span style={{fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#143B5E"}}>
                                                                                    {item.user_level ? item.user_level.level_name : "-"}
                                                                                </span>
                                                                            </Td>
                                                                            <Td style={{border: "none"}} align="center">
                                                                                <div>
                                                                                    <span style={{fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#143B5E"}}>
                                                                                        {
                                                                                            item.api_token_update ?
                                                                                            (moment(item.api_token_update).add(this.props.user.perusahaan.perusahaan_timezone === 'wib' ? 0 : this.props.user.perusahaan.perusahaan_timezone === 'wita' ? 1 : 2, 'hours').format('HH:mm:ss'))
                                                                                            : "-"
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div>
                                                                                    <span style={{fontWeight: 400, fontSize: "14px", lineHeight: "24px", color: "#143B5E"}}>
                                                                                        {
                                                                                            item.api_token_update ?
                                                                                            (moment(item.api_token_update).add(this.props.user.perusahaan.perusahaan_timezone === 'wib' ? 0 : this.props.user.perusahaan.perusahaan_timezone === 'wita' ? 1 : 2, 'hours').format('LL'))
                                                                                            : "-"
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </Td>
                                                                            <Td style={{border: "none"}} align={"center"}>
                                                                                <Switch
                                                                                    id={`detail-${item.p_user_id}`}
                                                                                    name="p_user_status"
                                                                                    checked={
                                                                                        item.p_user_status === '1' ? true : false
                                                                                    }
                                                                                    onChange={(val) => {
                                                                                        this.setState({
                                                                                            loading: true,
                                                                                        })

                                                                                        let formData = {
                                                                                            id: item.p_user_id,
                                                                                        }

                                                                                        this.props.request('ubah_status', formData, 'POST')
                                                                                            .then((response) => {
                                                                                                if (response.success) {
                                                                                                    Swal.fire({
                                                                                                        title: 'Berhasil!',
                                                                                                        text: response.message,
                                                                                                        icon: 'success',
                                                                                                        showConfirmButton: false,
                                                                                                        timer: 1500,
                                                                                                    })
                                                                                                } else {
                                                                                                    if (Array.isArray(response.message)) {
                                                                                                        response.message.map(item => {
                                                                                                            toast['error'](item)
                                                                                                            return true
                                                                                                        })
                                                                                                    } else {
                                                                                                        toast['error'](response.message)
                                                                                                    }
                                                                                                }
                                                                                                this.loadData()
                                                                                                this.setState({ loading: false })
                                                                                            })

                                                                                    }}
                                                                                    onColor="#1890ff"
                                                                                    offColor="#eb4034"
                                                                                    handleDiameter={16}
                                                                                    uncheckedIcon={
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                                height: "100%",
                                                                                                fontSize: 3,
                                                                                                color: "white",
                                                                                                paddingRight: -5
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faTimes}
                                                                                                style={{ fontSize: 15 }} />
                                                                                        </div>
                                                                                    }
                                                                                    checkedIcon={
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                                height: "100%",
                                                                                                fontSize: 4,
                                                                                                color: "white",
                                                                                                paddingLeft: 3
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faCheck}
                                                                                                style={{ fontSize: 15 }} />
                                                                                        </div>
                                                                                    }
                                                                                    boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                                    height={18}
                                                                                    width={40}
                                                                                    className="mr-1"
                                                                                />
                                                                            </Td>
                                                                            <Td style={{border: "none"}} align={"center"}>
                                                                                <Switch
                                                                                    id={`detail-${item.p_user_id}`}
                                                                                    name="p_user_status"
                                                                                    checked={
                                                                                        item.p_user_otp_status === '1' ? true : false
                                                                                    }
                                                                                    onChange={(val) => {
                                                                                        this.setState({
                                                                                            loading: true,
                                                                                        })

                                                                                        let formData = {
                                                                                            id: item.p_user_id,
                                                                                        }

                                                                                        this.props.request('ubah_status_otp', formData, 'POST')
                                                                                            .then((response) => {
                                                                                                if (response.success) {
                                                                                                    Swal.fire({
                                                                                                        title: 'Berhasil!',
                                                                                                        text: response.message,
                                                                                                        icon: 'success',
                                                                                                        showConfirmButton: false,
                                                                                                        timer: 1500,
                                                                                                    })
                                                                                                } else {
                                                                                                    if (Array.isArray(response.message)) {
                                                                                                        response.message.map(item => {
                                                                                                            toast['error'](item)
                                                                                                            return true
                                                                                                        })
                                                                                                    } else {
                                                                                                        toast['error'](response.message)
                                                                                                    }
                                                                                                }
                                                                                                this.loadData()
                                                                                                this.setState({ loading: false })
                                                                                            })

                                                                                    }}
                                                                                    onColor="#1890ff"
                                                                                    offColor="#eb4034"
                                                                                    handleDiameter={16}
                                                                                    uncheckedIcon={
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                                height: "100%",
                                                                                                fontSize: 3,
                                                                                                color: "white",
                                                                                                paddingRight: -5
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faTimes}
                                                                                                style={{ fontSize: 15 }} />
                                                                                        </div>
                                                                                    }
                                                                                    checkedIcon={
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                                height: "100%",
                                                                                                fontSize: 4,
                                                                                                color: "white",
                                                                                                paddingLeft: 3
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faCheck}
                                                                                                style={{ fontSize: 15 }} />
                                                                                        </div>
                                                                                    }
                                                                                    boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                                    height={18}
                                                                                    width={40}
                                                                                    className="mr-1"
                                                                                />
                                                                            </Td>
                                                                            <Td style={{border: "none"}} align={"center"}>
                                                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                    <>
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                p_user_id: item.p_user_id,
                                                                                                p_user_name: item.p_user_name,
                                                                                                p_user_email: item.p_user_email,
                                                                                                p_user_wa: item.p_user_wa ? item.p_user_wa : "",
                                                                                                p_user_level: item.user_level ? item.user_level.level_id : "",
                                                                                                visable: true
                                                                                            })
                                                                                        }}>
                                                                                        <i className="fa fa-pencil"
                                                                                            style={{
                                                                                                width: 35,
                                                                                                fontSize: 20,
                                                                                                padding: 11,
                                                                                                color: 'rgb(255 172 21)'
                                                                                            }}></i></a>
                                                                                        {this.props.user.p_user_primary == "1" ? (
                                                                                                <a onClick={() => {
                                                                                                    this.setState({
                                                                                                        p_user_id: item.p_user_id,
                                                                                                        edit_pw_visible: true
                                                                                                    })
                                                                                                }}>
                                                                                                    <i className="fa fa-key"
                                                                                                       style={{
                                                                                                           width: 35,
                                                                                                           fontSize: 20,
                                                                                                           padding: 11,
                                                                                                           color: 'rgba(20,59,94,0.78)'
                                                                                                       }}></i></a>
                                                                                        ) : null }
                                                                                    </>
                                                                                )}
                                                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                                <a onClick={() => {
                                                                                    this.setState({
                                                                                        idDel: item.p_user_id
                                                                                    })
                                                                                    this.wantDelete()
                                                                                }}><i className="fa fa-trash" style={{
                                                                                    width: 35,
                                                                                    fontSize: 20,
                                                                                    padding: 11,
                                                                                    color: 'rgb(255 37 37)'
                                                                                }}
                                                                                ></i></a>
                                                                                )}
                                                                            </Td>
                                                                        </Tr>
                                                                    )
                                                                })}
                                                                {this.state.isLoading &&
                                                                    <Tr>
                                                                        <Td colSpan="3">
                                                                            <Spin style={{ width: '100%' }} size="small" tip="Mohon Tunggu..." />
                                                                        </Td>
                                                                    </Tr>
                                                                }
                                                            </Tbody>
                                                        </Table>
                                                        <Paging
                                                            className="float-right"
                                                            handlePageClick={this.handlePageClick}
                                                            pageCount={this.state.pageCount}
                                                            currentPage={this.state.currentPage}
                                                        />
                                                    </div>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Fragment>
                    }
                </div>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    api_token: state.Auth.api_token,
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, auth_key, data) => dispatch(set_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPerusahaan);
