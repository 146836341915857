export const hitungBiayaAdmin = (type, value) =>  {
    switch (type){
        case "credit_card":
            return (0.029 * value) + 2000;
        case "echannel":
        case "bank_transfer":
            return 4000;
        case "shopeepay":
            return 0.02 * value;
        case "indomaret":
            return 6600;
        case "alfamart":
            return 5000;
        case "akulaku":
            return 0.017 * value;
        case "qris":
        case "other_qris":
            return 0.007 * value;
        default:
            return 0;
    }
}
