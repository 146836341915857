import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import "./style.css";
import CheckSvg from '../../../assets/icons/Check.svg';
import Arrowleft from '../../../assets/icons/arrow-left.svg';
import Ticket from '../../../assets/icons/ticket.svg';
import Rekomendasi from '../../../assets/icons/rekomendasi.svg';
import WhatsappIcon from '../../../assets/icons/whatsapp.svg';
import CheckSvgBlue from '../../../assets/icons/Check Blue.svg';
import Swal from "sweetalert2";
import CurrencyFormat from 'react-currency-format'

class PilihPaket extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paket: [],
            paket_id: 0,
            paket_month: 0,
            paket_harga: 0,
            list_paket: false
        }
    }

    loadData() {
        let form = {}
        this.props.request("paket/first", form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        paket: response.data,
                    })
                    if (Object.keys(this.props.paket_selected).length === 0) {
                        this.props.setState({
                            paket_selected: response.data[0]
                        })
                    }
                }
            })
    }

    prosesPaket = () => {
        this.setState({
            disabledButton: true
        })
        let form = {
            paket_id: this.state.paket_id,
            prosespaket: true
        }
        this.props.request("register_v2", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.setState({
                        paket_id: this.state.paket_id,
                        paketMonth: this.state.paket_month,
                        stepHeader: 1
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }

    componentDidMount() {
        this.loadData()
    }
    render() {
        let page;
        if (this.state.list_paket === true) {
            page =
                <div className='row'>
                    <div className='col'></div>
                    <div className='col-md-11'>
                        <div className='row mb-4'>
                            <div className='col'>
                                <button className='btn text-capitalize px-3 back-btn-list-paket d-flex align-items-center' onClick={() => this.setState({ list_paket: false }, () => this.props.setState({ hideStep: false }))}><img src={Arrowleft} style={{ color: "#143B5E", height: "20px", width: "20px", paddingRight: "5px" }} /> Kembali</button>
                            </div>
                        </div>
                        <div className='row'>
                            {this.props.paket.slice(0, 8).map((item, index) => {
                                return (
                                    <div className='col-md-3 d-flex align-items-stretch'>
                                        <div className='card border pb-2' style={{ width: "100%" }}>
                                            <div className='card-header border-bottom pb-0' style={{ backgroundColor: "#F9F9F9", padding: "14px", minHeight: "22%" }}>
                                                <div className='row'>
                                                    <div className='col-2' style={{ paddingLeft: "14px", paddingRight: "14px" }}><img src={Ticket} /></div>
                                                    <div className='col' style={{ fontSize: "12px", paddingRight: "14px", paddingLeft: "12px" }}><b style={{ color: "#252B2B" }}>{item.paket_nama}</b> {item.paket_harga === 0 ? <img src={Rekomendasi} style={{ height: "18px" }} /> : ""}<br /><p style={{ fontSize: "12px", marginBottom: "0px", color: "#5D6F80" }}>{item.paket_short_desc}</p></div>
                                                </div>
                                            </div>
                                            <div className='card-body px-3 pt-2'>
                                                <div className='row px-3 mb-3 d-flex align-items-end justify-content-start'>
                                                    <div className='col-auto px-0' style={{ fontSize: "24px", fontWeight: 700 }}>
                                                        <CurrencyFormat value={item.paket_harga} displayType={'text'} thousandSeparator={'.'} decimalSeparator={","} prefix={'Rp '} />
                                                    </div>
                                                    <div className='col-auto px-0'>
                                                        <p>/ bulan</p>
                                                    </div>
                                                </div>
                                                <div className='row mb-2'>
                                                    <div className='col'>
                                                        <button style={{ borderRadius: "8px", paddingTop: "12px", paddingBottom: "12px" }} onClick={() => this.setState({ list_paket: false }, () => this.props.setState({ paket_selected: item, hideStep: false, selected_new_paket: true }))} className={item.paket_harga === 0 ? "btn btn-primary btn-block text-capitalize" : "btn btn-block back-btn-pilih-paket text-capitalize"}>pilih paket</button>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <ul className="list-group">
                                                            <li className="list-group-item border-0 px-0 mb-2" style={{ fontSize: "12px", color: "#B6B6B6" }}>APA YANG ANDA PEROLEH</li>
                                                            {item.paket_harga === 0 ?
                                                                <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                    <div className='row flex-nowrap'>
                                                                        <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                        <div className='col'> Gratis! selama {item.paket_bulan} Bulan</div>
                                                                    </div>
                                                                </li>
                                                                : ""
                                                            }
                                                            {item.paket_premium === '1' ?
                                                                <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                    <div className='row flex-nowrap'>
                                                                        <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                        <div className='col'> Akses Fitur Premium</div>
                                                                    </div>
                                                                </li>
                                                                : ""
                                                            }
                                                            <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                <div className='row flex-nowrap'>
                                                                    <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                    <div className='col'>Maksimal {item.paket_jumlah} karyawan</div>
                                                                </div>
                                                            </li>
                                                            <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                <div className='row flex-nowrap'>
                                                                    <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                    <div className='col w-75'>{item.paket_desc}</div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {/* paket custom, jangan dihapus */}

                        {/* <div className='row'>
                            <div className='col-md-5'>
                                <div className='card border'>
                                    <div className='card-header py-3' style={{backgroundColor: "#143B5E", fontSize: "20px", fontWeight: 700, color: "#FFFFFF"}}>Paket Custom</div>
                                    <div className='card-body px-3 pt-2 pb-4'>
                                        <div className='row'>
                                            <div className='col'>
                                                <ul className='list-group'>
                                                    <li className='list-group-item border-0 px-0 py-1' style={{fontSize: "10px", color: "#5D6F80"}}>APA ITU PAKET CUSTOM?</li>
                                                    <li className='list-group-item border-0 px-0 py-1' style={{fontSize: "14px", fontWeight: 400, lineHeight: "24px"}}>
                                                        Kami menawarkan skema harga yang dapat disesuaikan dengan jumlah karyawan yang ada di perusahaan Anda.
                                                    </li>
                                                    <li className='list-group-item border-0 px-0 pb-1 pt-4'>
                                                        <a href='https://wa.me/6285700102030' className='btn btn-primary'><img src={WhatsappIcon} className={{paddingTop: "8px", paddingRight: "14px", paddingBottom: "8px", paddingLeft: "14px"}}/> Hubungi Kami</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col'></div>
                            <div className='col'></div>
                        </div> */}
                    </div>
                    <div className='col'></div>
                </div>
        } else {
            page =
                <div className='row'>
                    <div className='col'></div>
                    <div className='col-md-4 px-4'>
                        <div className='row'>
                            <div className='card border' style={{ color: "#143B5E" }}>
                                <div className='card-header py-2 px-4' style={{ backgroundColor: "#FAFDFF" }}>
                                    <div style={{ fontSize: "18px", fontWeight: 700, color: "#143B5E", lineHeight: "27px", letterSpacing: "0px" }}>Pilih Paket</div>
                                </div>
                                <div className='card-body pb-0 pt-2'>
                                    <div className='row' style={{ padding: "0px 10px" }}>
                                        <div className='col px-0'>
                                            {this.state.paket.length === 0 ?
                                                <div className='mb-2'>Paket Kosong</div> :
                                                this.props.selected_new_paket === true ?
                                                    <label>
                                                        <input onClick={() => { this.setState({ paket_selected: this.state.paket_selected }) }} type="radio" name="product" checked={this.props.paket_selected.paket_id === this.props.paket_selected.paket_id} className="card-input-element" />

                                                        <div className='card card-default card-input my-0' style={{ borderRadius: "10px", boxShadow: "none" }}>
                                                            <div className="card-body py-3 container">
                                                                <div className='row flex-nowrap'>
                                                                    <div className='col-2' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        <img src={CheckSvgBlue} alt="check icon" style={{ width: 24, height: 24 }} color="#057CE4" />
                                                                    </div>
                                                                    <div className='col' style={{ color: "#143B5E" }}>
                                                                        <div className='card-title' style={{ fontSize: "14px", fontWeight: 700 }}>{this.props.paket_selected.paket_nama}</div>
                                                                        <div className='card-text'><p style={{ fontSize: "12px" }}>{this.props.paket_selected.paket_desc}</p></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    :
                                                    this.state.paket.map((item, index) => {
                                                        return (
                                                            <label style={{ marginTop: "0px", marginBottom: "0px" }}>
                                                                <input onClick={() => { this.props.setState({ paket_selected: this.state.paket[index] }) }} type="radio" name="product" checked={this.props.paket_selected.paket_id === item.paket_id} className="card-input-element" />

                                                                <div className={this.props.paket_selected.paket_id === item.paket_id ? 'card card-default card-input mx-0 bg-selected-card' : 'card card-default card-input border mx-0 mt-0'} style={{ borderRadius: "10px", boxShadow: "none" }}>
                                                                    <div className="card-body py-3 container">
                                                                        <div className='row flex-nowrap'>
                                                                            <div className='col-2' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <img src={this.props.paket_selected.paket_id === item.paket_id ? CheckSvgBlue : CheckSvg} alt="check icon" style={{ width: 24, height: 24 }} color={this.props.paket_selected.paket_id === item.paket_id ? "#057CE4" : "grey"} />
                                                                            </div>
                                                                            <div className='col' style={{ color: "#143B5E" }}>
                                                                                <div className='card-title my-1' style={{ fontSize: "14px", fontWeight: 700 }}>{item.paket_nama}</div>
                                                                                <div className='card-text my-1'><p style={{ fontSize: "12px", lineHeight: "15px" }}>{item.paket_desc}</p></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        )
                                                    })
                                            }
                                            <div className='btn btn-block py-3 px-3 rounded text-capitalize text-left' onClick={() => {
                                                this.props.setState({ hideStep: true }, () => this.setState({ list_paket: true }))
                                            }} style={{ backgroundColor: "#F5F5F5", fontSize: "14px", fontWeight: 700, color: "#143B5E" }}>
                                                Lihat Semua Paket
                                                <FontAwesomeIcon icon={faArrowRight} className="pull-right pt-1" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row' style={{ padding: "0px 10px" }}>
                                        <div className='col px-0'>
                                            <div className='card' style={{ width: "100%", boxShadow: "none" }}>
                                                <div className='card-body px-0 py-0' style={{ fontSize: "14px", backgroundColor: "transparant", marginTop: "14px", marginBottom: "26px" }}>
                                                    <hr className='dashed-line mb-4' />
                                                    <div className='row mb-1 px-3'>
                                                        <div className='col-md-6 px-0' style={{ fontWeight: 400 }}><p>{this.props.paket_selected.paket_nama}</p></div>
                                                        <div className='col px-0' style={{ textAlign: "right", color: "#143B5E", fontWeight: 500 }}><CurrencyFormat value={this.props.paket_selected.paket_harga} displayType={'text'} thousandSeparator={'.'} decimalSeparator={","} prefix={'Rp '} /></div>
                                                    </div>
                                                    <div className='row mb-1 px-3'>
                                                        <div className='col-md-6 px-0' style={{ fontWeight: 400 }}><p>Pajak</p></div>
                                                        <div className='col px-0' style={{ textAlign: "right", color: "#143B5E", fontWeight: 500 }}>Rp 0</div>
                                                    </div>
                                                    <hr className='dashed-line mt-4' />
                                                    <div className='row mb-1 px-3'>
                                                        <div className='col-md-6 px-0' style={{ fontWeight: 700, color: "#143B5E" }}>Total</div>
                                                        <div className='col px-0' style={{ textAlign: "right", color: "#143B5E", fontWeight: 500 }}><CurrencyFormat value={this.props.paket_selected.paket_harga + 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={","} prefix={'Rp '} /></div>
                                                    </div>
                                                </div>
                                                <div align="center">
                                                    <button className='btn btn-primary btn-block text-capitalize' onClick={() => this.setState({ paket_id: this.props.paket_selected.paket_id, paket_month: this.props.paket_selected.paket_bulan }, () => this.prosesPaket())} style={{ fontSize: "14px", weight: "100px", fontWeight: 700, paddingTop: "12px", paddingBottom: "12px", borderRadius: "8px" }}>Daftar Akun Sekarang</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div style={{ textAlign: "center" }}>
                                <p>Dengan menekan “Daftar Sekarang” berarti Anda telah menyetujui Syarat dan Ketentuan serta Kebijakan Privasi presensi.co.id yang berlaku.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'></div>
                </div>
        }
        return (
            <main style={{ marginTop: "120px", marginBottom: '50px' }}>
                <div className='container'>
                    {page}
                </div>
            </main>
        )
    }
}

export default PilihPaket