import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import CKEditor from "react-ckeditor-component";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Helmet } from "react-helmet";
import { API_HOST } from "../../helper/host";
import { CloudUpload } from "@material-ui/icons";

export class Add_pengumuman extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            isLoading: true,
            judul: '',
            shortdesc: '',
            gambar: '',
            fileList: [],
            deskripsi: '',
        }
        this.handleChange = this.handleChange.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    onEditorStateChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            deskripsi: newContent
        })
    }

    simpanPengumuman = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            gambar: this.state.gambar,
            judul: this.state.judul,
            shortdesc: this.state.shortdesc,
            deskripsi: this.state.deskripsi,
        }

        this.props.request("pengumuman/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.props.history.push('/pengumuman')
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    componentDidMount() {
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Tambah Pengumuman - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <Breadcrumb title="Tambah Pengumuman" parent="Pengumuman" parentLink="/pengumuman" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="needs-validation user-add" noValidate="">
                                        <div>
                                            <div className="pull-right">
                                                {this.state.prosesSimpan === false ?
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={this.simpanPengumuman}><i className="fa fa-floppy-o"></i> Simpan</button>
                                                    :
                                                    <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                                }
                                            </div>
                                            <h3>Detail Pengumuman</h3>
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold"><span>*</span> Gambar Pengumuman</label>
                                            <div className="col-lg-3 col-md-4 col-sm-12">
                                                <ImgCrop
                                                    width={2000}
                                                    height={1000}>
                                                    <Upload
                                                        listType="picture-card"
                                                        style={{ width: '400px !important' }}
                                                        fileList={this.state.fileList}
                                                        customRequest={({ file, onSuccess }) => {
                                                            setTimeout(() => {
                                                                onSuccess("ok");
                                                            }, 0);
                                                        }}
                                                        onChange={(file) => {
                                                            this.setState({ fileList: file.fileList })

                                                            if (file.fileList.length > 0) {
                                                                let files = file.file.originFileObj
                                                                let reader = new FileReader()

                                                                reader.readAsDataURL(files)
                                                                reader.onloadend = () => {
                                                                    this.setState({
                                                                        gambar: {
                                                                            name: file.file.name,
                                                                            file: reader.result
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                            else {
                                                                this.setState({ gambar: [] })
                                                            }
                                                        }}
                                                    >
                                                        {this.state.fileList.length >= 1 ? null :
                                                            <div className="p-2">
                                                                <span>
                                                                    <CloudUpload color="primary" fontSize="large" />
                                                                </span><br />
                                                                <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                            </div>
                                                        }
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold"><span>*</span> Judul Pengumuman</label>
                                            <div>
                                                <input name="judul" className="form-control" placeholder="Judul Pengumuman..."
                                                    type="text" required="" onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold"><span>*</span> Deskripsi Singkat Pengumuman</label>
                                            <div>
                                                <input name="shortdesc" className="form-control" placeholder="Deksripsi Singkat Pengumuman..."
                                                    type="text" required="" onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold"><span>*</span> Deskripsi Pengumuman</label>
                                            <div>
                                                <CKEditor
                                                    content={this.state.deskripsi}
                                                    events={{
                                                        change: this.onEditorStateChange
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Add_pengumuman
