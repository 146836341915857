import React, { Component, Fragment } from 'react';
import {Empty, Modal, Spin, Tooltip, Upload} from 'antd';
import {Modal as ModalRs, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import moment from "moment";
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { connect } from "react-redux";
import { pageNumber } from "../../functions/functionGeneral";
import {API_HOST, APIKEY, BACKEND_API_HOST, IMG_HOST} from "../../helper/host";
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import ReactPaginate from "react-paginate";
import DocumentIcon from "../../assets/icons/document-text.svg";
import SearchIcon from "../../assets/icons/search.svg";
import CalendarIcon from "../../assets/icons/calendar-dark-blue.svg";
import ExportIcon from "../../assets/icons/download-white.svg"
import RefreshIcon from "../../assets/icons/refresh.svg";
import ElipsisIcon from "../../assets/icons/elipsis-horizontal.svg";
import EmptyPng from "../../assets/images/data-kosong.png";
import ArrowRight from '../../assets/icons/arrow-right.svg'
import ArrowLeft from '../../assets/icons/arrow-left.svg'
import plusIcon from "../../assets/icons/plus-white.svg"
import "./style.css";
import Select from "react-select";
import TimeInput from "../../helper/time_input";
import ImgCrop from "antd-img-crop";
import {CloudUpload} from "@material-ui/icons";
import UploadIcon from "../../assets/icons/import-icon-blue-light.svg";

export class List_kunjungan extends Component {
    constructor(props) {
        super(props);
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        var tanggal = new Date();
        this.state = {
            loading: true,
            loading_selected: false,
            karyawan: [],
            data: [],
            dataOutlet: [],
            idDel: '',
            keyword: "",
            modal: false,
            kunjunganStartDate: '',
            kunjunganEndDate: '',
            modalexport: false,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            guide: false,
            start: start,
            end: end,
            start_date: "",
            end_date: "",
            date: "",
            lat: "",
            lng: "",
            modalLampiran: false,
            lampiran1: null,
            lampiran2: null,
            lampiran3: null,
            lampiran1_split: null,
            lampiran2_split: null,
            lampiran3_split: null,
            totalPerpage: 0,
            dataDetail: {},
            modalAdd: false,
            karyawan_selected: null,
            jam_keluar: null,
            tanggal: tanggal,
            dateFilter: moment(),
            keterangan_kunjungan: "",
            fileSelfie: [],
            foto_selfie_karyawan: '',
            fileLampiran1: [],
            fileLampiran2: [],
            fileLampiran3: [],
            foto_lampiran1: "",
            foto_lampiran2: "",
            foto_lampiran3: "",
            fileList: [],
            karyawan_id: 0,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.exportLaporanKunjungan = this.exportLaporanKunjungan.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.searchKaryawan = this.searchKaryawan.bind(this)
        this.applyCallback = this.applyCallback.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    applyCallback(startDate, endDate) {
        this.setState({
            start: startDate,
            end: endDate,
            start_date: startDate.format("YYYY-MM-DD"),
            end_date: endDate.format("YYYY-MM-DD"),
        }, () => {
            this.setState({
                date: this.state.start_date + "-" + this.state.end_date
            })
            this.loadData()
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleOk = e => {
        this.setState({
            modalexport: false,
            modal: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalexport: false,
            modal: false,
        });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            modalexport: true,
            kunjunganStartDate: start,
            kunjunganEndDate: end
        }, () => {
            this.loadData()
        });
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter(event) {
        this.setState({
            keyword: '',
            kunjunganStartDate: '',
            kunjunganEndDate: '',
            data: [],
            loading: true,
            date: "",
            start_date: "",
            end_date: ""
        }, () => this.loadData())
    }


    exportLaporanKunjungan() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'outlet/export?token=' + AuthKey + '&kunjunganStartDate=' + this.state.kunjunganStartDate + '&kunjunganEndDate=' + this.state.kunjunganEndDate, "_blank")
    }

    searchKaryawan() {
        let form = {
            karyawan_name: this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false,
                        loading_selected: false
                    })
                }
            })
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Kunjungan?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        loading: true,
                        data: [],
                    })
                    this.props.request("outlet/delete_kunjungan", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
            keyword: this.state.keyword,
            kunjunganStartDate: this.state.start_date,
            kunjunganEndDate: this.state.end_date,
        }
        this.props.request("outlet/list_kunjungan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                        totalData: response.data.total,
                        totalPerpage: response.data.to,
                    })
                }
            });
    }

    simpanKunjungan = () => {
        this.setState({
            loading: true,
        })

        let form = {
            karyawan: this.state.karyawan_id,
            outlet_id: this.state.outlet_id,
            foto_selfie: this.state.foto_selfie_kunjungan,
            lampiran1: this.state.foto_lampiran1,
            lampiran2: this.state.foto_lampiran2,
            lampiran3: this.state.foto_lampiran3,
            keterangan: this.state.keterangan_kunjungan,
            jam: this.state.jam_kunjungan_absen,
            tanggal: this.state.tanggal
        }

        this.props.request("outlet/kunjungan_add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalAdd: false,
                                loading: true
                            }, () => this.loadData())
                        } else {
                            this.setState({
                                modalAdd: false,
                                karyawan_id: null,
                                karyawan_selected: null,
                                foto_selfie_kunjungan: null,
                                foto_lampiran1: null,
                                foto_lampiran2: null,
                                foto_lampiran3: null,
                                outlet_id: null,
                                keterangan_kunjungan: "",
                                jam_kunjungan_absen: "",
                                loading: true
                            }, () => this.loadData())
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const stylefoto = {
            maxHeight: '50px',
            maxWidth: '50px',
        }
        const steps = [
            {
                selector: '.tambah-kunjungan',
                content: 'Anda dapat menambah Kunjungan disini',
            },
            {
                selector: '.daftar-kunjungan',
                content: 'Daftar Kunjungan anda dapat dilihat disini',
            },
            {
                selector: '.filter-kunjungan',
                content: 'Anda dapat mem-Filter daftar Kunjungan anda lewat sini',
            },
        ];
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        let ranges = {
            "Today Only": [moment(start), moment(end)],
            "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
            "3 Days": [moment(start).subtract(3, "days"), moment(end)]
        }
        let local = {
            "format": "DD-MM-YYYY HH:mm",
            "sundayFirst": false
        }
        let maxDate = moment(start).add(24, "hour")

        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Kunjungan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Kunjungan - Presensi</title>
                        <meta name="description" content="Daftar Kunjungan" />
                    </Helmet>
                    <Modal
                        title="Export Absen"
                        visible={this.state.modalexport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <>
                            <button className={'btn btn-success mt-2 col-md-12'} onClick={this.exportLaporanKunjungan}>Export Laporan Kunjungan Karyawan</button>
                        </>
                    </Modal>
                    <ModalRs size='lg' isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modal: false })}>Detail Lokasi</ModalHeader>
                        <ModalBody>
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                height: '250px'
                            }}>
                                <Map google={this.props.google}
                                    initialCenter={{
                                        lat: this.state.lat,
                                        lng: this.state.lng
                                    }}
                                    zoom={15}>
                                    <Marker />
                                </Map>
                            </div>
                        </ModalBody>
                    </ModalRs>
                    <ModalRs size='sm' style={{ marginRight: "24px" }} isOpen={this.state.modalLampiran} toggle={() => this.setState({ modalLampiran: false })}>
                        <ModalHeader style={{ border: "none" }} toggle={() => this.setState({ modalLampiran: false })}><div style={{
                            fontSize: "18px",
                            fontWeight: 700,
                            lineHeight: "27px",
                            color: "#143B5E"
                        }}>Lampiran</div></ModalHeader>
                        <ModalBody>
                            <div className='row'>
                                <div className='col-md-12'>
                                    {this.state.lampiran1_split !== null && this.state.lampiran1 !== null && this.state.lampiran1 !== "" ?
                                        this.state.lampiran1_split[1] === "jpg" || this.state.lampiran1_split[1] === "jpeg" || this.state.lampiran1_split[1] === "png" || this.state.lampiran1_split[1] === "webp" ?
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran1} target={"_blank"}>
                                                <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran1} alt='' className='dk-img-lampiran' />
                                            </a>
                                            :
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran1} target={"_blank"}>
                                                <div style={{
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                    padding: "35px 44px 19px"
                                                }}>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center'>
                                                            <img alt='' src={DocumentIcon} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center' style={{
                                                            fontSize: "11px",
                                                            fontWeight: 500,
                                                            lineHeight: "17px",
                                                            color: "#5D6F80"
                                                        }}>{this.state.lampiran1}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        :
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='text-center' style={{
                                                    padding: "35px 44px 19px",
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                }}>
                                                    <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                    <div style={{
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        lineHeight: "17px",
                                                        color: "#5D6F80"
                                                    }}>Data Kosong</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12' style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: "#5D6F80"
                                }}>
                                    Lampiran 1
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    {this.state.lampiran2_split !== null && this.state.lampiran2 !== null && this.state.lampiran2 !== "" ?
                                        this.state.lampiran2_split[1] === "jpg" || this.state.lampiran2_split[1] === "jpeg" || this.state.lampiran2_split[1] === "png" || this.state.lampiran2_split[1] === "webp" ?
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran2} target={"_blank"}>
                                                <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran2} alt='' className='dk-img-lampiran' />
                                            </a>
                                            :
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran2} target={"_blank"}>
                                                <div style={{
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                    padding: "35px 44px 19px"
                                                }}>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center'>
                                                            <img alt='' src={DocumentIcon} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center' style={{
                                                            fontSize: "11px",
                                                            fontWeight: 500,
                                                            lineHeight: "17px",
                                                            color: "#5D6F80"
                                                        }}>{this.state.lampiran2}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        :
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='text-center' style={{
                                                    padding: "35px 44px 19px",
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                }}>
                                                    <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                    <div style={{
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        lineHeight: "17px",
                                                        color: "#5D6F80"
                                                    }}>Data Kosong</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12' style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: "#5D6F80"
                                }}>
                                    Lampiran 2
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    {this.state.lampiran3_split !== null && this.state.lampiran3 !== null && this.state.lampiran3 !== "" ?
                                        this.state.lampiran3_split[1] === "jpg" || this.state.lampiran3_split[1] === "jpeg" || this.state.lampiran3_split[1] === "png" || this.state.lampiran3_split[1] === "webp" ?
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran3} target={"_blank"}>
                                                <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran3} alt='' className='dk-img-lampiran' />
                                            </a>
                                            :
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran3} target={"_blank"}>
                                                <div style={{
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                    padding: "35px 44px 19px"
                                                }}>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center'>
                                                            <img alt='' src={DocumentIcon} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 text-center' style={{
                                                            fontSize: "11px",
                                                            fontWeight: 500,
                                                            lineHeight: "17px",
                                                            color: "#5D6F80"
                                                        }}>{this.state.lampiran3}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        :
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='text-center' style={{
                                                    padding: "35px 44px 19px",
                                                    border: "1px solid #DDDDDD",
                                                    borderRadius: "8px",
                                                }}>
                                                    <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                    <div style={{
                                                        fontSize: "11px",
                                                        fontWeight: 500,
                                                        lineHeight: "17px",
                                                        color: "#5D6F80"
                                                    }}>Data Kosong</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12' style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: "#5D6F80"
                                }}>
                                    Lampiran 3
                                </div>
                            </div>
                        </ModalBody>
                    </ModalRs>
                    <ModalRs style={{ maxWidth: "1000px" }} size='lg' isOpen={this.state.modalDetail} toggle={() => this.setState({ modalDetail: false, dataDetail: {} })}>
                        <ModalHeader toggle={() => this.setState({ modalDetail: false, dataDetail: {} })}>
                            <div className='dk-detail-header-font'>Detail Kunjungan</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div className='row mb-4'>
                                        <div className='col-md-5 mb-2'>
                                            {this.state.dataDetail.foto_kunjungan !== null || this.state.dataDetail.foto_kunjungan !== "" ? (
                                                <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.dataDetail.foto_kunjungan} target={"_blank"}>
                                                    <img
                                                        src={IMG_HOST + 'frontapp/kunjungan/' + this.state.dataDetail.foto_kunjungan}
                                                        style={{
                                                            height: "200px",
                                                            width: "100%",
                                                            objectFit: "cover",
                                                            borderRadius: "8px"
                                                        }}
                                                        className="img-fluid blur-up lazyloaded"
                                                        alt='' /></a>) : (
                                                <img alt='' src={IMG_HOST + 'user-profile.png'}
                                                    style={{ maxWidth: "100%", maxHeight: "250px" }}
                                                    className="img-fluid blur-up lazyloaded" />
                                            )}
                                        </div>
                                        <div className='col-md-7'>
                                            <div className='card' style={{ borderRadius: "8px", height: "100%" }}>
                                                <div className='card-header dk-ch-detail'>Kunjungan  {moment(this.state.dataDetail.kunjungan_create_date).format("dddd")} {moment(this.state.dataDetail.kunjungan_create_date).format("Do MMMM YYYY")}</div>
                                                <div className='card-body dk-cb-detail'>
                                                    <div className='row d-flex flex-nowrap'>
                                                        <div className='col-md-5 px-0 dk-cb-detail-label'>Nama Karyawan</div>
                                                        <div className='col-md-1'>:</div>
                                                        <div className='col-md-6 px-0 dk-cb-detail-value'>{this.state.dataDetail.karyawan && this.state.dataDetail.karyawan.karyawan_nama}</div>
                                                    </div>
                                                    <div className='row d-flex flex-nowrap'>
                                                        <div className='col-md-5 px-0 dk-cb-detail-label'>Lokasi Kunjungan</div>
                                                        <div className='col-md-1'>:</div>
                                                        <div className='col-md-6 px-0 dk-cb-detail-value'>{this.state.dataDetail.outlet && this.state.dataDetail.outlet.outlet_nama}</div>
                                                    </div>
                                                    <div className='row d-flex flex-nowrap'>
                                                        <div className='col-md-5 px-0 dk-cb-detail-label'>Keterangan Kunjungan</div>
                                                        <div className='col-md-1'>:</div>
                                                        <div className='col-md-6 px-0 dk-cb-detail-value'>{this.state.keterangan_kunjungan ? this.state.keterangan_kunjungan : "keterangan kosong"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-12'>
                                            <div className='dk-map-responsive'>
                                                <Map google={this.props.google} style={{ borderRadius: "8px" }}
                                                    initialCenter={{
                                                        lat: this.state.dataDetail && this.state.dataDetail.latitude_kunjungan,
                                                        lng: this.state.dataDetail && this.state.dataDetail.longitude_kunjungan
                                                    }}
                                                    zoom={15}>
                                                    <Marker />
                                                </Map>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            {this.state.lampiran1_split !== null && this.state.lampiran1 !== null && this.state.lampiran1 !== "" ?
                                                this.state.lampiran1_split[1] === "jpg" || this.state.lampiran1_split[1] === "jpeg" || this.state.lampiran1_split[1] === "png" || this.state.lampiran1_split[1] === "webp" ?
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran1} target={"_blank"}>
                                                        <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran1} alt='' className='dk-img-lampiran' />
                                                    </a>
                                                    :
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran1} target={"_blank"}>
                                                        <div style={{
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                            padding: "35px 44px 19px"
                                                        }}>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center'>
                                                                    <img alt='' src={DocumentIcon} />
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center' style={{
                                                                    fontSize: "11px",
                                                                    fontWeight: 500,
                                                                    lineHeight: "17px",
                                                                    color: "#5D6F80"
                                                                }}>{this.state.lampiran1}</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                :
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='text-center' style={{
                                                            padding: "35px 44px 19px",
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                        }}>
                                                            <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                            <div style={{
                                                                fontSize: "11px",
                                                                fontWeight: 500,
                                                                lineHeight: "17px",
                                                                color: "#5D6F80"
                                                            }}>Data Kosong</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-md-12' style={{
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            color: "#5D6F80"
                                        }}>
                                            Lampiran 1
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            {this.state.lampiran2_split !== null && this.state.lampiran2 !== null && this.state.lampiran2 !== "" ?
                                                this.state.lampiran2_split[1] === "jpg" || this.state.lampiran2_split[1] === "jpeg" || this.state.lampiran2_split[1] === "png" || this.state.lampiran2_split[1] === "webp" ?
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran2} target={"_blank"}>
                                                        <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran2} alt='' className='dk-img-lampiran' />
                                                    </a>
                                                    :
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran2} target={"_blank"}>
                                                        <div style={{
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                            padding: "35px 44px 19px"
                                                        }}>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center'>
                                                                    <img alt='' src={DocumentIcon} />
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center' style={{
                                                                    fontSize: "11px",
                                                                    fontWeight: 500,
                                                                    lineHeight: "17px",
                                                                    color: "#5D6F80"
                                                                }}>{this.state.lampiran2}</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                :
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='text-center' style={{
                                                            padding: "35px 44px 19px",
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                        }}>
                                                            <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                            <div style={{
                                                                fontSize: "11px",
                                                                fontWeight: 500,
                                                                lineHeight: "17px",
                                                                color: "#5D6F80"
                                                            }}>Data Kosong</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-md-12' style={{
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            color: "#5D6F80"
                                        }}>
                                            Lampiran 2
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            {this.state.lampiran3_split !== null && this.state.lampiran3 !== null && this.state.lampiran3 !== "" ?
                                                this.state.lampiran3_split[1] === "jpg" || this.state.lampiran3_split[1] === "jpeg" || this.state.lampiran3_split[1] === "png" || this.state.lampiran3_split[1] === "webp" ?
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran3} target={"_blank"}>
                                                        <img src={IMG_HOST + 'frontapp/kunjungan/' + this.state.lampiran3} alt='' className='dk-img-lampiran' />
                                                    </a>
                                                    :
                                                    <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.lampiran3} target={"_blank"}>
                                                        <div style={{
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                            padding: "35px 44px 19px"
                                                        }}>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center'>
                                                                    <img alt='' src={DocumentIcon} />
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12 text-center' style={{
                                                                    fontSize: "11px",
                                                                    fontWeight: 500,
                                                                    lineHeight: "17px",
                                                                    color: "#5D6F80"
                                                                }}>{this.state.lampiran3}</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                :
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='text-center' style={{
                                                            padding: "35px 44px 19px",
                                                            border: "1px solid #DDDDDD",
                                                            borderRadius: "8px",
                                                        }}>
                                                            <img alt='' src={EmptyPng} style={{ height: "100%", width: "100%" }} />
                                                            <div style={{
                                                                fontSize: "11px",
                                                                fontWeight: 500,
                                                                lineHeight: "17px",
                                                                color: "#5D6F80"
                                                            }}>Data Kosong</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12' style={{
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            color: "#5D6F80"
                                        }}>
                                            Lampiran 3
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </ModalRs>
                    <ModalRs style={{ maxWidth: "1000px" }} className={`modalAddKunjungan ${this.state.fileList !== null ? "modalAddKunjunganOff" : ""}`} size='lg' isOpen={this.state.fileList !== null ? this.state.modalAdd : ""} toggle={() => this.setState({ modalAdd: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF", padding: "12px 24px" }} toggle={() => this.setState({ modalAdd: false })}><div className='modal-header-reactstrap'>Tambah kunjungan</div></ModalHeader>
                        <ModalBody>
                            <div className='row px-3 mb-3'>
                                <div className='col-md-3 px-2'>
                                    <label htmlFor="exampleFormControlInput1" className="form-label font-edit-label">Tanggal
                                        Kunjungan</label>
                                    <input style={{fontSize: "12px", paddingTop: "10px", paddingBottom: "10px"}}
                                           type="date" name="tanggal" className="form-control font-edit-value"
                                           defaultValue={this.state.tanggal} onChange={this.handleChange}/>
                                </div>
                                <div className='col-md-3 px-2'>
                                    <label htmlFor="exampleFormControlInput1"
                                           className="form-label font-edit-label">Karyawan</label>
                                    <div className='form-control p-0'>
                                        <Select
                                            className="font-edit-value"
                                            placeholder="Nama Karyawan"
                                            backspaceRemoves={true}
                                            deleteRemoves={true}
                                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                            value={this.state.karyawan_selected}
                                            isLoading={this.state.karyawan_loading}
                                            onFocus={() => this.setState({karyawan_loading: true}, () => this.searchKaryawan())}
                                            onBlur={() => this.setState({karyawan_loading: false})}
                                            onInputChange={(val) => this.setState({karyawan_name: val}, () => this.setState({karyawan_loading: true}, () => this.searchKaryawan()))}
                                            options={
                                                this.state.karyawan != null ? (
                                                        this.state.karyawan.map((item, index) =>
                                                            (
                                                                {
                                                                    key: index,
                                                                    value: item.karyawan_id,
                                                                    label: item.karyawan_nama,
                                                                    data: item,
                                                                }
                                                            )
                                                        ))
                                                    : ("")
                                            }
                                            onChange={
                                                (karyawan_selected) => {
                                                    if (!karyawan_selected) {
                                                        this.setState({
                                                            karyawan_selected: '',
                                                            karyawan_id: 0,
                                                            pickKaryawan: false,
                                                            shift: []
                                                        })
                                                    } else {
                                                        this.setState({
                                                            karyawan_selected,
                                                            karyawan_id: karyawan_selected.value,
                                                            pickKaryawan: true,
                                                            shift: karyawan_selected.shift,
                                                            loading_selected: true,
                                                            dataOutlet: karyawan_selected.data.outlet,
                                                            karyawan: []
                                                        })
                                                    }
                                                }
                                            }
                                            isClearable={() => {
                                                this.setState({
                                                    isClearable: true,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6 px-2'></div>
                            </div>
                            <hr className='mb-3'/>
                            {this.state.loading_selected ?
                                <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                    <Spin
                                        size="small"
                                        tip="Loading..."
                                        style={{
                                            width: '100%',
                                            margin: 'auto',
                                        }}
                                    />
                                </div>
                                :
                                this.state.karyawan_selected &&
                                    <div className="row px-3">
                                        <div className="col-md-4 px-2">
                                            <div className='card border'>
                                                <div className='card-header modal-header-reactstrap px-3 border-bottom'
                                                     style={{
                                                         paddingTop: "10px",
                                                         paddingBottom: "10px",
                                                         fontSize: "16px",
                                                         lineHeight: "24px",
                                                         backgroundColor: "#FAFDFF"
                                                     }}>Detail Kunjungan
                                                </div>
                                                <div className='card-body p-3'>
                                                    <div className="mb-1">
                                                        <label className="form-label font-edit-label-2">Lokasi Outlet</label>
                                                        <select style={{fontSize: "12px"}} className="form-control"
                                                                onChange={this.handleChange} name="outlet_id">
                                                            <option value="">Pilih Lokasi</option>
                                                            {this.state.dataOutlet.length !== 0 ?
                                                                this.state.dataOutlet.map((item) => {
                                                                    return (
                                                                        <option value={item.outlet_id}>{item.outlet_nama}</option>
                                                                    )
                                                                })
                                                                : null}
                                                        </select>
                                                    </div>
                                                    <div className='mb-1'>
                                                        <label htmlFor="exampleFormControlInput1"
                                                               className="form-label font-edit-label-2">Jam</label>
                                                        <TimeInput
                                                            placeholder="Jam Pulang..."
                                                            style={{fontSize: "12px"}}
                                                            initTime={this.state.jam_kunjungan_absen ? this.state.jam_kunjungan_absen.substr(0, 5) : null}
                                                            className='s-input -time form-control px-3 py-2'
                                                            onTimeChange={(event) => {
                                                                const value = event;
                                                                this.setState({
                                                                    jam_kunjungan_absen: value + ':00'
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='mb-1'>
                                                        <label htmlFor="exampleFormControlInput1"
                                                               className="form-label font-edit-label-2">Keterangan</label>
                                                        <textarea style={{fontSize: "12px"}}
                                                                  value={this.state.keterangan_kunjungan} type="text"
                                                                  name="keterangan_kunjungan" className="form-control"
                                                                  placeholder="Keterangan Kunjungan..."
                                                                  onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 px-2">
                                            <div className='card border'>
                                                <div className='card-header modal-header-reactstrap px-3 border-bottom'
                                                     style={{
                                                         paddingTop: "10px",
                                                         paddingBottom: "10px",
                                                         fontSize: "16px",
                                                         lineHeight: "24px",
                                                         backgroundColor: "#FAFDFF"
                                                     }}>Foto Selfie
                                                </div>
                                                <div className='card-body p-3'>
                                                    <div className="mb-1">
                                                        <div className="col-xl-12 col-md-4">
                                                            <Upload customRequest={({ file, onSuccess }) => {
                                                                setTimeout(() => {
                                                                    onSuccess("ok");
                                                                }, 0);
                                                            }}
                                                                    listType="picture-card"
                                                                    fileList={this.state.fileSelfie}
                                                                    className="antd-upload-custom"
                                                                    onRemove={() => {
                                                                        this.setState({foto_selfie_kunjungan: null})
                                                                    }}
                                                                    onChange={(file) => {
                                                                        this.setState({fileSelfie: file.fileList})

                                                                        if (file.fileList.length > 0) {
                                                                            let files = file.file.originFileObj
                                                                            let reader = new FileReader()

                                                                            reader.readAsDataURL(files)
                                                                            reader.onloadend = () => {
                                                                                this.setState({
                                                                                    foto_selfie_kunjungan: {
                                                                                        name: file.file.name,
                                                                                        file: reader.result
                                                                                    }
                                                                                })
                                                                            }
                                                                        } else {
                                                                            this.setState({foto_selfie_kunjungan: []})
                                                                        }
                                                                    }}
                                                            >
                                                                {this.state.fileSelfie.length >= 1 ? null :
                                                                    <div className="foto-selfie">
                                                                    <span>
                                                                        <img src={UploadIcon}
                                                                             style={{height: "24px", width: "24px"}}/>
                                                                    </span>
                                                                        <p className="ant-upload-text mb-3" style={{
                                                                            fontSize: "12px",
                                                                            fontWeight: 400,
                                                                            lineHeight: "16px",
                                                                            marginTop: "6px"
                                                                        }}>Upload foto selfie karyawan</p>
                                                                        <a className="btn btn-outline-custom tambah-karyawan">Cari
                                                                            Gambar</a>
                                                                    </div>
                                                                }
                                                            </Upload>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card border'>
                                                <div className='card-header modal-header-reactstrap px-3 border-bottom'
                                                     style={{
                                                         paddingTop: "10px",
                                                         paddingBottom: "10px",
                                                         fontSize: "16px",
                                                         lineHeight: "24px",
                                                         backgroundColor: "#FAFDFF"
                                                     }}>Lampiran
                                                </div>
                                                <div className='card-body p-3'>
                                                    <div className="row mb-1">
                                                        <div className="col-lg-4 col-md-4">
                                                            <Upload
                                                                customRequest={({ file, onSuccess }) => {
                                                                    setTimeout(() => {
                                                                        onSuccess("ok");
                                                                    }, 0);
                                                                }}
                                                                listType="picture-card"
                                                                fileList={this.state.fileLampiran1}
                                                                className="antd-upload-custom"
                                                                onRemove={() => {
                                                                    this.setState({foto_lampiran1: null})
                                                                }}
                                                                onChange={(file) => {
                                                                    this.setState({fileLampiran1: file.fileList})

                                                                    if (file.fileList.length > 0) {
                                                                        let files = file.file.originFileObj
                                                                        let reader = new FileReader()

                                                                        reader.readAsDataURL(files)
                                                                        reader.onloadend = () => {
                                                                            this.setState({
                                                                                foto_lampiran1: {
                                                                                    name: file.file.name,
                                                                                    file: reader.result
                                                                                }
                                                                            })
                                                                        }
                                                                    } else {
                                                                        this.setState({foto_lampiran1: []})
                                                                    }
                                                                }}
                                                            >
                                                                {this.state.fileLampiran1.length >= 1 ? null :
                                                                    <div className="p-2" style={{height: "167px"}}>
                                                                <span>
                                                                    <img src={UploadIcon}
                                                                         style={{height: "24px", width: "24px"}}/>
                                                                </span>
                                                                        <p className="ant-upload-text mb-3" style={{
                                                                            fontSize: "12px",
                                                                            fontWeight: 400,
                                                                            lineHeight: "16px",
                                                                            marginTop: "6px"
                                                                        }}>Tambah lampiran kunjungan</p>
                                                                        <a className="btn btn-outline-custom tambah-karyawan">Cari
                                                                            Gambar</a>
                                                                    </div>
                                                                }
                                                            </Upload>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4">
                                                            <Upload
                                                                customRequest={({ file, onSuccess }) => {
                                                                    setTimeout(() => {
                                                                        onSuccess("ok");
                                                                    }, 0);
                                                                }}
                                                                listType="picture-card"
                                                                fileList={this.state.fileLampiran2}
                                                                className="antd-upload-custom"
                                                                onRemove={() => {
                                                                    this.setState({foto_lampiran2: null})
                                                                }}
                                                                onChange={(file) => {
                                                                    this.setState({fileLampiran2: file.fileList})

                                                                    if (file.fileList.length > 0) {
                                                                        let files = file.file.originFileObj
                                                                        let reader = new FileReader()

                                                                        reader.readAsDataURL(files)
                                                                        reader.onloadend = () => {
                                                                            this.setState({
                                                                                foto_lampiran2: {
                                                                                    name: file.file.name,
                                                                                    file: reader.result
                                                                                }
                                                                            })
                                                                        }
                                                                    } else {
                                                                        this.setState({foto_lampiran2: []})
                                                                    }
                                                                }}
                                                            >
                                                                {this.state.fileLampiran2.length >= 1 ? null :
                                                                    <div className="p-2" style={{height: "167px"}}>
                                                                <span>
                                                                    <img src={UploadIcon}
                                                                         style={{height: "24px", width: "24px"}}/>
                                                                </span>
                                                                        <p className="ant-upload-text mb-3" style={{
                                                                            fontSize: "12px",
                                                                            fontWeight: 400,
                                                                            lineHeight: "16px",
                                                                            marginTop: "6px"
                                                                        }}>Tambah lampiran kunjungan</p>
                                                                        <a className="btn btn-outline-custom tambah-karyawan">Cari
                                                                            Gambar</a>
                                                                    </div>
                                                                }
                                                            </Upload>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4">
                                                            <Upload
                                                                customRequest={({ file, onSuccess }) => {
                                                                    setTimeout(() => {
                                                                        onSuccess("ok");
                                                                    }, 0);
                                                                }}
                                                                listType="picture-card"
                                                                fileList={this.state.fileLampiran3}
                                                                className="antd-upload-custom"
                                                                onRemove={() => {
                                                                    this.setState({foto_lampiran3: null})
                                                                }}
                                                                onChange={(file) => {
                                                                    this.setState({fileLampiran3: file.fileList})

                                                                    if (file.fileList.length > 0) {
                                                                        let files = file.file.originFileObj
                                                                        let reader = new FileReader()

                                                                        reader.readAsDataURL(files)
                                                                        reader.onloadend = () => {
                                                                            this.setState({
                                                                                foto_lampiran3: {
                                                                                    name: file.file.name,
                                                                                    file: reader.result
                                                                                }
                                                                            })
                                                                        }
                                                                    } else {
                                                                        this.setState({foto_lampiran3: []})
                                                                    }
                                                                }}
                                                            >
                                                                {this.state.fileLampiran3.length >= 1 ? null :
                                                                    <div className="p-2" style={{height: "167px"}}>
                                                                <span>
                                                                    <img src={UploadIcon}
                                                                         style={{height: "24px", width: "24px"}}/>
                                                                </span>
                                                                        <p className="ant-upload-text mb-3" style={{
                                                                            fontSize: "12px",
                                                                            fontWeight: 400,
                                                                            lineHeight: "16px",
                                                                            marginTop: "6px"
                                                                        }}>Tambah lampiran kunjungan</p>
                                                                        <a className="btn btn-outline-custom tambah-karyawan">Cari
                                                                            Gambar</a>
                                                                    </div>
                                                                }
                                                            </Upload>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </ModalBody>
                        <ModalFooter style={{backgroundColor: "#F5F5F5", borderTop: 0}}>
                            <button className="btn button-add text-capitalize"
                                    onClick={() => this.setState({modalAdd: false})}>
                                Batalkan
                            </button>
                            {' '}
                            <button className="btn btn-primary text-capitalize" disabled={!this.state.karyawan_selected} onClick={this.simpanKunjungan}>
                                Simpan
                            </button>
                        </ModalFooter>
                    </ModalRs>
                    <div className="container-fluid">
                        <div className='row pt-4'>
                            <div className='col-md-12' style={{
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5D6F80"
                            }}>Presensi Admin Panel
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12' style={{
                                color: "#143B5E",
                                fontWeight: 700,
                                lineHeight: "36px",
                                fontSize: "24px"
                            }}>Daftar Kunjungan
                            </div>
                        </div>
                        <div className="card mb-0" style={{
                            borderBottomLeftRadius: "0px",
                            borderBottomRightRadius: "0px"
                        }}>
                            <div className="card-body p-4" style={{
                                border: "1px solid #EAECF0",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px"
                            }}>
                                <div className='row' style={{marginBottom: "16px"}}>
                                    <div className='col-md-3 dk-col-1'>
                                        <div class="input-group btn-search-kjngn mb-3">
                                            <span class="input-group-text" id="basic-addon1" style={{
                                                backgroundColor: "#F9F9F9",
                                                borderTopLeftRadius: "8px",
                                                borderBottomLeftRadius: "8px",
                                                borderTopRightRadius: "0px",
                                                border: "1px solid #EFEFEF",
                                                borderBottomRightRadius: "0px",
                                                borderRight: "0px",
                                                paddingLeft: "10px",
                                            }}>
                                                <img alt='' src={SearchIcon}/>
                                            </span>
                                            <input name="keyword" type="text" class="form-control dk-filter-name"
                                                   placeholder="Cari nama karyawan ..." aria-label="Username"
                                                   aria-describedby="basic-addon1" value={this.state.keyword}
                                                   onChange={this.handleChange}
                                                   onKeyUp={(event) => {
                                                       event.preventDefault()
                                                       if (event.which === 13) {

                                                           this.setState({
                                                               page: 0,
                                                               paging: this.state.paging,
                                                               loading: true
                                                           }, () => {
                                                               this.loadData()
                                                           });
                                                       }
                                                   }}/>
                                        </div>
                                    </div>
                                    <div className='col-md-3 dk-col-2'>
                                        <div className='d-flex flex-nowrap'>
                                            <div className='flex-fill col-tgl-kjngn'>
                                                <DateTimeRangeContainer
                                                    ranges={ranges}
                                                    start={this.state.start}
                                                    end={this.state.end}
                                                    local={local}
                                                    applyCallback={this.applyCallback}
                                                >
                                                    <div class="input-group col-tgl-kjngn mb-3">
                                                        <input type="text" class="form-control dk-filter-date btn-tgl-kjngn"
                                                               placeholder="Filter Tanggal" aria-label="Username"
                                                               aria-describedby="basic-addon1" value={this.state.date}/>
                                                        <span class="input-group-text" id="basic-addon1" style={{
                                                            backgroundColor: "#FFFFFF",
                                                            border: "1px solid #EFEFEF",
                                                            borderLeft: "none",
                                                            borderTopLeftRadius: "0px",
                                                            borderBottomLeftRadius: "0px",
                                                            borderTopRightRadius: "8px",
                                                            borderBottomRightRadius: "8px",
                                                        }}>
                                                            <img alt='' src={CalendarIcon}/>
                                                        </span>
                                                    </div>
                                                </DateTimeRangeContainer>
                                            </div>
                                            <div>
                                                <button onClick={this.resetFilter} className='dk-button-refresh ml-1'>
                                                    <img alt='' src={RefreshIcon}/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6' style={{textAlign: "right"}}>
                                        <button className='dk-button-export mr-1 btn-exp-knjgn' onClick={() => {
                                            this.setState({modalexport: true})
                                        }}><img alt='' src={ExportIcon}/> Export Excel
                                        </button>
                                        <button className='dk-button-export' onClick={() => {
                                            this.setState({
                                                modalAdd: true,
                                                jam_kunjungan_absen: moment().format('HH:mm'),
                                                karyawan_id: 0,
                                                karyawan_selected: "",
                                                fileSelfie: [],
                                                fileLampiran1: [],
                                                fileLampiran2: [],
                                                fileLampiran3: [],
                                                tanggal: moment(this.state.dateFilter._d).format("YYYY-MM-DD")
                                            })
                                        }}><img alt='' src={plusIcon}/> Tambah Kunjungan
                                        </button>
                                    </div>
                                </div>
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <Empty description="Data Kosong"
                                                   image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"/>
                                        </div>
                                    </div>
                                    :
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <Table className="mb-5 table table-borderless">
                                                <Thead>
                                                    <Tr>
                                                        <Th className="dk-th-style">#</Th>
                                                        <Th className="dk-th-style">Hari / Tanggal</Th>
                                                        <Th className="dk-th-style">Lokasi Kunjungan</Th>
                                                        <Th className="dk-th-style">Nama</Th>
                                                        <Th className="dk-th-style" style={{minWidth: "140px"}}>Jam
                                                            Kunjungan</Th>
                                                        <Th className="dk-th-style">Keterangan</Th>
                                                        <Th className="dk-th-style">Lampiran</Th>
                                                        <Th className="dk-th-style"></Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {this.state.loading ?
                                                        <Tr>
                                                            <Td colSpan={9}>
                                                                <Spin size="large" tip="Loading..." style={{
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px'
                                                                }}/>
                                                            </Td>
                                                        </Tr>
                                                        :
                                                        this.state.data.map((item, index) => {
                                                            no += 1
                                                            let status = 'badge'
                                                            return (
                                                                <Tr style={{
                                                                    backgroundColor: no % 2 === 0 && "#EFEFEF"
                                                                }}>
                                                                    <Td scope="row"
                                                                        className={no % 2 === 0 ? "dk-td-style dk-td-first-even" : "dk-td-style"}>{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                                    <Td className="dk-td-style">{item.absen_hari} / {moment(item.tanggal_kunjungan).format('ll')}</Td>
                                                                    <Td className="dk-td-style">
                                                                        <a href={"/edit-lokasi-kunjungan/" + item.outlet_id}>
                                                                            {item.outlet != null ?
                                                                                <span>{item.outlet.outlet_nama}</span>
                                                                                :
                                                                                <span>-</span>
                                                                            }
                                                                        </a>
                                                                    </Td>
                                                                    <Td className="dk-td-style">
                                                                        <a href={"/karyawan/profil/detail/" + item.karyawan_id}>
                                                                            {item.karyawan != null ?
                                                                                <span>{item.karyawan.karyawan_nama}</span>
                                                                                :
                                                                                <span>-</span>
                                                                            }
                                                                        </a>
                                                                    </Td>
                                                                    <Td className="dk-td-style">{item.jam_kunjungan_absen}</Td>
                                                                    <Td className="dk-td-style">{item.keterangan_kunjungan}</Td>
                                                                    <Td className="dk-td-style">
                                                                        <button onClick={() => {
                                                                            this.setState({
                                                                                modalLampiran: true,
                                                                                lampiran1: item.lampiran_kunjungan_1,
                                                                                lampiran2: item.lampiran_kunjungan_2,
                                                                                lampiran3: item.lampiran_kunjungan_3,
                                                                            }, () => this.setState({
                                                                                lampiran1_split: this.state.lampiran1 !== "" && this.state.lampiran1 !== null && this.state.lampiran1.split("."),
                                                                                lampiran2_split: this.state.lampiran2 !== "" && this.state.lampiran2 !== null && this.state.lampiran2.split("."),
                                                                                lampiran3_split: this.state.lampiran3 !== "" && this.state.lampiran3 !== null && this.state.lampiran3.split(".")
                                                                            }))
                                                                        }} className='dk-btn' style={{ color: "#057CE4" }}>Lihat Lampiran</button>
                                                                    </Td>
                                                                    <Td className={no % 2 === 0 ? "dk-td-style dk-td-last-even" : "dk-td-style"}>
                                                                        <Tooltip trigger={'click'} zIndex={100} placement="bottom" color={'#fff'} title={
                                                                            <div className={'p-2'}>
                                                                                <a onClick={() => {
                                                                                    this.setState({
                                                                                        modal: true,
                                                                                        lat: item.latitude_kunjungan,
                                                                                        lng: item.longitude_kunjungan
                                                                                    })
                                                                                }} style={{ marginRight: "50px" }} className={'text-dark'}>Lihat Pin Lokasi</a><br />
                                                                                <a onClick={() => this.setState({
                                                                                    lampiran1: item.lampiran_kunjungan_1,
                                                                                    lampiran2: item.lampiran_kunjungan_2,
                                                                                    lampiran3: item.lampiran_kunjungan_3,
                                                                                }, () => this.setState({
                                                                                    lampiran1_split: this.state.lampiran1 !== "" && this.state.lampiran1 !== null && this.state.lampiran1.split("."),
                                                                                    lampiran2_split: this.state.lampiran2 !== "" && this.state.lampiran2 !== null && this.state.lampiran2.split("."),
                                                                                    lampiran3_split: this.state.lampiran3 !== "" && this.state.lampiran3 !== null && this.state.lampiran3.split("."),
                                                                                    modalDetail: true,
                                                                                    dataDetail: item,
                                                                                }))} className={'text-dark'}>Detail</a><br />
                                                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            idDel: item.kunjungan_id
                                                                                        })
                                                                                        this.wantDelete()
                                                                                    }} className={'text-danger'}>Hapus</a>
                                                                                )}
                                                                            </div>
                                                                        }>
                                                                            <button className='dk-btn'>
                                                                                <img alt='' src={ElipsisIcon} />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='row mx-0 mb-4' style={{
                            backgroundColor: "#F5F5F5",
                            width: "100%",
                            border: "1px solid #EAECF0",
                            borderTop: "none",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                        }}>
                            {this.state.data.length !== 0 &&
                                <>
                                    <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.data.length === 0 ? "0" : this.state.data.length} dari {this.state.totalData} data</div>
                                    <div className='col'>
                                        <ReactPaginate
                                            previousLabel={<img src={ArrowLeft} alt='' />}
                                            nextLabel={<img src={ArrowRight} alt='' />}
                                            pageCount={this.state.pageCount}
                                            forcePage={this.state.currentPage}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={this.handlePageClick}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link custom-page-link'}
                                            containerClassName={'pagination my-2'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link custom-page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                            activeClassName={'active custom-active'}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

export default connect(mapStateToProps)(GoogleApiWrapper({
    apiKey: APIKEY
})(List_kunjungan));

