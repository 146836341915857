import React from "react";
import { Modal, ModalHeader, ModalBody, Progress } from "reactstrap";
import CheckBlue from '../../assets/icons/Check Blue.svg';
import Check from '../../assets/icons/Check.svg';
import AngleRight from '../../assets/icons/angle-right.svg';
import { Link } from "react-router-dom";
import { clear_auth, set_auth } from "../../reducers/Auth";
import ProgresImage from "../../assets/images/verification.png";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

class ModalDefault extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            endModal: false
        };
    }

    render() {
        let progres_value = 0;
        if (this.props.jam_kerja && this.props.jam_kerja.length !== 0) {
            progres_value += 20
        }
        if (this.props.shift && this.props.shift.length !== 0) {
            progres_value += 20
        }
        if (this.props.divisi && this.props.divisi.length !== 0) {
            progres_value += 20
        }
        if (this.props.karyawan && this.props.karyawan.length !== 0) {
            progres_value += 20
        }
        if (this.props.lokasi && this.props.lokasi.length !== 0) {
            progres_value += 20
        }

        const style = {
            info: {
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#5D6F80"
            },
            button_check: {
                width: "100%",
                padding: "17px 16px",
                backgroundColor: "#F5F5F5",
                color: "#143B5E",
            },
            button_checked: {
                width: "100%",
                padding: "17px 16px",
                backgroundColor: "#E8F4FF",
                color: "#143B5E",
                borderRadius: "8px"
            },
            text_button_checked: {
                textAlign: "left",
                paddingLeft: "10px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            text_button_check: {
                textAlign: "left",
                paddingLeft: "10px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            }
        }
        return (
            <>
                <Modal isOpen={this.props.confirm_modal_default}>
                    <ModalHeader style={{
                        backgroundColor: "#FAFDFF",
                        padding: "12px 24px"
                    }}>
                        <div style={{
                            fontSize: "24px",
                            lineHeight: "36px",
                            fontWeight: 700,
                            color: "#143B5E",
                            backgroundColor: "#FAFDFF",
                        }}>Kelengkapan Data</div>
                    </ModalHeader>
                    <ModalBody className="p-4">
                        {progres_value === 100 &&
                            <div className="row">
                                <div className="col-md-12" style={{
                                    textAlign: "center"
                                }}>
                                    <img alt="" src={ProgresImage} style={{
                                        height: "190px"
                                    }} />
                                </div>
                            </div>
                        }
                        <div className="row mt-3 d-flex flex-nowrap" style={{ paddingLeft: "14px", paddingRight: "14px" }}>
                            <div className="col-md-10 px-0">
                                <Progress style={{ height: "24px", borderRadius: "32px" }} barStyle={{ borderRadius: "32px" }} value={progres_value} />
                            </div>
                            <div className="col-md-2 pl-3 pr-0" style={{ lineHeight: "24px", fontSize: "16px", fontWeight: 500, maxWidth: "76px" }}>{progres_value}%</div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-md-12" style={style.info}>
                                {progres_value !== 100 ?
                                    "Silahkan mengikuti langkah-langkah di bawah ini untuk pengisian data perusahaan Anda:"
                                    :
                                    "Selamat anda sudah berhasil menambahkan data setting awal perusahaan."
                                }
                            </div>
                        </div>
                        <div style={{
                            height: progres_value === 100 ? "215px" : "400px",
                            overflowY: "auto",
                            overflowX: "hidden"
                        }}>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <div className="text-capitalize d-flex" style={style.button_checked}>
                                        <div className="px-0">
                                            <img alt="" src={CheckBlue} />
                                        </div>
                                        <div className="flex-fill pr-0" style={style.text_button_checked}>Kelengkapan Data Perusahaan</div>
                                    </div>
                                </div>
                            </div>
                            {this.props.jam_kerja.length === 0 ?
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <Link to={"/karyawan/jam-kerja"} className="btn d-flex text-capitalize" style={style.button_check} onClick={() => this.props.setState({ confirm_modal_default: false })}>
                                            <div className="px-0">
                                                <img alt="" src={Check} />
                                            </div>
                                            <div className="flex-fill pr-0" style={style.text_button_check}>Tambah Jam Kerja</div>
                                            <div className="px-0">
                                                <img alt="" src={AngleRight} />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                :
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="d-flex text-capitalize" style={style.button_checked}>
                                            <div className="px-0">
                                                <img alt="" src={CheckBlue} />
                                            </div>
                                            <div className="flex-fill pr-0" style={style.text_button_check}>Tambah Jam Kerja</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.props.shift.length === 0 ?
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <Link to={"/karyawan/shift"} className="btn d-flex text-capitalize" style={style.button_check} onClick={() => this.props.setState({ confirm_modal_default: false })}>
                                            <div className="px-0">
                                                <img alt="" src={Check} />
                                            </div>
                                            <div className="flex-fill pr-0" style={style.text_button_check}>Tambah shift</div>
                                            <div className="px-0">
                                                <img alt="" src={AngleRight} />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                :
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="d-flex text-capitalize" style={style.button_checked}>
                                            <div className="px-0">
                                                <img alt="" src={CheckBlue} />
                                            </div>
                                            <div className="flex-fill pr-0" style={style.text_button_check}>Tambah shift</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.props.divisi.length === 0 ?
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <Link to={"/karyawan/divisi-karyawan"} className="btn d-flex text-capitalize" style={style.button_check} onClick={() => this.props.setState({ confirm_modal_default: false })}>
                                            <div className="px-0">
                                                <img alt="" src={Check} />
                                            </div>
                                            <div className="flex-fill pr-0" style={style.text_button_check}>Tambah Divisi</div>
                                            <div className="px-0">
                                                <img alt="" src={AngleRight} />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                :
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="d-flex text-capitalize" style={style.button_checked}>
                                            <div className="px-0">
                                                <img alt="" src={CheckBlue} />
                                            </div>
                                            <div className="flex-fill pr-0" style={style.text_button_check}>Tambah Divisi</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.props.lokasi.length === 0 ?
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <Link to={"/tambah-lokasi-presensi"} className="btn d-flex text-capitalize" style={style.button_check} onClick={() => this.props.setState({ confirm_modal_default: false })}>
                                            <div className="px-0">
                                                <img alt="" src={Check} />
                                            </div>
                                            <div className="flex-fill pr-0" style={style.text_button_check}>Tambah Lokasi</div>
                                            <div className="px-0">
                                                <img alt="" src={AngleRight} />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                :
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="d-flex text-capitalize" style={style.button_checked}>
                                            <div className="px-0">
                                                <img alt="" src={CheckBlue} />
                                            </div>
                                            <div className="flex-fill pr-0" style={style.text_button_check}>Tambah Lokasi</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.props.karyawan.length === 0 ?
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <Link to={"/karyawan/tambah-karyawan"} className="btn d-flex text-capitalize" style={style.button_check} onClick={() => this.props.setState({ confirm_modal_default: false })}>
                                            <div className="px-0">
                                                <img alt="" src={Check} />
                                            </div>
                                            <div className="flex-fill pr-0" style={style.text_button_check}>Tambah Karyawan</div>
                                            <div className="px-0">
                                                <img alt="" src={AngleRight} />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                :
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <div className="d-flex text-capitalize" style={style.button_checked}>
                                            <div className="px-0">
                                                <img alt="" src={CheckBlue} />
                                            </div>
                                            <div className="flex-fill pr-0" style={style.text_button_check}>Tambah Karyawan</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div></div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}
const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalDefault);