import React, { Component, Fragment } from 'react'
import moment from 'moment';
import { Empty, Spin } from "antd";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Paging from "../../paging";
import { BACKEND_API_HOST } from "../../../helper/host";
import { pageNumber } from "../../../functions/functionGeneral";
import Swal from "sweetalert2";
import AddKontrak from "./kontrak/add";
import EditKontrak from "./kontrak/edit";
import { Tooltip} from 'antd';

export class KontrakKerja extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        this.state = {
            loading: false,
            kontrak: [],
            year: tahun,
            month: namabulan[bulan],
            absensiStartDate: '',
            absensiEndDate: '',
            contract_id: '',
            contract_type: '',
            contract_start: '',
            contract_duration: '',
            contract_note: '',
            contract_status: '',
            add_visible: false,
            edit_visible: false,
            pageCount: 0,
            perPage: 0,
            currentPage: 0,

        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
    }

    resetFilter(event) {
        this.setState({
            kunjunganStartDate: '',
            kunjunganEndDate: '',
            data_kunjungan: [],
            loading: true
        }, () => this.loadDataKaryawan())
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadDataKaryawan())
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, absen: [] }, () => {
            this.loadDataKaryawan();
        });
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            absensiStartDate: start,
            absensiEndDate: end
        }, () => {
            this.loadDataKaryawan()
        });
    }

    loadKontrakKaryawan() {
        this.setState({ loading: true })
        let form = {
            id: this.props.match.params.karyawan_slug,
        }
        this.props.request("kontrak/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        kontrak: response.data.data,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        visible: true
                    })
                }
                this.setState({ loading: false })
            });
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Kontrak Kerja ?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        loading: true,
                        data: [],
                    })
                    this.props.request("kontrak/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadKontrakKaryawan()
                            }
                        });
                }
            })
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    componentDidMount() {
        this.loadKontrakKaryawan()
    }

    render() {
        let to = 0;
        return (
            <Fragment>
                <AddKontrak
                    add_visible={this.state.add_visible}
                    request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                    changeState={(state, callback) => {
                        this.setState(state, () => {
                            if (typeof callback != 'undefined')
                                callback()
                        })
                    }}
                    id={this.props.match.params.karyawan_slug}
                    loadData={() => this.loadKontrakKaryawan()}
                />
                <EditKontrak
                    data={this.state}
                    request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                    changeState={(state, callback) => {
                        this.setState(state, () => {
                            if (typeof callback != 'undefined')
                                callback()
                        })
                    }}
                    id={this.props.match.params.karyawan_slug}
                    loadData={() => this.loadKontrakKaryawan()}
                />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex justify-content-between">
                                <h5>Kontrak Kerja Karyawan</h5>
                                <div>
                                    <button onClick={() => { this.setState({add_visible: true}) }} className={"btn btn-success"} style={{}}>Buat Kontrak Kerja Baru</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body daftar-absen">
                            {!this.state.loading && this.state.kontrak.length === 0 ?
                                <Empty description="Karyawan ini belum memiliki Kontrak Kerja"
                                       image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                :
                                <table className="table mb-5">
                                    <thead>
                                        <tr>
                                            <th style={{color: "#143B5E", fontSize: "14px"}}>#</th>
                                            <th style={{color: "#143B5E", fontSize: "14px"}}>Tipe Kontrak</th>
                                            <th style={{color: "#143B5E", fontSize: "14px"}} className="text-center">Tanggal Mulai Kontrak</th>
                                            <th style={{color: "#143B5E", fontSize: "14px"}} className="text-center">Durasi Kontrak</th>
                                            <th style={{color: "#143B5E", fontSize: "14px"}} className="text-center">Catatan Kontrak</th>
                                            <th style={{color: "#143B5E", fontSize: "14px"}} className="text-center" width={140}>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.loading &&
                                    <tr>
                                        <td colSpan={8}>
                                            <Spin size="large" tip="Loading..." style={{
                                                width: '100%',
                                                margin: 'auto',
                                                marginBottom: '10px',
                                                marginTop: '10px'
                                            }} />
                                        </td>
                                    </tr>
                                    }
                                    {this.state.kontrak.map((item, index) => {
                                        to += 1
                                        return (
                                            <tr style={{backgroundColor: item.contract_status === 'active' ?  '#e9ffd7' : '#fff'}}>
                                                <td>
                                                    <small>
                                                        <th style={{border: "none"}} scope="row">{pageNumber(to, this.state.perPage, this.state.currentPage)}</th>
                                                    </small>
                                                </td>
                                                <td style={{ fontSize: "14px", color: "#143B5E" }} align="center">{item.contract_type.toUpperCase()}</td>
                                                <td style={{ fontSize: "14px", color: "#143B5E" }} align="center">{moment(item.contract_start).format('ll')}</td>
                                                <td style={{ fontSize: "14px", color: "#143B5E" }} align="center">{item.contract_duration ? item.contract_duration + ' Bulan' : '-' }</td>
                                                <td style={{ fontSize: "14px", color: "#143B5E" }} align="center">{item.contract_note}</td>
                                                <td align="center">
                                                    <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                        <div className={'p-2'}>
                                                            <a className={'text-dark'}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        edit_visible: true,
                                                                        contract_id: item.contract_id,
                                                                        contract_type: item.contract_type,
                                                                        contract_start: item.contract_start,
                                                                        contract_duration: item.contract_duration,
                                                                        contract_note: item.contract_note,
                                                                        contract_status: item.contract_status,
                                                                    })
                                                                }}
                                                                rel="noopener noreferrer"
                                                            >
                                                                Edit
                                                            </a><br/>
                                                            <a rel="noopener noreferrer" style={{ color:'#E03616' }} onClick={() => {
                                                                this.setState({
                                                                    idDel: item.contract_id
                                                                })
                                                                this.wantDelete()
                                                            }}>
                                                                Hapus
                                                            </a>
                                                        </div>
                                                    }>
                                                        <a><i className="fa fa-ellipsis-h" style={{
                                                            width: 35,
                                                            fontSize: 18,
                                                            padding: 11,
                                                            color: 'rgb(51 51 51)'
                                                        }} /></a>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            }
                            <Paging
                                className="float-right"
                                handlePageClick={this.handlePageClick}
                                pageCount={this.state.pageCount}
                                currentPage={this.state.currentPage}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default KontrakKerja
