import React, {Component} from 'react'
import {Button, Drawer, Form, Select} from 'antd';
import Swal from "sweetalert2";
import {set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";

class AddKunjungan extends Component {
    constructor() {
        super();
        this.state = {
            outlet      : [],
            data        : [],
        }
    }
    simpanOutlet = () => {
        let form = {
            id: this.props.data.karyawan_id,
            outlet: this.props.data.outlet_karyawan
        }

        this.props.request("outlet/add_outlet_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.changeState({
                        outlet_karyawan: '',
                        add_visible: false
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    loadOutlet(){
        let form = {
        }
        this.props.request("outlet/get_outlet", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        outlet: response.data
                    })
                }
            });
    }

    componentDidMount() {
        this.loadOutlet()
    }

    render() {
        const { Option } = Select;
        return (
            <Drawer
                title="Tambah Outlet"
                width={350}
                onClose={() => {
                    this.props.changeState({add_visible: false, divisi_name:''});
                }}
                visible={this.props.add_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => {
                            this.props.changeState({add_visible: false, divisi_name:''});
                        }}
                                style={{ marginRight: 8 }}>
                            Batal
                        </Button>
                        <Button onClick={this.simpanOutlet} type="primary">
                            Simpan
                        </Button>
                    </div>
                }
            >
                <div layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Nama Outlet</label>
                        <Select name="outlet"
                                style={{ width: '100%' }}
                                mode={"multiple"}
                                placeholder="Pilih Outlet"
                                value={this.props.data.outlet_karyawan}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(event) => {
                                    this.props.changeState({
                                        outlet_karyawan: event
                                    })
                                }}>
                            {this.state.outlet.map((item, index) => {
                                return (<Option value={item.outlet_id} key={item.outlet_id}>{item.outlet_nama}</Option>)
                            })}
                        </Select>
                    </div>
                </div>
            </Drawer>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddKunjungan)
