import React, {Component, Fragment} from 'react';
import Breadcrumb from '../common/breadcrumb';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {Helmet} from "react-helmet";
import {APIKEY} from "../../helper/host";
import {GoogleApiWrapper, InfoWindow, Map, Marker, Polyline} from 'google-maps-react';
import {requestTracking} from "../../helper/request";
import {connect} from "react-redux";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import marker from '../../assets/images/marker-2.png'
import marker1 from '../../assets/images/marker-1.png'


export class Detail_tracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading             : true,
            data                : [],
            detail                : [],
            location: {
                lat: -7.865242,
                lng: 110.156070
            },
            activeMarker: {},
            selectedPlace: [],
            selectedDate    : new Date(),
            showingInfoWindow: true

        }
    }

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onMapClicked = (props, marker) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: true,
                activeMarker: marker
            })
        }
    };


    handleChange = (a) => {
        this.setState({selectedDate : a}, () => {
            this.loadData();
        });
        this.setState({ loading: true })
    };

    loadDetail(){
        let form = {
            createdAt   : moment(this.state.selectedDate).format('YYYY-MM-DD'),
            id: this.props.match.params.id
        }
        this.setState({
            loading: true,
        })
        requestTracking("trackings/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        detail        : response.data

                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }


    loadData() {
        let form = {
            createdAt   : moment(this.state.selectedDate).format('YYYY-MM-DD'),
            karyawanId  : this.props.match.params.id,
        }

        requestTracking("trackings/getTrackingById", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data        : response.data,
                        loading     : false
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.loadDetail()
    }

    render() {
        var triangleCoords = [];
        var jamMarker =[];
        this.state.data.map((item) => {
            triangleCoords.push({lat: item.latitude, lng: item.longitude})
            jamMarker.push(item.createdAt)
        });
        var last = triangleCoords[triangleCoords.length-1];
        var lastJam = jamMarker[jamMarker.length-1];
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Detail Tracking - Presensi</title>
                    <meta name="description" content="Daftar Tracking" />
                </Helmet>
                <Breadcrumb title="Detail Tracking Karyawan" parent="Karyawan" setGuide={() => {this.setState({guide: true}) }}/>
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <h5>Detail Tracking <a href={"/karyawan/profil/detail/" + this.state.detail.karyawanId}>
                                {this.state.detail.namaKaryawan != null ?
                                    <span>{this.state.detail.namaKaryawan}</span>
                                    :
                                    <span>-</span>
                                }
                            </a></h5>
                        </div>
                        <div className="card-body">
                            <div style={{ width: 200 }}>
                                <DatePicker
                                    className="form-control dateInput"
                                    placeholderText="Select Your Date"
                                    selected={this.state.selectedDate}
                                    onChange={ this.handleChange }
                                    dateFormat="d MMMM yyyy"
                                />
                            </div>
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                height: '500px'
                            }}>

                            <Map google={this.props.google}
                                 className={'map'}
                                 onClick={this.onMapClicked}
                                 initialCenter={this.state.location}

                                 zoom={15}>

                                <Marker
                                    onClick={this.onMarkerClick}
                                    name='mulai'
                                    jam ={moment(jamMarker[0]).format('LT')}
                                    position={triangleCoords[0]}
                                    icon={{
                                        url: marker1
                                    }}
                                    />

                                <Marker
                                    onClick={this.onMarkerClick}
                                    name ='akhir'
                                    jam ={moment(lastJam).format('LT')}
                                    position={last}
                                    icon={{
                                        url: marker
                                    }}/>

                                <Polyline
                                    path={triangleCoords}
                                    options={{
                                        strokeColor:"#2661bf",
                                        strokeOpacity:2.8,
                                        strokeWeight:8,
                                        icons: [{
                                            offset: '0',
                                            repeat: '10px'
                                        }]
                                    }}/>
                                <InfoWindow
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}
                                >
                                    <div>
                                        {this.state.selectedPlace.name} {this.state.selectedPlace.jam}
                                    </div>
                                </InfoWindow>

                            </Map>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

const google = GoogleApiWrapper({
    apiKey: APIKEY
})(Detail_tracking)
export default connect(mapStateToProps)(google)
