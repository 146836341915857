import React, { Component } from 'react'
import { Button, Drawer, Form } from 'antd';
import Swal from "sweetalert2";

class ShiftAdd extends Component {
    constructor() {
        super();
        this.state = {
            sift_name: '',
            sift_late_tolerance: '',
            loading: false
        }
    }
    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let form = {
            sift_name: this.state.sift_name,
            sift_late_tolerance: this.state.sift_late_tolerance,
        }

        this.props.request("shift/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({ sift_name: '', sift_late_tolerance: '' })
                    this.props.changeState({
                        sift_name: '',
                        sift_late_tolerance: '',
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => {
                        this.props.loadData()
                        this.props.loadCheck()
                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false
                })
            });
    }

    render() {
        return (
            <Drawer
                title="Tambah Sift"
                width={350}
                onClose={() => {
                    this.props.changeState({ add_visible: false, divisi_name: '' });
                }}
                visible={this.props.add_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => {
                            this.props.changeState({ add_visible: false, sift_name: '' });
                        }}
                            style={{ marginRight: 8 }}>
                            Batal
                        </Button>
                        <Button onClick={this.handleSubmit} disabled={this.state.loading} type="primary">
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> Loading</>
                                :
                                "Simpan"
                            }
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Nama Shift</label>
                        <input name="sift_name" className="form-control col-xl-12 col-md-12" placeholder="Sift..."
                            type="text" required="" value={this.state.sift_name}
                            onChange={(event) => {
                                const target = event.target;
                                const value = target.value;
                                const name = target.name;
                                this.setState({
                                    [name]: value
                                });
                            }} />
                    </div>
                    <div className="form-group">
                        <label>Toleransi Terlambat (menit)</label>
                        <input name="sift_late_tolerance" className="form-control col-xl-12 col-md-12" placeholder="Durasi Toleransi Waktu..."
                            type="number" required value={this.state.sift_late_tolerance}
                            onChange={(event) => {
                                const target = event.target;
                                const value = target.value;
                                const name = target.name;
                                this.setState({
                                    [name]: value
                                });
                            }} />
                    </div>
                </Form>
            </Drawer>

        )
    }
}

export default ShiftAdd
