import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import DropWrapper from "./component/DropWrapper";
import TaskItem from "./component/TaskItem";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import {Checkbox, Modal, Select} from "antd";
import CKEditor from "react-ckeditor-component";
import { toast } from "react-toastify";
import myImage from '../../assets/images/Mask_group.png';
import '../styling.css'
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";

export class TaskList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            idDel: '',
            filter_title: '',
            task_id: "",
            task_title: "",
            task_desc: "",
            task_deadline: "",
            task_karyawan: [],
            task_daily: false,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            modalAdd: false,
            modalDetail: false,
            guide: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    onEditorStateChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            task_desc: newContent
        })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleOk = e => {
        if(!this.state.loading) {
            this.setState({
                modalAdd: false,
                modalDetail: false,
            });
        }
    };

    handleCancel = e => {
        if(!this.state.loading) {
            this.setState({
                modalAdd: false,
                modalDetail: false,
            });
        }
    };

    resetFilter(event) {
        this.setState({
            filter_title: '',
            loading: true
        }, () => this.loadData())
    }

    moveTaskItem = (dragIndex, hoverIndex) => {
        const item = this.state.data[dragIndex];
        const newItems = this.state.data.filter((i, idx) => idx !== dragIndex);
        newItems.splice(hoverIndex, 0, item);
        this.setState({
            data: newItems
        })
    }

    dropTaskItem = (item, monitor, status) => {
        const newItems = this.state.data
            .filter((i) => i.task_id !== item.item.task_id)
            .concat({ ...item.item, task_status: status });
        this.changeCardStatus(item.item.task_id, status)
        this.setState({
            data: newItems
        })
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Tugas ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.task_id
                    }
                    this.setState({
                        modalDetail: false,
                        data: [],
                        loading: true
                    })
                    this.props.request("task/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                toast.success(response.message)
                                this.loadData()
                            }
                        });
                }
            })
    }

    changeCardStatus(id, status) {
        toast.dismiss()
        toast.info('Menyimpan...', {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
        let form = {
            id: id,
            status: status
        }

        this.props.request("task/change-status", form, 'POST')
            .then(response => {
                if (response.success) {
                    toast.dismiss()
                }
            });
    }

    createTask() {
        this.setState({
            loading: true
        })
        let form = {
            title: this.state.task_title,
            desc: this.state.task_desc,
            deadline: this.state.task_deadline,
            karyawan: this.state.task_karyawan,
            daily: this.state.task_daily ? "1" : "0",
        }
        this.props.request("task/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.loadData()
                    this.setState({
                        modalAdd: false,
                        task_title: "",
                        task_desc: "",
                        task_deadline: "",
                        task_karyawan: [],
                        loading: false
                    })
                }
            });
    }

    updateTask() {
        this.setState({
            loading: true
        })
        let form = {
            id: this.state.task_id,
            title: this.state.task_title,
            desc: this.state.task_desc,
            deadline: this.state.task_deadline,
            karyawan: this.state.task_karyawan,
            daily: this.state.task_daily ? "1" : "0",
        }
        this.props.request("task/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        loading: false,
                    })
                }
            });
    }

    openTaskDetail(item){
        this.setState({
            modalDetail: true,
            task_id: item.task_id,
            task_title: item.task_title,
            task_desc: item.task_desc,
            task_deadline: item.task_deadline,
            task_daily: item.task_daily == '1' ? true : false,
            task_karyawan: item.karyawan ? item.karyawan.map(function (item) {
                return parseInt(item.karyawan_id);
            }) : null,
        })
    }

    searchKaryawan(value){
        let form = {
            karyawan_name : value
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response)=> {
                if (response.success){
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }

    loadKaryawan(){
        let form = {}
        this.props.request("karyawan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        loading         : false
                    })
                }
            });
    }

    loadData() {
        let form = {
            title: this.state.filter_title
        }
        this.props.request("task/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        loading: false
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.loadKaryawan()
    }

    render() {
        const { Option } = Select;
        const steps = [
            {
                selector: '.tambah',
                content: 'Anda dapat membuat Pengumuman disini',
            },
            {
                selector: '.daftar-tugas',
                content: 'Daftar Pengumuman anda dapat dilihat disini',
            },
        ];
        if (this.props.user && this.props.user.perusahaan.langganan && this.props.user.perusahaan.langganan.paket.paket_premium == "1") {
            if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Tugas") >= 0) {
                return (
                    <Fragment>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>Daftar Tugas - Presensi</title>
                            <meta name="description" content="Daftar Tugas" />
                        </Helmet>
                        <Breadcrumb title="Daftar Tugas" parent="Tugas" setGuide={() => {
                            this.setState({ guide: true })
                        }} />
                        <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody}
                              onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header border-0 p-4">
                                    <div className='row'>
                                        <div className='col-md-6 pengumuman-col-filter'>
                                            <div className='d-flex flex-row flex-nowrap'>
                                                <div className='input-group mr-1 btn-search-tugas'>
                                                <span className='input-group-text pengumuman-filter-nama-input-text'>
                                                    <img alt='' src={SearchIcon} />
                                                </span>
                                                    <input name="filter_title"
                                                           className="form-control pengumuman-filter-nama-input"
                                                           placeholder="Cari Tugas..."
                                                           type="text"
                                                           required=""
                                                           value={this.state.filter_title}
                                                           style={{ display: "inline-block", boxSizing: 'border-box' }}
                                                           onChange={this.handleChange}
                                                           onKeyUp={(event) => {
                                                               if (event.which == 13) {
                                                                   event.preventDefault()
                                                                   this.setState({
                                                                       page: 0,
                                                                       paging: this.state.paging,
                                                                       loading: true
                                                                   }, () => {
                                                                       this.loadData()
                                                                   });
                                                               }
                                                           }} />
                                                </div>
                                                <button className='pengumuman-button-refresh' onClick={this.resetFilter}>
                                                    <img alt='' src={RefreshIcon} />
                                                </button>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                <button className='pengumuman-add-button' onClick={() => this.setState({
                                                    modalAdd: true
                                                })}><img alt='' src={PlusIcon} /> Tambah</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body daftar-pengumuman">
                                    <div className="row">
                                        <div className="col-wrapper col-md-4 mb-3">
                                            <div style={{
                                                padding: 16,
                                                borderStyle: 'solid',
                                                borderWidth: 1,
                                                borderRadius: '12px 12px 0px 0px',
                                                backgroundColor: '#E8F4FF',
                                                borderColor: '#fff',
                                            }}>
                                                <h4 style={{ fontSize: 'medium', fontWeight: 'bold', margin: '0' }} className="text-left">
                                                    Draft
                                                </h4>
                                            </div>
                                            {this.state.data.filter((i) => i.task_status === "draft").length > 0 ?
                                                <DropWrapper onDrop={this.dropTaskItem} status={"draft"}>
                                                    <div style={{
                                                        padding: 16,
                                                        borderStyle: 'solid',
                                                        borderWidth: 1,
                                                        borderRadius: '0px 0px 12px 12px',
                                                        backgroundColor: '#E8F4FF',
                                                        borderColor: '#fff',
                                                    }}>
                                                        {this.state.data
                                                            .filter((i) => i.task_status === "draft")
                                                            .map((item, index) => (
                                                                <div key={item.task_id} onClick={() => {this.openTaskDetail(item)}}>
                                                                    <TaskItem
                                                                        key={item.task_id}
                                                                        item={item}
                                                                        index={index}
                                                                        moveItem={this.moveTaskItem}
                                                                        status={"draft"}
                                                                    />
                                                                </div>
                                                            ))}
                                                    </div>
                                                </DropWrapper>
                                                :
                                                <DropWrapper onDrop={this.dropTaskItem} status={"draft"}>
                                                    <div className="p-3">
                                                        <div className="media static-top-widget">
                                                            <div className="media-body">
                                                                <p className={'item-title text-center'}>Data Kosong</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </DropWrapper>
                                            }
                                        </div>

                                        <div className="col-wrapper col-md-4 mb-3">
                                            <div style={{
                                                padding: 16,
                                                borderStyle: 'solid',
                                                borderWidth: 1,
                                                borderRadius: '12px 12px 0px 0px',
                                                backgroundColor: '#E8F4FF',
                                                borderColor: '#fff',
                                            }}>
                                                <h4 style={{ fontSize: 'medium', fontWeight: 'bold', margin: '0' }} className="text-left">
                                                    Sedang Dikerjakan
                                                </h4>
                                            </div>
                                            {this.state.data.filter((i) => i.task_status === "inprogress").length > 0 ?
                                                <DropWrapper onDrop={this.dropTaskItem} status={"inprogress"}>
                                                    <div style={{
                                                        padding: 16,
                                                        borderStyle: 'solid',
                                                        borderWidth: 1,
                                                        borderRadius: '0px 0px 12px 12px',
                                                        backgroundColor: '#E8F4FF',
                                                        borderColor: '#fff',
                                                    }}>
                                                        {this.state.data
                                                            .filter((i) => i.task_status === "inprogress")
                                                            .map((item, index) => (
                                                                <div onClick={() => {this.openTaskDetail(item)}}>
                                                                    <TaskItem
                                                                        key={item.task_id}
                                                                        item={item}
                                                                        index={index}
                                                                        moveItem={this.moveTaskItem}
                                                                        status={"inprogress"}
                                                                    />
                                                                </div>
                                                            ))}
                                                    </div>
                                                </DropWrapper>
                                                :
                                                <DropWrapper onDrop={this.dropTaskItem} status={"inprogress"}>
                                                    <div className="p-3">
                                                        <div className="media static-top-widget">
                                                            <div className="media-body">
                                                                <p className={'item-title text-center'}>Data Kosong</p></div>
                                                        </div>
                                                    </div>
                                                </DropWrapper>
                                            }
                                        </div>
                                        <div className="col-wrapper col-md-4">
                                            <div style={{
                                                padding: 16,
                                                borderStyle: 'solid',
                                                borderWidth: 1,
                                                borderRadius: '12px 12px 0px 0px',
                                                backgroundColor: '#E8F4FF',
                                                borderColor: '#fff',
                                            }}>
                                                <h4 style={{ fontSize: 'medium', fontWeight: 'bold', margin: '0' }} className="text-left">
                                                    Selesai Dikerjakan
                                                </h4>
                                            </div>
                                            {this.state.data.filter((i) => i.task_status === "finished").length > 0 ?
                                                <DropWrapper onDrop={this.dropTaskItem} status={"finished"}>
                                                    <div style={{
                                                        padding: 16,
                                                        borderStyle: 'solid',
                                                        borderWidth: 1,
                                                        borderRadius: '0px 0px 12px 12px',
                                                        backgroundColor: '#E8F4FF',
                                                        borderColor: '#fff',
                                                    }}>
                                                        {this.state.data
                                                            .filter((i) => i.task_status === "finished")
                                                            .map((item, index) => (
                                                                <div onClick={() => {this.openTaskDetail(item)}}>
                                                                    <TaskItem
                                                                        key={item.task_id}
                                                                        item={item}
                                                                        index={index}
                                                                        moveItem={this.moveTaskItem}
                                                                        status={"finished"}
                                                                    />
                                                                </div>
                                                            ))}
                                                    </div>
                                                </DropWrapper>
                                                :
                                                <DropWrapper onDrop={this.dropTaskItem} status={"finished"}>
                                                    <div className="p-3">
                                                        <div className="media static-top-widget">
                                                            <div className="media-body">
                                                                <p className={'item-title text-center'}>Data Kosong</p></div>
                                                        </div>
                                                    </div>
                                                </DropWrapper>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal
                                title="Tugas Baru"
                                visible={this.state.modalAdd}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                                footer={[
                                    this.state.loading ?
                                        <button className={'btn btn-primary'} style={{ textTransform: "none" }} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Menyimpan data</button>
                                        :
                                        <>
                                            <button className={'btn btn-primary'} style={{ textTransform: "none" }} onClick={() => {this.createTask()}}>Simpan</button>
                                        </>
                                ]}
                            >
                                <div>
                                    <div className="mb-2 row" style={{margin: 0}}>
                                        <div className="col-auto p-0">
                                            <Checkbox
                                                onChange={(val) => {
                                                    this.setState({
                                                        task_daily: val.target.checked
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <div>Tugas Harian<small className="text-muted">(Tugas akan muncul setiap hari pada App Karyawan)</small></div>
                                        </div>
                                    </div>
                                    <span className="mr-2" style={{ fontWeight: 'bold', fontSize: 'smaller' }}>Deadline</span>
                                    <div className="row align-items-center mt-1 mb-1" style={{margin: "auto"}}>
                                        <input name="task_deadline" value={this.state.task_deadline == null ? "0000-00-00" : this.state.task_deadline} className="form-control col-xl-8 col-md-7" onChange={this.handleChange}
                                               type="date" required="" />
                                    </div>
                                    <span className="mr-2" style={{ fontWeight: 'bold', fontSize: 'smaller' }}>Karyawan</span>
                                    <div className="row align-items-center mt-1 mb-2" style={{margin: "auto"}}>
                                        <Select name="task_karyawan"
                                                className={"col"}
                                                mode={"multiple"}
                                                placeholder="Pilih Karyawan (Kosongkan Untuk Semua Karyawan)"
                                                value={this.state.task_karyawan}
                                                onSearch={(value) => this.searchKaryawan(value)}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(event) => {
                                                    this.setState({
                                                        task_karyawan: event
                                                    })
                                                }}>
                                            {this.state.karyawan.map((item, index) => {
                                                return (<Option value={item.karyawan_id} key={item.karyawan_id}>{item.karyawan_nama}</Option>)
                                            })}
                                        </Select>
                                    </div>
                                    <span className="mr-2" style={{ fontWeight: 'bold', fontSize: 'smaller' }}>Judul Tugas</span>
                                    <div className=" mt-1 mb-4">
                                        <input name="task_title"
                                               className="form-control smaller-placeholder"
                                               placeholder="Ketik judul tugas disini"
                                               type="text" required="" value={this.state.task_title} onChange={this.handleChange}/>
                                    </div>
                                    <div>
                                        <CKEditor
                                            content={this.state.task_desc}
                                            events={{
                                                change:this.onEditorStateChange
                                            }}
                                        />
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                title="Detail Tugas"
                                visible={this.state.modalDetail}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                                footer={[
                                    this.state.loading ?
                                        <button className={'btn btn-primary'} style={{ textTransform: "none" }} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Menyimpan data</button>
                                        :
                                        <>
                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                            <button className={'btn btn-danger mr-3'} style={{ textTransform: "none" }} onClick={() => {this.wantDelete()}}>Hapus</button>
                                        )}
                                            <button className={'btn btn-primary'} style={{ textTransform: "none" }} onClick={() => {this.updateTask()}}>Simpan</button>
                                        </>
                                ]}
                            >
                                <div>
                                    <div className="mb-2 row" style={{margin: 0}}>
                                        <div className="col-auto p-0">
                                            <Checkbox
                                                checked={this.state.task_daily}
                                                onChange={(val) => {
                                                    this.setState({
                                                        task_daily: val.target.checked
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <div>Tugas Harian<small className="text-muted">(Tugas akan muncul setiap hari pada App Karyawan)</small></div>
                                        </div>
                                    </div>
                                    <span className="mr-2" style={{ fontWeight: 'bold', fontSize: 'smaller' }}>Deadline</span>
                                    <div className="row align-items-center mt-2 mb-2" style={{margin: "auto"}}>
                                        <input name="task_deadline" value={this.state.task_deadline == null ? "0000-00-00" : this.state.task_deadline} className="form-control col-xl-8 col-md-7" onChange={this.handleChange}
                                               type="date" required=""/>
                                    </div>
                                    <span className="mr-2" style={{ fontWeight: 'bold', fontSize: 'smaller' }}>Karyawan</span>
                                    <div className="row align-items-center mt-1 mb-2" style={{margin: "auto"}}>
                                        <Select name="task_karyawan"
                                                className={"col"}
                                                mode={"multiple"}
                                                placeholder="Pilih Karyawan (Kosongkan Untuk Semua Karyawan)"
                                                value={this.state.task_karyawan}
                                                onSearch={(value) => this.searchKaryawan(value)}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(event) => {
                                                    this.setState({
                                                        task_karyawan: event
                                                    })
                                                }}>
                                            {this.state.karyawan.map((item, index) => {
                                                return (<Option value={item.karyawan_id} key={item.karyawan_id}>{item.karyawan_nama}</Option>)
                                            })}
                                        </Select>
                                    </div>
                                    <span className="mr-2" style={{ fontWeight: 'bold', fontSize: 'smaller' }}>Judul Tugas</span>
                                    <div className=" mt-1 mb-4">
                                        <input name="task_title" className="form-control" placeholder="Judul Tugas"
                                               type="text" required="" value={this.state.task_title} onChange={this.handleChange}/>
                                    </div>
                                    <div>
                                        <CKEditor
                                            content={this.state.task_desc}
                                            events={{
                                                change:this.onEditorStateChange
                                            }}
                                        />
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                    </Fragment>
                )
            }
        }else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Fitur ini hanya tersedia untuk Paket Premium+.</h5>
                    <h5 className="text-center pt-4"><a href="/langganan">Upgrade ke Premium+.</a></h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
