import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Swal from "sweetalert2";
import { set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import Switch from "react-switch";
import { Helmet } from "react-helmet";
import InfoIcon from "../../../assets/icons/info-icon-blue.svg";
import {Modal, ModalBody} from "reactstrap";
import "../../styling.css"

export class PresensiSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: true,
            data: {},
            greet: '',
            quote: '',
            show_alpa: true,
            info_alpa: false,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    saveSettingMobile = () => {
        this.setState({
            loading: true,
        })
        let form = {
            show_alpa: this.state.show_alpa,
        }
        this.props.request("setting-alpa/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    loadData() {
        this.setState({
            loaded: false,
            loading: true
        })
        let form = {}
        this.props.request("setting-alpa/list", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        loaded: true,
                        show_alpa: response.data.setting_absen_alpa === "true",
                    })
                }
            })
        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        console.log(this.state.info_alpa);
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Pengatura Presensi</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>

                    <Breadcrumb title="Pengaturan Presensi" />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                                {this.state.loaded ?
                                                    <div className="mt-4">
                                                        <label className="header-input-basic">Pengaturan Alpa</label>
                                                        <div className="row">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Otomatisasi Alpa  <img className="icon-info" onClick={() => this.setState({info_alpa: true})} src={InfoIcon} alt=""/> </label>

                                                            <Switch
                                                                id={'show_alpa'}
                                                                name="show_alpa"
                                                                checked={this.state.show_alpa}
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        show_alpa: val
                                                                    })
                                                                }}
                                                                onColor="#1890ff"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1"
                                                            />
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                        <div className="pull-right mt-4">
                                            {this.state.loading == false ?
                                                <button type="button" className="btn btn-primary" disabled={!this.state.loaded} onClick={() => {this.saveSettingMobile()}}>Simpan</button>
                                                :
                                                <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin" /> Loading</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.info_alpa === true ?
                        <Modal className="modaling-style" size={"sm"} isOpen={this.state.info_alpa} toggle={() => this.setState({info_alpa: false})}>
                            <ModalBody>
                                <p>Fitur <strong>Otomatisasi Alpa</strong> digunakan untuk membuat data alpa secara otomatis waktu karyawan membuka aplikasi presensi mobile. Fitur tersebut membuat data alpa dari data hari sebelumnya yang ada jatah shift namun tidak ada data presensi masuk/keluar.</p>
                            </ModalBody>
                        </Modal> : ""
                    }
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    user: state.Auth.user,
    api_token: state.Auth.api_token,
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PresensiSetting)
