import React, {Component, Fragment} from 'react';
import queryString from 'query-string';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";
import validation from '../../helper/validation';
import logo from "../../assets/images/dashboard/logo-header.png";
import Swal from "sweetalert2";

export class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password_baru: '',
            konfirmasi_password: '',
            loading: true,
            success: false,
            invalidToken: false,
            invalidEmail: false,
            message: '',
            tick: 5,
            reqLink: false
        }
        this.verify = this.verify.bind(this);
        this.reqLink = this.reqLink.bind(this);
    }

    verify = ({ email, token }) => {
        const form = {
            email,
            token
        }

        this.props.request("verify", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        success: true,
                        message: response.message
                    })
                    setInterval(() => {
                        this.setState(state => {
                            return {
                                tick: state.tick -1
                            }
                        })
                    }, 1000)
                    setTimeout(() => {
                        this.props.history.push('/login')
                    }, this.state.tick * 1000)
                } else {
                    this.setState({
                        loading: false,
                        success: false,
                        message: response.message
                    })
                }
            });
    }

    reqLink() {
        const form = {
            email: this.state.email
        }

        this.setState({ loading: true })
        this.props.request("req-resubmit-verify", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        success: true,
                        message: response.message
                    })
                } else {
                    this.setState({
                        loading: false,
                        success: false,
                        message: response.message
                    })
                }
            });
    }

    onChangeText = ({ target }) => {
        this.setState({
            [target.name]: target.value
        })
    }

    submitReset = () => {
        const queries = queryString.parse(this.props.location.search);

        this.setState({
            prosesSimpan: true,
        })
        let form = {
            email: queries.email,
            password: this.state.password_baru,
            konfirmasi_password: this.state.konfirmasi_password
        }
        this.props.request("konfirmasi_password_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/login')
                }else{
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    componentDidMount() {
        const queries = queryString.parse(this.props.location.search);
        const emailCheck = validation(queries.email);
        if (queries.email == undefined || queries.token == undefined) {
            this.setState({
                loading: false,
                success: false,
                message: 'Link verifikasi tidak valid!'
            });
        } else {
            let linkValid = true;
            if (emailCheck === true) {
                this.setState({ email: queries.email });
            } else {
                linkValid = false;
                this.setState({
                    invalidEmail: true,
                    message: emailCheck
                });
            }

            if(queries.token.trim().length !== 40) {
                linkValid = false;
                this.setState({
                    invalidToken: true,
                    message: 'Token tidak valid!'
                });
            }
            if (linkValid) {
                this.verify(queries);
            } else {
                this.setState({
                    loading: false,
                    success: false,
                });
            }
        }
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Reset Password - Presensi</title>
                    <meta name="description" content="Reset Passwordl Aplikasi Presensi" />
                </Helmet>
                <div className="page-wrapper text-left">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <div >
                                                <div style={{ textAlign: 'center' }} className="card-header">
                                                    <div className="container">
                                                        <div className="logo-wrapper mb-3">
                                                            <img className="blur-up lazyloaded" src={logo} alt="" />
                                                        </div>
                                                        <div>
                                                            <h4>Masukkan Password Baru Anda</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal auth-form">
                                                        <div className="form-group">
                                                            <input required="" name="password_baru" onChange={(event) => this.onChangeText(event)} type="password"
                                                                   className="form-control" placeholder="Password Baru" />
                                                        </div>
                                                        <div className="form-group">
                                                            <input required="" name="konfirmasi_password" onChange={(event) => this.onChangeText(event)} type="password"
                                                                   className="form-control" id={"password"} placeholder="Konfirmasi Password"
                                                                   onKeyUp={(e)=>{
                                                                       if (e.key === 'Enter') {
                                                                           e.preventDefault()
                                                                           this.submitLogin()
                                                                       }}
                                                                   }

                                                            />
                                                        </div>
                                                        <div className="form-button">
                                                            <a className="btn btn-primary" type="submit" style={{ backgroundColor: 'gray !important', color: "white" }}
                                                               onClick={() => {
                                                                   this.submitReset()
                                                               }}
                                                            >Reset Password</a>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default ResetPassword;

