import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import {APIKEY, BACKEND_API_HOST} from "../../helper/host";
import { GoogleApiWrapper } from 'google-maps-react';
import { Empty, Modal, Spin } from 'antd';
import { Helmet } from "react-helmet";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Add from "./add";
import Edit from "./edit";
import { pageNumber } from "../../functions/functionGeneral";
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import refreshIcon from "../../assets/icons/refresh.svg";
import plusIconWhite from "../../assets/icons/IconPlusWhite.svg";
import "./style.css";
import deleteImages from "../../assets/images/delete-images.png"
import { ModalBody, ModalFooter } from "reactstrap";
import ImportIcon from "../../assets/icons/import-icon.svg";

export class List_istillah extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            add_visible: false,
            visable: false,
            filter_nama: '',
            filter_type: '',
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 25,
            modalEdit: false,
            modalDelete: false,
            modalimport: false,
            importFile: null,
            id_del: null
        }
        this.handleChange = this.handleChange.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.importExcel = this.importExcel.bind(this)

    }
    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }
    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_type: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete() {
        let form = {
            id: this.state.id_del
        }
        this.props.request("payroll/delete_term", form, 'POST')
            .then(response => {
                if (response.success == true) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 4000,
                    })
                    this.setState({
                        loading: true,
                        modalDelete: false,
                        data: []
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 5000,
                    })
                    this.setState({
                        loading: true,
                        data: []
                    })
                    this.loadData()
                }
            });
    }

    loadData() {
        this.setState({
            loading: true,
        })
        let form = {
            filter_nama: this.state.filter_nama,
            filter_type: this.state.filter_type,
            paginate: this.state.paging,
            page: this.state.currentPage + 1,
        }
        this.props.request("payroll/list_payroll_term", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    handleRowsPerPageChange = (newRowsPerPage) => {
        this.setState({ paging: newRowsPerPage }, () => {
            this.loadData();
        });
    };

    exportIstilahGaji = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'payroll/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'payroll/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;

        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Daftar Istillah") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Istilah Gaji - Presensi</title>
                        <meta name="description" content="Daftar Istilah" />
                    </Helmet>
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Edit
                        data={this.state}
                        modalEdit={this.state.modalEdit}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        setState={(val, callback) => this.setState(val, callback)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    {this.state.modalDelete &&
                    <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({ modalDelete: false})} style={{
                        top: "20%"
                    }}>
                        <ModalBody align={"center"}>
                            <img src={deleteImages} alt="" />
                            <h3 style={{
                                "fontWeight": 700,
                                "fontSize": "18px",
                                "lineHeight": "27px",
                                "color": "#143B5E"
                            }}
                            >Peringatan</h3>
                            <p>Anda yakin ingin menghapus istilah Lembur?</p>
                            <ModalFooter style={{
                                borderTop: 0,
                                backgroundColor: "#FFFFFF",
                                borderBottomLeftRadius: "8px",
                                borderBottomRightRadius: "8px",
                                justifyContent: "space-between"
                            }}>
                                            <button className="button-no" onClick={() => this.setState({ modalDelete: false})}>
                                                Batal
                                            </button>
                                            <>
                                            {/*{this.state.data.map((item, index) => {*/}
                                            {/*    return (*/}
                                                    <button className="button-yes" onClick={() => this.setState({
                                                        idDel: this.state.data.p_term_id
                                                    },this.wantDelete)}>
                                                        Yakin
                                                    </button>
                                            {/*)})}*/}
                                            </>
                            </ModalFooter>
                        </ModalBody>
                    </Modal>
                    }
                    <div className='row pt-4'>
                        <div className='col-md-12 header-istilah'>
                            <div className='row'>
                                <div className='col-md-12' style={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "16px",
                                    color: "#5D6F80"
                                }}>Presensi Admin Panel</div>
                            </div>
                            <div className='row' style={{ marginBottom: "28px" }}>
                                <div className='col-md-12' style={{
                                    fontSize: "24px",
                                    lineHeight: "36px",
                                    fontWeight: 700,
                                    color: "#143B5E"
                                }}>Daftar Istilah Gaji</div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header pb-0 px-3 mb-3" style={{ borderBottom: 'none' }}>
                                    <div className="row mx-0" style={{marginLeft: '5px'}}>
                                        <div className="col-md-4">
                                            <input name="filter_nama"
                                                   className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name input-select-style" : "form-control filter-name input-select-style"}
                                                   placeholder="       Cari nama istilah..."
                                                   type="text"
                                                   style={{
                                                       backgroundColor: "#F9F9F9",
                                                       fontSize: "12px",
                                                       fontWeight: 400,
                                                       paddingTop: "9px",
                                                       paddingBottom: "9px"
                                                   }}
                                                   required=""
                                                   value={this.state.filter_nama}
                                                   onChange={this.handleChange}
                                                   onKeyUp={(event) => {
                                                       event.preventDefault()
                                                       if (event.which == 13) {

                                                           this.setState({
                                                               page: 0,
                                                               paging: this.state.paging,
                                                               loading: true
                                                           }, () => {
                                                               this.loadData()
                                                           });
                                                       }
                                                   }}
                                            />
                                        </div>
                                        <div className="col-md-3 px-1">
                                            <select name="filter_type"
                                                    className="form-control input-select-style select-column"
                                                    onChange={this.handleFilter}
                                                    style={{ marginLeft: "0" }}
                                            >
                                                <option value="" selected={this.state.filter_type === ''}>Pilih
                                                    Tipe
                                                </option>
                                                <option value="kompensasi">Kompensasi</option>
                                                <option value="potongan">Potongan</option>
                                            </select>
                                        </div>
                                        <div className="px-2 mx-1">
                                            <button className="input-select-style button-reset"
                                                    onClick={this.resetFilter}><img src={refreshIcon} alt=""/>
                                            </button>
                                        </div>
                                        <div className="col"></div>
                                        <div className='col-auto px-0'>
                                            <button className="karyawan-import-button" style={{ height: "45px", marginTop: "10px" }} onClick={() => {
                                                this.setState({modalimport: true})
                                            }}><img src={ImportIcon} alt=''/> Import
                                            </button>
                                        </div>
                                        <div className='col-auto'>
                                            {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                <a className="btn btn-primary tambah-term"
                                                   style={{float: "right", marginTop: "10px", color: 'white', height: "45px"}}
                                                   onClick={() => {
                                                       this.setState({
                                                           add_visible: true
                                                       })
                                                   }}><img src={plusIconWhite} alt=""/> Tambah</a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {this.state.loading ?
                                    <div className="card-body">
                                        <Spin size="large" tip="Sedang memuat data..." style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }} />
                                    </div>
                                    :
                                    <div className="card-body">
                                        {!this.state.loading && this.state.data.length === 0 ?
                                            <Empty description="Data Kosong"
                                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                            :
                                            (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                                <Table striped className="mb-5">
                                                <Thead>
                                                    <Tr>
                                                        <Th width="30">#</Th>
                                                        <Th width="150" className="text-left">Nama</Th>
                                                        <Th width="150" className="text-left">Type</Th>
                                                        <Th width="100" className="text-center">Aksi</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {this.state.loading &&
                                                        <Tr>
                                                            <Td colSpan={9}>
                                                                <Spin size="large" tip="Loading..." style={{
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px'
                                                                }} />
                                                            </Td>
                                                        </Tr>
                                                    }
                                                    {this.state.data.map((item, index) => {
                                                        no += 1
                                                        return (
                                                            <Tr className="table-istilah">
                                                                <small>
                                                                    <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                </small>
                                                                <Td align="left">
                                                                    {item.p_term_name}
                                                                </Td>
                                                                <Td align="left">
                                                                    {item.p_term_type === 'kompensasi' ?
                                                                        <div style={{ color:"#6BAA61" }}>Kompensasi</div>
                                                                        :
                                                                        <div style={{ color:"#E03616" }}>Potongan</div>
                                                                    }
                                                                </Td>
                                                                <Td align={"center"}>
                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                        <a onClick={() => {
                                                                            this.setState({
                                                                                p_term_id: item.p_term_id,
                                                                                p_term_type: item.p_term_type,
                                                                                p_term_name: item.p_term_name,
                                                                                modalEdit: true,
                                                                            })
                                                                        }}>
                                                                            <button className={"btn-edit"}>Edit</button>
                                                                        </a>
                                                                    )}
                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                        <a onClick={() => {
                                                                            this.setState({
                                                                                modalDelete: true,
                                                                                id_del: item.p_term_id
                                                                            })
                                                                        }}><button className="btn-delete">Hapus</button></a>
                                                                    )}
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    })}
                                                </Tbody>
                                            </Table>
                                            )
                                        }
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>
                                            menampilkan {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} dari {this.state.totalData} data, Tampilkan
                                            <button className='button border px-1 ml-1' onClick={() => this.handleRowsPerPageChange(25)}>25</button>
                                            <button className='button border px-1 ml-1' onClick={() => this.handleRowsPerPageChange(50)}>50</button>
                                            <button className='button border px-1 ml-1' onClick={() => this.handleRowsPerPageChange(100)}>100</button>
                                        </div>
                                        <Paging
                                            className="float-right"
                                            handlePageClick={this.handlePageClick}
                                            pageCount={this.state.pageCount}
                                            currentPage={this.state.currentPage}
                                        />
                                        <Modal
                                            title="Import Istilah Gaji"
                                            visible={this.state.modalimport}
                                            // isOpen={this.state.modalimport}
                                            onOk={this.handleOk}
                                            onCancel={this.handleCancel}
                                            footer={[
                                                this.state.importLoading ?
                                                    <button className={'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Sedang mengupload data</button>
                                                    :
                                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                                            ]}
                                        >
                                            <div>
                                                <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                                                <a onClick={this.exportIstilahGaji} style={{ color: "#057CE4" }}>Unduh template disini</a>
                                                <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                                    <input type='file'
                                                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                           onChange={(event) => {
                                                               this.setState({
                                                                   importFile: event.target.files[0],
                                                               })
                                                           }} /><br />
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}


const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: APIKEY
})(List_istillah));
