import React, { Component, Fragment, useState } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Empty, Spin, Tooltip } from 'antd';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import ReactPaginate from 'react-paginate';
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import moment from "moment";
// import Edit from "./edit-lembur";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { pageNumber } from "../../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import ArrowUpIcon from "../../../assets/icons/arrow-up.svg";
import ArrowDownIcon from "../../../assets/icons/arrow-down.svg";
import RefreshIcon from "../../../assets/icons/refresh.svg";
import SearchIcon from "../../../assets/icons/search.svg";
import ElipsisIcon from "../../../assets/icons/ellipsis-vertical.svg";
import CheckIcon from "../../../assets/icons/Check Blue.svg";
import CrossMarkIcon from "../../../assets/icons/cross-mark.svg";
import PlusWhiteIcon from "../../../assets/icons/plus-white.svg";
import TrashIcon from "../../../assets/icons/trash.svg";
import "../style.css";
import Select from 'react-select';

export class List_revisi_data extends Component {
    constructor(props) {
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        super(props);
        this.state = {
            loading: true,
            revisi: [],
            perusahaan: [],
            karyawan: [],
            revisi_type: ['profile'],
            revisi_email: [],
            revisi_password: [],
            karyawan_nama: [],
            data: [],
            dataEdit: [],
            idDel: '',
            filter_nama: "",
            approvedRevisi: '',
            rejectRevisi: '',
            confirmApprove: false,
            confirmReject: false,
            checkAll: false,
            indeterminate: true,
            checked: [],
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            guide: false,
            month: namabulan[bulan],
            year: tahun,
            monthNow: namabulan[bulan],
            yearNow: tahun,
            totalPerPage: 0,
            checked_all: false,
            add_revisi_id: 0,
            add_karyawan_id: 0,
            add_perusahaan_id: 0,
            add_revisi_type: ['profile'],
            edit_revisi_id: 0,
            edit_karyawan_id: 0,
            edit_perusahaan_id: "",
            edit_revisi_type: ['profile'],
            action: false,
            editData: {}
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handlePaging = this.handlePaging.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.searchKaryawan = this.searchKaryawan.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            currentPage: 0,
            [name]: value,
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
            currentPage: 0,
            datas: [],
            loading: true
        }, () => this.loadData())
    }

    handlePaging(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        }, () => {
            this.loadData()
        })
    }

    // handleCheckBox(id) {
    //     {
    //         this.state.checked.includes(id) == true ?
    //             this.setState({
    //                 checked: this.state.checked.slice(this.state.checked.indexOf(id), 1)
    //             })
    //             :
    //             this.state.checked.push(id)
    //     }
    // }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            loading: true,
            month: this.state.monthNow,
            year: this.state.yearNow
        }, () =>
            this.loadData())
    }

    handleCheckBoxChange(id) {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checked_all: true
                    })
                } else {
                    this.setState({
                        checked_all: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checked_all: true
                    })
                } else {
                    this.setState({
                        checked_all: false
                    })
                }
            })
    }

    handleCheckAll(e) {
        let value = e.target.checked
        if (value) {
            this.state.data.map((item) => {
                if (!this.state.checked.includes(item.lembur_id)) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            checked: [...prevState.checked, item.lembur_id]
                        }
                    }, () => {
                        this.setState({
                            checked_all: true
                        })
                    })
                }
            })
        } else {
            this.setState({
                checked_all: false,
                checked: []
            })
        }
    }
    approvedRevisi = () => {
        this.setState({
            data: [],
            confirmApprove: false,
            loading: true
        })
        let form = {
            id: this.state.approvedRevisi
        }
        this.refs.btn.setAttribute("disabled", "disabled");
        this.props.request("revisi/approved", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            });
    }

    rejectRevisi = () => {
        this.setState({
            data: [],
            loading: true,
            confirmReject: false,
            rejectModal: false
        })
        let form = {
            id: this.state.rejectRevisi,
            reason_reject: this.state.reason_reject
        }
        this.refs.btn.setAttribute("disabled", "disabled");
        this.props.request("revisi/reject", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            });
    }

    wantDelete() {
        this.setState({
            data: [],
            loading: true
        })
        let form = {
            id: this.state.idDel
        }
        this.refs.btn.setAttribute("disabled", "disabled");
        this.props.request("revisi/delete", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        confirmDelete: false
                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            });
    }

    handleEdit = () => {
        let form = {
            id: this.state.edit_revisi_id,
            perusahaan_id: this.state.edit_perusahaan_id,
            karyawan_id: this.state.edit_karyawan_id,
            type: this.state.edit_revisi_type,
        }
        this.props.request("revisi/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        modalEdit: false,
                        edit_revisi_id: 0,
                        edit_perusahaan_id: 0,
                        edit_karyawan_id: 0,
                        edit_revisi_type: 0,
                    })
                    this.loadData()
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            page: this.state.currentPage + 1,
            month: this.state.month,
            year: this.state.year,
            paginate: 10,
        }
        this.props.request("revisi/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        totalPerPage: response.data.to,
                        loading: false
                    })
                }

            });
    }

    searchKaryawan() {
        let form = {
            karyawan_name: this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }

    searchPerusahaan() {
        let form = {
            perusahaan_name: this.state.perusahaan_name
        }
        this.props.request("perusahaan/search-perusahaan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        perusahaan: response.data,
                        perusahaan_loading: false
                    })
                }
            })
    }

    simpanRevisi = () => {
        let form = {
            karyawan_id: this.state.add_karyawan_id,
            perusahaan_id: this.state.add_perusahaan_id,
            revisi: this.state.add_revisi_id,
            type: this.state.add_revisi_type,
        }

        this.props.request("revisi/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        modalAdd: false,
                        add_perusahaan_id: 0,
                        add_karyawan_id: 0,
                        add_revisi_type: ['profile'],
                        karyawan_selected: undefined
                    })
                    this.loadData()
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }
    sortabledate = (val) => {

        const copyArray = this.state.data

        copyArray.sort((a, b) => {
            return val === 0 ? new Date(a.revisi_tanggal) - new Date(b.revisi_tanggal) : new Date(b.revisi_tanggal) - new Date(a.revisi_tanggal);
        });

        this.setState({
            data: copyArray
        })
    }
    compareBy(key) {
        return function (a, b) {
            if (a[key] < (b[key])) return -1;
            if (a[key] > (b[key])) return 1;
            return 0;
        };
    }
    sortablestatus = (val) => {

        const copyArray = this.state.data

        if (val === 0) {
            copyArray.sort(this.compareBy("revisi_status"));
        } else {
            copyArray.reverse();
        }

        this.setState({
            data: copyArray
        })
    }


    componentDidMount() {
        this.loadData()
    }

    render() {
        const style_add = {
            font_header: {
                "fontWeight": 700,
                "fontSize": "18px",
                "lineHeight": "27px",
                "color": "#143B5E"
            },
            font_label: {
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            font_time: {
                "padding": "16px",
                "textAlign": "center",
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            input: {
                "padding": "16px",
                "borderRadius": "8px",
                "fontWeight": 500,
                "fontSize": "14px",
                "lineHeight": "18px",
                "color": "#143B5E"
            }
        }
        let no = 0;
        let borderBottom = "1px solid #EFEFEF";
        const steps = [
            {
                selector: '.daftar-lembur',
                content: 'Daftar Lembur Karyawan anda dapat dilihat disini',
            },
        ];

        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengajuan Izin") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Pengajuan Revisi - Presensi</title>
                        <meta name="description" content="Detail Absensi" />
                    </Helmet>
                    <Modal centered isOpen={this.state.confirmReject} toggle={() => {
                        this.setState({
                            confirmReject: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmReject: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CrossMarkIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Tolak pengajuan revisi?</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='izin-tolak-button' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmReject: false
                                        })
                                    }}>
                                        Batal
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn btn-primary text-capitalize' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} ref="btn" onClick={this.rejectRevisi}>
                                        Ya
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.confirmApprove} toggle={() => {
                        this.setState({
                            confirmApprove: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmApprove: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#E8F4FF",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CheckIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Setujui pengajuan revisi?</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <button className='izin-tolak-button' onClick={() => {
                                this.setState({
                                    confirmApprove: false
                                })
                            }}>
                                Batal
                            </button>{' '}
                            <button className='btn btn-primary text-capitalize' style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }} ref="btn" onClick={this.approvedRevisi}>
                                Setujui
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.confirmDelete} toggle={() => {
                        this.setState({
                            confirmDelete: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmDelete: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={TrashIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Hapus pengajuan lembur?</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='izin-tolak-button' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmDelete: false
                                        })
                                    }}>
                                        Batal
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn btn-primary text-capitalize' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} ref="btn" onClick={this.wantDelete}>
                                        Iya
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    {/* <Modal centered isOpen={this.state.confirmRejectAll} toggle={() => {
                        this.setState({
                            confirmRejectAll: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmRejectAll: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CrossMarkIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Tolak pengajuan izin/cuti?</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checked.length} pengajuan terpilih</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>+
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='izin-tolak-button' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmRejectAll: false
                                        })
                                    }}>
                                        Batal
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn btn-primary text-capitalize' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.bulkRejectLembur}>
                                        Ya
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    {/* <Modal centered isOpen={this.state.confirmApproveAll} toggle={() => {
                        this.setState({
                            confirmApproveAll: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmApproveAll: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#E8F4FF",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CheckIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Setujui pengajuan izin/cuti?</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checked.length} pengajuan terpilih</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <button className='izin-tolak-button' onClick={() => {
                                this.setState({
                                    confirmApproveAll: false
                                })
                            }}>
                                Batal
                            </button>{' '}
                            <button className='btn btn-primary text-capitalize' style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }} onClick={this.bulkApprovedLembur}>
                                Setujui
                            </button>
                        </ModalFooter>
                    </Modal> */}
                    {/* <Modal centered isOpen={this.state.confirmReject} toggle={() => {
                        this.setState({
                            confirmReject: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmReject: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CrossMarkIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Tolak pengajuan izin/cuti?</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='izin-tolak-button' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmReject: false
                                        })
                                    }}>
                                        Batal
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn btn-primary text-capitalize' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.rejectLembur}>
                                        Ya
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal> */}
                    {/* <Modal centered isOpen={this.state.confirmApprove} toggle={() => {
                        this.setState({
                            confirmApprove: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmApprove: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#E8F4FF",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CheckIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Setujui pengajuan izin/cuti?</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <button className='izin-tolak-button' onClick={() => {
                                this.setState({
                                    confirmApprove: false
                                })
                            }}>
                                Batal
                            </button>{' '}
                            <button className='btn btn-primary text-capitalize' style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }} onClick={this.approvedRevisi}>
                                Setujui
                            </button>
                        </ModalFooter>
                    </Modal> */}
                    {/* <Edit
                        data={this.state}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    /> */}
                    {/* <revisiAdd
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    /> */}
                    {/* <Modal centered isOpen={this.state.confirmDelete} toggle={() => {
                        this.setState({
                            confirmDelete: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmDelete: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={TrashIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Hapus pengajuan lembur?</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='izin-tolak-button' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmDelete: false
                                        })
                                    }}>
                                        Batal
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn btn-primary text-capitalize' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.wantDelete}>
                                        Ya
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal> */}
                    {/* <Modal isOpen={this.state.modalAdd} toggle={() => {
                        this.setState({
                            modalAdd: false,
                            add_perusahaan_id: 0,
                            add_karyawan_id: 0,
                            add_revisi_type: "",
                            add_tanggal_revisi: (new Date()).toISOString().substr(0, 10),
                            karyawan_selected: undefined
                        })
                    }} style={{
                        marginRight: "10px",
                        maxWidth: "419px",
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                modalAdd: false,
                                add_perusahaan_id: 0,
                                add_karyawan_id: 0,
                                add_revisi_type: "",
                                add_tanggal_revisi: (new Date()).toISOString().substr(0, 10),
                                karyawan_selected: undefined
                            })
                        }}>
                            <div style={style_add.font_header}>Tambah Revisi</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <label for="nama_perusahaan" className="form-label" style={style_add.font_label}>Nama Perusahaan</label>
                                    <Select
                                        backspaceRemoves={true}
                                        deleteRemoves={true}
                                        classNames={{
                                            control: () => "form-control"
                                        }}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                padding: "8px 16px",
                                                borderRadius: "8px",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                                color: "#B6B6B6"
                                            })
                                        }}
                                        placeholder="Ketik Nama Perusahaan..."
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        value={this.state.perusahaan_selected}
                                        isLoading={this.state.perusahaan_loading}
                                        onFocus={() => this.setState({ perusahaan_loading: true }, () => this.searchPerusahaan())}
                                        onBlur={() => this.setState({ perusahaan_loading: false })}
                                        onInputChange={(val) => this.setState({ perusahaan_name: val }, () => this.setState({ perusahaan_loading: true }, () => this.searchPerusahaan()))}
                                        options={
                                            this.state.perusahaan != null ? (
                                                this.state.perusahaan.map((item, index) =>
                                                (
                                                    {
                                                        key: index,
                                                        value: item.perusahaan_id,
                                                        label: item.perusahaan_nama,
                                                        shift: item.divisi_sift
                                                    }
                                                )
                                                ))
                                                : ("")
                                        }
                                        onChange={
                                            (perusahaan_selected) => {
                                                if (!perusahaan_selected) {
                                                    this.setState({
                                                        perusahaan_selected: '',
                                                        add_perusahaan_id: 0,
                                                    })
                                                } else {
                                                    this.setState({
                                                        perusahaan_selected,
                                                        add_perusahaan_id: perusahaan_selected.value,
                                                    })
                                                }
                                            }
                                        }
                                        isClearable={() => {
                                            this.setState({
                                                isClearable: true,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <label for="nama_karyawan" className="form-label" style={style_add.font_label}>Nama Karyawan</label>
                                    <Select
                                        backspaceRemoves={true}
                                        deleteRemoves={true}
                                        classNames={{
                                            control: () => "form-control"
                                        }}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                padding: "8px 16px",
                                                borderRadius: "8px",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                                color: "#B6B6B6"
                                            })
                                        }}
                                        placeholder="Ketik Nama Karyawan..."
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        value={this.state.karyawan_id}
                                        isLoading={this.state.karyawan_loading}
                                        onFocus={() => this.setState({ karyawan_loading: true }, () => this.searchKaryawan())}
                                        onBlur={() => this.setState({ karyawan_loading: false })}
                                        onInputChange={(val) => this.setState({ karyawan_name: val }, () => this.setState({ karyawan_loading: true }, () => this.searchKaryawan()))}
                                        options={
                                            this.state.karyawan != null ? (
                                                this.state.karyawan.map((item, index) =>
                                                (
                                                    {
                                                        key: index,
                                                        value: item.karyawan_id,
                                                        label: item.karyawan_nama,
                                                        shift: item.divisi_sift
                                                    }
                                                )
                                                ))
                                                : ("")
                                        }
                                        onChange={
                                            (karyawan_selected) => {
                                                if (!karyawan_selected) {
                                                    this.setState({
                                                        karyawan_selected: '',
                                                        add_karyawan_id: 0,
                                                    })
                                                } else {
                                                    this.setState({
                                                        karyawan_selected,
                                                        add_karyawan_id: karyawan_selected.value,
                                                    })
                                                }
                                            }
                                        }
                                        isClearable={() => {
                                            this.setState({
                                                isClearable: true,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label for="tanggal_revisi" className="form-label" style={style_add.font_label}>Tanggal revisi</label>
                                    <input type="date" onChange={this.handleChange} className="form-control" id="tanggal_revisi" name='add_tanggal_revisi' style={style_add.input} value={this.state.add_tanggal_revisi} placeholder='Pilih Tanggal...' />
                                </div>
                            </div>
                            <div className='row' style={{ marginBottom: "70px" }}>
                                <div className='col-md-12'>
                                    <label for="nama_karyawan" className="form-label" style={style_add.font_label}>Deskripsi Lembur</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Deskripsi Lembur...' onChange={this.handleChange} name='add_deskripsi_lembur'></textarea>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            borderTop: 0,
                            backgroundColor: "#F5F5F5",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                        }}>
                            <button className='izin-tolak-button' onClick={() => {
                                this.setState({
                                    modalAdd: false,
                                    add_karyawan_id: 0,
                                    add_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                                    add_jam_lembur: 0,
                                    add_menit_lembur: 0,
                                    add_deskripsi_lembur: "",
                                    karyawan_selected: undefined
                                })
                            }}>
                                Batal
                            </button>{' '}
                            <button className='btn btn-primary text-capitalize' style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }} onClick={() => {
                                this.simpanRevisi()
                            }}>
                                Simpan
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalEdit} toggle={() => {
                        this.setState({
                            modalEdit: false,
                            edit_karyawan_id: 0,
                            edit_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                            edit_jam_lembur: 0,
                            edit_menit_lembur: 0,
                            edit_deskripsi_lembur: "",
                            karyawan_selected: undefined
                        })
                    }} style={{
                        marginRight: "10px",
                        maxWidth: "419px",
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                modalEdit: false,
                                edit_karyawan_id: 0,
                                edit_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                                edit_jam_lembur: 0,
                                edit_menit_lembur: 0,
                                edit_deskripsi_lembur: "",
                                karyawan_selected: undefined
                            })
                        }}>
                            <div style={style_add.font_header}>Edit Lembur</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <label for="nama_karyawan" className="form-label" style={style_add.font_label}>Nama Karyawan</label>
                                    <a style={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        lineHeight: "24px"
                                    }} href={"/karyawan/profil/detail/" + this.state.edit_karyawan_id} className='text-capitalize'>{this.state.edit_karyawan_nama}</a>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label for="tanggal_revisi" className="form-label" style={style_add.font_label}>Tanggal Revisi</label>
                                    <input type="date" onChange={this.handleChange} className="form-control" id="tanggal_revisi" name='edit_tanggal_revisi' style={style_add.input} value={this.state.edit_tanggal_revisi} placeholder='Pilih Tanggal...' />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-6'>
                                    <div className='d-flex flex-row'>
                                        <input type="number" className="form-control" id="jam" style={style_add.input} placeholder='000' onChange={(e) => {
                                            this.setState({
                                                edit_jam_lembur: e.target.value !== "" ? parseInt(e.target.value) : e.target.value
                                            })
                                        }} name='edit_jam_lembur' value={this.state.edit_jam_lembur} onFocus={() => {
                                            if (this.state.edit_jam_lembur === 0 || this.state.edit_jam_lembur === "0") {
                                                this.setState({ edit_jam_lembur: "" })
                                            }
                                        }} onBlur={() => {
                                            if (this.state.edit_jam_lembur === "") {
                                                this.setState({
                                                    edit_jam_lembur: 0
                                                })
                                            }
                                        }} />
                                        <label for="jam" type='number' className="form-label" style={style_add.font_time}>Jam</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='d-flex flex-row'>
                                        <input type="number" className="form-control" id="menit" style={style_add.input} placeholder='000' onFocus={() => {
                                            if (this.state.edit_menit_lembur === 0 || this.state.edit_menit_lembur === "0") {
                                                this.setState({ edit_menit_lembur: "" })
                                            }
                                        }} onChange={(e) => {
                                            this.setState({
                                                edit_menit_lembur: e.target.value !== "" ? parseInt(e.target.value) : e.target.value
                                            }, () => {
                                                let jam = Math.floor(this.state.edit_menit_lembur / 60);
                                                let pengurangan_menit = 60 * jam;
                                                let menit = this.state.edit_menit_lembur - pengurangan_menit;
                                                if (this.state.edit_menit_lembur > 59) {
                                                    this.setState({
                                                        edit_jam_lembur: this.state.edit_jam_lembur + jam,
                                                        edit_menit_lembur: menit
                                                    })
                                                }
                                            })
                                        }} name='edit_menit_lembur'
                                            value={this.state.edit_menit_lembur} onBlur={() => {
                                                if (this.state.edit_menit_lembur === "") {
                                                    this.setState({
                                                        edit_menit_lembur: 0
                                                    })
                                                }
                                            }} />
                                        <label for="menit" type='number' className="form-label" style={style_add.font_time}>Menit</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginBottom: "70px" }}>
                                <div className='col-md-12'>
                                    <label for="nama_karyawan" className="form-label" style={style_add.font_label}>Deskripsi Lembur</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Deskripsi Lembur...' onChange={this.handleChange} name='edit_deskripsi_lembur' value={this.state.edit_deskripsi_lembur}></textarea>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            borderTop: 0,
                            backgroundColor: "#F5F5F5",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                        }}>
                            <button className='izin-tolak-button' onClick={() => {
                                this.setState({
                                    modalEdit: false,
                                    edit_karyawan_id: 0,
                                    edit_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                                    edit_jam_lembur: 0,
                                    edit_menit_lembur: 0,
                                    edit_deskripsi_lembur: "",
                                    karyawan_selected: undefined
                                })
                            }}>
                                Batal
                            </button>{' '}
                            <button className='btn btn-primary text-capitalize' style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }} onClick={() => {
                                this.handleEdit()
                            }}>
                                Simpan
                            </button>
                        </ModalFooter>
                    </Modal> */}
                    <Breadcrumb title="Daftar Pengajuan Revisi" parent="Pengajuan" setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid">
                        <div className='card' style={{ border: "1px solid #DDDDDD" }}>
                            <div className='card-body'>
                                <div className='row mb-4'>
                                    <div className='col-md-5'>
                                        <div className='d-flex flex-row mb-2'>
                                            <div className='flex-fill pr-2'>
                                                <div className='input-group' style={{ height: "100%" }}>
                                                    <span className="input-group-text izin-search-karyawan-img" id="basic-addon1">
                                                        <img alt='' src={SearchIcon} />
                                                    </span>
                                                    <input type="text" name='filter_nama' onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.setState({
                                                                loading: true,
                                                                data: []
                                                            }, () => this.loadData())
                                                        }
                                                    }} value={this.state.filter_nama} onChange={this.handleChange} className="form-control izin-search-karyawan-form" placeholder="Cari nama karyawan..." aria-label="Username" aria-describedby="basic-addon1" />
                                                </div>
                                            </div>
                                            <div>
                                                <button className='izin-refresh-button' onClick={() => {
                                                    this.setState({
                                                        filter_nama: "",
                                                        loading: true,
                                                        data: []
                                                    }, () => this.loadData())
                                                }}>
                                                    <img alt='' src={RefreshIcon} />
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className='table table-borderless'>
                                                <thead>
                                                    <tr>
                                                        <th className='revisi-th-style'>#</th>
                                                        <th className='revisi-th-style' style={{ maxWidth: "115px" }}>Karyawan</th>
                                                        <th className='revisi-th-style' style={{ maxWidth: "115px" }}>Perubahan Data</th>
                                                        <th className='lembur-th-style'>
                                                            <div className='d-flex'>
                                                                <div className='izin-th-style'>Status </div>
                                                                <div className='ml-1 d-flex flex-column pt-1'>
                                                                    <img alt='' style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowUpIcon} onClick={() => {
                                                                        this.sortablestatus(1)
                                                                    }} />
                                                                    <img alt='' style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowDownIcon} onClick={() => {
                                                                        this.sortablestatus(0)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className='revisi-th-style'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.loading ?
                                                        <tr>
                                                            <td colSpan={10}>
                                                                <Spin size="large" tip="Loading..." style={{
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px'
                                                                }} />
                                                            </td>
                                                        </tr>
                                                        :
                                                        this.state.data.length === 0 ?
                                                            <tr>
                                                                <td colSpan={9}>
                                                                    <Empty description="Data Kosong"
                                                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                                                </td>
                                                            </tr>
                                                            :
                                                            this.state.data.map((item, index) => {
                                                                no += 1
                                                                return (
                                                                    <tr style={{
                                                                        borderBottom: no === this.state.data.length ? "" : borderBottom
                                                                    }}>
                                                                        <td className='lembur-td-style'>{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                        <td className='lembur-td-style'>
                                                                            {item.karyawan ?
                                                                            <>
                                                                                {item.karyawan.karyawan_nama}
                                                                            </>
                                                                                : "Nama karyawan tidak ditemukan mohon masukkan karyawan id dengan benar"
                                                                            }
                                                                        <input type="hidden" value={item.karyawan_id} />
                                                                        </td>
                                                                        <td className='lembur-td-style'>
                                                                            {item.revisi_profile ?
                                                                            <>
                                                                            Email Baru = {item.revisi_profile.revisi_email}<br/>
                                                                            Password Baru = {item.revisi_profile.revisi_password}</>
                                                                            : "-"
                                                                            }
                                                                        </td>
                                                                        <td className='lembur-td-style' style={{ color: item.revisi_status === "pending" || item.revisi_status === "draft" ? "#FF9C00" : item.revisi_status === "reject" ? "#E03616" : item.revisi_status === "approved" ? "#1E7E0E" : "" }}>
                                                                            {item.revisi_status === "pending" || item.revisi_status === "draft" ? "Menunggu" : item.revisi_status === "reject" ? "Ditolak" : item.revisi_status === "approved" ? "Disetujui" : ""}
                                                                        </td>
                                                                        <td className='lembur-td-style float-right'>
                                                                                {!this.state.loading &&
                                                                                    <>

                                                                                    {item.revisi_status !== "approved" &&
                                                                                        <button className='btn btn-primary text-capitalize mr-2' onClick={() => {
                                                                                            this.setState({approvedRevisi: item.revisi_id, confirmApprove: true})
                                                                                        }} style={{
                                                                                            lineHeight: "24px",
                                                                                            borderRadius: "8px"
                                                                                        }}
                                                                                        >setujui</button>
                                                                                    }
                                                                                    {item.revisi_status === "pending" ? <button className='lembur-tolak-button text-capitalize mr-2' onClick={() => {
                                                                                            this.setState({rejectRevisi: item.revisi_id, confirmReject: true})
                                                                                        }} style={{
                                                                                            borderRadius: "8px"
                                                                                        }} >tolak</button> : "" ?
                                                                                        "" : ""
                                                                                    }
                                                                                    <button  className='btn btn-danger text-capitalize' onClick={() => {
                                                                                        this.setState({
                                                                                            idDel: item.revisi_id,
                                                                                            confirmDelete: true
                                                                                        })
                                                                                    }} style={{
                                                                                        borderRadius: '8px'
                                                                                    }}
                                                                                    >Hapus</button>
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                        {/* <td className='lembur-td-style' style={{ color: item.revisi_type === "Profile"}}>
                                                                            {item.revisi_type === "Profile"}
                                                                        </td> */}
                                                                        {/* <td className='lembur-td-style'>
                                                                            <Tooltip placement="bottom" trigger={"click"} zIndex={1000} color={'#fff'} title={
                                                                                <div className={'d-flex flex-column'}>
                                                                                    <button style={{ width: "84px" }} className='izin-btn izin-tooltip-menu px-2 py-1 mb-1' onClick={() => {
                                                                                        let jam = Math.floor(item.lembur_jumlah / 60)
                                                                                        let menit = item.lembur_jumlah - 60 * jam
                                                                                        this.setState({
                                                                                            modalEdit: true,
                                                                                            edit_lembur_id: item.lembur_id,
                                                                                            edit_karyawan_id: item.karyawan.karyawan_id,
                                                                                            edit_karyawan_nama: item.karyawan.karyawan_nama,
                                                                                            edit_tanggal_lembur: item.lembur_tanggal,
                                                                                            edit_jam_lembur: jam,
                                                                                            edit_menit_lembur: menit,
                                                                                            edit_deskripsi_lembur: item.lembur_desc,
                                                                                        })
                                                                                    }}>Edit</button>
                                                                                    {item.lembur_status !== "approved" &&
                                                                                        <button style={{ width: "84px" }} className='izin-btn izin-tooltip-menu px-2 py-1 mb-1' onClick={() => {
                                                                                            this.setState({ approvedRevisi: item.lembur_id, confirmApprove: true })
                                                                                        }}>Setujui</button>
                                                                                    }
                                                                                    {item.lembur_status !== "reject" &&
                                                                                        <button style={{ width: "84px" }} className='izin-btn izin-tooltip-menu px-2 py-1 mb-1' onClick={() => {
                                                                                            this.setState({ rejectLembur: item.lembur_id, confirmReject: true })
                                                                                        }}>Tolak</button>
                                                                                    }
                                                                                    <button style={{ width: "84px", color: "red" }} className='izin-btn izin-tooltip-menu px-2 py-1' onClick={() => {
                                                                                        this.setState({
                                                                                            idDel: item.lembur_id,
                                                                                            confirmDelete: true
                                                                                        })
                                                                                    }}>Hapus</button>
                                                                                </div>
                                                                            }>
                                                                                <button className='izin-btn'>
                                                                                    <img alt='' src={ElipsisIcon} />
                                                                                </button>
                                                                            </Tooltip>

                                                                        </td> */}
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer p-0 border-0'>
                                <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                    {this.state.data.length !== 0 &&
                                        <>
                                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} dari {this.state.totalData} data</div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeftIcon} alt='' />}
                                                    nextLabel={<img src={ArrowRightIcon} alt='' />}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link lembur-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link lembur-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link lembur-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link lembur-prev-next-link'}
                                                    activeClassName={'active lembur-active'}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_revisi_data);
