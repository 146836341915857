import React, { Component, Fragment } from 'react';
import { Button, ButtonGroup, } from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import "./elipsis.css";
import { Empty } from "antd";
import { pageNumber } from "../../functions/functionGeneral";
import moment from "moment";
import format from "format-number";
import Paging from "../../components/paging";
import Tour from "reactour";

import "./style.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumb from '../common/breadcrumb';
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";

export class KasbonBayar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataBayar: [],
            loading: true,
            loadingSave: true,
            visible: false,
            visibleEdit: false,
            pilihBon: null,
            filter_nama: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
        }
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSelectBon = this.handleSelectBon.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
    }

    handleSelectBon = (pilihBon) => {
        this.setState({
            pilihBon,
            isLoading: true,
            kasbon_id: pilihBon.value,
            bon_id: pilihBon.value,
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter(event) {

        this.setState({
            filter_nama: "",
            dataBayar: [],
            loading: true
        }, () => this.loadData())
    }



    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }


    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("kasbon/list-kasbon-bayar", form, "POST")
            .then(response => {
                if (response.success) {
                    var data = response.data
                    this.setState({
                        dataBayar: data.data,
                        pageCount: data.last_page,
                        perPage: data.per_page,
                        loading: false,
                        loadingSave: false,
                    })
                }
            })
    }


    componentDidMount() {
        this.loadData()
    }

    render() {

        let no = 0
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pembayaran Kasbon") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Pembayaran Kasbon - Presensi</title>
                        <meta name="description" content="Daftar Pembayaran Kasbon" />
                    </Helmet>
                    <Breadcrumb title="Daftar Pembayaran Kasbon" parent="Pembayaran Kasbon" setGuide={() => {
                        this.setState({ guide: true })
                    }} />
                    <Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody}
                        onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid list-karyawan">
                        <div className="card">
                            <div className="card-header">
                                <h5>Daftar Pembayaran Kasbon</h5>
                                <div className="row filter-karyawan" style={{ marginLeft: '5px' }}>
                                    <div className="col-md-3">
                                        <label style={{ fontSize: '10px' }}>Filter Nama:</label><br />
                                        <input name="filter_nama"
                                               className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name" : "form-control filter-name"}
                                               placeholder="       Cari nama karyawan ..."
                                               type="text"
                                               style={{ backgroundColor: "#F9F9F9", fontSize: "12px", fontWeight: 400, paddingTop: "9px", paddingBottom: "9px" }}
                                               required=""
                                               value={this.state.filter_nama}
                                               onChange={this.handleChange}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()
                                                   if (event.which == 13) {

                                                       this.setState({
                                                           page: 0,
                                                           paging: this.state.paging,
                                                           loading: true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label style={{ fontSize: '10px' }}>Atur Ulang Filter:</label><br />
                                        <button className="btn btn-danger" onClick={this.resetFilter}><span className="fa fa-refresh"></span> Atur Ulang</button>
                                    </div>
                                </div>


                            </div>

                            <br />
                            <div className="card-body">
                                {!this.state.loading && this.state.dataBayar.length === 0 ?
                                    <Empty description="Data Kosong"
                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                    :
                                    <Table responsive>
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th className="text-center">Nama Karyawan</Th>
                                                <Th className="text-center">Total Kasbon</Th>
                                                <Th className="text-center">Total Bayar</Th>
                                                <Th className="text-center">Sisa Kasbon</Th>
                                                <Th className="text-center">Tgl. Terakhir</Th>
                                                <Th className="text-center">Detail</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                this.state.dataBayar.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <Tr key={index}>
                                                            <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                            <Td className="text-center">
                                                                {item.karyawan_nama}
                                                            </Td>
                                                            <Td className="text-center">
                                                                {format({
                                                                    prefix: 'Rp ',
                                                                    integerSeparator: "."
                                                                })(item.jumlah_kasbon)}
                                                            </Td>
                                                            <Td className="text-center">
                                                                {format({
                                                                    prefix: 'Rp ',
                                                                    integerSeparator: "."
                                                                })(item.jumlah_pembayaran)}
                                                            </Td>
                                                            <Td className="text-center">
                                                                {format({
                                                                    prefix: 'Rp ',
                                                                    integerSeparator: "."
                                                                })(item.sisa_kasbon)}
                                                            </Td>
                                                            <Td className="text-center">
                                                                {
                                                                    item.tanggal_pembayaran !== null ? (
                                                                        moment(item.tanggal_pembayaran).format('ll')
                                                                    ) : ('-')
                                                                }

                                                            </Td>
                                                            <Td className="text-center">
                                                                <ButtonGroup size="sm">
                                                                    <div>
                                                                        <Link
                                                                            to={"/pembayaran-kasbon/detail/" + item.karyawan_id}
                                                                            target="_blank">
                                                                            <Button color='info'>
                                                                                <i className="fa fa-info"></i>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </ButtonGroup>
                                                            </Td>
                                                        </Tr>
                                                    )
                                                })
                                            }
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(KasbonBayar);
