import React, {Component, Fragment} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export default class UpdateFiturModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 1,
            currentData: [],
        };
    }

    componentDidMount() {
        this.setState({
            currentData: Object.entries(this.props.version),
            currentIndex: Object.entries(this.props.version).length - 1
        })
    }

    render() {
        if (!this.props.showModal) return null;
        return (
            <Fragment>
                <Modal
                    isOpen={this.props.showModal}
                    toggle={this.props.handleClose}
                    className="modal-style"
                    style={{
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    height: '80vh',
                    maxWidth: '851px',
                    flexWrap: "wrap"}}
                >
                    <ModalHeader toggle={this.props.handleClose}><h4><strong>Pemberitahuan Update Fitur</strong></h4></ModalHeader>
                    <ModalBody className="modal-body-style">
                        <form>
                            <div className="form-group media-body-info p-2">
                                <div dangerouslySetInnerHTML={{__html: this.state.currentData.length > 0 ? this.state.currentData[this.state.currentIndex][1] : ""}}>
                                </div>
                            </div>
                            <div>
                                {this.state.currentData.length > 1 ? (
                                    <div className="row justify-content-between" style={{marginLeft: 0, marginRight: 4, marginBottom: 8}}>
                                        {this.state.currentIndex > 0 ? (
                                            <button
                                                type="button"
                                                className="btn btn-export"
                                                onClick={() => {
                                                    this.setState({
                                                        currentIndex: this.state.currentIndex - 1
                                                    })
                                                }}
                                            >
                                                Sebelumnya
                                            </button>
                                        ) : <div />}
                                        {this.state.currentIndex < this.state.currentData.length - 1 ? (
                                            <button
                                                type="button"
                                                className="btn btn-export"
                                                onClick={() => {
                                                    this.setState({
                                                        currentIndex: this.state.currentIndex + 1
                                                    })
                                                }}
                                            >
                                                Selanjutnya
                                            </button>
                                        ) : <div />}
                                    </div>
                                ) : null}
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <label className="fs-12">
                            <input
                                type="checkbox"
                                className="justify-content-start border rounded-4"
                                onChange={this.props.handlerCheckBoxChanges}
                            />&nbsp;&nbsp;Saya sudah paham. Jangan Tampilkan Lagi</label>
                    </ModalFooter>
                </Modal>
            </Fragment>

        )
    }
}
