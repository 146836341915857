import React, { Component, Fragment } from 'react'
import moment from 'moment';
import {APIKEY, IMG_HOST} from "../../../helper/host";
import {Empty, Spin} from "antd";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { BACKEND_API_HOST } from "../../../helper/host";
import Swal from "sweetalert2";
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import {Input, InputGroup, InputGroupAddon, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Select from "react-select";
import TimeInput from "../../../helper/time_input";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {DateRange} from "react-date-range";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import CalendarIcon from "../../../assets/icons/calendar-dark-blue.svg";
import RefreshIcon from "../../../assets/icons/refresh.svg";
import {pageNumber} from "../../../functions/functionGeneral";
import EyeIcon from "../../../assets/icons/eye.svg";
import PencilIcon from "../../../assets/icons/pencil.svg";
import TrashIcon from "../../../assets/icons/trash.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import Paging from "../../paging";

export class absensi extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        let start = moment(new Date(tgl.getFullYear(), tgl.getMonth(), tgl.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        this.state = {
            loading: false,
            loadingAbsen: false,
            absen: [],
            laporan: [],
            year: tahun,
            month: namabulan[bulan],
            absensiStartDate: '',
            absensiEndDate: '',
            pageCount: 0,
            perPage: 0,
            currentPage: 0,
            karyawan: [],
            dataDetail: {},
            modalDetail: false,
            detail_statusin: "",
            detail_statusout: "",
            dataLembur: [],
            dataKaryawan: {},
            DataDivisi: {},
            tanggal: "",
            karyawan_id: 0,
            shift_nama: "",
            jam_masuk: "",
            status_masuk: "",
            keterangan_masuk: "",
            jam_keluar: "",
            status_keluar: "",
            keterangan_keluar: "",
            karyawan_selected: null,
            reactDateRange: false,
            shift: [],
            data_lokasi: [],
            data_shift: [],
            filter_lokasi: "",
            filter_shift: "",
            totalData: 0,
            paging: 15,
            dataKosong: "",
            guide: false,
            totalPerpage: 0,
            filter: false,
            filter_belum_masuk: "",
            modalexport: false,
            start_date: new Date(),
            end_date: new Date(),
            start: start,
            end: end,
            dateFilter: moment(),
            valueFilterDate: moment()._d,
            start_date_absen: "",
            end_date_absen: ""
        }
        this.handlePageClickAbsen = this.handlePageClickAbsen.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this)
        this.applyCallback = this.applyCallback.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilterAbsen = this.handleFilterAbsen.bind(this)
    }

    resetFilter(event) {
        this.setState({

            kunjunganStartDate: '',
            kunjunganEndDate: '',
            data_kunjungan: [],
            loading: true
        }, () => this.loadDataKaryawan())
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === "month") {
            this.setState({
                [name]: value,
                data: [],
                loading: true,
                loadingAbsen: true
            }, () => {
                if (value !== "") {
                    this.loadDataKaryawan();
                    this.setState({
                        date: "",
                        start_date_absen: "",
                        end_date_absen: ""
                    }, () => this.loadData());
                }
            });
        } else {
            this.setState({
                [name]: value,
                data: [],
                loading: true
            },() => {
                this.loadDataKaryawan()
            });
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, absen: [] }, () => {
            this.loadDataKaryawan();
        });
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            absensiStartDate: start,
            absensiEndDate: end
        },() => {
                this.loadDataKaryawan();
                this.loadData();
            });
    }

    loadDataKaryawan() {
        this.setState({ loading: true })
        let form = {
            id_karyawan: this.props.match.params.karyawan_slug,
            absensiStartDate: this.state.absensiStartDate,
            absensiEndDate: this.state.absensiEndDate,
            month: this.state.month,
            page: this.state.currentPage + 1,
            year: this.state.year,
        }
        this.props.request("karyawan/get_absensi", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        laporan: response.laporan,
                        absen: response.absen.data,
                        pageCount: response.absen.last_page,
                        perPage: response.absen.per_page,
                        visible: true
                    })
                }
                this.setState({
                    loading: false,
                    loadingAbsen: false,
                })
            });
    }

    exportAbsensiKaryawan() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'karyawan/export-absen-karyawan?token=' + AuthKey + '&presensiStartDate=' + this.state.absensiStartDate + '&presensiEndDate=' + this.state.absensiEndDate + '&id=' + this.props.match.params.id, "_blank")
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };
    applyCallback(startDate, endDate) {
        this.setState({
            start: startDate,
            end: endDate,
            start_date_absen: startDate.format("YYYY-MM-DD"),
            end_date_absen: endDate.format("YYYY-MM-DD"),
        }, () => {
            this.setState({
                date: this.state.start_date_absen + "-" + this.state.end_date_absen
            })
            this.loadData()
        })
    }

    handlePageClickAbsen(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, absen: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleFilterAbsen(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loadingAbsen: true
        }, () => this.loadData())
    }
    handleCancel = e => {
        this.setState({
            modalAction: false,
            modalexport: false,
            modaludpate: false,
            modalManagement: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };

    simpanAbsensiList = () => {
        this.setState({
            prosesSimpan: true,
            loading: true,
            loadingAbsen: true,
        })

        let form = {
            id: this.state.idDetail,
            sift: this.state.shift_nama,
            tanggal: this.state.tanggal,
            jam_in: this.state.jam_masuk,
            jam_out: this.state.jam_keluar,
            status_in: this.state.status_masuk,
            status_out: this.state.status_keluar,
            keterangan_in: this.state.keterangan_masuk,
            keterangan_out: this.state.keterangan_keluar,
        }

        this.props.request("presensi/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalEdit: false,
                                prosesSimpan: false,
                                loading: true,
                                loadingAbsen: true,
                            }, () => this.loadDataKaryawan())
                        } else {
                            this.setState({
                                modalEdit: false,
                                prosesSimpan: false,
                                loading: true,
                                loadingAbsen: true,
                            }, () => this.loadDataKaryawan())
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadData() {
        this.setState({
            loadingAbsen: true,
            loading: true,
        })
        let form = {
            paginate: this.state.paging,
            page: this.state.currentPage + 1,
            id_karyawan: this.props.match.params.karyawan_slug,
            filter_lokasi: this.state.filter_lokasi,
            filter_divisi: this.state.filter_divisi,
            filter_shift: this.state.filter_shift,
            filter_belum_masuk: this.state.filter_belum_masuk,
            StartDate: this.state.start_date_absen,
            EndDate: this.state.end_date_absen,
            month: this.state.month
        }
        this.props.request("karyawan/get_absensi", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        absen: response.absen.data,
                        totalData: response.absen.total,
                        totalPerpage: response.absen.to,
                        pageCount: response.absen.last_page,
                        perPage: response.absen.per_page,
                        loadingAbsen: false,
                        loading: false,
                    })
                }
                console.log(this.state.date)
            });
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Presensi ?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        loadingAbsen: true,
                        loading: true,
                        data: [],
                    })
                    this.props.request("presensi/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    exportAbsensiListKaryawan() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'karyawan/export-absen-karyawan?token=' + AuthKey + '&presensiStartDate=' + this.state.exportStartDate + '&presensiEndDate=' + this.state.exportEndDate + '&id=' + this.props.match.params.karyawan_slug, "_blank")
    }
    loadDetail(type) {
        let form = {
            id: this.state.idDetail
        }
        this.props.request("presensi/profil", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataDetail: response.data,
                        detail_statusin: response.data.status_in,
                        detail_statusout: response.data.status_out,
                        dataLembur: response.lembur,
                        dataKaryawan: response.data.karyawan,
                        DataDivisi: response.data.divisi,
                        absenMasuk: {
                            lat: response.data.latitude_in,
                            lng: response.data.longitude_in,
                        },
                        absenPulang: {
                            lat: response.data.latitude_out,
                            lng: response.data.longitude_out,
                        },
                        tanggal: response.data.tanggal,
                        karyawan_id: response.data.karyawan_id,
                        shift_nama: response.data.sif_name,
                        jam_masuk: response.data.jam_masuk_absen,
                        status_masuk: response.data.status_in,
                        keterangan_masuk: response.data.keterangan_in,
                        jam_keluar: response.data.jam_keluar_absen,
                        status_keluar: response.data.status_out,
                        keterangan_keluar: response.data.keterangan_out,
                        karyawan_selected: {
                            value: response.data.karyawan_id,
                            label: response.data.karyawan.karyawan_nama
                        },
                        shift: response.data.karyawan.divisi_sift
                    })
                }
                if (type === "detail") {
                    this.setState({
                        modalDetail: true
                    })
                } else if (type === "edit") {
                    this.setState({
                        modalEdit: true
                    })
                }
            });
    }
    loadLokasi() {
        this.props.request("lokasi/list-lokasi", {}, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_lokasi: response.data
                    })
                }
            })
    }
    loadShift() {
        let form = {
            id_karyawan: this.props.match.params.id,
        }
        this.props.request("shift/list-shift", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_shift: response.data
                    })
                }
            })
    }
    componentDidMount() {
        this.loadData()
        this.loadLokasi()
        this.loadShift()
        this.loadDataKaryawan()
    }

    render() {
        let to = 0;
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }
        const detail_statusin = this.state.detail_statusin
        const detail_statusout = this.state.detail_statusout
        let tanggal_data_detail = this.state.dataDetail.tanggal;
        let tanggal_detail = moment(tanggal_data_detail).format("LL")
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        let ranges = {
            "Today Only": [moment(start), moment(end)],
            "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
            "3 Days": [moment(start).subtract(3, "days"), moment(end)]
        }
        let local = {
            "format": "DD-MM-YYYY HH:mm",
            "sundayFirst": false
        }
        return (
            <Fragment>
                {/*Laporan Absen*/}
                <div className="mb-2">
                    <div className="row">
                        <div className='col-md-12'>
                            <div style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }}>Laporan Presensi Karyawan</div>
                        </div>
                    </div>
                    <div className="row mb-2 mx-0">
                        <div className='col-md-3 pl-0'>
                            <label style={{ fontSize: '10px' }}>Filter Bulan:</label>
                            <select name="month" className="form-control" onChange={this.handleFilter} value={this.state.month}>
                                <option value="">Pilih Bulan</option>
                                <option value="1">Januari</option>
                                <option value="2">Februari</option>
                                <option value="3">Maret</option>
                                <option value="4">April</option>
                                <option value="5">Mei</option>
                                <option value="6">Juni</option>
                                <option value="7">Juli</option>
                                <option value="8">Agustus</option>
                                <option value="9">September</option>
                                <option value="10">Oktober</option>
                                <option value="11">November</option>
                                <option value="12">Desember</option>
                            </select>
                        </div>
                        <div className='col-md-2'>
                            <label style={{ fontSize: '10px' }}>Filter Tahun:</label>
                            <select name="year" className="form-control" onChange={this.handleFilter} value={this.state.year}>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                            </select>
                        </div>
                    </div>
                    <div className="daftar-absen" style={{ marginLeft: 12, paddingBottom: "24px" }}>
                        {this.state.loading ?
                            <Spin size="large" tip="Sedang memuat data..." style={{
                                width: '100%',
                                margin: 'auto',
                                marginBottom: '10px',
                                marginTop: '10px'
                            }} />
                            :
                            <div>
                                <div className="row">
                                    <div className='col-md-6 pl-0 mt-2'>
                                        <div className='row mb-2'>
                                            <div className='col-md-8' style={{fontSize: "14px", color: "#5D6F80"}}>
                                                Jumlah Hari
                                            </div>
                                            <div className='col-md-1' width="20" style={{fontSize: "14px"}}>
                                                :
                                            </div>
                                            <div className='col-md-3' style={{fontSize: "14px", color: "#143B5E"}}>
                                                {this.state.laporan.jumlah_hari_bulan} Hari
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-md-8' style={{fontSize: "14px", color: "#5D6F80"}}>
                                                Jumlah Presensi
                                            </div>
                                            <div className='col-md-1' style={{fontSize: "14px"}}>
                                                :
                                            </div>
                                            <div className='col-md-3' style={{fontSize: "14px", color: "#143B5E"}}>
                                                {this.state.laporan.jumlah_berangkat} Hari
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-md-8' style={{fontSize: "14px", color: "#5D6F80"}}>
                                                Jumlah Berangkat Tepat Waktu
                                            </div>
                                            <div className='col-md-1' style={{fontSize: "14px"}}>
                                                :
                                            </div>
                                            <div className='col-md-3' style={{fontSize: "14px", color: "#143B5E"}}>
                                                {this.state.laporan.jumlah_tepat} Hari
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-md-8' style={{fontSize: "14px", color: "#5D6F80"}}>
                                                Jumlah Terlambat
                                            </div>
                                            <div className='col-md-1' style={{fontSize: "14px"}}>
                                                :
                                            </div>
                                            <div className='col-md-3' style={{fontSize: "14px", color: "#143B5E"}}>
                                                {this.state.laporan.jumlah_telat} Hari
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-md-8' style={{fontSize: "14px", color: "#5D6F80"}}>
                                                Jumlah Izin/Cuti/Sakit
                                            </div>
                                            <div className='col-md-1' style={{fontSize: "14px"}}>
                                                :
                                            </div>
                                            <div className='col-md-3' style={{fontSize: "14px", color: "#143B5E"}}>
                                                {this.state.laporan.jumlah_izin} Hari
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-md-8' style={{fontSize: "14px", color: "#5D6F80"}}>
                                                Jumlah Lembur
                                            </div>
                                            <div className='col-md-1' style={{fontSize: "14px"}}>
                                                :
                                            </div>
                                            <div className='col-md-3' style={{fontSize: "14px", color: "#143B5E"}}>
                                                {this.state.laporan.jumlah_lembur} Hari
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-md-8' style={{fontSize: "14px", color: "#5D6F80"}}>
                                                Jumlah Jam Kerja Aktual
                                            </div>
                                            <div className='col-md-1' style={{fontSize: "14px"}}>
                                                :
                                            </div>
                                            <div className='col-md-3' style={{fontSize: "14px", color: "#143B5E"}}>
                                                {this.state.laporan.jam_kerja} Menit
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-md-8' style={{fontSize: "14px", color: "#5D6F80"}}>
                                                Jumlah Alpa
                                            </div>
                                            <div className='col-md-1' style={{fontSize: "14px"}}>
                                                :
                                            </div>
                                            <div className='col-md-3' style={{fontSize: "14px", color: "#143B5E"}}>
                                                {this.state.laporan.jumlah_alpa} Hari
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <ModalRs size='lg' style={{maxWidth: "1000px"}} isOpen={this.state.modalEdit}
                         toggle={() => this.setState({modalEdit: false})}>
                    <ModalHeader style={{backgroundColor: "#FAFDFF", padding: "12px 24px"}}
                                 toggle={() => this.setState({modalEdit: false})}>
                        <div className='modal-header-reactstrap'>Edit Presensi</div>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row px-3 mb-3'>
                            <div className='col-md-3 px-2'>
                                <label for="exampleFormControlInput1" class="form-label font-edit-label">Tanggal Presensi</label>
                                    <input style={{ fontSize: "12px", paddingTop: "10px", paddingBottom: "10px" }} type="date" name="tanggal" className="form-control font-edit-value" defaultValue={this.state.tanggal} onChange={this.handleChange} />
                                </div>
                                <div className='col-md-3 px-2'>
                                    <label for="exampleFormControlInput1" class="form-label font-edit-label">Karyawan</label>
                                    <div className='form-control p-0'>
                                        <Select
                                            isDisabled={true}
                                            className="font-edit-value"
                                            placeholder="Nama Karyawan"
                                            backspaceRemoves={true}
                                            deleteRemoves={true}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            value={this.state.karyawan_selected}
                                            isLoading={this.state.karyawan_loading}
                                            onFocus={() => this.setState({ karyawan_loading: true }, () => this.searchKaryawan())}
                                            onBlur={() => this.setState({ karyawan_loading: false })}
                                            onInputChange={(val) => this.setState({ karyawan_name: val }, () => this.setState({ karyawan_loading: true }, () => this.searchKaryawan()))}
                                            options={
                                                this.state.karyawan != null ? (
                                                        this.state.karyawan.map((item, index) =>
                                                            (
                                                                {
                                                                    key: index,
                                                                    value: item.karyawan_id,
                                                                    label: item.karyawan_nama,
                                                                    shift: item.divisi_sift
                                                                }
                                                            )
                                                        ))
                                                    : ("")
                                            }
                                            onChange={
                                                (karyawan_selected) => {
                                                    if (!karyawan_selected) {
                                                        this.setState({
                                                            karyawan_selected: '',
                                                            karyawan_id: 0,
                                                            pickKaryawan: false,
                                                            shift: []
                                                        })
                                                    } else {
                                                        this.setState({
                                                            karyawan_selected,
                                                            karyawan_id: karyawan_selected.value,
                                                            pickKaryawan: true,
                                                            shift: karyawan_selected.shift,
                                                            karyawan: []
                                                        }, () => this.searchLokasiIn(), this.searchLokasiOut())
                                                    }
                                                }
                                            }
                                            isClearable={() => {
                                                this.setState({
                                                    isClearable: true,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-3 px-2'>
                                    <label for="exampleFormControlInput1" class="form-label font-edit-label">Shift</label>
                                    <select name="shift_nama" className='form-control font-edit-value' onChange={this.handleChange}>
                                        <option>Pilih Shift</option>
                                        {this.state.shift.length > 0 &&
                                            this.state.shift.map((item, index) => {
                                                return (
                                                    <option value={item.sif.sift_name}
                                                            selected={item.sif.sift_name === this.state.dataDetail.sif_name}>{item.sif.sift_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='col-md-3 px-2'></div>
                            </div>
                            <hr className='mb-3' />
                            <div className='row px-3'>
                                <div className='col-md-4 px-2'>
                                    <div className='card border'>
                                        <div className='card-header modal-header-reactstrap px-3 border-bottom' style={{ paddingTop: "10px", paddingBottom: "10px", fontSize: "16px", lineHeight: "24px", backgroundColor: "#FAFDFF" }}>Masuk</div>
                                        <div className='card-body p-3'>
                                            <div className='mb-1'>
                                                <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Jam</label>
                                                <TimeInput
                                                    style={{ fontSize: "12px" }}
                                                    initTime={this.state.jam_masuk !== null && this.state.jam_masuk.substr(0, 5)}
                                                    className='s-input -time form-control px-3 py-2'
                                                    onTimeChange={(event) => {
                                                        const value = event;
                                                        this.setState({
                                                            jam_masuk: value + ':00'
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='mb-1'>
                                                <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Status</label>
                                                <select style={{ fontSize: "12px" }} name="status_masuk" value={this.state.status_masuk} className="form-control" onChange={this.handleChange}>
                                                    <option value="">Pilih Status</option>
                                                    <option value="Masuk Kerja">Masuk Kerja (Tepat Waktu)</option>
                                                    <option value="Terlambat">Terlambat</option>
                                                    <option value="Izin">Izin</option>
                                                    <option value="Sakit">Sakit</option>
                                                    <option value="Cuti">Cuti</option>
                                                </select>
                                            </div>
                                            <div className='mb-1'>
                                                <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Keterangan</label>
                                                <textarea style={{ fontSize: "12px" }} value={this.state.keterangan_masuk} name="keterangan_masuk" className="form-control" placeholder="Keterangan Presensi..." onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 px-2'>
                                    <div className='card border'>
                                        <div className='card-header modal-header-reactstrap px-3 border-bottom' style={{ paddingTop: "10px", paddingBottom: "10px", fontSize: "16px", lineHeight: "24px", backgroundColor: "#FAFDFF" }}>Keluar</div>
                                        <div className='card-body p-3'>
                                            <div className='mb-1'>
                                                <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Jam</label>
                                                <TimeInput
                                                    style={{ fontSize: "12px" }}
                                                    initTime={this.state.jam_keluar && this.state.jam_keluar.substr(0, 5)}
                                                    className='s-input -time form-control px-3 py-2'
                                                    onTimeChange={(event) => {
                                                        const value = event;
                                                        this.setState({
                                                            jam_keluar: value + ':00'
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='mb-1'>
                                                <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Status</label>
                                                <select style={{ fontSize: "12px" }} name="status_keluar" value={this.state.status_keluar} className="form-control" onChange={this.handleChange}>
                                                    <option value="">Pilih Status</option>
                                                    <option value="Pulang Kerja">Pulang Kerja</option>
                                                    <option value="Sakit">Sakit</option>
                                                    <option value="Izin">Izin</option>
                                                </select>
                                            </div>
                                            <div className='mb-1'>
                                                <label for="exampleFormControlInput1" class="form-label font-edit-label-2">Keterangan</label>
                                                <textarea style={{ fontSize: "12px" }} value={this.state.keterangan_keluar} type="text" name="keterangan_keluar" className="form-control" placeholder="Keterangan Presensi..." onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'></div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                            <button className="btn button-add text-capitalize" onClick={() => this.setState({ modalEdit: false })}>
                                Batalkan
                            </button>
                            {' '}
                            <button className="btn btn-primary text-capitalize" onClick={this.simpanAbsensiList}>
                                Simpan
                            </button>
                        </ModalFooter>
                    </ModalRs>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalDetail} toggle={() => this.setState({ modalDetail: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalDetail: false })}><div className='modal-header-reactstrap'>Laporan Presensi Karyawan</div></ModalHeader>
                        <ModalBody>
                            <div className='d-flex flex-row warning-info px-2 mb-2'>
                                <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                <div className='px-0' style={{ fontSize: "14px" }}>
                                    Perlu diketahui, setiap 3 bulan sekali foto presensi karyawan akan mereset secara otomatis. Apabila terdapat pertanyaan lebih lanjut dapat menghubungi Customer Service
                                </div>
                            </div>
                            <div className='row px-3'>
                                <div className='col-md-3 pl-0 pr-1'>
                                    <div className='card border px-0'>
                                        <div className='card-body px-2 py-2'>
                                            <h5 className='font-14 mb-0' style={{ fontWeight: 700, lineHeight: "24px", color: "#143B5E" }}>Presensi Masuk</h5>
                                            <div className='d-flex justify-content-start' style={{ fontSize: "12px", fontWeight: 500, lineHeight: "16px", marginBottom: "12px" }}>
                                                <div className='mr-1'>{this.state.dataDetail.jam_masuk_absen}</div>
                                                <div className={detail_statusin !== "Masuk Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{detail_statusin.replace(" Kerja", "")}</div></div>
                                            <div className='mb-3'>
                                                {this.state.dataDetail.foto_in !== null ?
                                                    <img style={{ width: "100%", borderRadius: "8px" }} src={IMG_HOST + 'frontapp/absensi/' + this.state.dataDetail.foto_in} alt="foto presensi masuk" />
                                                    :
                                                    <img style={{ width: "100%", borderRadius: "8px" }} src={IMG_HOST + "user-profile.png"} alt="foto presensi masuk" />
                                                }
                                            </div>
                                            <div>
                                                <button className='btn button-add text-capitalize btn-block' onClick={() => this.setState({ modalDetail: false }, () => this.setState({ modalMapIn: true }))}>Lihat Lokasi</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 pl-1 pr-0 mx-0'>
                                    <div className='card border px-0'>
                                        <div className='card-body px-2 py-2'>
                                            <h5 className='font-14 mb-0' style={{ fontWeight: 700, lineHeight: "24px", color: "#143B5E" }}>Presensi Keluar</h5>
                                            <div className='d-flex justify-content-start' style={{ fontSize: "12px", fontWeight: 500, lineHeight: "16px", marginBottom: "12px" }}>
                                                <div className='mr-1'>{this.state.dataDetail.jam_keluar_absen}</div>
                                                <div className={detail_statusout !== "Masuk Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{detail_statusout.replace(" Kerja", "")}</div></div>
                                            <div className='mb-3'>
                                                {this.state.dataDetail.foto_out !== null ?
                                                    <img style={{ width: "100%", borderRadius: "8px" }} src={IMG_HOST + 'frontapp/absensi/' + this.state.dataDetail.foto_out} alt="foto presensi masuk" />
                                                    :
                                                    <img style={{ width: "100%", borderRadius: "8px" }} src={IMG_HOST + "user-profile.png"} alt="foto presensi masuk" />
                                                }
                                            </div>
                                            <div>
                                                <button className='btn button-add text-capitalize btn-block' onClick={() => this.setState({ modalDetail: false }, () => this.setState({ modalMapOut: true }))}>Lihat Lokasi</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 pl-3 pr-0 mx-0'>
                                    <div className='card border'>
                                        <div className='card-header px-3 py-2' style={{ backgroundColor: "#FAFDFF", color: "#143B5E", fontSize: "16px", fontWeight: 700 }}>Presensi {this.state.dataDetail.absen_hari} {tanggal_detail}</div>
                                        <div className='card-body px-3'>
                                            <div className='row d-flex justify-content-start flex-nowrap'>
                                                <div className='col-md-5 font-detail-label pr-0'>Nama Karyawan</div>
                                                <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                <div className='col-md-6 px-0 font-detail-value'>{this.state.dataDetail.karyawan ? this.state.dataDetail.karyawan.karyawan_nama : "-"}</div>
                                            </div>
                                            <div className='row d-flex justify-content-start flex-nowrap'>
                                                <div className='col-md-5 font-detail-label pr-0'>Divisi</div>
                                                <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                <div className='col-md-6 px-0 font-detail-value'>
                                                    {this.state.dataDetail.divisi ? this.state.dataDetail.divisi.divisi_name : "-"}
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-start flex-nowrap'>
                                                <div className='col-md-5 font-detail-label pr-0'>Shift</div>
                                                <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                <div className='col-md-6 px-0 font-detail-value'>
                                                    {this.state.dataDetail.sif_name}
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-start flex-nowrap'>
                                                <div className='col-md-5 font-detail-label pr-0'>Keterangan Presensi Masuk</div>
                                                <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                <div className='col-md-6 px-0 font-detail-value'>
                                                    {this.state.dataDetail.keterangan_in === "" || this.state.dataDetail.keterangan_in === null ?
                                                        "-" : this.state.dataDetail.keterangan_in}
                                                </div>
                                            </div>
                                            <div className='row d-flex justify-content-start flex-nowrap'>
                                                <div className='col-md-5 font-detail-label pr-0'>Keterangan Presensi Pulang</div>
                                                <div className='col-md-1 px-0' style={{ maxWidth: "20px" }}>:</div>
                                                <div className='col-md-6 px-0 font-detail-value'>
                                                    {this.state.dataDetail.keterangan_out === "" || this.state.dataDetail.keterangan_out === null ?
                                                        "-" : this.state.dataDetail.keterangan_out}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <button className='btn btn-primary text-capitalize' style={{ fontFamily: 'DM Sans', fontSize: "14px", fontWeight: "14px" }} onClick={() => this.setState({ modalDetail: false }, () => this.setState({ modalEdit: true }))}>Edit Presensi</button>
                        </ModalFooter>
                    </ModalRs>
                <ModalRs size='lg' isOpen={this.state.modalMapIn} toggle={() => this.setState({ modalMapIn: false })}>
                    <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalMapIn: false })}>Detail Lokasi</ModalHeader>
                    <ModalBody>
                        <div style={{
                            position: 'relative',
                            width: '100%',
                            height: '250px'
                        }}>
                            <Map google={this.props.google}
                                 initialCenter={{
                                     lat: this.state.dataDetail.latitude_in,
                                     lng: this.state.dataDetail.longitude_in
                                 }}
                                 zoom={15}>
                                <Marker />
                            </Map>
                        </div>
                    </ModalBody>
                </ModalRs>
                <ModalRs size='lg' isOpen={this.state.modalMapOut} toggle={() => this.setState({ modalMapOut: false })}>
                    <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalMapOut: false })}>Detail Lokasi</ModalHeader>
                    <ModalBody>
                        <div style={{
                            position: 'relative',
                            width: '100%',
                            height: '250px'
                        }}>
                            <Map google={this.props.google}
                                 initialCenter={{
                                     lat: this.state.dataDetail.latitude_out,
                                     lng: this.state.dataDetail.longitude_out
                                 }}
                                 zoom={15}>
                                <Marker />
                            </Map>
                        </div>
                    </ModalBody>
                </ModalRs>
                    <ModalRs size='lg' isOpen={this.state.modalexport} toggle={this.handleCancel}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={this.handleCancel}>Export Excel</ModalHeader>
                        <ModalBody>
                            <div className='row'>
                                <div className='col'>
                                    <div className='d-flex flex-row warning-info px-2 mb-2'>
                                        <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                        <div className='px-0' style={{ fontSize: "14px" }}>
                                            Filter berdasarkan tanggal terlebih dahulu
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-5'>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                            </div>
                                        </InputGroupAddon>

                                        <Input bsSize="sm" className='font-edit-value' style={{ paddingTop: "8px", paddingBottom: "8px" }} onFocus={() => this.setState({ reactDateRange: true })} placeholder="Filter Tanggal" value={this.state.valueDate} />
                                    </InputGroup>
                                </div>
                                <div className='col-md-1'></div>
                                <div className='col-md-6'></div>
                            </div>
                            {this.state.reactDateRange === true &&
                                <div className='shadow d-flex flex-column' style={{ maxWidth: "332px" }}>
                                    <div>
                                        <DateRange
                                            onBlur={() => this.setState({ reactDateRange: false })}
                                            ranges={[selectionRange]}
                                            onChange={(e) => {
                                                this.setState({
                                                    start_date: e.selection.startDate,
                                                    end_date: e.selection.endDate,
                                                    exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                    exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-end pr-2'>
                                            <button className='btn button-add text-capitalize mb-2' style={{ fontSize: "14px", fontWeight: 500 }} onClick={() => this.setState({ reactDateRange: false })}>Batal</button>
                                            <button className='btn btn-primary mb-2 ml-2 text-capitalize' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }} onClick={
                                                () => this.setState({
                                                    valueDate: this.state.exportStartDate + " - " + this.state.exportEndDate,
                                                    reactDateRange: false
                                                })
                                            }>Terapkan</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.valueDate !== "" &&
                                <>
                                    <div className='row px-2'>
                                        <div className='col-md-4 px-1'>
                                            <button className={'btn btn-block btn-primary px-3 py-2 text-capitalize mb-2'} style={{ fontSize: "14px", borderRadius: "11px" }} onClick={() => {this.exportAbsensiListKaryawan()}}>Export Data Presensi</button>
                                        </div>

                                    </div>
                                </>
                            }
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                            <button className="btn button-add text-capitalize" onClick={this.handleCancel}>
                                Batalkan
                            </button>
                        </ModalFooter>
                    </ModalRs>
                    <div className="card p-4">
                        <div className="card-body daftar-absen p-0">
                            <div className="row mb-2">
                                <div className='col-md-2 dk-col-3'>
                                    <div className='d-flex flex-nowrap'>
                                        <div className='flex-fill'>
                                            <label className='form-label' style={{ fontSize: "12px" }}>Filter Tanggal</label>
                                            <DateTimeRangeContainer
                                                ranges={ranges}
                                                start={this.state.start}
                                                end={this.state.end}
                                                local={local}
                                                applyCallback={this.applyCallback}
                                                style={{ fontSize: "12px" }}
                                            >
                                                <div className="input-group mb-3" >
                                                    <input type="text" className="form-control dk-filter-date-2" placeholder="Filter Tanggal" aria-label="Date Filter" aria-describedby="basic-addon1" value={this.state.date}/>
                                                    <span className="input-group-text" id="basic-addon1" style={{
                                                        backgroundColor: "#FFFFFF",
                                                        border: "1px solid #DDDDDD",
                                                        borderLeft: "none",
                                                        borderTopLeftRadius: "0px",
                                                        borderBottomLeftRadius: "0px",
                                                        borderTopRightRadius: "8px",
                                                        borderBottomRightRadius: "8px",
                                                        height: "38px"
                                                    }}>
                                                        <img alt='' src={CalendarIcon} />
                                                    </span>
                                                </div>
                                            </DateTimeRangeContainer>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2 px-1'>
                                    <label className='form-label' style={{ fontSize: "12px" }}>Lokasi</label>
                                    <select className='form-control' name="filter_lokasi" value={this.state.filter_lokasi} onChange={this.handleFilterAbsen} style={{ fontSize: "12px" }}>
                                        <option value="">Pilih Lokasi</option>
                                        {this.state.data_lokasi.length !== 0 &&
                                            this.state.data_lokasi.map((item, index) => {
                                                return (
                                                    <option value={item.lokasi_id}>{item.lokasi_nama}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='col-md-2 px-1 mb-2'>
                                    <label className='form-label' style={{ fontSize: "12px" }}>Shift</label>
                                    <select name="filter_shift" className='form-control' value={this.state.filter_shift} style={{ fontSize: "12px" }} onChange={this.handleFilterAbsen}>
                                        <option value="">Pilih Shift</option>
                                        {this.state.data_shift.length !== 0 &&
                                            this.state.data_shift.map((item, index) => {
                                                return (
                                                    <option value={item.sift_id}>{item.sift_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-1 px-0 mr-2">
                                    <br/>
                                    <button className="btn secondary px-2 mt-2" style={{ height: "38px"}} onClick={
                                        () => {
                                            this.setState({
                                                loadingAbsen: true,
                                                loading: true,
                                                filter_lokasi: "",
                                                filter_status: "",
                                                filter_shift: "",
                                                date: "",
                                                start_date_absen: "",
                                                end_date_absen: ""
                                            }, () => this.loadData())
                                        }
                                    }><img src={RefreshIcon} /></button>
                                </div>
                                <div className="col-md-2">
                                    {this.state.absen.length > 0 &&
                                        <>
                                            <label style={{ fontSize: '10px' }}>Export Data Presensi</label>
                                            <button onClick={() => { this.setState({modalexport: true}) }} className={"btn btn-primary"} style={{}}>Export <i className="fa fa-share"></i></button>
                                        </>
                                    }
                                </div>
                            </div>
                            {!this.state.loadingAbsen && this.state.absen.length === 0 ?
                                <Empty description="Data Kosong"
                                       image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                :
                                <table className="table mb-5 table-borderless">
                                    <thead>
                                    <tr>
                                        <th style={{ color: "#143B5E", fontSize: "14px", borderBottom: "0px" }}>#</th>
                                        <th style={{ color: "#143B5E", fontSize: "14px", borderBottom: "0px" }}>Hari / Tanggal</th>
                                        <th style={{ color: "#143B5E", fontSize: "14px", borderBottom: "0px" }} className="text-center">Jam Masuk-Keluar</th>
                                        <th style={{ color: "#143B5E", fontSize: "14px", borderBottom: "0px" }} className="text-center">Jam Istirahat</th>
                                        <th style={{ color: "#143B5E", fontSize: "14px", borderBottom: "0px" }} className="text-center">Jam Aktual</th>
                                        <th style={{ color: "#143B5E", fontSize: "14px", borderBottom: "0px" }} className="text-center">Total Jam Kerja</th>
                                        <th style={{ color: "#143B5E", fontSize: "14px", borderBottom: "0px" }} className="text-center">Status</th>
                                        <th style={{ color: "#143B5E", fontSize: "14px", borderBottom: "0px" }} className="text-center" width={140}>Aksi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.loadingAbsen &&
                                        <tr>
                                            <td colSpan={8}>
                                                <Spin size="large" tip="Loading..." style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                    marginBottom: '10px',
                                                    marginTop: '10px'
                                                }} />
                                            </td>
                                        </tr>
                                    }
                                    {this.state.absen.map((item, index) => {
                                        to += 1
                                        let status = 'badge'
                                        const items = [
                                            {
                                                key: '1',
                                                label: (
                                                    <a rel="noopener noreferrer" onClick={() => this.setState({ idDetail: item.id_absensi },
                                                        () => this.loadDetail("detail"))}>
                                                        Detail
                                                    </a>
                                                ),
                                            },
                                            {
                                                key: '2',
                                                label: (
                                                    <a rel="noopener noreferrer" onClick={() => this.setState({ idDetail: item.id_absensi }, () => this.loadDetail("edit"))}>
                                                        Edit
                                                    </a>
                                                ),
                                            },
                                            {
                                                key: '3',
                                                label: (
                                                    <a rel="noopener noreferrer" style={{ color: '#E03616' }} onClick={() => {
                                                        this.setState({
                                                            idDel: item.id_absensi
                                                        })
                                                        this.wantDelete()
                                                    }}>
                                                        Hapus
                                                    </a>
                                                ),
                                            },
                                        ];
                                        return (
                                            <tr>
                                                <td>
                                                    <small>
                                                        <th style={{ border: "none" }} scope="row">{pageNumber(to, this.state.perPage, this.state.currentPage)}</th>
                                                    </small>
                                                </td>
                                                <td style={{ fontSize: "14px", color: "#143B5E" }}>{item.absen_hari} / {moment(item.tanggal).format('ll')}</td>
                                                <td style={{ fontSize: "14px" }} align="center">{item.jam_masuk_absen !== null || item.jam_masuk_absen !== "" ?
                                                    <div style={{ color: "#143B5E" }}>{item.jam_masuk_absen} - {item.jam_keluar_absen !== null || item.jam_keluar_absen !== "" ? item.jam_keluar_absen : ""}</div>
                                                    :
                                                    <div style={{ color: "#B6B6B6" }}>-</div>}
                                                </td>
                                                <td>{item.istirahat_mulai !== null ? item.istirahat_mulai.substring(0, 5) : "__:__"} - {item.istirahat_selesai !== null ? item.istirahat_selesai.substring(0, 5) : "__:__"}</td>
                                                <td style={{ fontSize: "14px", color: "#143B5E" }} align="center">{item.jam_terhitung ? item.jam_terhitung + ' menit' : "-"}</td>
                                                <td style={{ fontSize: "14px", color: "#143B5E" }} align="center">{item.jam_kerja ? item.jam_kerja + ' menit' : "-"}</td>
                                                <td style={{ fontSize: "14px", color: "#143B5E" }} align="center">
                                                    {/* <If condition={item.status_in === 'Masuk Kerja'}>
                                                    <Then>
                                                        <div style={{ color: '#6BAA61' }}>{item.status_in}</div>
                                                    </Then>
                                                    <ElseIf condition={item.status_in === 'Terlambat'}>
                                                        <div style={{ color: '#FF5507' }}>{item.status_in}</div>
                                                    </ElseIf>
                                                    <Else>
                                                        <div style={{ color: "#FFC107" }} >{item.status_in}</div>
                                                    </Else>
                                                </If> */}
                                                    <div className='d-flex flex-nowrap'>
                                                        {this.state.filter_belum_masuk === "belum_masuk" ?
                                                            <div>Belum Masuk</div>
                                                            :
                                                            <>
                                                                <div className={item.status_in !== "Masuk Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{item.status_in.replace(" Kerja", "")}</div>
                                                                <div>-</div>
                                                                <div className={item.status_out !== "Pulang Kerja" ? "warning-color mx-0 my-0 px-0" : "success-color mx-0 my-0 px-0"}>{item.status_out.replace(" Kerja", "")}</div>
                                                            </>
                                                        }
                                                    </div>
                                                </td>
                                                {/* <td align="center">
                                                <Space direction="vertical">
                                                    <Space wrap>
                                                        <Dropdown
                                                            menu={{ items }}
                                                            placement="bottom"
                                                        >
                                                            <Button style={{ border: "none" }}><i
                                                                className="fa fa-ellipsis-h" style={{
                                                                    fontSize: 18,
                                                                    color: 'rgb(51 51 51)'
                                                                }}
                                                            ></i></Button>
                                                        </Dropdown>
                                                    </Space>
                                                </Space>
                                            </td> */}
                                                <td className='px-0' style={{ fontSize: "14px", color: "#143B5E" }} align="center">
                                                    <a className='mr-3' onClick={() => this.setState({ idDetail: item.id_absensi },
                                                        () => this.loadDetail("detail"))}>
                                                        <img src={EyeIcon} alt="" />
                                                    </a>
                                                    <a onClick={() => this.setState({ idDetail: item.id_absensi }, () => this.loadDetail("edit"))} className="mr-3">
                                                        <img src={PencilIcon} alt="" />
                                                    </a>
                                                    <a onClick={() => {
                                                        this.setState({
                                                            idDel: item.id_absensi
                                                        })
                                                        this.wantDelete()
                                                    }}>
                                                        <img src={TrashIcon} alt="" />
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            }

                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.absen.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.absen.length === 0 || this.state.absen.length === null ? "0" : this.state.absen.length} dari {this.state.totalData} data</div>
                                        <div className='col mt-2'>
                                            <Paging
                                                className="float-right"
                                                handlePageClick={this.handlePageClickAbsen}
                                                pageCount={this.state.pageCount}
                                                currentPage={this.state.currentPage}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
            </Fragment>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: APIKEY
})(absensi)
