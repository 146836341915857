import React, {Component} from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import '../../home/landing.scss';
import {
    FacebookFilled,
    GoogleCircleFilled,
    InstagramFilled,
    MailTwoTone,
    PhoneTwoTone,
    PushpinTwoTone,
    YoutubeFilled
} from '@ant-design/icons';
import moment from 'moment';
import {requestData} from '../../../helper/request'
import {IMG_HOST} from '../../../helper/host'
import android from '../../../assets/images/android.png'
import apple from '../../../assets/images/apple.png'
import blogDefault from '../../../assets/images/dashboard/cat3.png'
import {Helmet} from "react-helmet";
import FrontHeader from "../../common/front_header";

export class BlogDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
            data: {},
            blog: [],
            list: [],
        }
    }

    loadData() {
        let form = {
            alias: this.props.match.params.alias
        }
        requestData("blog/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        blog: response.data,
                    })
                }else{
                    this.setState({
                        blog: null,
                    })
                }
            });
    }

    loadBlogList() {
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        requestData("blog/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        list: response.data.data,
                        totalData       : response.data.total,
                        pageCount       : response.data.last_page,
                        perPage         : response.data.per_page,
                        loading         : false
                    })
                }
            });
    }

    loadDataFooter() {
        requestData("home", {}, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.loadBlogList()
        this.loadDataFooter()
    }

    toggleMenu = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    }

    render() {
        return (
            <div className="landing-page-multikart" style={{ fontFamily: 'Lato, sans-serif' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Detail Blog - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <FrontHeader />

                {/*Blog Detail section*/}
                <section id="blog" className="section-lr feature-section mt-4">
                    <div className="container-fluid pt-4">
                        {this.state.blog !== null ?
                            <div className="row feature_row">
                                <div className="col-xl-9 col-md-9">
                                    <ol className="breadcrumb bg-transparent">
                                        <li className="breadcrumb-item active fs-5"><a href="/" style={{color: '#5D6F80'}}>Beranda</a></li>
                                        <li className="breadcrumb-item font-weight-bold fs-5 text-primary">Blog</li>
                                    </ol>
                                    <div className="feature-box">
                                        <div className="text-left mt-3">
                                            <h3 className="dark-blue-text font-weight-bold">{this.state.blog.blog_title}</h3>
                                        </div>
                                        <p className="text-left mb-3">
                                            {moment(this.state.blog.blog_create_date).format('lll')}
                                        </p>
                                        <div className="feature-image mb-4">
                                            {this.state.blog.blog_image !== null ?
                                                <img className="img-fluid lazyload"  style={{width: '100%'}} alt={this.state.blog.blog_image}
                                                     src={IMG_HOST + 'admin/blog/' + this.state.blog.blog_image}/>
                                                :
                                                <img className="img-fluid lazyload" alt='default Img'
                                                     src={blogDefault}/>
                                            }
                                        </div>
                                        <div className="pt-4 text-center" style={{display: 'block !important'}}>
                                            <h5 className="text-center">{this.state.blog.blog_short_desc}</h5>
                                        </div>
                                        <div className="blog-content" style={{display: 'block !important', textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: this.state.blog.blog_desc}}>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-3">
                                    <h5 className="dark-blue-text font-weight-bold text-left border-4 border-left border-primary mb-4">&nbsp;&nbsp;Artikel menarik lainnya</h5>
                                    {this.state.list.map((item, index) => {
                                        return (
                                            <div className="mb-4">
                                                <div className="feature-box">
                                                    <div className="feature-image">
                                                        {item.blog_image !== null ?
                                                            <a href={"/blog/" + item.blog_alias}>
                                                                <img className="lazyload" style={{width: "100%", maxHeight: "300px"}} alt={item.blog_image}
                                                                     src={IMG_HOST + 'admin/blog/' + item.blog_image}/>
                                                            </a>
                                                            :
                                                            <a href={"/blog/" + item.blog_alias}>
                                                                <img className="img-fluid lazyload" alt='default Img'
                                                                     src={blogDefault}/>
                                                            </a>
                                                        }
                                                    </div>
                                                    <div className="feature-content pt-1 text-left">
                                                        <a href={"/blog/" + item.blog_alias}>
                                                            <h6 className="pl-0">{item.blog_title}</h6>
                                                        </a>
                                                    </div>
                                                    <p className="date-text text-left">
                                                        {moment(item.blog_create_date).format('lll')}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-12">
                                    <div className="feature text-center">
                                        <div>
                                            <h5 className="title-landing">Blog tidak ditemukan</h5>
                                            <p className="pb-3"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section>

                {/*Kontak*/}
                <section id="kontak" className="main-feature section-b-space grey-bg text-left">
                    <div className="container mt-5">
                        <h4 className="pb-3">Punya pertanyaan mengenai absensi online?</h4>
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-12">
                                <h5 className="title-landing">Hubungi Kami</h5>
                                <p>Presensi.co.id</p>
                                <ul className="navbar-nav">
                                    <li>
                                        <PhoneTwoTone/>
                                        <a className='ml-2 text-custome-16'
                                           href={'tel' + this.state.data.contact_phone}
                                           alt={'Telepon ' + this.state.data.contact_phone}
                                           title={'Telepon ' + this.state.data.contact_phone}>
                                            {this.state.data.contact_phone}</a>
                                    </li>
                                    <li className="mt-3">
                                        <MailTwoTone/>
                                        <a className='ml-2 text-custome-16'
                                           href={"mailto:" + this.state.data.contact_email}
                                           alt={"email:" + this.state.data.contact_email}
                                           title={"email:" + this.state.data.contact_email}>
                                            {this.state.data.contact_email}</a>
                                    </li>
                                    <li className="mt-3 mb-3">
                                        <PushpinTwoTone/>
                                        <span className='ml-2 text-custome-16'>
                                            {this.state.data.contact_address}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Menu</h5>
                                <ul className="navbar-nav">
                                    <li className="mb-3">
                                        <AnchorLink className="text-custome-16" href='/login'>Masuk</AnchorLink>
                                    </li>
                                    <li className="mb-3">
                                        <AnchorLink className="text-custome-16" href='/register'>Daftar</AnchorLink>
                                    </li>
                                    <li className="mb-3">
                                        <a className="text-custome-16" href='/kebijakan-privasi'>Kebijakan & Privasi</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Temukan Kami</h5>
                                <ul>
                                    <li>
                                        <GoogleCircleFilled className="text-custome-16"/>
                                    </li>
                                    <li className="ml-4">
                                        <a target='_blank' href={this.state.data.contact_facebook_link}><FacebookFilled
                                            className="text-custome-16"/></a>
                                    </li>
                                    <li className="ml-4">
                                        <a target='_blank'
                                           href={this.state.data.contact_instagram_link}><InstagramFilled
                                            className="text-custome-16"/></a>
                                    </li>
                                    <li className="ml-4 mb-3">
                                        <a target='_blank' href={this.state.data.contact_youtube_link}><YoutubeFilled
                                            className="text-custome-16"/></a>\
                                    </li>

                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Tersedia di</h5>
                                <div className="set-image mb-3">
                                    <a target='_blank' href={this.state.data.app_url_android}>
                                        <img style={{maxWidth: 100}} src={android} alt="android"/>
                                    </a>
                                </div>
                                <div className="set-image">
                                    <a target='_blank' href={this.state.data.app_url_ios}>
                                        <img style={{maxWidth: 100}} src={apple} alt="apple"/>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <div className="footer-shadow text-center">
                    Copyright by presensi.co.id 2020
                </div>

                {/*Tap To Top*/}
                <div className="tap-top">
                    <div>
                        <i className="fa fa-angle-double-up"></i>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogDetail
