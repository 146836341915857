import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Swal from "sweetalert2";
import { set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import Switch from "react-switch";
import { Helmet } from "react-helmet";
import InfoIcon from "../../../assets/icons/info-icon-blue.svg";

export class SettingMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: true,
            data: {},
            greet: '',
            quote: '',
            show_istirahat: true,
            show_laporan: true,
            show_kunjungan: true,
            show_payroll: true,
            show_beban: true,
            show_tugas: true,
            show_lembur: true,
            show_izin: true,
            show_kasbon: true,
            show_revisi: true,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    saveSettingMobile = () => {
        this.setState({
            loading: true,
        })

        let form = {
            greet: this.state.greet,
            quote: this.state.quote,
            show_istirahat: this.state.show_istirahat,
            show_laporan: this.state.show_laporan,
            show_kunjungan: this.state.show_kunjungan,
            show_payroll: this.state.show_payroll,
            show_beban: this.state.show_beban,
            show_tugas: this.state.show_tugas,
            show_lembur: this.state.show_lembur,
            show_izin: this.state.show_izin,
            show_kasbon: this.state.show_kasbon,
            show_revisi: this.state.show_revisi,
        }

        this.props.request("setting-mobile/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    loadData() {
        this.setState({
            loaded: false,
            loading: true
        })
        let form = {}
        this.props.request("setting-mobile/list", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        loaded: true,
                        greet: response.data.setting_m_greet,
                        quote: response.data.setting_m_quote,
                        show_istirahat: response.data.setting_m_istirahat === "true",
                        show_laporan: response.data.setting_m_laporan === "true",
                        show_kunjungan: response.data.setting_m_kunjungan === "true",
                        show_payroll: response.data.setting_m_payroll === "true",
                        show_beban: response.data.setting_m_beban === "true",
                        show_tugas: response.data.setting_m_tugas === "true",
                        show_lembur: response.data.setting_m_lembur === "true",
                        show_izin: response.data.setting_m_izin === "true",
                        show_kasbon: response.data.setting_m_kasbon === "true",
                        show_revisi: response.data.setting_m_revisi === "true",
                    })
                }
            })
        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Pengaturan Aplikasi - Presensi</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>
                    <Breadcrumb title="Pengaturan Aplikasi" />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div>
                                            <div>
                                                <div>
                                                    <label className="header-input-basic">Pengaturan Kata Kata Sapaan</label>
                                                    <div className='d-flex flex-row blue-light-info px-2 mb-2 b-r-4'>
                                                        <div className='px-0 mx-2'><img src={InfoIcon} alt="" /></div>
                                                        <div className='px-0' style={{ fontSize: "14px", lineHeight: "22px" }}>
                                                            Anda dapat menambahkan <b>$nama</b> untuk menampilkan nama lengkap karyawan
                                                        </div>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className='form-label label-input-basic'>Kalimat Sapaan</label>
                                                        <input className='form-control input-basic' type='text' name='greet' onChange={(e) => this.handleChange(e)} value={this.state.greet} placeholder='Hallo 👋 $nama' />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className='form-label label-input-basic'>Kalimat Kutipan</label>
                                                        <input className='form-control input-basic' type='text' name='quote' onChange={(e) => this.handleChange(e)} value={this.state.quote} placeholder='Semangat Kerja ya!' />
                                                    </div>
                                                </div>
                                                {this.state.loaded ?
                                                    <div className="mt-4">
                                                        <label className="header-input-basic">Pengaturan Menu Halaman Depan</label>
                                                        <div className="row">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Istirahat</label>
                                                            <Switch
                                                                id={'show_istirahat'}
                                                                name="show_istirahat"
                                                                checked={this.state.show_istirahat}
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        show_istirahat: val
                                                                    })
                                                                }}
                                                                onColor="#1890ff"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1 switch-settings-app"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Laporan</label>
                                                            <Switch
                                                                id={'show_laporan'}
                                                                name="show_laporan"
                                                                checked={this.state.show_laporan}
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        show_laporan: val
                                                                    })
                                                                }}
                                                                onColor="#1890ff"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1 switch-settings-app"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Kunjungan</label>
                                                            <Switch
                                                                id={'show_kunjungan'}
                                                                name="show_kunjungan"
                                                                checked={this.state.show_kunjungan}
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        show_kunjungan: val
                                                                    })
                                                                }}
                                                                onColor="#1890ff"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1 switch-settings-app"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Payroll</label>
                                                            <Switch
                                                                id={'show_payroll'}
                                                                name="show_payroll"
                                                                checked={this.state.show_payroll}
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        show_payroll: val
                                                                    })
                                                                }}
                                                                onColor="#1890ff"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1 switch-settings-app"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Klaim Beban</label>
                                                            <Switch
                                                                id={'show_beban'}
                                                                name="show_beban"
                                                                checked={this.state.show_beban}
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        show_beban: val
                                                                    })
                                                                }}
                                                                onColor="#1890ff"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1 switch-settings-app"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Tugas</label>
                                                            <Switch
                                                                id={'show_tugas'}
                                                                name="show_tugas"
                                                                checked={this.state.show_tugas}
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        show_tugas: val
                                                                    })
                                                                }}
                                                                onColor="#1890ff"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1 switch-settings-app"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Lembur</label>
                                                            <Switch
                                                                id={'show_lembur'}
                                                                name="show_lembur"
                                                                checked={this.state.show_lembur}
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        show_lembur: val
                                                                    })
                                                                }}
                                                                onColor="#1890ff"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1 switch-settings-app"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Izin</label>
                                                            <Switch
                                                                id={'show_izin'}
                                                                name="show_izin"
                                                                checked={this.state.show_izin}
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        show_izin: val
                                                                    })
                                                                }}
                                                                onColor="#1890ff"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1 switch-settings-app"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Kasbon</label>
                                                            <Switch
                                                                id={'show_kasbon'}
                                                                name="show_kasbon"
                                                                checked={this.state.show_kasbon}
                                                                onChange={(val) => {
                                                                    this.setState({
                                                                        show_kasbon: val
                                                                    })
                                                                }}
                                                                onColor="#1890ff"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="mr-1 switch-settings-app"
                                                            />
                                                        </div>
                                                        {/*<div className="row">*/}
                                                        {/*    <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Revisi Data</label>*/}
                                                        {/*    <Switch*/}
                                                        {/*        id={'show_revisi'}*/}
                                                        {/*        name="show_revisi"*/}
                                                        {/*        checked={this.state.show_revisi}*/}
                                                        {/*        onChange={(val) => {*/}
                                                        {/*            this.setState({*/}
                                                        {/*                show_revisi: val*/}
                                                        {/*            })*/}
                                                        {/*        }}*/}
                                                        {/*        onColor="#1890ff"*/}
                                                        {/*        offColor="#eb4034"*/}
                                                        {/*        handleDiameter={18}*/}
                                                        {/*        uncheckedIcon={false}*/}
                                                        {/*        checkedIcon={false}*/}
                                                        {/*        boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"*/}
                                                        {/*        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"*/}
                                                        {/*        height={24}*/}
                                                        {/*        width={48}*/}
                                                        {/*        className="mr-1"*/}
                                                        {/*    />*/}
                                                        {/*</div>*/}
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="pull-right mt-4">
                                            {this.state.loading == false ?
                                                <button type="button" className="btn btn-primary" disabled={!this.state.loaded} onClick={() => {this.saveSettingMobile()}}>Simpan</button>
                                                :
                                                <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin" /> Loading</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    user: state.Auth.user,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingMobile)
