import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../../components/paging";
import { Empty, Spin } from 'antd';

import AddUserLevel from "./addlevel";
import EditLevel from "./editlevel";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../../functions/functionGeneral";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";

const imgMyimageexample = require('../../../assets/images/bg3.png');
const divStyle = {
    width: '100%',
    height: '50%',
};

export class UserLevelPerusahaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            divisi: [],
            dataPrimary: '',
            idDel: '',
            isLoading: true,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            p_user_name: '',
            p_user_id: '',
            p_user_email: '',
            filter_nama: '',
            add_visible: false,
            visable: false,
            guide: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            isLoading: true
        }, () => this.loadData())
    }


    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true,
                        data: []
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("userlevel/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    data: []
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadDivisi() {
        let form = {
            filter_shift: "",
            filter_nama: "",
            paginate: 99,
            page: 1,
        }
        this.props.request("divisi/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        divisi: response.data.data,
                    })
                }
            });
    }

    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("userlevel/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        dataPrimary: response.primary,
                        isLoading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.loadDivisi()
    }

    render() {
        let no = 0;

        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <div>
                    {!this.state.isLoading &&
                        <Fragment>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>Daftar User Level - Presensi</title>
                                <meta name="description" content="Detail User" />
                            </Helmet>

                            <EditLevel
                                data={this.state}
                                request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                changeState={(state, callback) => {
                                    this.setState(state, () => {
                                        if (typeof callback != 'undefined')
                                            callback()
                                    })
                                }}
                                loadData={() => this.loadData()}
                            />

                            <AddUserLevel
                                add_visible={this.state.add_visible}
                                divisi={this.state.divisi}
                                request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                changeState={(state, callback) => {
                                    this.setState(state, () => {
                                        if (typeof callback != 'undefined')
                                            callback()
                                    })
                                }}
                                loadData={() => this.loadData()}
                            />

                            <Breadcrumb parent="Manajemen User" title="Daftar User Level" setGuide={() => { this.setState({ guide: true }) }} />
                            <Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card profile-card">
                                            <div className="card-header">
                                                <div className='form-group'>
                                                    <a className="btn btn-primary tambah-user" style={{ float: "right", color: "white" }} onClick={() => {
                                                        this.setState({
                                                            add_visible: true
                                                        })
                                                    }}><i className={"fa fa-plus"}></i> Tambah</a>
                                                    <h4>Daftar User Level</h4>
                                                </div>
                                            </div>
                                            <div className="row filter-karyawan" style={{ marginLeft: '5px' }}>
                                                <div className="col-4">
                                                    <label style={{ fontSize: '10px' }}>Filter Nama Level:</label><br />
                                                    <input name="filter_nama"
                                                        className="form-control"
                                                        placeholder="Nama Level..."
                                                        type="text"
                                                        value={this.state.filter_nama}
                                                        onChange={this.handleChange}
                                                        onKeyUp={(event) => {
                                                            event.preventDefault()
                                                            if (event.which == 13) {

                                                                this.setState({
                                                                    page: 0,
                                                                    paging: this.state.paging,
                                                                    isLoading: true
                                                                }, () => {
                                                                    this.loadData()
                                                                });
                                                            }
                                                        }} />
                                                </div>
                                                <div className="col-4">
                                                    <label style={{ fontSize: '10px' }}>Atur Ulang Filter:</label><br />
                                                    <button className="btn btn-danger" onClick={this.resetFilter}><span className="fa fa-refresh"></span> Atur Ulang</button>
                                                </div>
                                            </div>
                                            <div className="card-body daftar-shift">
                                                {!this.state.isLoading && this.state.data.length === 0 ?
                                                    <Empty
                                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                        imageStyle={{ height: 60 }}
                                                        description={<span>Data tidak ditemukan!</span>}
                                                    />
                                                    :
                                                    <div>
                                                        <Table striped className="mb-5">
                                                            <Thead>
                                                                <Tr>
                                                                    <Th width="30">#</Th>
                                                                    <Th>Nama Level</Th>
                                                                    <Th>Akses Halaman</Th>
                                                                    <Th>Akses Divisi</Th>
                                                                    <Th>Akses Aksi</Th>
                                                                    <Th className={"text-center"} width="150">Aksi</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {this.state.data.map((item, index) => {
                                                                    no += 1
                                                                    return (
                                                                        <Tr>
                                                                            <small>
                                                                                <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                            </small>
                                                                            <Td>
                                                                                {item.level_name}
                                                                            </Td>
                                                                            <Td>
                                                                                {item.level_page_limited ? item.level_page_limited : "Semua Halaman"}
                                                                            </Td>
                                                                            <Td>
                                                                                {item.level_divisi_limited ? item.level_divisi_limited : "Semua Divisi"}
                                                                            </Td>
                                                                            <Td>
                                                                                {item.level_action_limited ? item.level_action_limited : "Semua Aksi"}
                                                                            </Td>
                                                                            <Td align={"center"}>
                                                                                <a onClick={() => {
                                                                                    item.level_default != "1" &&
                                                                                    this.setState({
                                                                                        level_id: item.level_id,
                                                                                        level_name: item.level_name,
                                                                                        level_page_limited: item.level_page_limited ? item.level_page_limited.split(",") : null,
                                                                                        level_divisi_limited: item.level_divisi_id ? item.level_divisi_id.split(",").map(function (x) {
                                                                                            return parseInt(x);
                                                                                        }) : null,
                                                                                        level_action_limited: item.level_action_id ? item.level_action_id.split(",").map(function (x) {
                                                                                            return x;
                                                                                        }) : null,
                                                                                    }, () => {
                                                                                        this.setState({
                                                                                            visable: true
                                                                                        })
                                                                                    })
                                                                                }}>
                                                                                    <i className="fa fa-pencil"
                                                                                        style={{
                                                                                            width: 35,
                                                                                            fontSize: 20,
                                                                                            padding: 11,
                                                                                            color: item.level_default != "1" ? 'rgb(255 172 21)' : "rgb(200, 200, 200)"
                                                                                        }}></i></a>
                                                                                <a onClick={() => {
                                                                                    item.level_default != "1" &&
                                                                                        this.setState({
                                                                                            idDel: item.level_id
                                                                                        }, () => {
                                                                                            this.wantDelete()
                                                                                        })
                                                                                }}><i className="fa fa-trash" style={{
                                                                                    width: 35,
                                                                                    fontSize: 20,
                                                                                    padding: 11,
                                                                                    color: item.level_default != "1" ? 'rgb(255 37 37)' : "rgb(200, 200, 200)"
                                                                                }}
                                                                                ></i></a>
                                                                            </Td>
                                                                        </Tr>
                                                                    )
                                                                })}
                                                                {this.state.isLoading &&
                                                                    <Tr>
                                                                        <Td colSpan="3">
                                                                            <Spin style={{ width: '100%' }} size="small" tip="Mohon Tunggu..." />
                                                                        </Td>
                                                                    </Tr>
                                                                }
                                                            </Tbody>
                                                        </Table>
                                                        <Paging
                                                            className="float-right"
                                                            handlePageClick={this.handlePageClick}
                                                            pageCount={this.state.pageCount}
                                                            currentPage={this.state.currentPage}
                                                        />
                                                    </div>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Fragment>
                    }
                </div>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    api_token: state.Auth.api_token,
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, auth_key, data) => dispatch(set_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserLevelPerusahaan);
