import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import {Empty, Modal, Select, Spin, Tooltip} from 'antd';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { pageNumber } from "../../functions/functionGeneral";
import {APIKEY, BACKEND_API_HOST} from "../../helper/host";
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import RefreshIcon from "../../assets/icons/refresh.svg";
import {ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import MapPicker from "react-google-map-picker";
import ImportIcon from "../../assets/icons/import-icon.svg";

export class List_outlet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            idDel: '',
            filter_nama: "",
            radius: "",
            radius_outlet: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            openModal: false,
            guide: false,
            prosesSimpan: false,
            modalAdd: false,
            modalimport: false,
            importFile: null,
            isLoading       : true,
            nama_outlet     : '',
            email_outlet    : '',
            no_hp_outlet    : '',
            alamat          : '',
            nama_pemilik    : '',
            location        : {
                lat: -7.865242,
                lng: 110.156070
            },
            lokasi_karyawan: [],

        }
        this.handleChange = this.handleChange.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleOk = e => {
        this.setState({
            modalexport: false,
            modalimport: false,
            modal: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalexport: false,
            modalimport: false,
            modal: false

        });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,

            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Lokasi Kunjungan?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        loading: true,
                        data: [],
                    })
                    this.props.request("outlet/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama
        }
        this.props.request("outlet/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        radius_outlet: response.perusahaan_radius_outlet,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    lokasiKaryawanHandler = () => {
        if (this.state.lokasi_karyawan === 'all') {
            this.setState({
                lokasi_karyawan: []
            })
        } else {
            this.setState({
                lokasi_karyawan: 'all'
            })
        }
    }

    simpanOutlet = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            nama            : this.state.nama_outlet,
            email           : this.state.email_outlet,
            no              : this.state.no_hp_outlet,
            latitude        : this.state.location.lat,
            longtitude      : this.state.location.lng,
            alamat          : this.state.alamat,
            nama_pemilik    : this.state.nama_pemilik,
            radius          : this.state.radius,
            karyawan        : this.state.lokasi_karyawan,
        }

        this.props.request("outlet/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.loadData()
                    this.setState({
                        modalAdd: false,
                        loading: false,
                        radius: "",
                        nama_outlet     : '',
                        email_outlet    : '',
                        no_hp_outlet    : '',
                        alamat          : '',
                        nama_pemilik    : '',
                        location        : {
                            lat: -7.865242,
                            lng: 110.156070
                        },
                        lokasi_karyawan: []
                    })
                    Swal.fire({
                        title               : 'Berhasil!',
                        text                : response.message,
                        icon                : 'success',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                    this.props.history.push('/lokasi-kunjungan')
                }else{
                    Swal.fire({
                        title               : 'Gagal!',
                        text                : response.message,
                        icon                : 'warning',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadKaryawan(){
        let form = {}
        this.props.request("karyawan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        loading         : false
                    })
                }
            });
    }

    exportLokasiKunjungan = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'outlet/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'outlet/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    componentDidMount() {
        this.loadData()
        this.loadKaryawan()
    }

    render() {
        let no = 0;
        const { Option } = Select;
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            hari: {
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#252B2",
                textAlign: "left",
                margin: "0px"
            },
        }
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Lokasi Kunjungan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Lokasi Kunjungan - Presensi</title>
                        <meta name="description" content="Daftar Lokasi Kunjungan" />
                    </Helmet>
                    <Breadcrumb title="Daftar Lokasi Kunjungan" parent="Lokasi Kunjungan" setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <Modal visible={this.state.modalAdd} bodyStyle={{ maxWidth: "982px", padding: 0, borderRadius: "8px" }} width={1000}
                           closeIcon={<></>}
                           footer={null}
                           onCancel={() => this.setState({
                               modalAdd: false,
                                nama_outlet     : '',
                                email_outlet    : '',
                                no_hp_outlet    : '',
                                alamat          : '',
                                nama_pemilik    : '',
                                location        : {
                                    lat: -7.865242,
                                    lng: 110.156070
                                },
                                lokasi_karyawan: []
                           })}>
                        <ModalHeader style={style_add.modal_header} className="borderRadiusHeader" toggle={() => this.setState({
                            modalAdd: false,
                                nama_outlet     : '',
                                email_outlet    : '',
                                no_hp_outlet    : '',
                                alamat          : '',
                                nama_pemilik    : '',
                                location        : {
                                    lat: -7.865242,
                                    lng: 110.156070
                                },
                                lokasi_karyawan: []
                        })}><div style={style_add.title_header}>Tambah Lokasi</div></ModalHeader>
                        <ModalBody className='p-4'>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <MapPicker
                                        key={Math.random}
                                        style={{ position: 'relative', width: '100%', height: '387px' }}
                                        name='location'
                                        defaultLocation={this.state.location}
                                        zoom={10}
                                        onChangeLocation={(lat, lng) => {
                                            this.setState({
                                                location: {
                                                    lat: lat,
                                                    lng: lng
                                                }
                                            })
                                        }}
                                        apiKey={APIKEY} />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Nama Lokasi Kunjungan</label>
                                    <input className='form-control' type='text' name='nama_outlet'
                                           onChange={(e) => this.handleChange(e)} value={this.state.nama_outlet}
                                           style={style_add.input} placeholder='Ketik lokasi kunjungan disini'/>
                                </div>
                                <div className="col-md-4">
                                    <div className="input-group" id={"input-radius"}>
                                        <label className='form-label' style={style_add.label_input}>Radius
                                            Kunjungan</label>
                                        <input type="text" name="radius" className="form-control"
                                               style={{borderRadius: "5px"}} placeholder="Min. 100"
                                               onChange={this.handleChange} value={this.state.radius}/>
                                        <div className="input-group-append">
                                            <div className="input-group-text bg-meter">
                                                <label>Meter</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2 d-flex justify-content-end'>
                                    <div className='input-group'>
                                        <label className='form-label' style={style_add.label_input}>Latitudee</label>
                                        <input
                                            className='form-control input-custom'
                                            value={this.state.location.lat}
                                            type='number'
                                            onChange={(event) => {
                                                const target = event.target;
                                                const value = target.value;
                                                this.setState({
                                                    location: {
                                                        lat: parseFloat(value),
                                                        lng: this.state.location.lng
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className='input-group'>
                                        <label className='form-label' style={style_add.label_input}>Longitude</label>
                                        <input
                                            className='form-control input-custom'
                                            value={this.state.location.lng}
                                            type='number'
                                            onChange={(event) => {
                                                const target = event.target;
                                                const value = target.value;
                                                this.setState({
                                                    location: {
                                                        lat: this.state.location.lat,
                                                        lng: parseFloat(value)
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Nama Pemilik</label>
                                    <input className='form-control' type='text' name='nama_pemilik'
                                           onChange={(e) => this.handleChange(e)} value={this.state.nama_pemilik}
                                           style={style_add.input} placeholder='Ketik nama pemilik disini'/>
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>No. Telepon Lokasi Kunjungan</label>
                                    <input className='form-control' type='text' name='no_hp_outlet'
                                           onChange={(e) => this.handleChange(e)} value={this.state.no_hp_outlet}
                                           style={style_add.input} placeholder='Ketik nomor telepon disini'/>
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Email Lokasi Kunjungan</label>
                                    <input type="text" name="email_outlet"
                                           className="form-control"
                                           value={this.state.email_outlet}
                                           placeholder="Email Lokasi Kunjungan..." onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-6'>
                                    <label className='form-label' style={style_add.label_input}>Alamat Lokasi</label>
                                    <textarea className='form-control' name='alamat'
                                           onChange={(e) => this.handleChange(e)} value={this.state.alamat}
                                           style={style_add.input} placeholder='Ketik alamat disini'/>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label style={{fontSize: "12px", color: "#143B5E"}}
                                               className="mb-2 font-weight-bold">Karyawan</label>
                                        {this.state.lokasi_karyawan !== 'all' ?
                                            <Select name="select_karyawan"
                                                    className={"col-xl-8 col-md-7"}
                                                    mode={"multiple"}
                                                    placeholder="Pilih Karyawan"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    onChange={(event) => {
                                                        this.setState({
                                                            lokasi_karyawan: event
                                                        })
                                                    }}>
                                                {this.state.karyawan.map((item, index) => {
                                                    return (<Option
                                                        key={item.karyawan_id}>{item.karyawan_nama}</Option>)
                                                })}
                                            </Select> : null
                                        }
                                        <label style={{fontSize: "12px"}}>
                                            <input style={{fontSize: "16px", borderRadius: "8px", marginTop: "10px"}}
                                                   type="checkbox"
                                                   checked={this.state.lokasi_karyawan === 'all'}
                                                   onChange={() => this.lokasiKaryawanHandler()}/>
                                            &nbsp;Semua Karyawan
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F9F9F9",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }} className='px-4 py-3'>
                            <button className='btn text-capitalize batalkan-add-jamker' onClick={() => this.setState({
                                modalAdd: false,
                                nama_outlet: '',
                                email_outlet: '',
                                no_hp_outlet: '',
                                alamat: '',
                                nama_pemilik: '',
                                location: {
                                    lat: -7.865242,
                                    lng: 110.156070
                                },
                                lokasi_karyawan: []
                            })}>Batalkan
                            </button>
                            <button className='btn btn-primary text-capitalize' onClick={() => this.simpanOutlet()}
                                    disabled={this.state.prosesSimpan} style={{
                                padding: "8px 14px",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "24px"
                            }}>Tambahkan Lokasi
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal
                        title="Import Lokasi Kunjungan"
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Sedang mengupload data</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                            <a onClick={this.exportLokasiKunjungan} style={{ color: "#057CE4" }}>Unduh template disini</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header pb-0 px-3 mb-3" style={{borderBottom: 'none'}}>
                                <div className="row mx-0" style={{marginLeft: '5px'}}>
                                    <div className="col-md-4">
                                        <div className="input-group">
                                            <input name="filter_nama"
                                                   className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name" : "form-control filter-name"}
                                                   placeholder="       Cari nama kunjungan ..."
                                                   type="text"
                                                   style={{
                                                       backgroundColor: "#F9F9F9",
                                                       fontSize: "12px",
                                                       fontWeight: 400,
                                                       paddingTop: "9px",
                                                       paddingBottom: "9px"
                                                   }}
                                                   required=""
                                                   value={this.state.filter_nama}
                                                   onChange={this.handleChange}
                                                   onKeyUp={(event) => {
                                                       event.preventDefault()
                                                       if (event.which == 13) {

                                                           this.setState({
                                                               page: 0,
                                                               paging: this.state.paging,
                                                               loading: true
                                                           }, () => {
                                                               this.loadData()
                                                           });
                                                       }
                                                   }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <button className='lokasi-button-refresh' style={{marginLeft: '0'}}
                                                onClick={this.resetFilter}>
                                            <img alt='' src={RefreshIcon}/>
                                        </button>
                                    </div>
                                    <div className="col"></div>
                                    <div className='col-auto px-0'>
                                        <button className="karyawan-import-button" onClick={() => {
                                            this.setState({modalimport: true})
                                        }}>
                                            <img src={ImportIcon} alt=''/> Import
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                            <Link to="/tambah-lokasi-kunjungan"
                                                  className="btn btn-primary tambah-absen ml-2"
                                                  style={{
                                                      float: "right",
                                                      color: "white",
                                                      textTransform: "none",
                                                      lineHeight: "25px"
                                                  }}><i
                                                className="fa fa-plus"></i> Tambah Lokasi</Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body daftar-absen">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description="Data Kosong"
                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                    :
                                    (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                        <Table striped className="mb-5">
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th className="text-center">Lokasi Kunjungan</Th>
                                                <Th className="text-center">Email</Th>
                                                <Th className="text-center">Nama Pemilik</Th>
                                                <Th className="text-center">Alamat</Th>
                                                <Th className="text-center">No. Telepon</Th>
                                                <Th className="text-center">Karyawan</Th>
                                                <Th className="text-center" width={140}>Aksi</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={9}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.data.map((item, index) => {
                                                no += 1
                                                let status = 'badge'
                                                return (
                                                    <Tr>
                                                        <small>
                                                            <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                        </small>
                                                        <Td align="center">{item.outlet_nama}</Td>
                                                        <Td align="center">{item.outlet_email !== null ? item.outlet_email : '-'}</Td>
                                                        <Td align="center">{item.outlet_nama_pemilik !== null ? item.outlet_nama_pemilik : '-'}</Td>
                                                        <Td align="center">{item.outlet_alamat !== null ? item.outlet_alamat : '-'}</Td>
                                                        <Td align="center">{item.outlet_no_hp !== null ? item.outlet_no_hp : '-'}</Td>
                                                        <Td align="center">{item.karyawan.length} Karyawan</Td>
                                                        <Td align="center">
                                                            <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                                <div className={'p-2'}>
                                                                    <a onClick={() => {
                                                                        this.setState({
                                                                            modal: item.outlet_id
                                                                        })
                                                                    }} className={'text-dark'}>Lihat Lokasi</a><br />
                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                        <>
                                                                            <a href={"/edit-lokasi-kunjungan/" + item.outlet_id} className={'text-dark'}>Edit</a><br />
                                                                        </>
                                                                    )}
                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                        <a onClick={() => {
                                                                            this.setState({
                                                                                idDel: item.outlet_id
                                                                            })
                                                                            this.wantDelete()
                                                                        }} className={'text-danger'}>Hapus</a>
                                                                    )}
                                                                </div>
                                                            }>
                                                                <a><i
                                                                    className="fa fa-ellipsis-v" style={{
                                                                        width: 35,
                                                                        fontSize: 18,
                                                                        padding: 11,
                                                                        color: 'rgb(51 51 51)'
                                                                    }}
                                                                ></i></a>
                                                            </Tooltip>
                                                            <Modal
                                                                visible={this.state.modal == item.outlet_id}
                                                                onOk={this.handleOk}
                                                                onCancel={this.handleCancel}
                                                            >
                                                                <div style={{
                                                                    position: 'relative',
                                                                    width: '100%',
                                                                    height: '250px'
                                                                }}>
                                                                    <Map
                                                                        google={this.props.google}
                                                                        initialCenter={{
                                                                            lat: item.outlet_latitude,
                                                                            lng: item.outlet_longitude
                                                                        }}
                                                                        zoom={15}>
                                                                        <Marker />
                                                                    </Map>
                                                                </div>
                                                            </Modal>

                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                    )
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: APIKEY
})(List_outlet));
