import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Empty, Modal, Spin, Tooltip } from "antd";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import {APIKEY, BACKEND_API_HOST} from "../../helper/host";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import ModalDefault from '../menu-guide/modal-default';
import FilterIcon from "../../assets/icons/filter.svg";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import {ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import MapPicker from "react-google-map-picker";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import PencilIcon from "../../assets/icons/pencil.svg";
import TrashIcon from "../../assets/icons/trash.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ImportIcon from "../../assets/icons/import-icon.svg";

export class List_lokasi_absen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            modal: false,
            idDel: '',
            filter_nama: '',
            filter_tipe: '',
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            guide: false,
            modalAdd: false,
            modalimport: false,
            importFile: null,
            location: {
                lat: -7.865242,
                lng: 110.156070
            },
            nama_lokasi: "",
            tipe_lokasi: "pusat",
            alamat_lokasi: "",
            radius_lokasi: 100,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            confirm_modal_default: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }
    showModal = (item) => {
        this.setState({
            modal: true,
            latitudeModal: item.latitude,
            longitudeModal: item.longitude,
        });
    };

    handleOk = e => {
        this.setState({
            modal: false,
            modalimport: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modal: false,
            modalimport: false
        });
    };

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_tipe: '',
            absensiStartDate: '',
            absensiEndDate: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    saveLocation = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            nama: this.state.nama_lokasi,
            perusahaan_title: this.state.tipe_lokasi === '' ? 'pusat' : this.state.tipe_lokasi,
            perusahaan_alamat: this.state.alamat_lokasi,
            latitude: this.state.location.lat,
            longitude: this.state.location.lng,
            max_jarak: this.state.radius_lokasi
        }

        this.props.request("lokasi/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalAdd: false,
                                nama_lokasi: "",
                                location: {},
                                tipe_lokasi: "",
                                radius_lokasi: 0,
                                alamat_lokasi: ""
                            }, () => {
                                this.loadData()
                                this.loadCheck()
                            })
                        } else {
                            this.setState({
                                data: [],
                                modalAdd: false,
                                nama_lokasi: "",
                                location: {},
                                tipe_lokasi: "",
                                radius_lokasi: 0,
                                alamat_lokasi: ""
                            }, () => {
                                this.loadData()
                                this.loadCheck()
                            })
                        }
                    })
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    editLocation = () => {
        this.setState({
            modalEdit: false,
            loading: true,
        })
        let form = {
            id: this.state.id_lokasi,
            nama: this.state.nama_lokasi,
            perusahaan_title: this.state.tipe_lokasi === '' ? 'pusat' : this.state.tipe_lokasi,
            perusahaan_alamat: this.state.alamat_lokasi,
            latitude: this.state.location.lat,
            longitude: this.state.location.lng,
            max_jarak: this.state.radius_lokasi
        }
        this.props.request("lokasi/update", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.setState({
                        data: [],
                        nama_lokasi: "",
                        location: {
                            lat: -7.865242,
                            lng: 110.156070
                        },
                        tipe_lokasi: "",
                        radius_lokasi: 100,
                        alamat_lokasi: ""
                    }, () => {
                        this.loadData()
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning'
                    })
                }
            })
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus lokasi presensi ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        data: [],
                        loading: true
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("lokasi/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.props.set_auth(this.props.api_token, response.perusahaan);
                                this.loadData()
                                this.loadCheck()
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                })
                            }
                        });
                }
            })
    }

    wantSetLocationKaryawan() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menambahkan lokasi ini pada setiap Karyawan?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        data: [],
                        loading: true
                    })
                    let form = {
                        id: this.state.idSet
                    }
                    this.props.request("karyawan/set-lokasi-all", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                                this.loadCheck()
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                })
                            }
                        });
                }
            })
    }

    detailLokasi() {
        let form = {
            id: this.state.id_lokasi
        }
        this.props.request("lokasi/detail", form, "POST")
            .then((response) => {
                const data = response.data
                if (response.success) {
                    this.setState({
                        nama_lokasi: data.lokasi_nama,
                        radius_lokasi: data.max_jarak,
                        alamat_lokasi: data.perusahaan_alamat,
                        location: {
                            lng: data.longitude,
                            lat: data.latitude
                        },
                        tipe_lokasi: data.perusahaan_title,
                        modalEdit: true
                    })
                }
            })
    }

    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            filter_tipe: this.state.filter_tipe,
            paging: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("lokasi/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                    })
                }
            });
    }

    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        karyawan_check: response.karyawan,
                        jam_kerja_check: response.jam_kerja
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                            this.setState({
                                confirm_modal_default: true
                            })
                        }
                    })
                }
            })
    }

    exportLokasiPresensi = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'lokasi/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'lokasi/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    componentDidMount() {
        this.loadData()
        this.loadCheck()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-lokasi',
                content: 'Anda dapat menambah Lokasi Presensi disini',
            },
            {
                selector: '.daftar-pengumuman',
                content: 'Daftar Lokasi Presensi anda dapat dilihat disini',
            },
        ];
        const style = {
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B",
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            hari: {
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#252B2",
                textAlign: "left",
                margin: "0px"
            },
        }
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Lokasi Kerja") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>List Lokasi Presensi - Presensi</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>
                    <Modal visible={this.state.modalAdd} bodyStyle={{ maxWidth: "1000px", padding: 0 }} width={1000}
                           closeIcon={<></>}
                           footer={null}
                           onCancel={() => this.setState({
                               modalAdd: false,
                               nama_lokasi: "",
                               location: {
                                   lat: -7.865242,
                                   lng: 110.156070
                               },
                               tipe_lokasi: "",
                               radius_lokasi: 100,
                               alamat_lokasi: ""
                           })}>
                        <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                            modalAdd: false,
                            nama_lokasi: "",
                            location: {
                                lat: -7.865242,
                                lng: 110.156070
                            },
                            tipe_lokasi: "",
                            radius_lokasi: 100,
                            alamat_lokasi: ""
                        })}><div style={style_add.title_header}>Tambah Lokasi</div></ModalHeader>
                        <ModalBody className='p-4'>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <MapPicker
                                        key={Math.random}
                                        style={{ position: 'relative', width: '100%', height: '250px' }}
                                        name='location'
                                        defaultLocation={this.state.location}
                                        zoom={10}
                                        onChangeLocation={(lat, lng) => {
                                            this.setState({
                                                location: {
                                                    lat: lat,
                                                    lng: lng
                                                }
                                            })
                                        }}
                                        apiKey={APIKEY} />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4'>
                                    <div className='input-group'>
                                        <div className='input-group-append'>
                                            <span className='input-group-field br-left-4'><small>Latitude</small></span>
                                        </div>
                                        <input
                                            className='form-control input-custom'
                                            value={this.state.location.lat}
                                            type='number'
                                            onChange={(event) => {
                                                const target = event.target;
                                                const value = target.value;
                                                this.setState({
                                                    location: {
                                                        lat: parseFloat(value),
                                                        lng: this.state.location.lng
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='input-group'>
                                        <div className='input-group-append'>
                                            <span className='input-group-field br-left-4'><small>Longitude</small></span>
                                        </div>
                                        <input
                                            className='form-control input-custom'
                                            value={this.state.location.lng}
                                            type='number'
                                            onChange={(event) => {
                                                const target = event.target;
                                                const value = target.value;
                                                this.setState({
                                                    location: {
                                                        lat: this.state.location.lat,
                                                        lng: parseFloat(value)
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Nama Lokasi</label>
                                    <input className='form-control' type='text' name='nama_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.nama_lokasi} style={style_add.input} placeholder='Masukkan nama lokasi' />
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Tipe Lokasi</label>
                                    <select className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.tipe_lokasi} name="tipe_lokasi" style={style_add.select}>
                                        <option value="">Pilih Tipe Lokasi</option>
                                        <option value="pusat">Pusat</option>
                                        <option value="cabang">Cabang</option>
                                    </select>
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Radius</label>
                                    <div className='input-group'>
                                        <input className='form-control' value={this.state.radius_lokasi} type='number' onChange={(e) => this.handleChange(e)} name='radius_lokasi' style={style_add.input} />
                                        <div className='input-group-append'>
                                            <span className='input-group-text' style={style_add.input_group_text}>Meter</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Alamat Lokasi</label>
                                    <input className='form-control' type='text' name='alamat_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.alamat_lokasi} style={style_add.input} placeholder='Masukkan alamat lengkap' />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F9F9F9",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }} className='px-4 py-3'>
                            <button className='btn text-capitalize batalkan-add-jamker' onClick={() => this.setState({
                                modalAdd: false,
                                nama_lokasi: "",
                                location: {},
                                tipe_lokasi: "",
                                radius_lokasi: 100,
                                alamat_lokasi: ""
                            })}>Batalkan</button>
                            <button className='btn btn-primary text-capitalize' onClick={() => this.saveLocation()} disabled={this.state.prosesSimpan} style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>Tambahkan Lokasi</button>
                        </ModalFooter>
                    </Modal>
                    <Modal visible={this.state.modalEdit} bodyStyle={{ maxWidth: "1000px", padding: 0 }} width={1000}
                           closeIcon={<></>}
                           footer={null}
                           onCancel={() => this.setState({
                               modalEdit: false,
                               nama_lokasi: "",
                               location: {},
                               tipe_lokasi: "",
                               radius_lokasi: 100,
                               alamat_lokasi: ""
                           })}>
                        <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                            modalEdit: false,
                            nama_lokasi: "",
                            location: {},
                            tipe_lokasi: "",
                            radius_lokasi: 100,
                            alamat_lokasi: ""
                        })}><div style={style_add.title_header}>Edit Lokasi</div></ModalHeader>
                        <ModalBody className='p-4'>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <MapPicker
                                        style={{ position: 'relative', width: '100%', height: '250px' }}
                                        name='location'
                                        defaultLocation={this.state.location}
                                        zoom={10}
                                        onChangeLocation={(lat, lng) => {
                                            this.setState({
                                                location: {
                                                    lat: lat,
                                                    lng: lng
                                                }
                                            })
                                        }}
                                        apiKey={APIKEY} />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4'>
                                    <div className='input-group'>
                                        <div className='input-group-append'>
                                            <span className='input-group-field br-left-4'><small>Latitude</small></span>
                                        </div>
                                        <input
                                            className='form-control input-custom'
                                            value={this.state.location.lat}
                                            type='number'
                                            onChange={(event) => {
                                                const target = event.target;
                                                const value = target.value;
                                                this.setState({
                                                    location: {
                                                        lat: parseFloat(value),
                                                        lng: this.state.location.lng
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='input-group'>
                                        <div className='input-group-append'>
                                            <span className='input-group-field br-left-4'><small>Longitude</small></span>
                                        </div>
                                        <input
                                            className='form-control input-custom'
                                            value={this.state.location.lng}
                                            type='number'
                                            onChange={(event) => {
                                                const target = event.target;
                                                const value = target.value;
                                                this.setState({
                                                    location: {
                                                        lat: this.state.location.lat,
                                                        lng: parseFloat(value)
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Nama Lokasi</label>
                                    <input className='form-control' type='text' name='nama_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.nama_lokasi} style={style_add.input} placeholder='Masukkan nama lokasi' />
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Tipe Lokasi</label>
                                    <select className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.tipe_lokasi} name="tipe_lokasi" style={style_add.select}>
                                        <option value="pusat">Pusat</option>
                                        <option value="cabang">Cabang</option>
                                    </select>
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Radius</label>
                                    <div className='input-group'>
                                        <input className='form-control' value={this.state.radius_lokasi} type='number' onChange={(e) => this.handleChange(e)} name='radius_lokasi' style={style_add.input} />
                                        <div className='input-group-append'>
                                            <span className='input-group-text' style={style_add.input_group_text}>Meter</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Alamat Lokasi</label>
                                    <input className='form-control' type='text' name='alamat_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.alamat_lokasi} style={style_add.input} placeholder='Masukkan alamat lengkap' />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F9F9F9",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }} className='px-4 py-3'>
                            <button className='btn text-capitalize batalkan-add-jamker' onClick={() => this.setState({
                                modalEdit: false,
                                nama_lokasi: "",
                                location: {},
                                tipe_lokasi: "",
                                radius_lokasi: 100,
                                alamat_lokasi: ""
                            })}>Batalkan</button>
                            <button className='btn btn-primary text-capitalize' disabled={this.state.loading} onClick={() => this.editLocation()} style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>Edit Lokasi</button>
                        </ModalFooter>
                    </Modal>
                    <Modal
                        title="Import Lokasi Presensi"
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Sedang mengupload data</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn btn-primary'} style={{ textTransform: "none", width: "100%" }} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                            <a onClick={this.exportLokasiPresensi} style={{ color: "#057CE4" }}>Unduh template disini</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    <Breadcrumb title="Daftar Lokasi Presensi" parent="Lokasi Presensi" parentLink="/lokasi-presensi" setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <ModalDefault
                        shift={this.state.shift_check}
                        lokasi={this.state.lokasi_check}
                        divisi={this.state.divisi_check}
                        karyawan={this.state.karyawan_check}
                        confirm_modal_default={this.state.confirm_modal_default}
                        setState={(name, val) => this.setState(name, val)}
                        history={this.props.history}
                        jam_kerja={this.state.jam_kerja_check}
                    />
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header pb-0 px-3 mb-3" style={{borderBottom: 'none'}}>
                                <div className="row mx-0" style={{marginLeft: '5px'}}>
                                    <div className="col-md-4">
                                        <div className='input-group'>
                                            <input name="filter_nama"
                                                   className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name" : "form-control filter-name"}
                                                   placeholder="       Cari nama lokasi ..."
                                                   type="text"
                                                   style={{
                                                       backgroundColor: "#F9F9F9",
                                                       fontSize: "12px",
                                                       fontWeight: 400,
                                                       paddingTop: "9px",
                                                       paddingBottom: "9px"
                                                   }}
                                                   required=""
                                                   value={this.state.filter_nama}
                                                   onChange={this.handleChange}
                                                   onKeyUp={(event) => {
                                                       event.preventDefault()
                                                       if (event.which == 13) {

                                                           this.setState({
                                                               page: 0,
                                                               paging: this.state.paging,
                                                               loading: true
                                                           }, () => {
                                                               this.loadData()
                                                           });
                                                       }
                                                   }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            className='btn primary button-filter width-filter filter-button'
                                            style={{lineHeight: "30px"}}
                                            onClick={() => this.setState({filter: !this.state.filter})}><img
                                            src={FilterIcon}/> Filter
                                        </button>
                                    </div>
                                    <div className="col"></div>
                                    <div className='col-auto px-0'>
                                        <button className="karyawan-import-button" style={{ height:"45px" }} onClick={() => {
                                            this.setState({modalimport: true})
                                        }}>
                                            <img src={ImportIcon} alt=''/> Import
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                            <div>
                                                {!this.state.loading &&
                                                    <>
                                                        <button
                                                            className='btn btn-primary text-capitalize float-right izin-btn-w100'
                                                            style={{lineHeight: "24px"}} onClick={() => {
                                                            this.setState({
                                                                modalAdd: true,
                                                                location: {
                                                                    lat: -7.865242,
                                                                    lng: 110.156070
                                                                },
                                                            })
                                                        }}><img className='mr-2' alt='' src={PlusWhiteIcon}/> Tambah
                                                            Lokasi
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {this.state.filter &&
                                    <div className='row mx-0'>
                                        <div className='col-md-2 px-3 mb-2'>
                                            <label className='form-label' style={{fontSize: "12px"}}>Status
                                                Lokasi</label>
                                            <select name="filter_tipe" className='form-control'
                                                    value={this.state.filter_tipe} style={{fontSize: "12px"}}
                                                    onChange={this.handleFilter}>
                                                <option value="">Pilih Status</option>
                                                <option value="pusat">Pusat</option>
                                                <option value="cabang">Cabang</option>
                                            </select>
                                        </div>
                                        <div className='col-auto px-1 d-flex align-items-end justify-content-end pb-2'>
                                            <button className='btn text-capitalize px-2 width-filter btn-reset-filter'
                                                    onClick={
                                                        () => {
                                                            this.setState({
                                                                loading: true,
                                                                filter_nama: "",
                                                                filter_tipe: "",
                                                            }, () => this.loadData())
                                                        }
                                                    }><img src={RefreshIcon} alt="icon refresh"/> Reset Filter
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card-body daftar-lokasi">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className='table table-striped table-borderless'>
                                                <thead>
                                                <tr>
                                                    <th style={{
                                                        borderBottom: "0px",
                                                        padding: "16px 12px",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        lineHeight: "24px",
                                                        color: "#252B2B",
                                                        width: "30px"
                                                    }}>#
                                                    </th>
                                                    <th style={{
                                                        borderBottom: "0px",
                                                        padding: "16px 12px",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        lineHeight: "24px",
                                                        color: "#252B2B",
                                                        minWidth: "50px",
                                                        maxWidth: "447px"
                                                    }}>Nama
                                                    </th>
                                                    <th style={style.th_style}>Radius</th>
                                                    <th style={style.th_style}>Tipe Lokasi</th>
                                                    <th style={style.th_style}>Status</th>
                                                    <th style={{
                                                        borderBottom: "0px",
                                                        padding: "16px 12px",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        lineHeight: "24px",
                                                        color: "#252B2B",
                                                        minWidth: "140px"
                                                    }}></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.loading ?
                                                    <Tr>
                                                        <Td colSpan={10}>
                                                            <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }} />
                                                        </Td>
                                                    </Tr>
                                                    :
                                                    this.state.data.length === 0 ?
                                                        <tr>
                                                            <td colSpan={5} style={{ textAlign: "center", backgroundColor: "#FFFFFF" }}>
                                                                <img src={EmptyDataImage} alt="icon data kosong" />
                                                                <p className='mb-2'>Data Kosong...</p>
                                                            </td>
                                                        </tr>
                                                        :
                                                        this.state.data.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <tr>
                                                                    <td style={style.td_style}>{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                    <td style={style.td_style}>
                                                                        <div className='row m-0' style={{
                                                                            fontWeight: 700,
                                                                            fontSize: "14px",
                                                                            color: "#252B2B",
                                                                            lineHeight: "24px"
                                                                        }}>{item.lokasi_nama}</div>
                                                                    </td>
                                                                    <td style={style.td_style}>{item.max_jarak}m</td>
                                                                    <td className='text-capitalize' style={style.td_style}>{item.perusahaan_title}</td>
                                                                    <td className='text-capitalize' style={style.td_style}>
                                                                        <Switch
                                                                            id={`detail-${item.lokasi_id}`}
                                                                            name="lokasi_status"
                                                                            checked={
                                                                                item.lokasi_status === '1' ? true : false
                                                                            }
                                                                            onChange={(val) => {
                                                                                if (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0){
                                                                                    this.setState({
                                                                                        loading: true,
                                                                                        data: []
                                                                                    })

                                                                                    let formData = {
                                                                                        lokasi_id: item.lokasi_id,
                                                                                    }

                                                                                    this.props.request('lokasi/ubah_status', formData, 'POST')
                                                                                        .then((response) => {
                                                                                            if (response.success) {
                                                                                                Swal.fire({
                                                                                                    title: 'Berhasil!',
                                                                                                    text: response.message,
                                                                                                    icon: 'success',
                                                                                                    showConfirmButton: false,
                                                                                                    timer: 1500,
                                                                                                })
                                                                                            } else {
                                                                                                if (Array.isArray(response.message)) {
                                                                                                    response.message.map(item => {
                                                                                                        toast['error'](item)
                                                                                                        return true
                                                                                                    })
                                                                                                } else {
                                                                                                    toast['error'](response.message)
                                                                                                }
                                                                                            }
                                                                                            this.loadData()
                                                                                            this.setState({ loading: false })
                                                                                        })
                                                                                }
                                                                            }}
                                                                            onColor="#1890ff"
                                                                            offColor="#eb4034"
                                                                            handleDiameter={18}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                            height={24}
                                                                            width={48}
                                                                            className="mr-1"
                                                                        />
                                                                    </td>
                                                                    <td className='text-right' style={style.td_style}>
                                                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                            <button className='hover-pointer' style={{
                                                                                backgroundColor: "transparent",
                                                                                border: "0px"
                                                                            }} onClick={() => {
                                                                                this.setState({
                                                                                    id_lokasi: item.lokasi_id
                                                                                }, () => {
                                                                                    this.detailLokasi()
                                                                                })
                                                                            }}>
                                                                                <img alt='' src={PencilIcon} />
                                                                            </button>
                                                                        )}
                                                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                            <button className='hover-pointer' style={{
                                                                                backgroundColor: "transparent",
                                                                                border: "0px"
                                                                            }} onClick={() => {
                                                                                this.setState({
                                                                                    idDel: item.lokasi_id
                                                                                })
                                                                                this.wantDelete()
                                                                            }}>
                                                                                <img alt='' src={TrashIcon} />
                                                                            </button>
                                                                        )}
                                                                        <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                                            <div className={'p-2'}>
                                                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                    <>
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                idSet: item.lokasi_id
                                                                                            }, () => {
                                                                                                this.wantSetLocationKaryawan()
                                                                                            })
                                                                                        }}
                                                                                           className={'text-dark'}>Tambah Lokasi ke Semua Karyawan</a><br/>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        }>
                                                                            <a><i
                                                                                className="fa fa-ellipsis-h" style={{
                                                                                width: 35,
                                                                                fontSize: 18,
                                                                                padding: 11,
                                                                                color: 'rgb(51 51 51)'
                                                                            }}
                                                                            ></i></a>
                                                                        </Tooltip>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer p-0'>
                                <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                    {/* {this.state.data.length !== 0 && */}
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} dari {this.state.totalData} data</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCount}
                                                forcePage={this.state.currentPage}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageClick}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                            {/* catatan.
                                                custom classname pagination dipanggil dari file css di folder absensi harian
                                                ga hanya pagination, semua classname dari css absensi harian bisa dipanggil file ini */}
                                        </div>
                                    </>
                                    {/* // } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: APIKEY
})(List_lokasi_absen));
