import React, {Component, Fragment} from 'react'
import {Empty, Spin} from "antd";
import {Button, ButtonGroup, FormGroup, Input, Label} from "reactstrap";
import {pageNumber} from "../../functions/functionGeneral";
import Paging from "../../components/paging";
import format from "format-number";
import moment from "moment";
import CurrencyInput from 'react-currency-input-field';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

export class Pembayaran extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading         : false,
            data            : [],
            dataPembayaran  : [],
            visible         : false,
            k_bayar_desc    : "",
            desc    : "",
            k_bayar_nominal : "",
            pageCount       : 0,
            currentPage     : 0,
            totalData       : 0,
            perPage         : 0,
            paging          : 20,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleEventChange = this.handleEventChange.bind(this)
        this.handleEventChangeAmount = this.handleEventChangeAmount.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleEventChange(event, maskedvalue) {
        const target = event.target;
        const name = target.name;
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        this.setState({
            [name]: final
        });
    }

    handleEventChangeAmount(event, maskedvalue) {
        const target = event.target;
        const name = target.name;
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        this.setState({
            [name]: final
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSubmit() {
        let formData = {
            karyawan_id : this.props.match.params.id,
            nominal     : this.state.nominal,
            desc        : this.state.desc,
        }
        this.props.request("kasbon/add-kasbon-bayar", formData, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        nominal: "",
                        desc: "",
                        karyawan_id: "",
                        loadingSave: false,
                        visible: false,
                    }, () => {
                        Swal.fire({
                            title: 'Berhasil!',
                            text: response.message,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1200,
                        })
                        this.loadData()
                    })
                } else {
                    this.setState({
                        loadingSave: false
                    })
                    Swal.fire({
                        title: 'gagal!',
                        text: response.message,
                        icon: 'danger',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                }
            })
    }

    handleSubmitEdit() {
        let formData = {
            id          : this.state.dataPembayaran.k_bayar_id,
            nominal     : this.state.k_bayar_nominal,
            desc        : this.state.k_bayar_desc,
        }
        this.props.request("kasbon/edit-bayar", formData , "POST")
            .then(response=>{
                if(response.success){
                    this.setState({
                        bayar_nominal   : "",
                        k_bayar_id      : "",
                        bon_id          : "",
                        bayar_desc      : "",
                        visibleEdit     : false,
                        loadingSave     : false
                    },()=>{
                        Swal.fire({
                            title: 'Berhasil!',
                            text: response.message,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1200,
                        })
                        this.loadData()
                    })
                }
            })
    }

    deletePembayaran() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus data pembayaran kasbon karyawan?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let formData = {
                        id          : this.state.dataPembayaran.k_bayar_id,
                    }
                    this.props.request("kasbon/delete-bayar", formData , "POST")
                        .then(response=>{
                            if(response.success){
                                this.setState({
                                    dataPembayaran   : "",
                                    loadingSave     : false
                                },()=>{
                                    Swal.fire({
                                        title: 'Berhasil!',
                                        text: response.message,
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1200,
                                    })
                                    this.loadData()
                                })
                            }
                        })
                }
            })
    }

    loadData() {
        this.setState({loading: true})
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
            karyawan_id: this.props.match.params.id,
        }
        this.props.request("kasbon/bayar_by_karywan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const {loadingSave, visible, visibleEdit} = this.state;

        let no = 0
        let formAdd;
        let formEdit;
        let buttonAdd;
        let buttonClose;
        if (visible) {
            formAdd =
                <div>
                    <div className="text-center">
                        <strong>TAMBAH PEMBAYARAN</strong>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <FormGroup>
                                    <div className="col-md-4">
                                        <Label className="mr-sm-2">Nominal </Label>
                                    </div>
                                    <div className="col-md-8">
                                        <CurrencyInput
                                            name="nominal"
                                            className="form-control"
                                            thousandSeparator="."
                                            defaultValue={this.state.nominal}
                                            prefix="Rp "
                                            id="nominal"
                                            precision={0}
                                            onValueChange={(value, name) => this.setState({nominal: value})}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div>
                            <FormGroup>
                                <div className="col-md-4">
                                    <Label className="mr-sm-2">Deskripsi </Label>
                                </div>
                                <div className="col-md-8">
                                    <Input
                                        type="textarea"
                                        name="desc"
                                        className="form-control"
                                        value={this.state.desc}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </FormGroup>
                        </div>
                        <div className="pull-right">
                            <button type="button" className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({
                                            loadingSave: true
                                        }, () => {
                                            this.handleSubmit()
                                        })
                                    }}
                                    disabled={loadingSave}>
                                {loadingSave && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{marginRight: "5px"}}
                                    />
                                )}
                                {loadingSave && <span>Simpan</span>}
                                {!loadingSave && <span>Simpan</span>}
                            </button>
                        </div>
                    </div>
                </div>
            buttonAdd =
                <button className="btnClose"
                        onClick={() => {
                            this.setState({
                                visible: false
                            })
                        }}
                ><FontAwesomeIcon icon={faChevronUp} className="mr-2"/>
                    Close
                </button>
        } else if (this.state.visibleEdit == true) {
            formAdd = ""
        } else {
            buttonAdd =
                <button className="BtnF"
                        onClick={() => {
                            this.setState({
                                visible: true,
                                visibleEdit: false
                            })
                        }}
                ><FontAwesomeIcon icon={faChevronDown} className="mr-2"/>
                    Tambah
                </button>
        }

        if (visibleEdit) {
            formEdit =
                <div>
                    <div className="text-center">
                        <strong>EDIT PEMBAYARAN KASBON</strong>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <FormGroup>
                                    <div className="col-md-4">
                                        <Label className="mr-sm-2">Nominal </Label>
                                    </div>
                                    <div className="col-md-8">
                                        <CurrencyInput
                                            name="k_bayar_nominal"
                                            id="k_bayar_nominal"
                                            className="form-control"
                                            thousandSeparator="."
                                            value={this.state.k_bayar_nominal}
                                            prefix={"Rp "}
                                            precision={0}
                                            onValueChange={(value, name) => this.setState({k_bayar_nominal: value})}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div>
                            <FormGroup>
                                <div className="col-md-4">
                                    <Label className="mr-sm-2">Deskripsi </Label>
                                </div>
                                <div className="col-md-8">
                                    <Input
                                        type="textarea"
                                        name="k_bayar_desc"
                                        id="k_bayar_desc"
                                        className="form-control"
                                        value={this.state.k_bayar_desc}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </FormGroup>
                        </div>
                        <div className="pull-right">
                            <button type="button" className="btn btn-primary"
                                    onClick={()=>{
                                        this.setState({
                                            loadingSave : true
                                        },()=>{
                                            this.handleSubmitEdit()
                                        })
                                    }}
                                    disabled={loadingSave}>
                                {loadingSave && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {loadingSave && <span>Simpan</span>}
                                {!loadingSave && <span>Simpan</span>}
                            </button>
                        </div>
                    </div>
                </div>
            buttonClose =
                <button className="btnClose"
                        onClick={()=>{
                            this.setState({
                                visibleEdit: false
                            })
                        }}
                ><FontAwesomeIcon icon={faChevronUp} className="mr-2"/>
                    Close
                </button>
        } else if(this.state.visible == true){
            formEdit = ""
        }
        else {
            buttonClose =
                ""
        }
        return (
            <Fragment>
                <div className="card-header">
                    <div className="btn-actions-pane-right">
                        {buttonAdd}
                        {buttonClose}
                    </div>
                </div>
                {formAdd}
                {formEdit}
                <div className="card-body daftar-karyawan">
                    {!this.state.loading && this.state.data.length === 0 ?
                        <Empty description="Data Kosong"
                               image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"/>
                        :
                        <Table striped className="mb-5">
                            <Thead>
                            <Tr>
                                <Th width="30">#</Th>
                                <Th className="text-center">Nominal</Th>
                                <Th className="text-center">Deskripsi</Th>
                                <Th className="text-center">Tanggal</Th>
                                <Th className="text-center">Aksi</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                            {this.state.loading &&
                            <Tr>
                                <Td colSpan={10}>
                                    <Spin size="large" tip="Loading..." style={{
                                        width: '100%',
                                        margin: 'auto',
                                        marginBottom: '10px',
                                        marginTop: '10px'
                                    }}/>
                                </Td>
                            </Tr>
                            }
                            {this.state.data.map((item, index) => {
                                no += 1
                                return (
                                    <Tr>
                                        <small>
                                            <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                        </small>
                                        <Td className="text-center">
                                            {format({prefix: 'Rp ', integerSeparator: "."})(item.k_bayar_nominal)}
                                        </Td>
                                        <Td className="text-center">
                                            {item.k_bayar_desc}
                                        </Td>
                                        <Td className="text-center">
                                            {moment(item.k_bayar_date).format('ll')}
                                        </Td>
                                        <Td className="text-center">
                                            <ButtonGroup size="sm">
                                                <div className={"mx-1"}>
                                                    <Button color='info'
                                                            onClick={() => {
                                                                this.setState({
                                                                    visible: false,
                                                                    visibleEdit : true,
                                                                    dataPembayaran: item,
                                                                    k_bayar_nominal: item.k_bayar_nominal,
                                                                    k_bayar_desc: item.k_bayar_desc,
                                                                })}}
                                                    >
                                                        <i className="fa fa-pencil-square-o "></i>
                                                    </Button>
                                                </div>
                                                <div className={"mx-1"}>
                                                    <Button color='danger'
                                                            onClick={() => {
                                                                this.setState({
                                                                    dataPembayaran: item,
                                                                }, () => {this.deletePembayaran()})}}
                                                    >
                                                        <i className="fa fa-trash "></i>
                                                    </Button>
                                                </div>
                                            </ButtonGroup>
                                        </Td>
                                    </Tr>
                                )
                            })}
                            </Tbody>
                        </Table>
                    }
                    <Paging
                        className="float-right"
                        handlePageClick={this.handlePageClick}
                        pageCount={this.state.pageCount}
                        currentPage={this.state.currentPage}
                    />

                </div>
            </Fragment>
        )
    }
}

export default (Pembayaran)
