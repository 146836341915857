import React, { Component, Fragment } from 'react';
import { Empty, Spin } from 'antd';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {convertToRupiah, pageNumber} from "../../functions/functionGeneral";
import { toast } from "react-toastify";
import { Button, FormGroup, InputGroup, InputGroupAddon, Label, UncontrolledTooltip } from "reactstrap";
import CurrencyInput from 'react-currency-input-field';
import KOMPENSASI from "./modalKompensasi"
import CUTISETTING from "./modalCutiSetting"
import "./style.css"
import refreshIcon from '../../assets/icons/refresh.svg'
import {IMG_HOST} from "../../helper/host";
import pencilIcon from "../../assets/icons/edit_pencil.svg"

export class ListCutiKaryawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            jabatan: [],
            data: [],
            kompensasi: [],
            potongan: [],
            data_term: [],
            data_term_potongan: [],
            rows: [{}],
            idDel: '',
            filter_nama: "",
            filter_jabatan: "",
            filter_tahun: 2024,
            karyawan_id: "",
            current_karyawan: {},
            importFile: null,
            modalimport: false,
            modalAction: false,
            visibleKom: false,
            addDetail: false,
            visiblePot: false,
            p_term_name: "",
            p_term_type: "",
            inputType: "select",
            dataAction: [],
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 25,
            guide: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleSubmitKom = this.handleSubmitKom.bind(this)
        this.handleSubmitIstilah = this.handleSubmitIstilah.bind(this)
        this.handleSubmitAmount = this.handleSubmitAmount.bind(this)
        this.kompensasiModal = this.kompensasiModal.bind(this)
        this.loadKompensasi = this.loadKompensasi.bind(this)
        this.handleChangeStock = this.handleChangeStock.bind(this)
        this.handleChangeistilah = this.handleChangeistilah.bind(this)
        this.wantDeleteKom = this.wantDeleteKom.bind(this)
        this.addClickKom = this.addClickKom.bind(this)
        this.removeClickKom = this.removeClickKom.bind(this)
        this.handlePotonganType = this.handlePotonganType.bind(this)
        this.handlePotonganAmount = this.handlePotonganAmount.bind(this)
        this.handleSubmitPotongan = this.handleSubmitPotongan.bind(this)
        this.potonganDelete = this.potonganDelete.bind(this)

    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }
    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }


    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_jabatan: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleOk = e => {
        this.setState({
            modalimport: false,
            modalAction: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false,
            modalAction: false
        });
    };

    handleSubmitAmount(event, id) {
        const target = event.target;
        const name = target.name;
        var value;
        if (target.value.search(",") >= 0) {
            value = parseInt(target.value.split(',').join(''));
        } else {
            value = parseInt(target.value.split('.').join(''));
        }

        let formData = {
            karyawan_id: id,
            form: { [name]: value }
        }
        this.props.request('karyawan/payroll_update', formData, 'POST')
            .then(response => {
                if (response.success) {
                    toast['success'](response.message, { toastId: Math.floor(Math.random() * 2) })
                    this.setState({
                        loading: true,
                        visiblePot: false,
                        editedValue: '',
                        data: [],
                    })
                    this.loadData()
                }
            });
    }

    // data kompensasi

    kompensasiModal = () => {
        this.setState({
            visibleKom: !this.state.visibleKom
        })
    };

    istillahBatal = () => {
        this.setState({
            inputType: "select",
            rows: [{}]
        })
    };

    handleChangeStock = id => e => {

        const { name, value } = e.target;
        const list = [...this.state.rows];

        if (value == 'text') {
            this.setState({
                inputType: value,
            })
        }

        list[id] = { ...list[id], [name]: value };

        this.setState({
            rows: list,
        })
    };

    handleChangeistilah = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        })
    }

    handleSubmitIstilah(id) {
        this.setState({ loading: true })

        let formData = {
            p_term_type: id,
            p_term_name: this.state.p_term_name
        }
        this.props.request("payroll/add-payroll-term", formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message, { toastId: Math.floor(Math.random() * 2) })
                    this.setState({
                        loading: false,
                        rows: [{}],
                        inputType: "select",
                        p_term_name: ""
                    })
                    this.loadDataTerm()
                    this.loadDataTermPotongan()
                }
                else {
                    if (Array.isArray(response.message)) {

                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                    this.setState({ loading: false })
                }

                this.setState({ loading: false })
            })
    }

    handleSubmitKom(event, id) {
        this.setState({ loading: true })

        let formData = {
            rows: this.state.rows,
            karyawan_id: id,
        }
        this.props.request('kompensasi/add', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message, { toastId: Math.floor(Math.random() * 2) })
                    this.setState({
                        loading: false,
                        addDetail: true,
                        rows: [{}],
                    })
                    this.loadKompensasi(id)
                }
                else {
                    if (Array.isArray(response.message)) {

                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                    this.setState({ loading: false })
                }

                this.setState({ loading: false })
            })
    }

    addClickKom() {
        this.setState({
            rows: [...this.state.rows, { k_kompensasi_type: "", p_term_id: "", k_kompensasi_amount: "" }]
        })
    }

    removeClickKom = (id) => {
        this.state.rows.splice(id, 1);
        this.setState({ rows: this.state.rows });
    };

    loadKompensasi(id) {
        let formData = {
            karyawan_id: id,

        }

        this.props.request('kompensasi/list_by_karyawan', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    var result = response.data;
                    this.setState({
                        kompensasi: result.data,
                        visibleKom: true
                    })
                }
                this.setState({ isLoading: false })
            })
    }

    loadDataTerm() {
        this.setState({ loading: true })

        this.props.request("kompensasi/list-by-payroll-term", 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_term: response.data,

                    })
                }
                this.setState({ loading: false })
            });
    }

    loadDataTermPotongan() {
        this.setState({ loading: true })

        this.props.request("karyawan_potongan/list-payroll-term", 'POST')
            .then(response => {
                if (response.success) {

                    this.setState({
                        data_term_potongan: response.data,

                    })
                }
                this.setState({ loading: false })
            });
    }

    wantDeleteKom(id, karyawan_id) {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus data kompensasi?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true
                    })
                    let form = {
                        id: id
                    }
                    this.props.request("kompensasi/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true
                                })
                                this.loadKompensasi(karyawan_id)
                            }
                        });
                }
            })
    }

    // data potongan

    potonganModal = () => {
        this.setState({
            visiblePot: !this.state.visiblePot
        })
    };

    loadPotongan(id) {
        this.setState({ isLoading: true })
        let form = {
            karyawan_id: id,
        }
        this.props.request("karyawan_potongan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        potongan: response.data,
                        visiblePot: true
                    })
                }
                this.setState({ isLoading: false })
            });
    }

    handlePotonganType = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.potongan];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            potongan: list,
        })
    };

    handlePotonganAmount = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.potongan];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };
        this.setState({
            potongan: list,
        })
    };

    handleChangePotongan = id => e => {

        const { name, value } = e.target;
        const list = [...this.state.potongan];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            potongan: list,
        })
    };

    handleSubmitPotongan(id) {
        let formData = {
            data: this.state.potongan,
            row: this.state.rows,
            id: id,
        }
        this.props.request('karyawan_potongan/update', formData, 'POST')
            .then(response => {
                if (response.success) {
                    toast['success'](response.message, { toastId: Math.floor(Math.random() * 2) })
                    this.setState({
                        isLoading: true,
                        rows: [{
                            "p_term_id": "", "k_potongan_type": "", "k_potongan_amount": ""
                        }]
                    })
                    this.loadPotongan(id)
                }
            });
    }


    potonganDelete(id, id_pot) {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: id_pot
                    }
                    this.props.request("karyawan_potongan/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                })
                                this.loadPotongan(id)
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                })
                            }
                        });
                }
            })
    }

    loadJabatan() {
        let form = {}
        this.props.request("get_jabatan_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        jabatan: response.data,
                    })
                }
            });
    }

    loadData() {
        let form = {
            paginate: this.state.paging,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
            filter_jabatan: this.state.filter_jabatan,
            filter_tahun: this.state.filter_tahun,
        }
        this.props.request("payroll/list_upah", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    handleRowsPerPageChange = (newRowsPerPage) => {
        this.setState({ paging: newRowsPerPage }, () => {
            this.loadData();
        });
    };

    cekMasterData() {
        if (this.props.user.perusahaan.divisi === null || this.props.user.perusahaan.jabatan === [] || this.props.user.perusahaan.lokasi === null) {
            Swal.fire({
                title: 'Peringatan!',
                text: 'Anda harus menambahkan Seluruh Master Data terlebih dahulu',
                icon: 'warning',
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push('/karyawan/shift')
                    }
                })
        }
    }

    componentDidMount() {
        this.loadData()
        this.cekMasterData()
        this.loadJabatan()
    }

    render() {
        const stylefoto = {
            height: '50px',
            width: '50px',
            objectFit: 'cover',
            borderRadius: "6px"
        }
        let no = 0;
        const steps = [
            {
                selector: '.daftar-karyawan',
                content: 'Daftar Karyawan anda dapat dilihat disini',
            },
            {
                selector: '.filter-karyawan',
                content: 'Anda dapat mem-Filter daftar Karyawan anda lewat sini',
            },
        ];
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Daftar Gaji Karyawan") >= 0) {
            return (
                <Fragment>
                    <div className="body">
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>Pengaturan Cuti Karyawan - Presensi</title>
                            <meta name="description" content="Daftar Gaji Karyawan - Presensi" />
                        </Helmet>
                        <div className="container-fluid">
                            <div className="page-header">
                                <div className="col-lg-6">
                                    <div className="page-header-left">
                                        <h3 style={{ color: "#143B5E"}}>
                                            <small style={{ fontSize:"12px"}} className="mb-1">Presensi Admin panel</small><br />
                                            Pengaturan Cuti Karyawan
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                        <div className="container-fluid list-karyawan rounded">
                            <div className="card border border-secondary-subtle">
                                <div className="row filter-karyawan" style={{ marginLeft: '5px', marginTop: "20px", marginBottom: '6px'}}>
                                    <div className="col-md-2 pl-0 pr-0 mt-1 column-filter column-filter-input">
                                        <input name="filter_nama"
                                               className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name" : "form-control filter-name"}
                                               placeholder="    Nama Karyawan..."
                                               type="text"
                                               value={this.state.filter_nama}
                                               onChange={this.handleChange}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()
                                                   if (event.which == 13) {

                                                       this.setState({
                                                           page: 0,
                                                           paging: this.state.paging,
                                                           loading: true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }} />
                                    </div>
                                    <div className="col-md-2 pl-0 pr-0 mt-1 column-filter">
                                        <select name="filter_jabatan" className="form-control col" style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                            <option value="">Pilih Divisi</option>
                                            {this.state.jabatan.map((item, index) => {
                                                return (
                                                    <option value={item.jabatan_id}>{item.jabatan_nama}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-2 pl-0 pr-0 mt-1 column-filter">
                                        <select name="filter_tahun" className="form-control col" style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                            <option value="2024">2024</option>
                                            <option value="2023">2023</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                        </select>
                                    </div>
                                    <div className="col-md-auto pl-0 mt-1">
                                        <button className="btn button-styling px-2" onClick={() => { this.resetFilter() }}><img src={refreshIcon} /></button>
                                    </div>
                                </div>
                                <hr style={{marginTop: '8px', marginBottom: "8px"}} />
                                <div className="card-body daftar-karyawan pt-0">
                                    {!this.state.loading && this.state.data.length === 0 ?
                                        <Empty description="Data Kosong"
                                               image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                                        :
                                        (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                            <Table className="mb-5 table-style">
                                                <Thead>
                                                    <Tr className="thead-tr">
                                                        <Th width="30">#</Th>
                                                        <Th className="text-left" width="90"><span style={{ fontSize: 12 }}>Foto</span></Th>
                                                        <Th className="text-left" width="200"><span style={{ fontSize: 12 }}>Nama Karyawan</span></Th>
                                                        <Th className="text-left" width="180"><span style={{ fontSize: 12 }}>Jabatan-Divisi</span></Th>
                                                        <Th className="text-left" width="200"><span style={{ fontSize: 12 }}>Upah Cuti</span></Th>
                                                        <Th className="text-center" width="100"><span style={{ fontSize: 12 }}>Jatah Cuti Tahunan</span></Th>
                                                        <Th className="text-center" width="100"><span style={{ fontSize: 12 }}></span></Th>
                                                    </Tr>
                                                </Thead>
                                                <br/>
                                                <Tbody className="tbody-table">
                                                    {this.state.loading &&
                                                        <Tr>
                                                            <Td colSpan={10}>
                                                                <Spin size="large" tip="Loading..." style={{
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px'
                                                                }} />
                                                            </Td>
                                                        </Tr>
                                                    }
                                                    {this.state.data.map((item, index) => {
                                                        no += 1
                                                        return (
                                                            <Tr className="table-tr">
                                                                <Th className="number-list" scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                <Td style={{ border: "none" }}>{item.karyawan_foto !== null ? (
                                                                    <img style={stylefoto} alt=''
                                                                         src={IMG_HOST + 'frontapp/karyawan/' + item.karyawan_foto} />
                                                                ) : (
                                                                    <img style={stylefoto} alt=''
                                                                         src={IMG_HOST + "user-profile.png"} />
                                                                )}</Td>
                                                                <Td align="left">
                                                                    <span style={{ fontWeight: 600 }}>{item.karyawan_nama}</span><br/>
                                                                    <p style={{ fontWeight: 300 }}>{item.karyawan_email}</p>
                                                                </Td>
                                                                <Td align="left">
                                                                    <span style={{ fontWeight: 600 }}>{item && item.jabatan && item.jabatan.jabatan_nama}</span>
                                                                    <p style={{ fontWeight: 300 }}>{item.divisi.divisi_name}</p>
                                                                </Td>
                                                                {/* upah cuti */}
                                                                <Td className="text-start">
                                                                    <div style={{ padding: 4 }} className="input-column">
                                                                        {convertToRupiah(item.karyawan_cuti_amount, true)} / Hari
                                                                    </div>
                                                                </Td>
                                                                {/* upah gaji */}
                                                                <Td align="center">
                                                                    {item.cuti_used}/{item.karyawan_max_cuti}
                                                                </Td>
                                                                <Td align="center">
                                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                        <>
                                                                            <Button id={`list-${item.karyawan_id}`}
                                                                                    className="btn-shadow button-style-edit"
                                                                                    style={{ width: "77px", paddingLeft:"5px" }}
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            current_karyawan: item,
                                                                                            visiblePot: true
                                                                                        })
                                                                                    }}
                                                                            ><img src={pencilIcon} /> Edit</Button>&nbsp;
                                                                            <UncontrolledTooltip placement="top" target={`list-${item.karyawan_id}`}>
                                                                                Edit
                                                                            </UncontrolledTooltip>
                                                                        </>
                                                                    )}
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    })}
                                                </Tbody>
                                            </Table>
                                        )
                                    }
                                    <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>
                                        menampilkan {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} dari {this.state.totalData} data, Tampilkan
                                        <button className='button border px-1 ml-1' onClick={() => this.handleRowsPerPageChange(25)}>25</button>
                                        <button className='button border px-1 ml-1' onClick={() => this.handleRowsPerPageChange(50)}>50</button>
                                        <button className='button border px-1 ml-1' onClick={() => this.handleRowsPerPageChange(100)}>100</button>
                                    </div>
                                    <Paging
                                        className="float-right"
                                        handlePageClick={this.handlePageClick}
                                        pageCount={this.state.pageCount}
                                        currentPage={this.state.currentPage}
                                    />
                                </div>
                            </div>
                        </div>
                        <KOMPENSASI
                            kompensasi={this.state.kompensasi}
                            id={this.state.karyawan_id}
                            visibleKom={this.state.visibleKom}
                            dataTerm={this.state.data_term}
                            rows={this.state.rows}
                            addClickKom={this.addClickKom}
                            handleSubmitKom={this.handleSubmitKom}
                            removeClickKom={this.removeClickKom}
                            handleChangeStock={this.handleChangeStock}
                            handleChangeistilah={this.handleChangeistilah}
                            wantDeleteKom={this.wantDeleteKom}
                            kompensasiModal={this.kompensasiModal}
                            loadKompensasi={this.loadKompensasi}
                            request={this.props.request}
                            loading={this.state.loading}
                            inputType={this.state.inputType}
                            p_term_name={this.state.p_term_name}
                            handleSubmitIstilah={this.handleSubmitIstilah}
                            istillahBatal={this.istillahBatal}
                            changeState={(event) => {
                                this.setState(event)
                            }}

                        />
                        <CUTISETTING
                            karyawan={this.state.current_karyawan}
                            user={this.props.user}
                            visiblePot={this.state.visiblePot}
                            handleSubmitAmount={this.handleSubmitAmount}
                            potonganModal={this.potonganModal}
                            handlePotonganType={this.handlePotonganType}
                            handlePotonganAmount={this.handleChangePotongan}
                            handleSubmitPotongan={this.handleSubmitPotongan}
                            handleSubmitIstilah={this.handleSubmitIstilah}
                            istillahBatal={this.istillahBatal}
                            inputType={this.state.inputType}
                            p_term_name={this.state.p_term_name}
                            removeClick={this.potonganDelete}
                            handleChangeistilah={this.handleChangeistilah}
                            changeState={(event) => {
                                this.setState(event)
                            }}

                        />
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

export default connect(mapStateToProps)(ListCutiKaryawan);
