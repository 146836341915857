import React, { Component } from 'react'
import { Button, Drawer, Form, Select } from 'antd';
import Swal from "sweetalert2";
import { set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";

class Add extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            divisi_name: '',
            divisi_sift: '',
            divisi_payroll_type: '',
            shift: []
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    simpanDivisi = () => {
        this.setState({
            loading: true
        })
        let form = {
            name: this.state.divisi_name,
            divisi_sift: this.state.divisi_sift,
            payroll: this.state.divisi_payroll_type
        }

        this.props.request("divisi/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({ divisi_name: '', divisi_sift: ''})
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                    this.props.changeState({
                        divisi_name: '',
                        add_visible: false
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    loadShift() {
        let form = {}
        this.props.request("shift/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        shift: response.data.data
                    })
                }
            });
    }

    componentDidMount() {
        this.loadShift()
    }

    render() {
        const { Option } = Select;
        return (
            <Drawer
                title="Tambah Divisi"
                width={350}
                onClose={() => {
                    this.props.changeState({ add_visible: false, divisi_name: '' });
                }}
                visible={this.props.add_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => {
                            this.props.changeState({ add_visible: false, divisi_name: '' });
                        }}
                            style={{ marginRight: 8 }}>
                            Batal
                        </Button>
                        <Button onClick={this.simpanDivisi} disabled={this.state.loading} type="primary">
                            Simpan
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Nama Divisi</label>
                        <input name="divisi_name" className="form-control col-xl-12 col-md-12" placeholder="Nama Divisi..."
                            type="text" required="" value={this.state.divisi_name}
                            onChange={(event) => {
                                const target = event.target;
                                const value = target.value;
                                const name = target.name;
                                this.setState({
                                    [name]: value
                                });
                            }} />
                    </div>
                    <div className="form-group">
                        <label>Shift Divisi</label>
                        <Select name="divisi_sift"
                            style={{ width: '100%' }}
                            mode={"multiple"}
                            placeholder="Pilih Shift"
                            data_karyawan
                            onChange={(event) => {
                                this.setState({
                                    divisi_sift: event
                                })
                            }}>
                            {this.state.shift.map((item, index) => {
                                return (<Option key={item.sift_id}>{item.sift_name}</Option>)
                            })}
                        </Select>
                    </div>
                    <div className="form-group">
                        <label>Perhitungan Gaji</label>
                        <select
                            name="divisi_payroll_type"
                            className="form-control col-xl-12 col-md-12"
                            value={this.state.divisi_payroll_type}
                            onChange={(event) => this.handleChange(event)}>
                            <option value="">Pilih Perhitungan Gaji</option>
                            <option value="jam">Jam</option>
                            <option value="hari">Hari</option>
                            <option value="bulan">Bulan</option>
                        </select>
                    </div>
                </Form>
            </Drawer>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add)
