import React from "react";
import { Spin } from "antd";

import InfoIcon from '../../assets/icons/info-icon-blue.svg'
import EmptyDataImage from '../../assets/images/data-kosong.png';
import PlusIcon from '../../assets/icons/plus.svg'
import { Link } from "react-router-dom";


class JamKerjaDivisi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ids: this.props.id_jamkerja
        };
        this.handleCheckboxAdd = this.handleCheckboxAdd.bind(this);
    }

    handleCheckboxAdd(e, id) {
        const name = e.target.name;
        const value = e.target.checked;

        if (name !== "all") {
            if (value) {
                this.props.setState({
                    id_jamkerja: [...this.props.id_jamkerja, id],
                }, () => {
                    this.setState({
                        ids: this.props.id_jamkerja
                    })
                    if (this.props.dataJamkerja.length === this.props.id_jamkerja.length) {
                        this.props.setState({
                            checked_all: true
                        })
                    }
                }
                )
            } else {
                let deleted_value = this.props.id_jamkerja.filter(function (item) {
                    return item !== id
                })
                this.props.setState({
                    id_jamkerja: deleted_value
                }, () => {
                    this.setState({
                        ids: this.props.id_jamkerja
                    })
                    if (this.props.dataJamkerja.length !== this.props.id_jamkerja.length) {
                        this.props.setState({
                            checked_all: false
                        })
                    }
                }
                )
            }
        } else {
            if (value) {
                this.props.dataJamkerja.map((item, index) => {
                    if (!this.props.id_jamkerja.includes(item.sift_id)) {
                        this.setState(prevState => {
                            return {
                                ...prevState,
                                ids: [...prevState.ids, item.sift_id]
                            }
                        }, () => this.props.setState({
                            id_jamkerja: this.state.ids,
                            checked_all: true
                        }))
                    }
                })
            } else {
                this.props.setState({
                    id_jamkerja: [],
                    checked_all: false
                }, () => {
                    this.setState({
                        ids: this.props.id_jamkerja
                    })
                })
            }
        }
    }

    componentDidMount() {
        if (!this.props.modalAdd && this.props.id_jamkerja.length === this.props.dataJamkerja.length) {
            this.props.setState({
                checked_all: true
            })
        }
    }

    render() {
        const style = {
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        return (
            <div className="container-fluid" style={{ padding: "12px 0px" }}>
                {this.props.loadingJamkerja ?
                    <div className="row">
                        <div className="col-md-12" style={{ textAlign: "center" }}>
                            <Spin size="large" />
                        </div>
                    </div>
                    :
                    this.props.dataJamkerja.length === 0 ?
                        <>
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <div style={{ backgroundColor: "#E8F4FF", borderRadius: "6px", padding: "12px" }} className="d-flex">
                                        <img className="mr-2" alt="" src={InfoIcon} />
                                        <div style={{
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            color: "#143B5E"
                                        }}>Silahkan memilih jam kerja yang berlaku pada divisi ini</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ width: "100%" }}>
                                <div className='col' style={{ textAlign: "center" }}>
                                    <img src={EmptyDataImage} alt="icon data kosong" />
                                    <p className='mb-3'>Jam kerja tidak tersedia, silahkan menambahkan jam kerja baru</p>
                                    <Link to={"/guide/jam-kerja"} className="btn batalkan-add-jamker text-capitalize"><img alt="" src={PlusIcon} />Tambahkan Jam Kerja</Link>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <table className="table table-borderless table-striped">
                                <thead>
                                    <tr>
                                        <th style={style.th_style}>
                                            <input type='checkbox' checked={this.props.checked_all} id='all' name='all' onChange={this.handleCheckboxAdd} />
                                        </th>
                                        <th style={style.th_style}>Nama</th>
                                        <th style={style.th_style}>Jadwal Kerja</th>
                                        <th style={style.th_style}>Jam Kerja</th>
                                        <th style={style.th_style}>Toleransi Keterlambatan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.dataJamkerja.map((item, index) => {
                                        return (
                                            <tr>
                                                <td style={style.td_style}>
                                                    <input type='checkbox' name={"row-" + item.sift_id} checked={this.props.id_jamkerja.includes(item.sift_id)} onChange={(e) => this.handleCheckboxAdd(e, item.sift_id)} />
                                                </td>
                                                <td style={style.td_style}>{item.sift_name}</td>
                                                <td style={style.td_style}>{item.jam_kerja.length} hari kerja</td>
                                                <td style={style.td_style}>{item.jamkerja.jam_masuk.slice(0, 5)} - {item.jamkerja.jam_keluar.slice(0, 5)}</td>
                                                <td style={style.td_style}>{item.sift_late_tolerance} menit</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='row'>
                                <div className='col-md-12' style={{ color: "#143B5E", fontSize: "14px", fontWeight: 500 }}>
                                    {this.props.id_jamkerja.length} jam kerja telah terpilih
                                </div>
                            </div>
                        </>
                }
            </div>
        )
    }
}
export default JamKerjaDivisi